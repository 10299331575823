import { AssignOrbPricingPlanTrialRequest } from "api/models/Billing"
import { BaseOrbAdmin } from "api/models/User"
import buildUrl from "api/utils/buildUrl"
import useAuthedApiWhenLoggedIn from "api/utils/useAuthedApiWhenLoggedIn"
import { queryClient } from "queryClient"

import { Query } from "lib/types/Query"

export default function useAdminOrbManagementQueries() {
  const api = useAuthedApiWhenLoggedIn({ baseUrl: buildUrl("/api/v1/admin/orbs") })

  return {
    assignOrbPricingPlanTrialMutation,
    assignOrbPricingPlanExternallyPaidMutation,
    getOrbAdminsQuery,
  }

  function assignOrbPricingPlanTrialMutation() {
    const mutationOptions = {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["showAdminOrbs"])
      },
    }

    return {
      mutationOptions,
      mutationFn: function assignOrbPricingPlan(pricingPlanRequest: AssignOrbPricingPlanTrialRequest) {
        return api.post(`${pricingPlanRequest.orbId}/trial`, { json: pricingPlanRequest }).json()
      },
    }
  }

  function getOrbAdminsQuery(orbId: number): Query<BaseOrbAdmin[]> {
    return {
      queryKey: ["admin", "list-admins", orbId],
      queryFn: function listAdminUsers() {
        return api.get(`${orbId}/admins`).json()
      },
    }
  }

  function assignOrbPricingPlanExternallyPaidMutation() {
    const mutationOptions = {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["showAdminOrbs"])
      },
    }

    return {
      mutationOptions,
      mutationFn: function assignOrbPricingPlan(pricingPlanRequest: AssignOrbPricingPlanTrialRequest) {
        return api.post(`${pricingPlanRequest.orbId}/externally_paid_subscription`, { json: pricingPlanRequest }).json()
      },
    }
  }
}
