import { calculateRequiredPrecision, formatNumber } from "./utils"

export const fontFamily =
  '-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'

export const chart = {
  fontFamily,
  dropShadow: {
    enabled: false,
  },
  zoom: {
    enabled: true,
  },
  toolbar: {
    show: true,
    tools: {
      download: true,
      selection: false,
      zoom: false,
      zoomin: true,
      zoomout: true,
      pan: true,
      reset: false,
    },
  },
}

const legend = {
  fontFamily,
  show: true,
}

const stroke = {
  width: 2,
  curve: "straight",
}

const tooltip = {
  marker: { show: false },
  x: {
    format: "dd MMM, HH:mm",
  },
  y: {
    formatter: xAxisFormatter,
  },
}

export const yaxis = {
  tickAmount: 3,
  labels: {
    show: true,
    formatter: yAxisFormatter,
  },
}

const dataLabels = {
  enabled: false,
  enabledOnSeries: [0],
}

const theme = {
  mode: "light",
  palette: "palette8",
}

const noData = {
  text: "No data available.",
}

function xAxisFormatter(number: number) {
  return formatNumber(number, 10)
}

function yAxisFormatter(number: number) {
  const precision = calculateRequiredPrecision(number)
  return formatNumber(number, precision)
}

export const chartOptions = {
  chart,
  legend,
  stroke,
  tooltip,
  yaxis,
  dataLabels,
  noData,
  theme,
}
