import { Feature } from "@turf/turf"
import dayjs from "dayjs"
import { Map } from "ol"
import { getCenter } from "ol/extent"
import GeoJSON from "ol/format/GeoJSON"
import { Polygon } from "ol/geom"
import { Vector } from "ol/source"
import { getArea } from "ol/sphere"

const dateStringToDate = (value: dayjs.ConfigType, format = "YYYY-MM-DD") => {
  const date = dayjs(value, format).toDate()
  return !isNaN(date.valueOf()) ? date : null
}

const formatMoneyValue = (price: number, currency: string = "USD"): string =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(price)

function currencyToSign(currency: string) {
  return new Intl.NumberFormat("en-US", { style: "currency", currency }).format(0).charAt(0)
}

function getAppType(workflowId?: string): "snap" | "gee" | "openeo" | undefined {
  if (!workflowId) return

  const type = {
    "input-snap": "snap",
    "input-gee": "gee",
    "openeo-gee": "openeo",
  } as const
  const workflowIdPrefix = workflowId.split("-").slice(0, 2).join("-") as keyof typeof type
  return type[workflowIdPrefix]
}

export function featureToVectorSource(feature: Feature) {
  return new Vector({
    features: new GeoJSON().readFeatures(feature, {
      featureProjection: "EPSG:3857",
    }),
  })
}

export function flyToVectorSource(map: Map, vectorSource: Vector, callback?: (finished: boolean) => void) {
  if (!map) return

  const view = map.getView()

  const extent = vectorSource.getExtent()

  const resolution = view.getResolutionForExtent(extent)
  let zoom = view.getZoomForResolution(resolution)

  if (!zoom || zoom > 20) {
    zoom = 20
  }

  var center = getCenter(extent)

  let animateArgs: Array<any> = [
    {
      center: center,
      zoom: zoom - 1,
    },
  ]

  if (callback) {
    animateArgs.push(callback)
  }

  view.animate(...animateArgs)
}

export function formatArea(polygon: Polygon) {
  const area = getArea(polygon)
  let output
  if (area > 10000) {
    output = `${Math.round((area / 1000000) * 100) / 100} km<sup>2</sup>`
  } else {
    output = `${Math.round(area * 100) / 100} m<sup>2</sup>`
  }
  return output
}

export function getPolygonAreaInKm2(polygon: Polygon) {
  const area = getArea(polygon)

  return Math.round((area / 1000000) * 100) / 100
}

function sanitizeFileName(filename: string) {
  const ext = filename.split(".").pop()
  const leftPart = filename.substring(0, filename.lastIndexOf(".")) || filename

  const sanitizedLeftPart = leftPart.replace(/[|&;$*%@"<>()+,. -]/g, "")

  return `${sanitizedLeftPart.toLowerCase()}.${ext}`
}

export { dateStringToDate, getAppType, formatMoneyValue, currencyToSign, sanitizeFileName }
