export const bone = [
  "#000000",
  "#101016",
  "#201F2C",
  "#303043",
  "#404059",
  "#50506F",
  "#5F657F",
  "#707B90",
  "#8091A0",
  "#8FA7AF",
  "#9FBCBF",
  "#B4CFCF",
  "#CEE0DF",
  "#E6EFEF",
  "#FFFFFF",
]
