export const hot = [
  "#0B0000",
  "#3A0000",
  "#690000",
  "#9B0000",
  "#CA0000",
  "#F90000",
  "#FF2A00",
  "#FF5C00",
  "#FF8B00",
  "#FFBA00",
  "#FFE900",
  "#FFFF26",
  "#FFFF71",
  "#FFFFB8",
  "#FFFFFF",
]
