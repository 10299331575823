import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  chakra,
} from "@chakra-ui/react"
import React from "react"
import { HexColorInput, HexColorPicker } from "react-colorful"

import "./ColorPicker.scss"

const ChakraColorInput = chakra(HexColorInput, {
  shouldForwardProp: (prop) => !["color", "setColor", "borderWidth", "borderColor"].includes(prop),
})

const ChakraHexColorPicker = chakra(HexColorPicker, {
  shouldForwardProp: (prop) => !["color", "setColor", "borderWidth", "borderColor"].includes(prop),
})

export default function ColorPicker(props: ColorPickerProps) {
  const { color, setColor } = props

  return (
    <Flex>
      <Popover>
        <Tooltip label="Select color…" shouldWrapChildren>
          <PopoverTrigger>
            <Button
              aria-label={color}
              background={color}
              size="xs"
              rounded="sm"
              borderColor="gray.400"
              borderWidth={1}
            />
          </PopoverTrigger>
        </Tooltip>
        <PopoverContent w="250px">
          <PopoverArrow />
          <PopoverBody flexDirection="column" p={2} justifyContent="center" alignItems="center">
            <ChakraHexColorPicker color={color} onChange={setColor} />
            <ChakraColorInput
              placeholder="Enter color code (HEX)…"
              width="100%"
              fontSize="md"
              p={1}
              color={color}
              textColor="black"
              onChange={setColor}
              borderColor="gray.400"
              borderWidth={1}
              rounded="md"
              my={2}
              mx={0}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  )
}

type ColorPickerProps = {
  color: string
  setColor: (color: string) => void
}
