export const Pastel1 = [
  "#FBB4AE",
  "#B3CDE3",
  "#B3CDE3",
  "#CCEBC5",
  "#CCEBC5",
  "#DECBE4",
  "#DECBE4",
  "#FED9A6",
  "#FFFFCC",
  "#FFFFCC",
  "#E5D8BD",
  "#E5D8BD",
  "#FDDAEC",
  "#FDDAEC",
  "#F2F2F2",
]
