import { useCallback } from "react"
import { useQuery } from "react-query"

import useOpeneoApi from "services/api/openeoApi"

function useCollections() {
  const { listCollections } = useOpeneoApi()
  const { isLoading, isError, data } = useQuery(["listCollections"], listCollections, { staleTime: 60000 })

  const getCollectionById = useCallback(
    (id) => {
      if (!data?.collections) {
        return null
      }

      return data?.collections.find((collection) => collection.id === id)
    },
    [data]
  )

  return { isLoading, isError, collections: data?.collections, getCollectionById }
}

export default useCollections
