import { Badge, Box, HStack, Link } from "@chakra-ui/react"

import { stacProviderType } from "./stacPropTypes"

const STACProvider = ({ provider }) => (
  <Box mb={2}>
    <Link color="brand.700" fontSize="xs" fontWeight="600" href={provider.url}>
      {provider.name}
    </Link>
    <HStack>
      {provider.roles.map((role, index) => (
        <Badge key={index} fontSize="0.45em">
          {role}
        </Badge>
      ))}
    </HStack>
  </Box>
)

STACProvider.propTypes = {
  provider: stacProviderType.isRequired,
}

export default STACProvider
