import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Text,
} from "@chakra-ui/react"
import PropTypes from "prop-types"
import { useState } from "react"

import Markdown from "components/markdown/Markdown"
import {
  allowedOperators,
  buildArguments,
} from "components/openeo-editor/parameters/controls/types/metadata-filter/utils"

export default function MetadataFilterInput(props) {
  const { onSave, properties, useCustomProperties } = props

  const [currentProperty, setCurrentProperty] = useState(null)
  const [currentOperator, setCurrentOperator] = useState("")
  const [currentValue, setCurrentValue] = useState("")

  const canSave = currentProperty && currentOperator && currentValue

  function handleMenuPropertyChange(property) {
    setCurrentProperty(property)
  }

  function handleOperatorChange({ target: { value } }) {
    setCurrentOperator(value)
  }

  function handleValueChange({ target: { value } }) {
    setCurrentValue(value)
  }

  function handleSave() {
    const value = [
      currentProperty.name,
      {
        process_graph: {
          1: {
            process_id: currentOperator,
            arguments: buildArguments(currentProperty.name, currentValue, currentOperator),
            result: true,
          },
        },
      },
    ]
    onSave(value)
  }

  function PropertiesInput() {
    function handleInputChange(event) {
      event.preventDefault()

      const {
        target: { value },
      } = event
      setCurrentProperty({ name: value })
    }

    return (
      <FormControl>
        <Input
          size="xs"
          value={currentProperty?.name || ""}
          placeholder="Enter property name..."
          onChange={handleInputChange}
        />
      </FormControl>
    )
  }

  function PropertiesMenu() {
    return (
      <Menu>
        <MenuButton size="sm" w="full" type="button">
          <Flex
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            px={2}
            py={1}
            rounded="sm"
            borderWidth={1}
            borderColor="gray.200"
          >
            {currentProperty ? (
              <MetadataFilter name={currentProperty.name} description={currentProperty.description} />
            ) : (
              <Text fontSize="xs">Select property...</Text>
            )}
          </Flex>
        </MenuButton>

        <MenuList maxW="sm" maxH="sm" overflowY="scroll">
          {properties?.map((property, index) => {
            function onClick() {
              handleMenuPropertyChange(property)
            }

            return (
              <MenuItem key={index} onClick={onClick}>
                <MetadataFilter name={property.name} description={property.description} />
              </MenuItem>
            )
          })}
        </MenuList>
      </Menu>
    )
  }

  return (
    <Flex w="full" flexDirection="column" pt={2} pb={3}>
      <FormHelperText fontSize="2xs">Property</FormHelperText>
      {useCustomProperties ? <PropertiesInput /> : <PropertiesMenu />}

      <FormControl w="50%">
        <FormHelperText fontSize="2xs">Operator</FormHelperText>
        <Select size="xs" placeholder="Select operator..." value={currentOperator} onChange={handleOperatorChange}>
          {allowedOperators.map((operator) => (
            <option key={operator} value={operator}>
              {operator}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl>
        <FormHelperText fontSize="2xs">Value</FormHelperText>
        <Input size="xs" value={currentValue} onChange={handleValueChange} />
      </FormControl>

      <Flex w="full" py={3}>
        <Button size="xs" variant="secondary" disabled={!canSave} onClick={handleSave} isFullWidth>
          Save
        </Button>
      </Flex>
    </Flex>
  )
}

MetadataFilterInput.propTypes = {
  onSave: PropTypes.func.isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired, description: PropTypes.string })),
  useCustomProperties: PropTypes.bool.isRequired,
}

function MetadataFilter(props) {
  const { name, description } = props

  return (
    <Flex alignItems="flex-start" flexDirection="column">
      <Text fontWeight="semibold" fontSize="xs">
        {name}
      </Text>
      {description && (
        <Markdown flexGrow={0} fontSize="2xs" color="gray.400" overflowX="hidden" textAlign="left">
          {description}
        </Markdown>
      )}
    </Flex>
  )
}

MetadataFilter.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
}
