import { useMutation } from "react-query"

import useWorkflowGraphQueries from "./queryFunctions/useWorkflowGraphQueries"

export default function useResetWorkflowGraph() {
  const { resetWorkflowMutation } = useWorkflowGraphQueries()
  const { mutationFn, mutationOptions } = resetWorkflowMutation()

  const { isLoading, mutateAsync: resetGraph } = useMutation(mutationFn, mutationOptions)

  return {
    isLoading,
    resetGraph,
  }
}
