import { Flex, VStack } from "@chakra-ui/react"
import { FieldArray, useField } from "formik"

import { parameterType, schemaType } from "components/openeo-editor/lib/propTypes"
import InsertPaletteButton from "components/openeo-editor/parameters/controls/InsertPaletteButton"
import {
  BandNameEntry,
  BandNameEntryButton,
} from "components/openeo-editor/parameters/controls/types/array/BandNameEntry"
import {
  AddDefaultEntryButton,
  DefaultEntry,
} from "components/openeo-editor/parameters/controls/types/array/DefaultEntry"

function ArrayPaletteType(props) {
  const { parameter, schema } = props
  const [field, , { setValue }] = useField(parameter.name)

  const isColorPalette = parameter.name === "palette"
  const hasEntries = field.value && field.value.length > 0

  // TODO: Extract selecting entry / button to functio when more types are supported
  const isBandsNameSelector = schema.items?.some(({ type, subtype }) => type === "string" && subtype === "band-name")

  const Entry = isBandsNameSelector ? BandNameEntry : DefaultEntry
  const AddEntryButton = isBandsNameSelector ? BandNameEntryButton : AddDefaultEntryButton

  return (
    <FieldArray
      name={field.name}
      render={({ insert, remove, push }) => (
        <VStack spacing={1} alignItems="flex-start">
          {hasEntries &&
            field.value.map((entry, index) => (
              <Entry
                key={index}
                name={field.name}
                index={index}
                onInsert={insert}
                onRemove={remove}
                isAddingEnabled={schema.maxItems ? field.value.length < schema.maxItems : true}
              />
            ))}
          <Flex w="full">
            {!hasEntries && <AddEntryButton onPush={push} hasEntries={hasEntries} setValue={setValue} />}
            {isColorPalette && <InsertPaletteButton onInsertColorMap={setValue} />}
          </Flex>
        </VStack>
      )}
    />
  )
}

ArrayPaletteType.propTypes = {
  parameter: parameterType.isRequired,
  schema: schemaType.isRequired,
}

export { ArrayPaletteType }
