export const BuGn = [
  "#F7FCFD",
  "#EDF8FB",
  "#E2F4F7",
  "#D3EEEB",
  "#BDE6DE",
  "#A0DBCD",
  "#84CFB9",
  "#65C2A3",
  "#51B689",
  "#3DA96F",
  "#2C9553",
  "#19833E",
  "#05712F",
  "#005B25",
  "#00441B",
]
