export const jet = [
  "#000080",
  "#0000D1",
  "#0010FF",
  "#005CFF",
  "#00A4FF",
  "#06ECF1",
  "#40FFB7",
  "#7DFF7A",
  "#B7FF40",
  "#F1FC06",
  "#FFB900",
  "#FF7700",
  "#FF3000",
  "#D10000",
  "#800000",
]
