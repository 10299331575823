import {
  Box,
  Button,
  Flex,
  Icon,
  Kbd,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import TagManager from "react-gtm-module"
import { MdErrorOutline } from "react-icons/md"
import { VscAdd, VscCheck, VscEye, VscSave } from "react-icons/vsc"
import useGetEmbed from "state/useGetEmbed"

import ToolboxButton from "components/common/toolbox-button/ToolboxButton"
import CodeEditorToolbar from "components/openeo-editor/code-editor/CodeEditorToolbar"
import CodeEditorCloseButton from "components/openeo-editor/code-editor/toolbar/CodeEditorCloseButton"
import CodeEditorSaveButton from "components/openeo-editor/code-editor/toolbar/CodeEditorSaveButton"
import { useOpenEOEditorDrawerState } from "components/openeo-editor/drawer/atoms"
import ConfigureLegendButton from "components/openeo-editor/legend/ConfigureLegendButton"
import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"
import ToggleConsoleButton from "components/orb-openeo-workflow/openeo-workflow-console/ToggleConsoleButton"
import ExecuteWorkflowButton from "components/orb-openeo-workflow/openeo-workflow-toolbox/ExecuteWorkflowButton"
import OpeneoWorkflowToolboxGraphTools from "components/orb-openeo-workflow/openeo-workflow-toolbox/OpeneoWorkflowToolboxGraphTools"
import useOrbConfiguration from "components/orb-workflow/hooks/useOrbConfiguration"

function OpeneoWorkflowToolbox() {
  const { isCodeEditorOpen } = useOpeneoWorkflowContext()
  const isEmbed = useGetEmbed()
  return (
    <Flex
      w="full"
      h={10}
      bgColor="gray.200"
      shadow="base"
      backdropFilter="blur(3px)"
      justifyContent="space-between"
      position="absolute"
      top={0}
      left={0}
      zIndex={5}
    >
      {isCodeEditorOpen ? <CodeEditorToolbox /> : <FlowEditorToolbox />}

      <Flex alignItems="center" justifyContent="center" m={1}>
        <GenericGraphError />

        {!isEmbed && <ConfigureLegendButton />}
        <Spacer w={4} />
        <OpeneoWorkflowToolboxGraphTools />
        <ExecuteWorkflowButton />
        <Spacer w={2} />
        <ToggleConsoleButton />
      </Flex>
    </Flex>
  )
}

function FlowEditorToolbox() {
  const { legends, onSaveGraph, workflows, currentWorkflow, setCurrentWorkflow } = useOpeneoWorkflowContext()
  const { onDrawerOpen } = useOpenEOEditorDrawerState()
  const isEmbed = useGetEmbed()

  function handleGraphSave() {
    onSaveGraph(legends)
  }

  return (
    <Flex alignItems="center" m={1}>
      <Spacer w={4} />
      {workflows?.length > 1 && (
        <>
          <Menu>
            <MenuButton
              as={Button}
              rounded="base"
              colorScheme="gray"
              fontWeight="normal"
              alignItems="center"
              me="-px"
              px={2}
              size="sm"
              shadow="sm"
            >
              Switch workflow
            </MenuButton>
            <MenuList>
              {workflows?.map((workflow) => (
                <MenuItem
                  icon={currentWorkflow?.id === workflow?.id ? <VscCheck /> : null}
                  onClick={() => setCurrentWorkflow(workflow)}
                  key={workflow.id}
                  value={workflow.id}
                >
                  {workflow.buffer_type || "default"}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          <Spacer w={4} />
        </>
      )}
      <ToolboxButton
        data-id="workflow-configurator__open-drawer"
        icon={VscAdd}
        label="Add element"
        tooltipLabel="Add element to the graph"
        onClick={onDrawerOpen}
        isWide
      />
      <Spacer w={4} />
      <ToolboxButton
        icon={VscSave}
        label="Save"
        tooltipLabel={<SaveTooltipLabel />}
        data-testid="save-openeo-graph"
        onClick={handleGraphSave}
        colorScheme="green"
        isWide
      />

      {!isEmbed && <OpenApplicationButton />}
    </Flex>
  )
}

function CodeEditorToolbox() {
  const isEmbed = useGetEmbed()

  return (
    <>
      <Flex alignItems="center" m={1}>
        <CodeEditorCloseButton />
        <Spacer w={4} />
        <CodeEditorSaveButton />
        {!isEmbed && <OpenApplicationButton />}
      </Flex>
      <CodeEditorToolbar />
    </>
  )
}

function SaveTooltipLabel() {
  return (
    <Flex alignItems="center">
      Save (<Kbd color="black">^ + s</Kbd>)
    </Flex>
  )
}

function GenericGraphError() {
  const { currentError } = useOpeneoWorkflowContext()
  const isGenericError = currentError && !currentError?.node_id

  if (!isGenericError) {
    return null
  }

  return (
    <Tooltip
      hasArrow
      bg="red.500"
      label={
        <Box w="full" p={2}>
          <Text fontWeight="bold" fontSize="md" mb={2}>
            {currentError.code}
          </Text>
          <Text>{currentError.message}</Text>
        </Box>
      }
      rounded="md"
    >
      <Box>
        <Icon as={MdErrorOutline} boxSize={6} color="red.500" me={2} />
      </Box>
    </Tooltip>
  )
}

function OpenApplicationButton() {
  const { isLoading, isError, orb } = useOrbConfiguration()
  const isDisabled = isLoading || isError

  function handleAnalytics() {
    const event = "visit_application_click"

    const tagManagerArgs = {
      dataLayer: {
        event,
        [event]: {
          orb_name: orb?.url,
          orb_id: orb?.id && Number(orb.id),
          from: "Workflow preview",
        },
      },
    }

    TagManager.dataLayer(tagManagerArgs)
  }

  return (
    <ToolboxButton
      as="a"
      href={`https://${orb?.url}.${process.env.REACT_APP_BASE_DOMAIN}/`}
      target="_blank"
      icon={VscEye}
      isWide
      label="View as user"
      tooltipLabel="View application as user"
      onClick={handleAnalytics}
      disabled={isDisabled}
      ms={8}
    />
  )
}

export default OpeneoWorkflowToolbox
