import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  chakra,
} from "@chakra-ui/react"
import { Formik } from "formik"
import { InputControl } from "formik-chakra-ui"
import { AiOutlinePlus } from "react-icons/ai"

import useAdminOrganisationsAddNewController, {
  initialValues,
  validationSchema,
} from "./adminOrganisationsAddNewController"

export default function AdminOrganisationsAddNew() {
  const { isOpen, onOpen, onClose, isLoading, handleSubmit } = useAdminOrganisationsAddNewController()

  return (
    <>
      <Button size="sm" colorScheme="primary" leftIcon={<AiOutlinePlus />} onClick={onOpen}>
        Add new organisation
      </Button>
      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {(formik) => (
            <chakra.form onSubmit={formik.handleSubmit}>
              <ModalContent>
                <ModalCloseButton size="sm" />
                <ModalHeader>Add new organisation</ModalHeader>
                <ModalBody>
                  <InputControl name="name" label="Organisation name" inputProps={{ size: "sm", rounded: "md" }} />
                </ModalBody>
                <ModalFooter>
                  <Button size="sm" mr={3} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button size="sm" type="submit" colorScheme="primary" isLoading={isLoading}>
                    Create
                  </Button>
                </ModalFooter>
              </ModalContent>
            </chakra.form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
