import { Flex } from "@chakra-ui/react"
import useGetEmbed from "state/useGetEmbed"

import LoadingOverlay from "components/common/loading-overlay/LoadingOverlay"
import { MapContainer } from "components/common/open-layers/MapContextProvider"
import { MapboxVectorLayer } from "components/common/open-layers/layers"
import Legend from "components/openeo-editor/legend/Legend"
import WorkflowCharts from "components/orb-openeo-workflow/openeo-workflow-charts/WorkflowCharts"
import OpeneoWorkflowDrawControls from "components/orb-openeo-workflow/openeo-workflow-preview-map-ol/OpeneoWorkflowDrawControls"
import useOpeneoPreviewMapController from "components/orb-openeo-workflow/openeo-workflow-preview-map/openeoWorkflowPreviewMapController"

import OpeneoWorkflowMapToolbox from "../openeo-workflow-map-toolbox/OpeneoWorkflowMapToolbox"
import AreasOfInterestSavedMapLayers from "./AreasOfInterestSavedMapLayers"
import OpeneoWorkflowLayers from "./OpeneoWorkflowLayers"

export default function OpeneoWorkflowPreviewMap() {
  const { isLoading, accessToken } = useOpeneoPreviewMapController()
  const isEmbed = useGetEmbed()

  return (
    <Flex id="openeo-preview-map__wrapper" w="full" h="full">
      <LoadingOverlay isActive={isLoading} />
      <MapContainer>
        <Layers accessToken={accessToken} />
        {!isEmbed && <Legend />}
        <WorkflowCharts />
        <Controls />
      </MapContainer>
    </Flex>
  )
}

function Layers(props) {
  return (
    <>
      <MapboxVectorLayer />
      <AreasOfInterestSavedMapLayers />
      <OpeneoWorkflowLayers accessToken={props.accessToken} />
    </>
  )
}

function Controls() {
  return (
    <>
      <OpeneoWorkflowDrawControls />
      <OpeneoWorkflowMapToolbox />
    </>
  )
}
