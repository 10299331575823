import { Box, Flex, Icon, Spinner, Text, Tooltip } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { memo } from "react"
import { Handle, Position } from "react-flow-renderer"
import { MdErrorOutline } from "react-icons/md"

import handleStyle from "components/openeo-editor/lib/handleStyle"
import { processDataType } from "components/openeo-editor/lib/propTypes"
import useConnectionValidator from "components/openeo-editor/lib/useConnectionValidator"
import useProcesses from "components/openeo-editor/lib/useProcesses"
import CodeEditorButton from "components/openeo-editor/node-types/CodeEditorButton"
import NodeRemoveButton from "components/openeo-editor/node-types/NodeRemoveButton"
import Parameters from "components/openeo-editor/parameters/Parameters"
import Process from "components/openeo-editor/process/Process"

function ProcessNode(props) {
  const { data, selected } = props
  const { isLoading } = useProcesses()
  const { isValidConnection } = useConnectionValidator()

  const nodeBgColor = data.categories.includes("input") ? "purple" : "green"
  const bgColor = !!data.error ? "red" : nodeBgColor

  if (isLoading) {
    return <ProcessNodeLoadingSpinner />
  }

  return (
    <Flex
      w={72}
      bg={`${bgColor}.${selected ? 100 : 50}`}
      borderColor={`${bgColor}.${selected ? 600 : 100}`}
      borderWidth={1}
      fontSize="xs"
      p={1}
      shadow="sm"
      rounded="md"
      flexDirection="column"
      role="group"
      _hover={{ shadow: "md" }}
    >
      <ProcessNodeError error={data.error} />
      <NodeRemoveButton data={data} />
      <Handle type="source" position={Position.Right} style={handleStyle} isValidConnection={isValidConnection} />
      <Process process={data} />
      <Parameters data={data} />
      <CodeEditorButton data={data} />
    </Flex>
  )
}

ProcessNode.propTypes = {
  data: processDataType.isRequired,
  selected: PropTypes.bool,
}

function ProcessNodeLoadingSpinner() {
  return (
    <Flex
      w={72}
      bg="green.50"
      borderColor="green.100"
      borderWidth={1}
      p={1}
      shadow="md"
      rounded="md"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner />
    </Flex>
  )
}

function ProcessNodeError(props) {
  const { error } = props

  if (!error) {
    return null
  }

  return (
    <Flex position="absolute" top={3} right={3}>
      <Tooltip
        hasArrow
        bg="red.500"
        label={
          <Box w="full" p={2}>
            <Text fontWeight="bold" fontSize="md" mb={2}>
              {error.code}
            </Text>
            <Text>{error.message}</Text>
          </Box>
        }
        rounded="md"
      >
        <Box>
          <Icon as={MdErrorOutline} boxSize={4} color="red.500" />
        </Box>
      </Tooltip>
    </Flex>
  )
}

ProcessNodeError.propTypes = {
  error: PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.string,
  }),
}

export default memo(ProcessNode)
