import OLVectorLayer from "ol/layer/Vector"
import PropTypes from "prop-types"
import { useEffect } from "react"

import { useMapContext } from "../MapContextProvider"

export default function VectorLayer({ className = "ol-vector-Layer", source, style, zIndex = 0 }) {
  const { map } = useMapContext()

  useEffect(() => {
    if (!map) return

    let vectorLayer = new OLVectorLayer({
      className,
      source,
      style,
      zIndex,
    })

    map.addLayer(vectorLayer)

    return () => {
      if (map) {
        map.removeLayer(vectorLayer)
      }
    }
  }, [className, map, source, style, zIndex])

  return null
}

VectorLayer.propTypes = {
  className: PropTypes.string,
  source: PropTypes.object.isRequired,
  style: PropTypes.object,
  zIndex: PropTypes.number,
}
