import { MutationCache, QueryCache, QueryClient } from "react-query"

import displayServerError from "./services/displayServerError"

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => displayServerError(error as string | Error),
  }),
  mutationCache: new MutationCache({
    onError: (error) => displayServerError(error as string | Error),
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (count, error) => {
        return ![406, 404, 403].includes((error as any).response?.status) && count < 2
      },
    },
  },
})
