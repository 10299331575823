import dayjs from "dayjs"
import { useField } from "formik"
import PropTypes from "prop-types"
import { useEffect, useMemo } from "react"

import DatesRangePicker from "components/common/dates-range-picker/DatesRangePicker"
import { dateStringToDate } from "lib/utils"

function DateRangeSelector(props) {
  const { name, onPush, onPop, entries } = props
  const [startDateField, , { setValue: setStartValue }] = useField(`${name}.0`)
  const [endDateField, , { setValue: setEndValue }] = useField(`${name}.1`)

  const dateRange = useMemo(() => {
    return {
      from: dateStringToDate(startDateField?.value),
      to: dateStringToDate(endDateField?.value),
    }
  }, [endDateField?.value, startDateField?.value])

  const onDateRangeChange = (range) => {
    setStartValue(dayjs(range.from).format("YYYY-MM-DD"))
    setEndValue(dayjs(range.to).format("YYYY-MM-DD"))
  }

  useEffect(() => {
    // make sure the number of fields is two
    const entries_diff = Math.abs(2 - entries.length)
    for (let i = 0; i < entries_diff; i++)
      if (entries.length > 2) onPop()
      else onPush("")
  }, [entries, onPop, onPush])

  return (
    <>
      {entries.length === 2 && (
        <DatesRangePicker
          inputFormat={{ size: "xs" }}
          range={dateRange}
          onRangeChange={(range) => onDateRangeChange(range)}
          nYears={30}
        />
      )}
    </>
  )
}

DateRangeSelector.propTypes = {
  name: PropTypes.string.isRequired,
  onPush: PropTypes.func.isRequired,
  onPop: PropTypes.func.isRequired,
  entries: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export { DateRangeSelector }
