import { CustomProcess, CustomProcessCreateRequest } from "api/models/CustomProcess"
import buildUrl from "api/utils/buildUrl"
import { useQueryClient } from "react-query"

import { Mutation } from "lib/types/Mutation"
import { Query } from "lib/types/Query"
import useAuthenticatedApi from "services/api/authenticatedApi"

export default function useAdminCustomProcessesQueries() {
  const api = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1/admin/openeo") })
  const queryClient = useQueryClient()

  return {
    listCustomProcessesQuery,
    listActiveBranchesQuery,
    createCustomProcessesMutation,
    updateCustomProcessesMutation,
    deleteCustomProcessesMutation,
    refreshCustomProcessesMutation,
  }

  function listCustomProcessesQuery(): Query<CustomProcess[]> {
    return {
      queryKey: ["listAdminCustomProcesses"],
      queryFn: function listCustomProcesses() {
        return api.get("custom_processes").json()
      },
    }
  }

  function listActiveBranchesQuery(): Query<string[]> {
    return {
      queryKey: ["listAdminCustomProcesses", "active-branches"],
      queryFn: function listCustomProcesses() {
        return api.get("custom_processes/active_branches").json()
      },
    }
  }

  function createCustomProcessesMutation(): Mutation<CustomProcess> {
    return {
      mutationOptions: {
        onSuccess: () => queryClient.invalidateQueries(["listAdminCustomProcesses"]),
      },
      mutationFn: function createCustomProcess(customProcess: CustomProcessCreateRequest) {
        return api.post("custom_processes", { json: customProcess }).json()
      },
    }
  }

  function updateCustomProcessesMutation(): Mutation<CustomProcess> {
    return {
      mutationOptions: {
        onSuccess: () => queryClient.invalidateQueries(["listAdminCustomProcesses"]),
      },
      mutationFn: function updateCustomProcess(customProcess: CustomProcessCreateRequest) {
        return api.post(`custom_processes/${customProcess.process_id}`, { json: customProcess }).json()
      },
    }
  }

  function deleteCustomProcessesMutation(): Mutation<CustomProcess> {
    return {
      mutationOptions: {
        onSuccess: () => queryClient.invalidateQueries(["listAdminCustomProcesses"]),
      },
      mutationFn: function createCustomProcess(process_id: string) {
        return api.delete(`custom_processes/${process_id}`).json()
      },
    }
  }

  function refreshCustomProcessesMutation(): Mutation<CustomProcess> {
    return {
      mutationOptions: {
        onSuccess: () => queryClient.invalidateQueries(["listAdminCustomProcesses"]),
      },
      mutationFn: function createCustomProcess(process_id: string) {
        return api.post(`custom_processes/${process_id}/refresh`).json()
      },
    }
  }
}
