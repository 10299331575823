import { Badge, Box } from "@chakra-ui/react"
import useGetAllowedBIllingFeatures from "api/useGetAllowedBIllingFeatures"
import { usePageTitle, withRoleRequired } from "hooks"
import { useParams } from "react-router-dom"

import AccessControlTabs from "components/common/access-control/AccessControlTabs"
import AddUserButton from "components/common/access-control/AddUserButton"
import Section from "components/layout/section/Section"
import SectionHeader from "components/layout/section/SectionHeader"
import { ORBIFY_USER_PERMISSION } from "services/userPermissions"

function OrbAccessControl() {
  usePageTitle("Application Dashboard - Access Control")
  const { id } = useParams()
  const { allowedToManageAppUsers } = useGetAllowedBIllingFeatures()

  return (
    <Box p={4}>
      {!allowedToManageAppUsers && (
        <Badge position="absolute" colorScheme="red" rounded="base" fontSize="2xs">
          Premium
        </Badge>
      )}
      <Section
        header={
          <SectionHeader
            title="Access control"
            description="Use access control to view, invite and manage users of your application"
          >
            <AddUserButton />
          </SectionHeader>
        }
      >
        <AccessControlTabs id={id} />
      </Section>
    </Box>
  )
}

export default withRoleRequired(OrbAccessControl, ORBIFY_USER_PERMISSION)
