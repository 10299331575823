import { Flex, Heading, LinkBox, LinkOverlay } from "@chakra-ui/react"
import useAssignPricingPlanToOrb from "api/useAssignPricingPlanToOrb"

import { useNotifications } from "components/common/notifications"

export default function NoPricingPlan(props: NoPricingPlanProps) {
  const { isCurrent = false, onSelected } = props
  const { isLoading, assignPricingPlan } = useAssignPricingPlanToOrb()
  const { showErrorNotification } = useNotifications()

  const flexStyle = isCurrent
    ? { borderColor: "green.200" }
    : {
        borderColor: "primary.100",
        _hover: { shadow: "md" },
      }
  const overlayProps = isCurrent ? {} : { href: "#", onClick: handleClick }

  async function handleClick(event: any) {
    event.preventDefault()

    if (isLoading) {
      return
    }

    try {
      await assignPricingPlan({ id: null })
    } catch (error: any) {
      if (error.response.status !== 409) showErrorNotification(error, "Failed to select pricing plan")
    } finally {
      onSelected && onSelected()
    }
  }

  return (
    <Flex w="full" maxW="2xl">
      <LinkBox w="full">
        <Flex w="full" maxW="2xl" p={4} justifyContent="space-between" rounded="md" borderWidth={1} {...flexStyle}>
          <Flex w="full" me={6} alignItems="flex-start" flexDirection="column">
            <Heading w="full" fontSize="xl" color="primary.900">
              <LinkOverlay {...overlayProps}>No plan</LinkOverlay>
            </Heading>
          </Flex>
        </Flex>
      </LinkBox>
    </Flex>
  )
}

type NoPricingPlanProps = {
  isCurrent?: boolean
  onSelected?: () => void
}
