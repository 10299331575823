export const YlOrBr = [
  "#FFFFE5",
  "#FFFACE",
  "#FFF4B6",
  "#FEE89D",
  "#FEDA7E",
  "#FEC859",
  "#FEB23F",
  "#FE9829",
  "#F4811D",
  "#E76B11",
  "#D55607",
  "#BE4503",
  "#A03704",
  "#832D05",
  "#662506",
]
