import { useMutation, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import { useNotifications } from "components/common/notifications"
import useOrbUsersApi from "services/api/orbUsers"
import Rollbar from "services/rollbar"

const useInvitationSender = () => {
  const { id } = useParams()
  const { showNotification, showErrorNotification } = useNotifications()

  const queryClient = useQueryClient()
  const { invite } = useOrbUsersApi()
  const { mutateAsync } = useMutation(invite.bind(null, id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries("listOrbUsers")
      await queryClient.invalidateQueries(["countOrbInvitations", id])
    },
    retry: false,
  })
  const onSubmit = (values, onSuccess = null, onError = null) => {
    if (!values.id) values = { ...values, id }
    mutateAsync(values)
      .then(({ invitation_sent, reason }) => {
        if (invitation_sent) {
          showNotification("Invitation sent", `Invited ${values.email}`, "success")
        } else {
          showNotification("Could not send invitation", reason, "error")
          Rollbar.error(`Could not send invitation: ${reason}`)
        }
        if (onSuccess) onSuccess()
      })
      .catch((error) => {
        showErrorNotification(error, "Failed to send invitation")
        if (onError) onError()
      })
  }

  return {
    onSubmitInvitation: onSubmit,
  }
}

export default useInvitationSender
