import { Badge, Flex, Text, VStack } from "@chakra-ui/react"
import { createColumnHelper } from "@tanstack/react-table"
import { CustomProcess } from "api/models/CustomProcess"
import useAdminGetCustomProcesses from "api/useAdminGetCustomProcesses"

import { LoadingSpinner } from "components/common"
import { DataTable } from "components/common/data-table/DataTable"
import withRoleRequired from "hooks/withRoleRequired"
import { ORBIFY_ADMIN_PERMISSION } from "services/userPermissions"

import CustomProcessesActions from "./CustomProcessesActions"

function CustomProcessesList() {
  const { isLoading, customProcesses } = useAdminGetCustomProcesses()

  const columnHelper = createColumnHelper<CustomProcess>()

  const columns = [
    columnHelper.accessor("process_id", {
      header: "Process ID",
      cell: (props) => (
        <VStack alignItems="flex-start">
          <Text fontWeight="bold">{props.getValue()}</Text>
          <Text fontSize="xs" fontFamily="monospace">
            {props.row.original.remote_access.base_path}
          </Text>
        </VStack>
      ),
    }),
    columnHelper.accessor("remote_access", {
      id: "branch",
      header: "Branch",
      cell: (props) => (
        <Badge fontSize="2xs" color="green" variant="outline">
          {props.getValue().branch}
        </Badge>
      ),
    }),
    columnHelper.accessor("schema", {
      id: "actions",
      header: "",
      enableSorting: false,
      cell: (props) => <CustomProcessesActions customProcess={props.row.original} />,
    }),
  ]

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Flex my={10}>
      <DataTable
        data={customProcesses}
        columns={columns}
        sortingState={[{ id: "process_id", desc: true }]}
        size="sm"
        boxShadow="0 0 0 1px var(--chakra-colors-gray-200)"
        rounded="base"
      />
    </Flex>
  )
}

export default withRoleRequired(CustomProcessesList, ORBIFY_ADMIN_PERMISSION)
