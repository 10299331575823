import { collectionType } from "components/openeo-editor/lib/propTypes"

function getBandsInfo(collection) {
  const summaries = collection.summaries || {}
  const bands = summaries["eo:bands"] || []

  const bandsList = bands.reduce((obj, band) => {
    obj.push(buildEntry(band))
    return obj
  }, [])

  function buildEntry(band) {
    let entry = { name: band.name }

    const units = band["gee:units"]
    if (units) {
      entry = { ...entry, units }
    }

    const minimumMaximum = summaries[band.name]
    if (minimumMaximum) {
      const { minimum: min, maximum: max } = minimumMaximum
      entry = { ...entry, min, max }
    }

    const pixelSize = band.gsd
    if (pixelSize) {
      entry = { ...entry, "pixel size": `${pixelSize} m` }
    }

    const wavelength = band["gee:wavelength"]
    if (wavelength) {
      entry = { ...entry, wavelength }
    }

    entry.description = band.description

    return entry
  }

  const availableColumns = ["name", "units", "min", "max", "pixel size", "wavelength", "description"]
  const existingColumns = [...new Set(bandsList.map((entry) => Object.keys(entry)).flat())]
  const columns = availableColumns.filter((column) => existingColumns.includes(column))

  return {
    columns,
    bandsList,
  }
}

getBandsInfo.propTypes = {
  collection: collectionType.isRequired,
}

function getProperties(collection) {
  const summaries = collection.summaries || {}
  return summaries["gee:schema"] || []
}

getProperties.propTypes = {
  collection: collectionType.isRequired,
}

export { getBandsInfo, getProperties }
