import { Flex, Spinner } from "@chakra-ui/react"
import ReactFlow, { Controls, MiniMap } from "react-flow-renderer"

import CodeEditor from "components/openeo-editor/code-editor/CodeEditor"
import edgeTypes from "components/openeo-editor/edge-types/edgeTypes"
import useFlowEditorController from "components/openeo-editor/flow/flowEditorController"
import nodeTypes from "components/openeo-editor/node-types/nodeTypes"
import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"

import "./FlowEditor.scss"

function FlowEditor() {
  const { onLoad, onDragOver, onDrop, onConnect } = useFlowEditorController()
  const { elements, currentError, isCodeEditorOpen } = useOpeneoWorkflowContext()

  return (
    <Flex flexGrow={1} position="relative">
      <AddingElementIndicator />
      {isCodeEditorOpen ? (
        <CodeEditor />
      ) : (
        <ReactFlow
          className="no-code-editor"
          snapToGrid={true}
          snapGrid={[10, 10]}
          elements={elements}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onLoad={onLoad}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onConnect={onConnect}
          style={{ backgroundColor: currentError ? "var(--chakra-colors-red-50)" : "white" }}
        >
          <MiniMap />
          <Controls showInteractive={false} />
        </ReactFlow>
      )}
    </Flex>
  )
}

function AddingElementIndicator() {
  const { isCodeEditorOpen, isAddingElement } = useOpeneoWorkflowContext()

  if (isCodeEditorOpen || !isAddingElement) {
    return null
  }

  return (
    <Flex
      w="full"
      h="full"
      position="absolute"
      bgColor="blackAlpha.400"
      justifyContent="flex-end"
      p={2}
      top={0}
      left={0}
      zIndex={10}
      _hover={{ cursor: "not-allowed" }}
    >
      <Spinner size="lg" thickness="7px" color="whiteAlpha.500" />
    </Flex>
  )
}

export default FlowEditor
