import { useQuery } from "react-query"

import useAdminCustomProcessesQueries from "./queryFunctions/useAdminCustomProcessesQueries"

export default function useAdminGetCustomProcesses() {
  const { listCustomProcessesQuery } = useAdminCustomProcessesQueries()
  const { queryKey, queryFn } = listCustomProcessesQuery()

  const { isLoading, isError, data } = useQuery(queryKey, queryFn)

  const customProcesses = data || []
  return {
    isLoading,
    isError,
    customProcesses,
  }
}
