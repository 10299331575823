export const turbo = [
  "#30123B",
  "#4146AC",
  "#4776EE",
  "#38A5FB",
  "#1BD0D5",
  "#25ECA7",
  "#61FC6C",
  "#A4FC3C",
  "#D2E935",
  "#F4C73A",
  "#FE9B2D",
  "#F46617",
  "#DA3907",
  "#B21A01",
  "#7A0403",
]
