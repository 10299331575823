import { useAuth0 } from "@auth0/auth0-react"
import { Flex } from "@chakra-ui/react"
import { useRollbar } from "@rollbar/react"
import dayjs from "dayjs"
import mixpanel from "mixpanel-browser"
import { ReactNode } from "react"
import TagManager from "react-gtm-module"
import { useLocation } from "react-router-dom"
import useGetEmbed from "state/useGetEmbed"

import { useDecodedAuthToken } from "services/useDecodedAuthToken"
import { isDev } from "services/utils"

import LayoutSidebar from "./LayoutSidebar"

const suffix = isDev() ? ` - ${process.env.REACT_APP_ENVIRONMENT}` : ""
const application = `Orbify - Application management dashboard${suffix}`

export default function Layout(props: { children: ReactNode }) {
  const { isAuthenticated } = useAuth0()
  const isEmbed = useGetEmbed()
  const { pathname } = useLocation()
  const { children } = props
  const shouldShowSidebar = isAuthenticated && !isEmbed && !pathname.startsWith("/business_email_required")

  useConfigureRollbar()
  useConfigureDataLayer()
  useConfigureLinkedInInsight()
  useConfigureMixpanel()

  return (
    <Flex className="app">
      {shouldShowSidebar && <LayoutSidebar />}
      <MainArea>{children}</MainArea>
    </Flex>
  )
}

function MainArea(props: { children: ReactNode }) {
  const { children } = props

  return (
    <Flex data-id="main__content" w="full" flexDirection="column" flexWrap="wrap" h="100vh">
      {children}
    </Flex>
  )
}

function useConfigureRollbar() {
  const { user } = useAuth0()
  const Rollbar = useRollbar()

  // Only send a maximum of 10 items to Rollbar per page load
  Rollbar.global({ maxItems: 10, itemsPerMinute: 5 })

  if (!user) {
    return
  }

  Rollbar.configure({
    captureIp: "anonymize",
    payload: {
      person: {
        id: user.sub || null,
      },
    },
  })
}

function useConfigureDataLayer() {
  const { user } = useAuth0()

  if (!user) return

  const tagManagerArgs = {
    dataLayer: {
      userId: user?.sub,
      project: "Corvus",
      application,
      is_orbify_email: shouldStopTrackingOrbifyEmailsInProd(user?.email),
      environment: process.env.REACT_APP_ENVIRONMENT,
    },
  }

  TagManager.dataLayer(tagManagerArgs)
}

function useConfigureLinkedInInsight() {
  const { isLoading, createdAt } = useDecodedAuthToken()
  const conversionId = process.env.REACT_APP_LINKED_IN_INSIGHT_CONVERSION_ID

  if (isDev() || isLoading || !createdAt || !conversionId) {
    return null
  }

  const now = dayjs().unix() * 1000
  const isNewAccount = now - Date.parse(createdAt) < 60 * 1000

  // @ts-ignore
  const linkedInTracker = window.lintrk

  if (isNewAccount && !!linkedInTracker) {
    linkedInTracker("track", { conversion_id: conversionId })
  }
}

function useConfigureMixpanel() {
  const { user } = useAuth0()

  if (!user) return

  mixpanel.register({
    application,
    environment: process.env.REACT_APP_ENVIRONMENT,
    is_orbify_email: shouldStopTrackingOrbifyEmailsInProd(user?.email),
  })
  mixpanel.identify(user?.sub)
  mixpanel.people.set({
    $name: user.name,
    $phone_number: user.phone_number,
    $email: user.email,
    $avatar: user.picture,
  })
}

function shouldStopTrackingOrbifyEmailsInProd(email?: string) {
  return email?.includes("@orbify") && !isDev()
}
