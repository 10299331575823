import { Flex } from "@chakra-ui/react"

import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"
import OpenEOEditor from "components/openeo-editor/OpenEOEditor"
import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"

function OpeneoWorkflowEditor() {
  const { isGraphLoading } = useOpeneoWorkflowContext()

  if (isGraphLoading) {
    return (
      <Flex w="full" h="full" alignItems="center" justifyContent="center">
        <LoadingSpinner />
      </Flex>
    )
  }

  return <OpenEOEditor />
}

export default OpeneoWorkflowEditor
