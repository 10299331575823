import { apply } from "ol-mapbox-style"
import { TileJSON, VectorTile } from "ol/source"
import VectorSource from "ol/source/Vector"
import PropTypes from "prop-types"
import { useEffect } from "react"

import { useMapContext } from "../MapContextProvider"

export default function MapboxVectorLayer({ style = "mapbox://styles/mapbox/outdoors-v11", zIndex = 0 }) {
  // TODO: We should consider to move to Mapbox GL JS https://docs.mapbox.com/mapbox-gl-js/api/ instead of using
  //  a 2D canvas. OpenLayers seems to have some support for integrating MapboxGL - though this is an experimental feature for now
  //  https://openlayers.org/en/latest/examples/mapbox-layer.html
  const { map } = useMapContext()

  useEffect(() => {
    if (!map) return

    apply(map, style, {
      accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
    }).then((map) => {
      map
        .getLayers()
        .getArray()
        .forEach(function (layer) {
          layer.set("updateWhileAnimating", true)
          layer.set("updateWhileInteracting", true)

          const source = layer.getProperties().source

          if (source instanceof TileJSON) {
            source.setTileUrlFunction(mapboxTileUrl)
            // tiles is typed as `string[]` but Mapbox's tileJSON tiles contain `string``
            const tilesUrlTemplate = source.getTileJSON().tiles

            function mapboxTileUrl(tileCoords) {
              return tilesUrlTemplate
                .replace("{a-d}.tiles", "api")
                .replace("{z}/{x}/{y}", tileCoords.join("/"))
                .replace(".vector.pbf", "@2x.webp")
            }
          } else if (source instanceof VectorSource || source instanceof VectorTile) {
            // Add default Mapbox attributions in VectorSources
            source.setAttributions([
              '© <a href="https://www.mapbox.com/map-feedback/">Mapbox</a> ',
              '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>',
            ])
          }
        })
    })
  }, [map, style, zIndex])
  return null
}

MapboxVectorLayer.propTypes = {
  source: PropTypes.object,
  style: PropTypes.object,
  zIndex: PropTypes.number,
}
