export const nipy_spectral = [
  "#000000",
  "#7E008F",
  "#1800A7",
  "#0025DD",
  "#0090DD",
  "#00AAA5",
  "#00A13D",
  "#00BC00",
  "#00EC00",
  "#A1FF00",
  "#F3E500",
  "#FFA900",
  "#F90000",
  "#D30000",
  "#CCCCCC",
]
