import { Flex, HStack, Heading, Link, LinkBox, LinkOverlay, VStack } from "@chakra-ui/react"
import { OrbConfig } from "api/models/OrbConfig"

import OrbLogo from "components/orb/orb-logo/OrbLogo"

export function ApplicationCard({ orb, isSmall = false }: Props) {
  const href = `https://${orb.url}.${process.env.REACT_APP_BASE_DOMAIN}/`

  return (
    <Flex p={3} flexGrow={0} role="group">
      <LinkBox as={Flex} variant="ghost" w="full">
        {isSmall ? (
          <HStack
            w="full"
            p={1}
            justifyContent="flex-start"
            bgColor="whiteAlpha.800"
            shadow="md"
            rounded="md"
            _groupHover={{ shadow: "xs" }}
          >
            <OrbLogo orb={orb} size="xs" />
            <LinkOverlay as={Link} href={href} fontSize="xs" _hover={{ textDecoration: "none" }}>
              {orb.name}
            </LinkOverlay>
          </HStack>
        ) : (
          <VStack w="full" justifyContent="center" spacing={6}>
            <OrbLogo orb={orb} size="xl" shadow="lg" _groupHover={{ shadow: "xs" }} />

            <Flex flexDirection="column">
              <Heading fontSize="md" fontWeight="semibold" color="gray.500">
                <LinkOverlay as={Link} href={href} _hover={{ textDecoration: "none" }}>
                  {orb.name}
                </LinkOverlay>
              </Heading>
            </Flex>
          </VStack>
        )}
      </LinkBox>
    </Flex>
  )
}

interface Props {
  orb: OrbConfig
  isSmall?: boolean
}
