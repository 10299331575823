import { Alert, AlertIcon, AlertTitle, Skeleton, chakra } from "@chakra-ui/react"
import { OpenEOJobResultAsset } from "api/models/OpenEO"
import useGetChartFeatures from "api/useGetChartFeatures"
import ApexChart from "react-apexcharts"

import { chart, chartOptions, fontFamily, yaxis } from "./chartConfig"
import { convertCSVFeaturesToApexChartData } from "./converter"
import { calculateRequiredPrecision, ceilNumber, floorNumber } from "./utils"

const ChakraChart = chakra(ApexChart, {
  shouldForwardProp: () => true,
})

export default function WorkflowChart(props: WorkflowChartProps) {
  const { asset } = props
  const { chart_configuration = {} } = asset.metadata || {}

  const { isLoading, isError: isFeaturesLoadingError, features } = useGetChartFeatures(asset.href)
  const {
    isError: isFeaturesConversionError,
    series,
    xAxisType,
  } = convertCSVFeaturesToApexChartData(features, chart_configuration)
  const isError = isFeaturesLoadingError || isFeaturesConversionError

  function min(number: number) {
    return floorNumber(number, calculateRequiredPrecision(number))
  }

  function max(number: number) {
    return ceilNumber(number, calculateRequiredPrecision(number))
  }

  return (
    <Skeleton w="full" h="250px" isLoaded={!isLoading}>
      {isError ? (
        <WorkflowChartError />
      ) : (
        <ChakraChart
          width="100%"
          height="250px"
          type={chart_configuration.chart_type || "line"}
          options={{
            ...chartOptions,
            chart,
            title: {
              text: asset.title,
            },
            xaxis: { type: xAxisType, labels: { datetimeUTC: true } },
            yaxis: {
              ...yaxis,
              title: {
                text: chart_configuration.unit_name || "",
                offsetX: 25,
                style: { fontFamily, fontSize: "16px", fontWeight: 400 },
              },
              min,
              max,
            },
          }}
          series={series}
        />
      )}
    </Skeleton>
  )
}

function WorkflowChartError() {
  return (
    <Alert
      w="full"
      h="250px"
      rounded="md"
      variant="subtle"
      status="error"
      justifyContent="center"
      flexDirection="column"
    >
      <AlertIcon boxSize={12} m={4} />
      <AlertTitle fontSize="lg">Failed to build chart</AlertTitle>
    </Alert>
  )
}

type WorkflowChartProps = {
  asset: OpenEOJobResultAsset
}
