export const GnBu = [
  "#F7FCF0",
  "#EAF7E4",
  "#DDF2D8",
  "#D1EDCB",
  "#C2E7C0",
  "#ADDFB7",
  "#95D6BB",
  "#7ACCC4",
  "#61BDCD",
  "#49ADD0",
  "#3597C4",
  "#2182B9",
  "#0D6DAE",
  "#085799",
  "#084081",
]
