import { Button, Flex, useDisclosure } from "@chakra-ui/react"
import useGetAllowedBIllingFeatures from "api/useGetAllowedBIllingFeatures"
import PropTypes from "prop-types"
import { IoMail } from "react-icons/io5"

import PremiumButtonIndicator from "components/premium-indicators/PremiumButtonIndicator"

import InvitationModal from "./InvitationModal"

export default function AddUserButton({ id }) {
  const invitationModal = useDisclosure()
  const { allowedToManageAppUsers } = useGetAllowedBIllingFeatures()

  return (
    <Flex w="full" justifyContent="flex-end" mb={4}>
      <PremiumButtonIndicator
        isPremium={!allowedToManageAppUsers}
        info="Your current plan does not allow managing users"
        wrapperOptions={{ w: "full" }}
      >
        <Button
          variant="solid"
          colorScheme="primary"
          leftIcon={<IoMail />}
          onClick={invitationModal.onOpen}
          isFullWidth
        >
          Invite user
        </Button>
      </PremiumButtonIndicator>
      <InvitationModal {...invitationModal} id={id} />
    </Flex>
  )
}

AddUserButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
