import { BiChevronRightCircle } from "react-icons/bi"

import SidebarItem from "components/layout/sidebar/SidebarItem"

import { useSidebarContext } from "./SidebarContextProvider"

const SidebarToggleButton = () => {
  const { isOpen, onToggle } = useSidebarContext()
  const label = `${isOpen ? "Collapse" : "Expand"} sidebar`

  return (
    <SidebarItem
      as=""
      icon={BiChevronRightCircle}
      tooltip={label}
      mt={6}
      mb={3}
      color="gray.400"
      fontWeight="normal"
      iconProps={{ transform: isOpen && "rotate(180deg)" }}
      onClick={onToggle}
    >
      {label}
    </SidebarItem>
  )
}

export default SidebarToggleButton
