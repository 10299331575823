import { Flex, Heading, chakra } from "@chakra-ui/react"
import PropTypes from "prop-types"

import { Markdown } from "components/markdown"
import { parameterType } from "components/openeo-editor/lib/propTypes"
import useFlowUtils from "components/openeo-editor/lib/useFlowUtils"
import ParameterConnection from "components/openeo-editor/parameters/editor/ParameterConnection"
import ParameterControl from "components/openeo-editor/parameters/editor/ParameterControl"
import ParameterRequiredIndicator from "components/openeo-editor/parameters/editor/ParameterRequiredIndicator"

function ParameterEntry(props) {
  const { parentId, parameter } = props
  const { name, description, optional } = parameter

  const { isHandleConnected } = useFlowUtils()
  const hasConnection = isHandleConnected("target", parentId, name)

  return (
    <Flex w="full" fontSize="sm" flexDirection="column">
      <Heading fontSize="sm" mb={2}>
        <ParameterRequiredIndicator optional={optional}>
          <chakra.span textTransform="capitalize">{name.replaceAll("_", " ")}</chakra.span>
        </ParameterRequiredIndicator>
      </Heading>

      <Flex justifyContent="space-between">
        <Flex flexBasis="40%" flexDirection="column">
          <Markdown fontSize="xs" color="gray.400">
            {description}
          </Markdown>
        </Flex>
        <Flex flexBasis="55%">
          {hasConnection ? (
            <ParameterConnection parentId={parentId} parameter={parameter} />
          ) : (
            <ParameterControl parameter={parameter} />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

ParameterEntry.propTypes = {
  parentId: PropTypes.string.isRequired,
  parameter: parameterType,
}

export default ParameterEntry
