import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"
import useOrbCodeSnippetsApi from "services/api/orbCodeSnippetsApi"
import { QueryParams } from "services/types"
import { fetchUrlContentAndHeaders } from "services/utils"

import { useCodeEditorContext } from "./CodeEditorContext"

export default function useGetCodeEditorFile() {
  const { id: orbId } = useParams<QueryParams>()
  const { isCodeEditorOpen } = useOpeneoWorkflowContext() as any
  const { code, codeSnippetUuid } = useCodeEditorContext()
  const { getCodeSnippet } = useOrbCodeSnippetsApi()

  const { isFetched, data } = useQuery(
    ["getCodeSnippet", orbId, codeSnippetUuid],
    () => getCodeSnippet(orbId, codeSnippetUuid),
    { enabled: !!codeSnippetUuid && isCodeEditorOpen }
  )

  const fileContentAndHeaders = useQuery(
    ["showFileContentAndHeaders", data?.file_name],
    () => {
      return fetchUrlContentAndHeaders(data?.url)
    },
    {
      enabled: isFetched && isCodeEditorOpen,
    }
  )

  const [fileContent, fileHeaders] = fileContentAndHeaders?.data ?? [null, null]

  const file = {
    file_name: data?.file_name,
    language: fileHeaders?.get("x-amz-meta-editor-language") ?? "text",
    data: code || fileContent,
  }

  return { isFetched, file }
}
