import { Flex, Text } from "@chakra-ui/react"
import { ReactNode } from "react"

export default function SidebarSection(props: SidebarSectionProps) {
  const { title, isOpen, children } = props

  return (
    <Flex w="full" mt={1} mb={3} flexDirection="column">
      {isOpen && (
        <Text mx={3} my={1} fontWeight="bold" textTransform="uppercase" fontSize="2xs" color="gray.300">
          {title}
        </Text>
      )}
      {children}
    </Flex>
  )
}

type SidebarSectionProps = {
  title: string
  isOpen: boolean
  children: ReactNode
}
