import GeoJSON from "ol/format/GeoJSON"
import { Vector } from "ol/source"
import PropTypes from "prop-types"
import { memo } from "react"

import { VectorLayer } from "components/common/open-layers/layers"

function GeoJSONMapLayer({ geoJsonURL, layerIndex, layersAmount, stylingTheme }) {
  return (
    <VectorLayer
      className="ol-geojson-vector-layer"
      source={
        new Vector({
          url: geoJsonURL,
          format: new GeoJSON(),
        })
      }
      /* Base on https://openlayers.org/workshop/en/vector/style.html, it will be useful in a future to implement styles by user preferences */
      /* Static style */
      /*
      style={geoJSONStyles[stylingTheme]({ layersAmount, layerIndex })}
      */
      /* custom setting by geometry */
      /*
      style={function (feature) {
        console.group("feature")
        console.log('style', feature.getStyle())
        console.log("properties", feature.getProperties())
        console.log('type', feature.getGeometry().getType())
        console.groupEnd()

        return styles[feature.getGeometry().getType()]
      }}
      */
      zIndex={layerIndex + 10 || 10}
    />
  )
}

GeoJSONMapLayer.propTypes = {
  geoJsonURL: PropTypes.string,
  layerIndex: PropTypes.number,
  layersAmount: PropTypes.number,
  stylingTheme: PropTypes.string,
}

export default memo(GeoJSONMapLayer)
