import useGetCurrentPricingPlan from "api/useGetCurrentPricingPlan"
import useGetPlarmformUsage from "api/useGetPlatformUsage"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"

import { dateFormat } from "lib/constants"

import { ChartDataSerie, DetailedChartData } from "./types"

dayjs.extend(customParseFormat)

export default function usePlatformUsageController() {
  const { isLoading, platformUsage } = useGetPlarmformUsage()
  const { assignedPricingPlan } = useGetCurrentPricingPlan()

  let invalidDatesIndexes: number[] = []

  const dates = platformUsage?.map((item) => formatDates(item.date)).filter(Boolean) || []

  const productCostsSeries = generateChartDataSeries(getValuesFromPlatformUsage("product_costs"))
  const usedResourcesSeries = generateChartDataSeries(getValuesFromPlatformUsage("used_resources"))

  return {
    isLoading,
    costsChartData: { dates, series: productCostsSeries, currency: assignedPricingPlan?.currency } as DetailedChartData,
    usageChartData: {
      dates,
      series: usedResourcesSeries,
      currency: assignedPricingPlan?.currency,
    } as DetailedChartData,
  }

  function formatDates(date?: string) {
    const formatedDate = dayjs(date, dateFormat).format("DD MMM")

    if (!dayjs(formatedDate).isValid()) {
      invalidDatesIndexes.push(invalidDatesIndexes.length)
      return false
    }

    return formatedDate
  }

  function getValuesFromPlatformUsage(type: "product_costs" | "used_resources") {
    return (
      platformUsage?.reduce((acc, item) => {
        for (const key in item[type]) {
          const shortUpperCaseKey = key.split("_")[0].toUpperCase()
          acc[shortUpperCaseKey] = acc[shortUpperCaseKey] || []
          acc[shortUpperCaseKey].push(item[type][key])
        }
        return acc
      }, {} as { [k: string]: number[] }) || { T1: [] }
    )
  }

  function generateChartDataSeries(data: { [k: string]: number[] | [] }): ChartDataSerie[] {
    return Object.entries(data).map(([key, serieValue]) => {
      const valuesForValidDates = serieValue.filter((_, index) => !invalidDatesIndexes.includes(index))
      return {
        name: key,
        data: valuesForValidDates,
      }
    })
  }
}
