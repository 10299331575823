import { Flex, chakra } from "@chakra-ui/react"
import ApexChart from "react-apexcharts"

import { chart, legend, tooltip } from "./chartOptions"
import { DetailedChartData } from "./types"

const Chart = chakra(ApexChart, {
  shouldForwardProp: () => true,
})

export default function UsageChart(props: UsageChartProps) {
  const {
    chartData: { dates, series, currency },
    chartType,
  } = props

  if (!chartType || !dates?.length) {
    return (
      <Flex align={"center"} justify={"center"} width={"full"}>
        No data available
      </Flex>
    )
  }

  let yAxesFormatter

  switch (chartType) {
    case "COSTS":
      yAxesFormatter = (value: number) =>
        new Intl.NumberFormat("en-us", {
          style: "currency",
          currency: currency || "USD",
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }).format(value)
      break
    case "USAGE":
      yAxesFormatter = (value: number) => `${value} km²`
      break
    default:
      yAxesFormatter = (value: string | number) => value
  }

  const options = {
    chart,
    tooltip,
    legend,
    yaxis: {
      labels: {
        formatter: yAxesFormatter,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "category",
      tickAmount: "dataPoints",
      categories: dates,
    },
  }

  return (
    <Chart
      options={{
        ...options,
      }}
      series={series}
      type="bar"
      width="100%" //FIXME chart is not responsive
      height={350}
    />
  )
}

type UsageChartProps = {
  chartData: DetailedChartData
  chartType: "COSTS" | "USAGE"
}
