import { Badge, Flex, Text } from "@chakra-ui/react"

import OpenEOEditorDrawerItem from "components/openeo-editor/drawer/OpenEOEditorDrawerItem"
import { BaseProcessType } from "components/openeo-editor/types"
import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"

export default function ProcessListEntry(props: ProcessListEntryProps) {
  const { process } = props
  const { disabledProcessCategories } = useOpeneoWorkflowContext() as any
  const isDisabled = isProcessDisabled(process)
  const isInputProcess = process.categories.includes("input")

  function isProcessDisabled(process: BaseProcessType) {
    if (!disabledProcessCategories.length) {
      return false
    }

    return process.categories.some((element) => disabledProcessCategories.includes(element))
  }

  return (
    <OpenEOEditorDrawerItem
      data-flow-id={process.id}
      data-flow-type="process"
      disabled={isDisabled}
      borderColor={isInputProcess ? "purple.200" : "green.200"}
      bgColor={process.hidden || isDisabled ? "gray.50" : "unset"}
      minH="85px"
      maxH="85px"
      p={3}
      position="relative"
    >
      <Flex flexDirection="column">
        <Text
          mb={1}
          fontWeight="semibold"
          fontSize="xs"
          fontFamily="console"
          color={isDisabled ? "primary.200" : "black"}
        >
          {process.title}
        </Text>

        <Text fontSize="2xs" color="gray.400">
          {process.summary}
        </Text>
      </Flex>

      {process.hidden && (
        <Flex position="absolute" top={-2} right={3}>
          <Badge fontSize="2xs" colorScheme="red">
            HIDDEN
          </Badge>
        </Flex>
      )}
    </OpenEOEditorDrawerItem>
  )
}

type ProcessListEntryProps = {
  process: BaseProcessType
}
