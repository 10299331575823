import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react"

const CodeEditorContext = createContext<CodeEditorContextResult | null>(null)

export default function CodeEditorContextProvider(props: CodeEditorContextProviderProps) {
  const { children } = props
  const [code, setCode] = useState<any>()
  const [codeSnippetUuid, setCodeSnippetUuid] = useState<string | null>(null)

  function clearContext() {
    setCode(null)
    setCodeSnippetUuid(null)
  }

  return (
    <CodeEditorContext.Provider value={{ code, setCode, codeSnippetUuid, setCodeSnippetUuid, clearContext }}>
      {children}
    </CodeEditorContext.Provider>
  )
}

export function useCodeEditorContext() {
  const context = useContext(CodeEditorContext)
  if (!context) {
    throw new Error("CodeEditorContext was not initialized. Maybe component was not wrapped in provider")
  }

  return context
}

type CodeEditorContextResult = {
  code: any
  setCode: Dispatch<SetStateAction<any>>
  codeSnippetUuid: string | null
  setCodeSnippetUuid: Dispatch<SetStateAction<string | null>>
  clearContext: () => void
}

type CodeEditorContextProviderProps = {
  children: ReactNode
}
