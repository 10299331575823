import { chakra } from "@chakra-ui/react"

import { LinkType } from "components/openeo-editor/types"
import { htmlColors } from "lib/htmlColors"

const HEADING_REGEXP = new RegExp("^([^#]*)\\s?(#\\d+)?$")

export function formatHeading(heading: string) {
  const [, text, identifier] = HEADING_REGEXP.exec(heading) || []

  return (
    <>
      <chakra.span>{text.replaceAll("/", " › ")}</chakra.span>
      <chakra.span color="gray.400" ms={2}>
        {identifier}
      </chakra.span>
    </>
  )
}

export function getLinkHref(links: LinkType[], linkType: string) {
  return links?.find((link) => link.rel === linkType)?.href || null
}

export function rightSplit(string: string, separator: string, maxSplit = 1) {
  const parts = string.split(separator)
  return maxSplit ? [parts.slice(0, -maxSplit).join(separator)].concat(parts.slice(-maxSplit)) : parts
}

export function convertVisualization(visualization: { [key: string]: any }) {
  const bandVis = visualization.image_visualization?.band_vis
  if (!bandVis) {
    return {}
  }

  return {
    ...bandVis,
    palette: convertPalette(bandVis.palette),
  }
}

export function convertPalette(palette?: string[]) {
  if (!palette) {
    return []
  }

  return palette.map((entry) => htmlColors[entry] || (isHexColor(entry) ? `#${entry}` : entry))
}

function isHexColor(color: string) {
  return color.match(/^(?:[0-9a-fA-F]{3}){1,2}$/) !== null
}
