import buildUrl from "api/utils/buildUrl"
import { useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import { UseParamsType } from "components/workflow-creator/types"
import useAuthenticatedApi from "services/api/authenticatedApi"

export default function useWorkflowGraphQueries() {
  const api = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1/external/openeo/process_graphs") })
  const queryClient = useQueryClient()

  const { id: orbId } = useParams<UseParamsType>()

  return {
    resetWorkflowMutation,
  }

  function resetWorkflowMutation() {
    const mutationOptions = {
      onSuccess: () => queryClient.invalidateQueries(["getOpeneoGraph"]),
    }

    return {
      mutationFn: resetWorkflow,
      mutationOptions,
    }

    function resetWorkflow(graph_id: number | null): Promise<boolean> {
      return api.post(`${orbId}/reset`, { json: { graph_id } }).json()
    }
  }
}
