import { useQuery } from "react-query"

import useOrbUsersQueries from "./queryFunctions/useOrbUsersQueries"

export default function useGetOrbUsers(id: number | string, invitesOnly: boolean, pagination: any) {
  const { listUsersQuery } = useOrbUsersQueries()
  const { queryKey, queryFn, options } = listUsersQuery(id, invitesOnly, pagination)

  const { isLoading, isError, data: users } = useQuery(queryKey, queryFn, options)

  return { isLoading, isError, users }
}
