import { Flex } from "@chakra-ui/react"
import { ReactNode } from "react"

export default function Section(props: SectionProps) {
  const { header, children, hasGap = true, dataId } = props

  return (
    <Flex w="full" data-id={dataId}>
      <Flex w={hasGap ? "25%" : "30%"} minW="200px" me={hasGap ? 10 : 4}>
        {header}
      </Flex>
      <Flex w="full" flexDirection="column">
        {children}
      </Flex>
    </Flex>
  )
}

type SectionProps = {
  header: ReactNode
  children?: ReactNode
  hasGap?: boolean
  dataId?: string
}
