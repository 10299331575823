import { useAuth0 } from "@auth0/auth0-react"
import { useState } from "react"

import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"
import { useEffectOnce } from "hooks/useEffectOnce"

function useOpeneoPreviewMapController() {
  const { getAccessTokenSilently } = useAuth0()
  const [accessToken, setAccessToken] = useState(null)
  const { isResultsLoading } = useOpeneoWorkflowContext()

  useEffectOnce(() => {
    getAccessTokenSilently().then((token) => setAccessToken(token))
  })

  return {
    isLoading: !accessToken || isResultsLoading,
    accessToken: accessToken,
  }
}

export default useOpeneoPreviewMapController
