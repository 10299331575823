import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react"
import { useField } from "formik"

import { parameterType } from "components/openeo-editor/lib/propTypes"

function NumberType(props) {
  const { parameter } = props
  const [{ value }, , { setValue }] = useField(parameter.name)

  return (
    <NumberInput value={value} onChange={setValue} size="xs" step={0.1}>
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  )
}

NumberType.propTypes = {
  parameter: parameterType.isRequired,
}

export { NumberType }
