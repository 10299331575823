import { useBreakpointValue } from "@chakra-ui/react"
import { useStoredDisclosure } from "hooks"
import { ReactNode, createContext, useContext } from "react"

const SidebarContext = createContext<SidebarContextResult | null>(null)

export default function SidebarContextProvider(props: SidebarContextProviderProps) {
  const { children } = props
  const defaultIsOpen = useBreakpointValue({ base: false, md: true })
  const sidebarDisclosure = useStoredDisclosure({ defaultIsOpen, key: "layout-sidebar" })

  return <SidebarContext.Provider value={sidebarDisclosure}>{children}</SidebarContext.Provider>
}

export function useSidebarContext() {
  const context = useContext(SidebarContext)
  if (!context) {
    throw new Error("SidebarContext was not initialized. Maybe component was not wrapped in provider")
  }

  return context
}

type SidebarContextProviderProps = {
  children: ReactNode
}

type SidebarContextResult = {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  onToggle: () => void
}
