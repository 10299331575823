import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from "@chakra-ui/react"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import { useMemo } from "react"
import { DayPicker } from "react-day-picker"

import { childrenType } from "lib/CustomPropTypes"
import { dateStringToDate } from "lib/utils"

const DateInput = ({ children, value, setValue }) => {
  const { onOpen, onClose, isOpen } = useDisclosure()
  const formatDate = (date) => {
    try {
      return dayjs(date).format("YYYY-MM-DD")
    } catch (e) {
      return dayjs().format("YYYY-MM-DD")
    }
  }

  const handleDayClick = (day) => {
    setValue(formatDate(day))
    onClose()
  }

  const selectedDate = useMemo(() => dateStringToDate(value), [value])

  return (
    <Popover placement="bottom-start" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />

          <PopoverBody>
            <DayPicker
              defaultMonth={selectedDate}
              className="datePicker"
              placeholder="Select date..."
              onDayClick={handleDayClick}
              formatDate={formatDate}
              selected={selectedDate}
              disabled={(date) => dayjs(date).isAfter(dayjs())}
              fromYear={1980}
              toYear={new Date().getFullYear()}
              captionLayout="dropdown"
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

DateInput.propTypes = {
  children: childrenType,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
}

export default DateInput
