import { useStoreState } from "react-flow-renderer"

import { flowToProcessGraph } from "components/openeo-editor/lib/process-graph-builder"

function useProcessGraphBuilder() {
  const nodes = useStoreState((state) => state.nodes)
  const edges = useStoreState((state) => state.edges)

  function buildProcessGraph() {
    return flowToProcessGraph(nodes, edges)
  }

  return { buildProcessGraph }
}

export default useProcessGraphBuilder
