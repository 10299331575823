import { Flex } from "@chakra-ui/react"

import Label from "./Label"
import SubTitle from "./SubTitle"
import Title from "./Title"
import { HeaderBaseProps, HeaderFunction } from "./types"

const Header: HeaderFunction = function (props: HeaderBaseProps) {
  const { children, ...rest } = props

  return (
    <Flex width="full" flexDirection="column" {...rest}>
      {children}
    </Flex>
  )
}

Header.Label = Label
Header.Title = Title
Header.SubTitle = SubTitle

export default Header
