export const cividis = [
  "#00224E",
  "#002F6D",
  "#213B6E",
  "#3A486C",
  "#4C556C",
  "#5D616E",
  "#6C6E72",
  "#7D7C78",
  "#8E8978",
  "#9F9775",
  "#B1A570",
  "#C4B468",
  "#D9C55C",
  "#EDD54A",
  "#FEE838",
]
