import { Icon } from "@chakra-ui/icons"
import { Button, Flex } from "@chakra-ui/react"
import { CgArrowsExchange, CgCheck } from "react-icons/cg"

import Header from "components/common/header"

export default function Organisation(props: OrganisationProps) {
  const { name, description, selected, onClick } = props
  return (
    <Button
      variant="outline"
      isFullWidth
      isDisabled={selected}
      p={3}
      shadow="sm"
      onClick={onClick}
      rounded="md"
      borderWidth="1px"
      rightIcon={<Icon as={selected ? CgCheck : CgArrowsExchange} boxSize={6} />}
    >
      <Flex w="full">
        <Header alignItems="flex-start">
          <Header.Title fontSize="md">{name}</Header.Title>
          <Header.SubTitle fontSize="sm" fontWeight="normal">
            {description}
          </Header.SubTitle>
        </Header>
      </Flex>
    </Button>
  )
}

interface OrganisationProps {
  id: number
  name: string
  description?: string
  selected?: boolean
  onClick: () => void
}
