import { Center, Container, Spinner } from "@chakra-ui/react"
import PropTypes from "prop-types"

const LoadingSpinner = ({ mb = 0, size = "xl", thickness = "6px" }) => (
  <Container mb={mb} flexGrow={1}>
    <Center width="full" minHeight="full">
      <Spinner size={size} thickness={thickness} color="brand.600" />
    </Center>
  </Container>
)

LoadingSpinner.propTypes = {
  size: PropTypes.string,
  thickness: PropTypes.string,
  mb: PropTypes.number,
}

export default LoadingSpinner
