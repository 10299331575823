import { Flex, IconButton, Text } from "@chakra-ui/react"
import { User } from "api/models/User"
import { FaUserMinus } from "react-icons/fa"

export default function OrganisationMembersList(props: OrganisationMembersProps) {
  const { users, handleDeleteUser } = props

  return (
    <Flex flexDirection="column">
      <Text fontSize="lg">Current members</Text>
      {users.map((user) => (
        <ListEntry key={user.id} user={user} handleDeleteUser={handleDeleteUser} />
      ))}
    </Flex>
  )
}

export function ListEntry(props: ListEntryProps) {
  const { user, handleDeleteUser } = props

  function handleClick(event: any) {
    event.preventDefault()
    handleDeleteUser(user)
  }

  return (
    <Flex justifyContent="space-between" alignItems="center" fontSize="sm">
      {user.email}
      <IconButton
        size="xs"
        variant="ghost"
        colorScheme="gray"
        color="gray.300"
        me={2}
        icon={<FaUserMinus />}
        aria-label="Add user"
        onClick={handleClick}
      />
    </Flex>
  )
}

type OrganisationMembersProps = {
  users: User[]
  handleDeleteUser: (user: User) => void
}

type ListEntryProps = {
  user: User
  handleDeleteUser: (user: User) => void
}
