import { Box, Flex } from "@chakra-ui/react"
import { usePageTitle } from "hooks"

import { LoadMore } from "components/common"
import Section from "components/layout/section/Section"
import SectionHeader from "components/layout/section/SectionHeader"

import AdminOrganisationFilter from "./AdminOrganisationFilter"
import AdminOrganisationList from "./AdminOrganisationList"
import AdminOrganisationsAddNew from "./AdminOrganisationsAddNew"
import useAdminOrganisationsController from "./adminOrganisationsController"

export default function AdminOrganisations() {
  usePageTitle("Administration Panel - Organisations")

  const { filterOptions, handleSearch, isLoading, organisations, hasNextPage, fetchNextPage } =
    useAdminOrganisationsController()

  return (
    <Box w="full" p={4}>
      <Section
        header={
          <SectionHeader title="Organisations">
            <AdminOrganisationsAddNew />
          </SectionHeader>
        }
      >
        <Flex w="full" my={10} flexDirection="column">
          <Flex w="full" justifyContent="flex-end" mb={6}>
            <AdminOrganisationFilter options={filterOptions} handleSearch={handleSearch} />
          </Flex>
          <AdminOrganisationList organisations={organisations} isLoading={isLoading} />
          <LoadMore hasNextPage={hasNextPage} fetchNextPage={fetchNextPage} isFetchingNextPage={isLoading} />
        </Flex>
      </Section>
    </Box>
  )
}
