import { Modal, ModalContent, ModalOverlay, Spinner } from "@chakra-ui/react"
import PropTypes from "prop-types"

function LoadingOverlay(props) {
  const { isActive = true } = props
  const ignore = () => {}

  return (
    <Modal isOpen={isActive} onClose={ignore} isCentered>
      <ModalOverlay bgColor="blackAlpha.700" />
      <ModalContent alignItems="center" bgColor="transparent" shadow="none">
        <Spinner thickness="18px" speed="0.8s" color="primary.900" boxSize={24} />
      </ModalContent>
    </Modal>
  )
}

LoadingOverlay.propTypes = {
  isActive: PropTypes.bool,
}

export default LoadingOverlay
