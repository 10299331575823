import { Alert, AlertDescription, AlertIcon, Grid } from "@chakra-ui/react"
import { forwardRef } from "react"

import { LoadingSpinner } from "components/common"
import TemplateCard from "components/orb/settings/template/TemplateCard"
import useTemplateSelectorController from "components/orb/settings/template/templateSelectorController"

const TemplateSelector = forwardRef((props, ref) => {
  const { isLoading, isError, templates, group, getRadioProps } = useTemplateSelectorController(ref)

  if (isError) {
    return (
      <Alert status="error" fontSize="sm" rounded="md">
        <AlertIcon />
        <AlertDescription>Failed to load available templates. Please refresh the page to try again.</AlertDescription>
      </Alert>
    )
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Grid
      w="full"
      alignItems="flex-start"
      rowGap={6}
      columnGap={4}
      mb={2}
      pr={2}
      {...group}
      templateColumns="repeat(2, 1fr)"
      wrap="wrap"
    >
      {templates.map((template) => {
        const { id } = template
        const radio = getRadioProps({ value: id })

        return <TemplateCard key={id} template={template} {...radio} />
      })}
    </Grid>
  )
})

export default TemplateSelector
