import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SimpleGrid,
  VStack,
  useDisclosure,
} from "@chakra-ui/react"
import { OrbConfig } from "api/models/OrbConfig"
import { useHistory } from "react-router-dom"

import Logo from "components/logo/Logo"

import { ApplicationCard } from "./ApplicationCard"

export function ApplicationsList({ orbs }: Props) {
  const history = useHistory()
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
  const applicationsGDP = orbs.filter((orb) => orb.template === "forest")
  const applicationsNotGDP = orbs.filter((orb) => orb.template !== "forest")

  function handleClose() {
    onClose()
    history.replace("/orbs")
  }

  return (
    <Modal
      size="6xl"
      isOpen={isOpen}
      onClose={handleClose}
      scrollBehavior="inside"
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalContent p={3} bgColor="whiteAlpha.900" backdropFilter="blur(2px)" shadow="dark-lg">
        <ModalCloseButton size="sm" />
        <ModalHeader>
          <Heading fontSize="sm" textTransform="uppercase" color="brand.400">
            Data platforms
          </Heading>
        </ModalHeader>
        <ModalBody>
          <VStack w="full" spacing={20}>
            <VStack w="full" spacing={6}>
              <SimpleGrid w="full" columns={6} gap={6}>
                {applicationsGDP.map((orb, index) => (
                  <ApplicationCard key={index} orb={orb} />
                ))}
              </SimpleGrid>
            </VStack>
          </VStack>

          {applicationsNotGDP.length > 0 && (
            <VStack w="full" spacing={3} mt={6} alignItems="flex-start">
              <Heading fontSize="sm" textTransform="uppercase" color="brand.400" align="left">
                Other applications
              </Heading>
              <VStack w="full" spacing={6}>
                <SimpleGrid w="full" columns={6} gap={3}>
                  {applicationsNotGDP.map((orb, index) => (
                    <ApplicationCard key={index} orb={orb} isSmall />
                  ))}
                </SimpleGrid>
              </VStack>
            </VStack>
          )}
        </ModalBody>
        <ModalFooter justifyContent="center">
          <VStack spacing={6} mt={6}>
            {applicationsNotGDP.length && (
              <Button variant="outline" color="brand" px={12} onClick={handleClose} fontSize="sm">
                Continue to Orbify Sandbox
              </Button>
            )}
            <Logo />
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

interface Props {
  orbs: OrbConfig[]
}
