import { MAP_LAYER_ASSETS, OpenEOJobResultAsset, OpeneoWorkflowContextResult } from "api/models/OpenEO"
import { useEffect, useMemo } from "react"

import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"

export default function useLayerAssets() {
  const { isResultsLoading, availableAssets, selectedLayersIds, setSelectedLayersIds } =
    useOpeneoWorkflowContext() as OpeneoWorkflowContextResult

  const mapLayers = useMemo(() => {
    const mapLayerAssets = Object.values(availableAssets || {}).filter((asset) => MAP_LAYER_ASSETS.includes(asset.type))

    const regularMapLayers = mapLayerAssets
      .filter((asset) => !asset.group)
      .map((asset) => ({
        id: asset.name,
        label: asset.title,
        computedVariables: asset.metadata?.computed_variables,
        assets: [asset],
      }))

    const groupedMapLayers = Object.entries(
      mapLayerAssets.filter((asset) => asset.group).reduce(groupAssetsByName, {})
    ).map(([groupId, assets]) => ({
      id: groupId,
      label: groupId,
      assets: assets,
    }))

    function groupAssetsByName(group: { [assetName: string]: OpenEOJobResultAsset[] }, asset: OpenEOJobResultAsset) {
      const groupName = asset.group || asset.name
      group[groupName] = group[groupName] ?? []
      group[groupName].push(asset)
      return group
    }

    return [...regularMapLayers, ...groupedMapLayers]
  }, [availableAssets])

  useEffect(() => {
    setSelectedLayersIds(mapLayers.length > 0 ? [mapLayers[0].id] : null)
  }, [isResultsLoading, mapLayers, setSelectedLayersIds])

  const selectedAssets = useMemo(() => {
    if (!mapLayers) return []

    if (!selectedLayersIds) return []

    return mapLayers
      .filter((layer) => selectedLayersIds.includes(layer.id))
      .reduce((assets: OpenEOJobResultAsset[], layer) => [...assets, ...layer.assets], [])
  }, [mapLayers, selectedLayersIds])

  return { mapLayers, selectedAssets, isLoading: isResultsLoading }
}
