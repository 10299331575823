import { Box, Code, Text } from "@chakra-ui/react"
import { ReactNode } from "react"

import { SafeConfirmModal } from "components/common/safe-confirm-modal"

import { AdminOrbsEntry } from "../gdp-demos/types"
import { useCloseOrbController } from "./useCloseOrbController"

export default function CloseOrb({ children, orb }: props) {
  const { onOpen, isOpen, onClose, onConfirm, disableButton } = useCloseOrbController(orb)

  const additionalInfo = (
    <>
      <Text>
        You want to close orb:
        <Box px={4} py={2} my={4} rounded="md" bg="primary.50" color="primary.900" fontWeight="bold">
          <Code fontSize="sm">{orb.name}</Code>
        </Box>
      </Text>
      <Text p={4} my={4} color="red.500" bgColor="red.50" fontWeight="bold" rounded="md">
        Closing Orbs cancels Stripe subscription (for paid orbs) or extended trial (off-platform paid deals). This
        action can be difficult to revert.
      </Text>
    </>
  )
  return (
    <>
      {children({ onOpen })}
      <SafeConfirmModal
        modalDisclosure={{ isOpen, onOpen, onClose }}
        onConfirm={onConfirm}
        textConfirmation={orb.name}
        disableButton={disableButton}
        header="Close Orb"
        additionalInfo={additionalInfo}
        actionLabel="Close Orb"
        requireConfirmation={true}
      />
    </>
  )
}

type ChildrenProps = {
  onOpen: () => void
}

type props = {
  children: (props: ChildrenProps) => ReactNode
  orb: AdminOrbsEntry
}
