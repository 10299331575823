import { SimpleGrid } from "@chakra-ui/react"

import WorkflowChart from "./chart/WorkflowChart"
import useChartAssets from "./chart/useChartAssets"

export default function WorkflowChartsGrid() {
  const { chartAssets } = useChartAssets()
  const columnsCount = Math.min(chartAssets.length, 4)

  return (
    <SimpleGrid w="full" h="full" columns={columnsCount} spacing={8} p={4} overflow="scroll">
      {chartAssets.map((asset) => (
        <WorkflowChart key={asset.href} asset={asset} />
      ))}
    </SimpleGrid>
  )
}
