import { Steps } from "./types"

const steps: Steps = {
  category: {
    number: 1,
    stepCaption: "Which category best fits your problem?",
    heading: "Select problem category",
    text: "Start building your workflow by selecting a category of the problem your application solves",
    pageTitle: "Workflow Creator - Select Category",
  },
  workflowTemplate: {
    number: 2,
    stepCaption: "What problem do you want to solve?",
    heading: "Select a specific problem",
    text: "Select the specific problem your application solves",
    pageTitle: "Workflow Creator - Select Workflow",
  },
}

export default steps
