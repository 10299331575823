import { useCallback } from "react"
import { addEdge } from "react-flow-renderer"

import { useEditorContext } from "components/openeo-editor/OpenEOEditorContext"
import useAddElementHandler from "components/openeo-editor/lib/useAddElementHandler"
import useProcesses from "components/openeo-editor/lib/useProcesses"
import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"

function useFlowEditorController() {
  const { elements, setElements, setIsAddingElement } = useOpeneoWorkflowContext()
  const { editorWrapper, flowInstance, onLoad } = useEditorContext()
  const { getNodeData } = useProcesses()
  const { onAddElement } = useAddElementHandler()

  const onInsertElementById = useCallback(
    async (id, type) => {
      setIsAddingElement.on()

      const data = getNodeData(id, type)

      const bounds = editorWrapper.current.getBoundingClientRect()

      const position = flowInstance.project({
        x: bounds.right / 2,
        y: 5,
      })

      await onAddElement(id, type, position, data)
      setIsAddingElement.off()
    },
    [editorWrapper, flowInstance, getNodeData, onAddElement, setIsAddingElement]
  )

  const onDragOver = useCallback((event) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = "move"
  }, [])

  const onDrop = useCallback(
    async (event) => {
      event.preventDefault()

      setIsAddingElement.on()

      const { dataTransfer, clientX, clientY } = event

      const bounds = editorWrapper.current.getBoundingClientRect()
      const type = dataTransfer.getData("application/reactflow-nodeType")
      if (typeof type === "undefined" || !type) {
        // reject drop of unsupported node types
        return
      }

      const data = JSON.parse(dataTransfer.getData("application/reactflow-nodeData"))
      const id = dataTransfer.getData("application/reactflow-nodeId")

      const position = flowInstance.project({
        x: clientX - bounds.left,
        y: clientY - bounds.top,
      })

      await onAddElement(id, type, position, data)
      setIsAddingElement.off()
    },
    [editorWrapper, flowInstance, onAddElement, setIsAddingElement]
  )

  const onConnect = useCallback(
    (params) => {
      setElements((existingElements) =>
        addEdge(
          {
            ...params,
            arrowHeadType: "arrowclosed",
            type: "removableEdge",
          },
          existingElements
        )
      )
    },
    [setElements]
  )

  return {
    elements,
    onLoad,
    onDragOver,
    onDrop,
    onConnect,
    onInsertElementById,
  }
}

export default useFlowEditorController
