import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Table,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react"

import { ContinuousLegendType } from "./types"

export default function ContinuousLegend(props: ContinuousLegendProps) {
  const { legend } = props

  return (
    <AccordionItem borderWidth={0}>
      <AccordionButton px={0}>
        <ContinuousLegendTitle legend={legend} />
        <AccordionIcon />
      </AccordionButton>

      <AccordionPanel px={0} borderBottomWidth={0}>
        <ContinuousLegendBody legend={legend} />
      </AccordionPanel>
    </AccordionItem>
  )
}

export function ContinuousLegendTitle(props: ContinuousLegendProps) {
  const { legend } = props

  return (
    <Flex w="full" fontSize="sm" fontWeight="semibold">
      {legend.title}
    </Flex>
  )
}

export function ContinuousLegendBody(props: ContinuousLegendProps) {
  const { legend } = props
  const { palette } = legend
  const gradient = `linear(to-b, ${palette.map((item) => item.color).join(",")})`
  const colorStops = palette.map((item) => item.value)

  return (
    <Flex minHeight="200px" justifyContent="center">
      <Flex w="30px" py={2} me={2}>
        <Flex w="full" h="full" bgGradient={gradient} rounded="md" borderColor="gray.200" borderWidth={2} />
      </Flex>
      <Flex>
        <Table variant="unstyled" size="sm">
          <Tbody>
            {colorStops.map((value, index, arr) => (
              <Tr key={index}>
                <Td
                  px={0}
                  py={2}
                  borderColor="gray.300"
                  borderBottomWidth={index + 1 === arr.length ? 0 : 2}
                  verticalAlign={index === 0 ? "top" : index + 1 === arr.length ? "bottom" : "middle"}
                >
                  {value}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Flex>
    </Flex>
  )
}

type ContinuousLegendProps = {
  legend: ContinuousLegendType
}
