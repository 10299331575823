import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Text } from "@chakra-ui/react"

import { BasicLegendType } from "./types"

export default function BasicLegend(props: BasicLegendProps) {
  const { legend } = props

  return (
    <AccordionItem borderWidth={0}>
      <AccordionButton px={0}>
        <BasicLegendTitle legend={legend} />
        <AccordionIcon />
      </AccordionButton>

      <AccordionPanel px={0} borderWidth={0}>
        <BasicLegendBody legend={legend} />
      </AccordionPanel>
    </AccordionItem>
  )
}

export function BasicLegendTitle(props: BasicLegendProps) {
  const { legend } = props
  return (
    <Flex w="full" fontSize="sm" fontWeight="semibold">
      {legend.title}
    </Flex>
  )
}

export function BasicLegendBody(props: BasicLegendProps) {
  const { legend } = props

  return (
    <>
      {legend.items.map((legendItem, index) => (
        <Flex key={index} w="full" alignItems="center">
          <Box boxSize={4} bgColor={legendItem.color} borderColor="gray.200" borderWidth={1} rounded="sm" me={4} />
          <Text fontSize="sm">{legendItem.title}</Text>
        </Flex>
      ))}
    </>
  )
}

type BasicLegendProps = {
  legend: BasicLegendType
}
