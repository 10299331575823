export const gnuplot2 = [
  "#000000",
  "#000048",
  "#000090",
  "#0000DC",
  "#1D00FF",
  "#5500FF",
  "#8D04FB",
  "#C92AD5",
  "#FF4EB1",
  "#FF728D",
  "#FF9669",
  "#FFBA45",
  "#FFE01F",
  "#FFFF1E",
  "#FFFFFF",
]
