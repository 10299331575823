import { useQuery } from "react-query"

import { FeatureKey } from "./models/Billing"
import useBillingQueries from "./queryFunctions/useBillingQueries"

export default function useGetAllowedBIllingFeatures(): useGetAllowedBIllingFeaturesResult {
  const { getAllowedBillingFeaturesQuery } = useBillingQueries()
  const { queryKey, queryFn, options } = getAllowedBillingFeaturesQuery()

  const { data, isLoading } = useQuery(queryKey, queryFn, options)

  const allowedToManageAppUsers = !!data && data.includes("users_roles_and_access_management")

  return {
    isLoading,
    allowedBillingFeatures: data,
    allowedToManageAppUsers,
  }
}

type useGetAllowedBIllingFeaturesResult = {
  isLoading: boolean
  allowedBillingFeatures?: FeatureKey[]
  allowedToManageAppUsers: boolean
}
