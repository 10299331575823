import { useDisclosure } from "@chakra-ui/react"
import PropTypes from "prop-types"

import { useLocalStorage } from "./useLocalStorage"

const useStoredDisclosure = ({ defaultIsOpen, key }) => {
  const [storedIsOpen, setStoredIsOpen] = useLocalStorage(key, defaultIsOpen)
  const disclosure = useDisclosure({ defaultIsOpen: defaultIsOpen })

  const handleOnOpen = () => {
    setStoredIsOpen(true)
    disclosure.onOpen()
  }

  const handleOnClose = () => {
    setStoredIsOpen(false)
    disclosure.onClose()
  }

  const handleOnToggle = () => (storedIsOpen ? handleOnClose() : handleOnOpen())

  return {
    isOpen: storedIsOpen,
    onOpen: handleOnOpen,
    onClose: handleOnClose,
    onToggle: handleOnToggle,
  }
}

useStoredDisclosure.propTypes = {
  defaultIsOpen: PropTypes.bool,
  key: PropTypes.string.isRequired,
}

export { useStoredDisclosure }
