import { useAuth0 } from "@auth0/auth0-react"
import jwtDecode from "jwt-decode"
import { useEffect, useState } from "react"

import { useEffectOnce } from "hooks/useEffectOnce"
import Rollbar from "services/rollbar"

const useDecodedAuthToken = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [accessToken, setAccessToken] = useState(null)
  const [decodedToken, setDecodedToken] = useState(null)

  useEffectOnce(() =>
    getAccessTokenSilently()
      .then((token) => setAccessToken(token))
      .catch((error) => Rollbar.error(error))
  )

  useEffect(() => {
    if (!accessToken) return
    let data = {}
    try {
      data = jwtDecode(accessToken)
    } finally {
      setDecodedToken(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  let metadata = {}
  let createdAt = null
  let permissions = []
  let loginsCount = undefined
  if (decodedToken) {
    metadata = decodedToken[process.env.REACT_APP_AUTH0_NAMESPACE + "/app_metadata"] || {}
    createdAt = decodedToken[process.env.REACT_APP_AUTH0_NAMESPACE + "/created_at"] || null
    loginsCount = decodedToken[process.env.REACT_APP_AUTH0_NAMESPACE + "/logins_count"] || undefined
    permissions = decodedToken.permissions || []
  }

  return {
    isLoading: decodedToken === null,
    createdAt,
    metadata,
    permissions,
    loginsCount,
  }
}

export { useDecodedAuthToken }
