import { usePermission } from "hooks"
import Feature from "ol/Feature"
import Geolocation from "ol/Geolocation"
import Point from "ol/geom/Point"
import OLVectorLayer from "ol/layer/Vector"
import { Vector } from "ol/source"
import { useEffect, useState } from "react"

import { useMapContext } from "../MapContextProvider"
import { styles } from "../styles"

// import { getCenter } from "ol/extent"  // TODO: remove after GeoTIFF implementation. Used for testing GeoTIFF for Khartoum corrected

function useGeolocationControl() {
  const { map } = useMapContext()
  const view = map?.getView()

  const geoLocationEnabled = usePermission("geolocation", navigator.geolocation !== undefined)

  const [position, setPosition] = useState()
  const [accuracyGeometry, setAccuracyGeometry] = useState()

  useEffect(() => {
    if (!geoLocationEnabled || !view) {
      return
    }

    const geolocation = new Geolocation({
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: view?.getProjection(),
    })
    geolocation.setTracking(true)
    geolocation.on("change:position", () => {
      setPosition(geolocation.getPosition())
    })
    geolocation.on("change:accuracyGeometry", function () {
      setAccuracyGeometry(geolocation.getAccuracyGeometry())
    })
    return () => geolocation.setTracking(false)
  }, [geoLocationEnabled, view])

  function addLocationPin() {
    let positionFeature = new Feature()
    positionFeature.setStyle(styles.Position)
    position && positionFeature.setGeometry(new Point(position))
    const positionSource = new Vector({ features: [positionFeature] })
    const positionLayer = new OLVectorLayer({
      className: "ol-location-layer",
      source: positionSource,
      style: styles.Position,
    })
    map.addLayer(positionLayer)
  }

  function drawAccuracyCircle() {
    let accuracyLayer
    const accuracyFeature = new Feature()
    if (accuracyGeometry) {
      accuracyFeature.setGeometry(accuracyGeometry)
      const accuracySource = new Vector({ features: [accuracyFeature] })
      accuracyLayer = new OLVectorLayer({
        className: "ol-accuracy-layer",
        source: accuracySource,
        style: styles.Accuracy,
      })
    }
    if (accuracyFeature.getGeometry()) map.addLayer(accuracyLayer)
  }

  function travelToLocation() {
    const zoom = view?.getZoom()
    // TODO: this animation to location was used as example. It should be discussed and implemented a new animation if it's necessary.
    view?.animate(
      {
        zoom: zoom - 1,
        duration: 250,
      },
      {
        center: position,
        // center: getCenter([3673522.2593, 1826729.3716, 3788458.8587, 1942369.9109]), // TODO: remove after GeoTIFF implementation. Used for testing GeoTIFF for Khartoum corrected
        duration: 1500,
      },
      {
        zoom: zoom,
        duration: 250,
      }
    )
  }

  function locateUser() {
    addLocationPin()
    drawAccuracyCircle()
    travelToLocation()
  }

  return {
    geoLocationEnabled,
    locateUser,
  }
}

export default useGeolocationControl
