import dagre from "dagre"
import { isNode } from "react-flow-renderer"

const DEFAULT_NODE_PROPS = { width: 400, height: 150 }

function generateLayout(elements, rankdir = "LR") {
  const { width, height } = DEFAULT_NODE_PROPS

  const graph = new dagre.graphlib.Graph()
  graph.setDefaultEdgeLabel(() => ({}))
  graph.setGraph({ rankdir })

  elements.forEach((element) => {
    if (isNode(element)) {
      graph.setNode(element.id, { width: width, height: height })
    } else {
      graph.setEdge(element.source, element.target)
    }
  })

  dagre.layout(graph)

  return elements.map((element) => {
    if (isNode(element)) {
      const nodeWithPosition = graph.node(element.id)
      element.position = {
        x: nodeWithPosition.x - width / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - height / 2 + 100,
      }
    }

    return element
  })
}

export default generateLayout
