import { useQuery } from "react-query"

import useAPIKeysQueries from "./queryFunctions/useAPIKeysQueries"

export default function useGetAPIKeys() {
  const { listAPIKeysQuery } = useAPIKeysQueries()
  const { queryKey, queryFn } = listAPIKeysQuery()

  const { data, isLoading } = useQuery(queryKey, queryFn)

  return {
    isLoading,
    apiKeys: data || [],
  }
}
