import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"
import { Box, Flex, VStack, chakra } from "@chakra-ui/react"
import { Formik } from "formik"
import { InputControl } from "formik-chakra-ui"
import { usePageTitle } from "hooks"
import mixpanel from "mixpanel-browser"
import { AiOutlineUser } from "react-icons/ai"

import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"
import Section from "components/layout/section/Section"
import SectionHeader from "components/layout/section/SectionHeader"
import { useEffectOnce } from "hooks/useEffectOnce"

import APIAccessKeysSection from "./api-access-keys/APIAccessKeysSection"

function MyAccountSettings() {
  const pageTitle = "Account - Settings"
  usePageTitle(pageTitle)
  useEffectOnce(() => {
    mixpanel.track(`${pageTitle} view`, { page_title: pageTitle })
  })

  const { user } = useAuth0()
  const inputProps = { variant: "filled", size: "sm", rounded: "md", isReadOnly: true, isDisabled: true }
  const initialValues = { name: "", email: "", ...user }

  return (
    <Box p={4}>
      <Section header={<SectionHeader title="Account settings" description="Modify your Orbify account settings" />}>
        <VStack spacing={6} divider={<chakra.hr borderColor="gray.100" borderWidth={1} w="full" />} pb={16}>
          <Section
            header={
              <SectionHeader
                size="sm"
                title="Basic settings"
                description="Personal information related to your Orbify account"
                icon={AiOutlineUser}
              />
            }
          >
            <Flex my={10}>
              <Formik initialValues={initialValues} onSubmit={() => {}}>
                {() => (
                  <chakra.form w="full">
                    <InputControl
                      name="name"
                      label="Full name"
                      pb={6}
                      inputProps={{ ...inputProps, placeholder: "Full name" }}
                    />
                    <InputControl
                      name="email"
                      label="E-mail address"
                      pb={6}
                      inputProps={{ ...inputProps, placeholder: "E-mail address" }}
                    />
                  </chakra.form>
                )}
              </Formik>
            </Flex>
          </Section>
          <APIAccessKeysSection />
        </VStack>
      </Section>
    </Box>
  )
}

export default withAuthenticationRequired(MyAccountSettings, {
  onRedirecting: () => <LoadingSpinner />,
})
