import { useSetAtom } from "jotai"

import { embedAtom } from "./atoms/embed"

export default function useSetEmbed() {
  const setIsEmbed = useSetAtom(embedAtom)

  function enableEmbed() {
    setIsEmbed(true)
  }

  function disableEmbed() {
    setIsEmbed(false)
  }

  return { enableEmbed, disableEmbed }
}
