import { IconType } from "react-icons"
import { BiLandscape } from "react-icons/bi"
import { BsCloudSun, BsWater } from "react-icons/bs"
import { IoImageOutline } from "react-icons/io5"
import { MdLensBlur, MdOutlineAgriculture } from "react-icons/md"

import { BaseCollectionType } from "components/openeo-editor/types"

export type CategoryMeta = {
  icon: IconType
  description?: string
  keywords: string[]
  showPaidFeatureBadge?: boolean
}

export interface CollectionCategory extends CategoryMeta {
  collections: BaseCollectionType[]
}

const categoryOther: CategoryMeta = {
  icon: IoImageOutline,
  description: "",
  keywords: [],
}

export const collectionCategories: { [category: string]: CategoryMeta } = {
  "High resolution": {
    icon: MdLensBlur,
    description: "View landscape and urban environments in fine details using high-resolution imagery",
    keywords: ["highres", "naip", "pansharpened", "planet", "planet_derived"],
  },
  Agriculture: {
    icon: MdOutlineAgriculture,
    description: "Use data to better understand ecosystem and environment",
    keywords: ["agriculture", "biodiversity", "crop", "cropland"],
  },
  Landcover: {
    icon: BiLandscape,
    description: "Categorize land cover with classes such as forest, water, built to describe physical landscape",
    keywords: ["built", "built_up", "builtup", "corine", "corine_derived", "landcover"],
  },
  "Climate and weather": {
    icon: BsCloudSun,
    description: "View predictions and interpolations of historical surface variables based on climate models",
    keywords: [
      "aer",
      "aerosol",
      "air_quality",
      "air_temperature",
      "atmosphere",
      "atmospheric",
      "carbon_monoxide",
      "carbon_organic",
      "climate",
      "climate_change",
      "climatic_water_balance",
      "cloud",
      "coldest",
      "condensation",
      "daymet",
      "environment",
      "formaldehyde",
      "methane",
      "nature_conservancy",
      "nitrogen",
      "nitrogen_dioxide",
      "no2",
      "o3",
      "phosphorus",
      "sulfur",
      "sulfur_dioxide",
      "weather",
      "worldclim",
    ],
  },
  "Water and oceans": {
    icon: BsWater,
    description:
      "Precipitation, evapotranspiration, groundwater, surface water and other water related data obtained from earth observation",
    keywords: [
      "aqua",
      "coastal",
      "fishing",
      "flood",
      "glacier",
      "hydroatlas",
      "hydrography",
      "hydrology",
      "hydrosheds",
      "ocean",
      "ocean_color",
      "oceandata",
      "river_networks",
      "river_width",
      "sea_ice",
      "sea_salt",
      "sea_surface_temperature",
      "water",
      "water_mask",
      "water_temp",
      "watercontent",
      "watershed",
    ],
  },
}

export default function categorizeCollections(collections: BaseCollectionType[]) {
  let remainingCollections = [...collections]

  const result = Object.entries(collectionCategories).reduce(
    (categorized: { [category: string]: CollectionCategory }, [categoryName, categoryMeta]) => {
      const matchingCollections = remainingCollections.filter(
        (collection) => !!collection.keywords?.some((keyword) => !!categoryMeta.keywords.includes(keyword))
      )

      categorized[categoryName] = {
        ...categoryMeta,
        collections: matchingCollections,
      }

      remainingCollections = remainingCollections.filter((collection) => !matchingCollections.includes(collection))

      return categorized
    },
    {}
  )

  result["Other"] = {
    ...categoryOther,
    collections: remainingCollections,
  }

  return result
}
