import { Box, Button, ButtonGroup, Flex, IconButton, Input, InputGroup, InputLeftElement } from "@chakra-ui/react"
import { useField } from "formik"
import PropTypes from "prop-types"
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai"

function DefaultEntry(props) {
  const { name, index, onInsert, onRemove, isAddingEnabled } = props
  const [field] = useField(`${name}.${index}`)

  const isColor = () => {
    if (!field.value) {
      return false
    }

    const style = new Option().style
    style.color = field.value

    return !!style.color
  }

  const handleRemove = () => onRemove(index)
  const handleInsert = () => onInsert(index, "")

  return (
    <Flex w="full">
      <InputGroup size="xs">
        {isColor() && (
          <InputLeftElement
            pointerEvents="none"
            children={<Box bgColor={field.value} borderColor="gray.200" rounded="sm" boxSize="12px" />}
          />
        )}
        <Input rounded="md" {...field} />

        <ButtonGroup ml={2} isAttached>
          <IconButton
            aria-label="Remove entry"
            variant="outline"
            icon={<AiOutlineMinus />}
            onClick={handleRemove}
            mr="-px"
          />
          {isAddingEnabled && (
            <IconButton aria-label="Add entry" variant="outline" icon={<AiOutlinePlus />} onClick={handleInsert} />
          )}
        </ButtonGroup>
      </InputGroup>
    </Flex>
  )
}

DefaultEntry.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onInsert: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isAddingEnabled: PropTypes.bool.isRequired,
}

function AddDefaultEntryButton(props) {
  const { onPush } = props

  const handleClick = () => onPush("")

  return (
    <Button variant="secondary" size="xs" me={2} onClick={handleClick}>
      Add entry
    </Button>
  )
}

AddDefaultEntryButton.propTypes = {
  onPush: PropTypes.func.isRequired,
}

export { DefaultEntry, AddDefaultEntryButton }
