import { Flex, IconButton, Tooltip } from "@chakra-ui/react"
import { useCallback } from "react"
import { removeElements } from "react-flow-renderer"
import { VscClose } from "react-icons/vsc"

import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"

function NodeRemoveButton(props) {
  const { data } = props
  const { setElements } = useOpeneoWorkflowContext()

  const onRemove = useCallback(() => {
    const removeNodeReference = (element) => {
      const values = element.data?.values
      if (!values) {
        return element
      }

      element.data.values = Object.entries(values).reduce((obj, [name, value]) => {
        let fromNode = value?.from_node
        if (!fromNode) {
          try {
            const valueObj = JSON.parse(value)
            fromNode = valueObj?.from_node
          } catch {
            // value is not always a stringified object, so parsing failure is expected
          }
        }

        if (fromNode && fromNode === data.id) {
          obj[name] = null
        } else {
          obj[name] = value
        }

        return obj
      }, {})

      return element
    }

    setElements((existingElements) => removeElements([data], existingElements).map(removeNodeReference))
  }, [data, setElements])

  return (
    <Flex position="absolute" top={-1.5} right={-1.5}>
      <Tooltip label="Remove" shouldWrapChildren>
        <IconButton
          minW={4}
          boxSize={4}
          rounded="full"
          shadow="md"
          colorScheme="red"
          variant="solid"
          icon={<VscClose />}
          aria-label="Remove"
          onClick={onRemove}
          visibility="hidden"
          _groupHover={{ visibility: "visible" }}
        />
      </Tooltip>
    </Flex>
  )
}

export default NodeRemoveButton
