import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react"
import { CustomProcess } from "api/models/CustomProcess"
import useAdminRefreshCustomProcess from "api/useAdminRefreshCustomProcess"
import { BsThreeDotsVertical } from "react-icons/bs"
import { VscEdit, VscFile, VscRefresh, VscTrash } from "react-icons/vsc"

import { useNotifications } from "components/common/notifications"

import { CustomProcessDeleteModal } from "./CustomProcessDeleteModal"
import { CustomProcessEditAction } from "./CustomProcessEditAction"
import CustomProcessSchema from "./CustomProcessSchema"

export default function CustomProcessesActions(props: CustomProcessesActionsProps) {
  const { showErrorNotification } = useNotifications()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { customProcess } = props
  const { refreshCustomProcess } = useAdminRefreshCustomProcess()

  function handleRefresh(process_id: string) {
    refreshCustomProcess(process_id).catch((error: any) =>
      showErrorNotification(error, "Failed to refresh custom process schema")
    )
  }

  return (
    <Flex textAlign="start">
      <Menu>
        <Tooltip label="Show options">
          <MenuButton as={IconButton} variant="shadow" icon={<BsThreeDotsVertical />} />
        </Tooltip>
        <MenuList>
          <MenuItem icon={<VscRefresh />} onClick={() => handleRefresh(customProcess.process_id)}>
            Refresh
          </MenuItem>
          <MenuDivider />
          <CustomProcessEditAction customProcess={customProcess}>
            {({ onOpen }) => (
              <MenuItem icon={<VscEdit />} onClick={onOpen}>
                Edit
              </MenuItem>
            )}
          </CustomProcessEditAction>
          <CustomProcessSchema process_id={customProcess.process_id} schema={customProcess.schema}>
            {({ onOpen }) => (
              <MenuItem icon={<VscFile />} onClick={onOpen}>
                View schema
              </MenuItem>
            )}
          </CustomProcessSchema>
          <MenuDivider />
          <MenuItem icon={<VscTrash />} color="red.500" onClick={onOpen}>
            Remove
          </MenuItem>
        </MenuList>
      </Menu>
      <CustomProcessDeleteModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} customProcess={customProcess} />
    </Flex>
  )
}

type CustomProcessesActionsProps = {
  customProcess: CustomProcess
}
