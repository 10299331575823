import { useBoolean } from "@chakra-ui/react"
import { useMonaco } from "@monaco-editor/react"
import { useEffect, useState } from "react"

export default function useCodeEditorToolbarController() {
  const monaco = useMonaco()
  const [editor, setEditor] = useState<any>()

  useEffect(() => {
    if (!monaco?.editor) {
      return
    }

    monaco.editor.onDidCreateEditor(setEditor)
  }, [monaco])

  function handleToggleComment() {
    editor?.getAction("editor.action.commentLine").run()
  }

  function handleFoldAll() {
    editor?.getAction("editor.foldAll").run()
  }

  function handleUnfoldAll() {
    editor?.getAction("editor.unfoldAll").run()
  }

  const [isFormattingDocument, setIsFormattingDocument] = useBoolean()

  function handleFormatDocument() {
    setIsFormattingDocument.on()
    editor?.getAction("editor.action.formatDocument").run().then(setIsFormattingDocument.off)
  }

  return {
    handleToggleComment,
    handleFoldAll,
    handleUnfoldAll,
    isFormattingDocument,
    handleFormatDocument,
  }
}
