import { Flex } from "@chakra-ui/react"

import { childrenType } from "lib/CustomPropTypes"

function ParameterName(props) {
  const { children } = props

  return <Flex flexBasis="30%">{children}</Flex>
}

ParameterName.propTypes = {
  children: childrenType,
}

export default ParameterName
