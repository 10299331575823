import { CheckIcon } from "@chakra-ui/icons"
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { OrganisationInfo } from "api/models/Organisation"
import { User } from "api/models/User"
import useAdminUpdateOrganisationMembers from "api/useAdminUpdateOrganisationMembers"
import { ReactNode, useMemo, useState } from "react"

import OrganisationMembersList from "components/admin/organisations/user-management/OrganisationMembersList"
import { useNotifications } from "components/common/notifications"

import AvailableUsersList from "./AvailableUsersList"

export default function UserManagement(props: UserManagementProps) {
  const { children, organisation } = props

  const [organisationMembers, setOrganisationMembers] = useState<User[]>(organisation.users_details)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { showErrorNotification } = useNotifications()
  const { isLoading, updateOrganisationMembers } = useAdminUpdateOrganisationMembers()

  const organisationMembersChanged = useMemo(() => {
    if (organisation.users_details.length !== organisationMembers.length) return true

    const changedValues = organisationMembers.filter(
      (member) => !organisation.users_details.map((user) => user.id).includes(member.id)
    )
    return !!changedValues.length
  }, [organisation, organisationMembers])

  function handleAddUser(user: User) {
    setOrganisationMembers((existing) => [...existing, user])
  }

  function handleDeleteUser(userToRemove: User) {
    setOrganisationMembers((existing) => existing.filter((user) => user.id !== userToRemove.id))
  }

  function handleClose() {
    setOrganisationMembers(organisation.users_details)
    onClose()
  }

  function handleAddUsers() {
    updateOrganisationMembers({
      organisationId: organisation.id,
      users: organisationMembers,
    })
      .catch((error) => showErrorNotification(error, "Failed to update members of the organisation"))
      .finally(handleClose)
  }

  return (
    <>
      {children({ onOpen })}

      <Modal isOpen={isOpen} onClose={handleClose} size="4xl" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton size="sm" />
          <ModalHeader>
            <Text fontSize="lg" fontWeight="bold">
              {organisation.name}
            </Text>
            <Text fontSize="md" fontWeight="normal" color="red.300">
              Manage users
            </Text>
          </ModalHeader>
          <ModalBody>
            <SimpleGrid columns={2} spacing={6}>
              <AvailableUsersList selectedUsers={organisationMembers} handleAddUser={handleAddUser} />
              <OrganisationMembersList users={organisationMembers} handleDeleteUser={handleDeleteUser} />
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" variant="secondary" mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              size="sm"
              colorScheme="primary"
              rightIcon={<CheckIcon />}
              isLoading={isLoading}
              isDisabled={!organisationMembersChanged}
              onClick={handleAddUsers}
            >
              Save changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

type ChildrenProps = {
  onOpen: () => void
}

type UserManagementProps = {
  organisation: OrganisationInfo
  children: (props: ChildrenProps) => ReactNode
}
