export const terrain = [
  "#333399",
  "#1B63C9",
  "#0393F9",
  "#00BB9B",
  "#25D36D",
  "#6DE27C",
  "#B5F08A",
  "#FEFE98",
  "#DAD085",
  "#B6A272",
  "#92735E",
  "#91726C",
  "#B7A39F",
  "#DBD1CF",
  "#FFFFFF",
]
