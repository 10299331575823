import { Workflow } from "api/models/Workflows"
import buildUrl from "api/utils/buildUrl"
import { useParams } from "react-router-dom"

import { UseParamsType } from "components/workflow-creator/types"
import { Query } from "lib/types/Query"
import useAuthenticatedApi from "services/api/authenticatedApi"

export default function useWorkflowExternalQueries() {
  const api = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1/external/orbs") })

  const { id: orbId } = useParams<UseParamsType>()

  return {
    getWorkflowsQuery,
  }

  function getWorkflowsQuery(): Query<Workflow> {
    return {
      queryKey: ["showWorkflows", orbId],
      queryFn: function showWorkflows() {
        return api.get(`${orbId}/workflows`).json()
      },
    }
  }
}
