import { useCallback } from "react"
import { useStoreState } from "react-flow-renderer"

import { isSchemaMappedWithParameterControls } from "components/openeo-editor/parameters/controls/parameterTypeMapping"

function useConnectionValidator() {
  const nodes = useStoreState((state) => state.nodes)
  const edges = useStoreState((state) => state.edges)

  const isValidConnection = useCallback(
    ({ source, _sourceHandle, target, targetHandle }) => {
      const sourceNode = findMatchingNode(nodes, source)
      const targetParameter = findMatchingParameter(nodes, target, targetHandle)
      const targetSchema = targetParameter?.schema

      // connection may be invalid if target already connected with something
      const isTargetAlreadyConnected = edges.some(
        (edge) => edge.target === target && edge.targetHandle === targetHandle
      )

      // connection is invalid if target connected with something and target is not accepting multiple connections
      const isTargetHandlingMultipleConnections = isSchemaHandlingMultipleInputs(targetParameter.name, targetSchema)
      const isAcceptingConnections = isTargetHandlingMultipleConnections || !isTargetAlreadyConnected

      // connection is invalid if target schema has only parameters with input controls (user-editable)
      const isTargetSchemaOnlyUserEditable = isSchemaMappedWithParameterControls(targetSchema)

      // connection is invalid if target expects different parameter schema
      const sourceSchema = sourceNode?.data.returns.schema
      const isSourceSchemaCompatibleWithTargetSchema = areSchemasCompatible(sourceSchema, targetSchema)

      return isAcceptingConnections && !isTargetSchemaOnlyUserEditable && isSourceSchemaCompatibleWithTargetSchema
    },
    [nodes, edges]
  )

  return { isValidConnection }
}

function findMatchingNode(nodes, nodeId) {
  return nodes.find((node) => node.id === nodeId)
}

function findMatchingParameter(nodes, nodeId, parameterName) {
  const matchingNode = findMatchingNode(nodes, nodeId)
  return matchingNode?.data.parameters.find((parameter) => parameter.name === parameterName)
}

function areSchemasCompatible(sourceSchema, targetSchema) {
  if (!sourceSchema || !targetSchema) {
    return false
  }

  const sourceTypes = Array.isArray(sourceSchema) ? sourceSchema.map(({ type }) => type) : [sourceSchema.type]
  const targetTypes = Array.isArray(targetSchema) ? targetSchema.map(({ type }) => type) : [targetSchema.type]

  return sourceTypes.some((type) => targetTypes.includes(type))
}

function isSchemaHandlingMultipleInputs(name, schema) {
  if (name === "context") {
    // "context" is a special case of parameter allowing to provide a list of edges as an inputs,
    // which are used to build an object
    return true
  }

  if (!schema) {
    return false
  }

  return schema.filter(({ type }) => type === "array").length > 0
}

export default useConnectionValidator
