import { useMemo, useState } from "react"
import { useInfiniteQuery } from "react-query"

import useAdminOrbsApi from "services/api/admin/adminOrbs"

const ORB_SORT_OPTIONS = [
  { name: "Newest First", value: "created_at,asc" },
  { name: "Oldest First", value: "created_at,desc" },
  { name: "A-z", value: "name,asc" },
  { name: "z-A", value: "name,desc" },
]

const PLAN_STATUSES = [
  { name: "All plan statuses", value: "" },
  { name: "Trial", value: "TRIAL" },
  { name: "Extended Trial", value: "EXTENDED_TRIAL" },
  { name: "Active Stripe", value: "STRIPE" },
]

export default function useAdminGDPDemosController() {
  const [searchOption, setSearchOption] = useState("created_at,desc")
  const [searchPlanStatus, setSearchPlanStatus] = useState("")
  const [searchText, setSearchText] = useState("")

  const { list } = useAdminOrbsApi()
  const { isLoading, isFetched, data, hasNextPage, fetchNextPage } = useInfiniteQuery(
    ["showAdminOrbs", searchText, searchPlanStatus, searchOption],
    ({ pageParam = null }) => list(searchOption, searchPlanStatus, searchText, pageParam),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page
      },
    }
  )

  const orbs = useMemo(() => {
    return data?.pages?.map((page) => page.results).flat(2) || []
  }, [data])

  function handleSearch(filterOption: string, planStatus: string, searchText: string) {
    setSearchOption(filterOption)
    setSearchText(searchText)
    setSearchPlanStatus(planStatus)
  }

  return {
    sortOptions: ORB_SORT_OPTIONS,
    planStatuses: PLAN_STATUSES,
    handleSearch,
    isLoading: isLoading && !isFetched,
    orbs: orbs,
    hasNextPage: hasNextPage || false,
    fetchNextPage,
  }
}
