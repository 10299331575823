import { Box, Code, Kbd, Text } from "@chakra-ui/react"
import PropTypes from "prop-types"

import { SafeConfirmModal } from "components/common/safe-confirm-modal"

import { useImpersonateController } from "./impersonateController"

const Impersonate = ({ children, organisation }) => {
  const { onOpen, isOpen, onClose, onConfirm, disableButton } = useImpersonateController(organisation)

  const additionalInfo = (
    <>
      <Text>
        Impersonate and switch to organisation:
        <Box px={4} py={2} my={4} rounded="md" bg="primary.50" color="primary.900" fontWeight="bold">
          <Code fontSize="sm">{organisation.name}</Code>
        </Box>
      </Text>
      <Text p={4} my={4} color="red.500" bgColor="red.50" fontWeight="bold" rounded="md">
        All the actions you do will apply to the selected organisation and may cause a financial load
      </Text>
      <Text my={6} fontWeight="bold">
        To return to your organisation use <Kbd>Switch organization</Kbd> in the user menu
      </Text>
    </>
  )
  return (
    <>
      {children({ onOpen })}
      <SafeConfirmModal
        modalDisclosure={{ isOpen, onOpen, onClose }}
        onConfirm={onConfirm}
        textConfirmation=""
        disableButton={disableButton}
        header="Impersonate organisation"
        additionalInfo={additionalInfo}
        actionLabel="Impersonate"
        requireConfirmation={false}
      />
    </>
  )
}

Impersonate.propTypes = {
  children: PropTypes.func.isRequired,
  organisation: PropTypes.object.isRequired,
}

export default Impersonate
