import { useBoolean } from "@chakra-ui/react"
import { Feature, Overlay } from "ol"
import BaseEvent from "ol/events/Event"
import GeoJSON from "ol/format/GeoJSON"
import { Geometry, Polygon } from "ol/geom"
import { Draw, Interaction, Modify, Snap } from "ol/interaction"
import { DrawEvent } from "ol/interaction/Draw"
import { ModifyEvent } from "ol/interaction/Modify"
import { Layer } from "ol/layer"
import OLVectorLayer from "ol/layer/Vector"
import { get } from "ol/proj"
import { Source, Vector as VectorSource } from "ol/source"
import { ReactNode, createContext, useContext, useEffect, useRef } from "react"

import { useMapContext } from "components/common/open-layers/MapContextProvider"
import { WEB_MERCATOR_PROJECTION } from "components/common/open-layers/utils"
import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"
import { useOpeneoEventsContext } from "components/orb-openeo-workflow/openeo-workflow-console/OpeneoEventsContext"
import ExecuteWorkflowConsoleAction from "components/orb-openeo-workflow/openeo-workflow-console/actions/ExecuteWorkflowConsoleAction"
import { formatArea, getPolygonAreaInKm2 } from "lib/utils"
import { calculateSquareKms } from "services/areaCalculation"

import MeasureTooltip from "./MeasureTooltip"

const DrawContext = createContext<DrawContextResult | null>(null)
const draw = new Draw({
  type: "Polygon",
  source: new VectorSource({ wrapX: false }),
})

const DRAWN_AOI_VECTOR_LAYER_CLASS_NAME = "drawn-aoi-vector-layer"

export default function DrawContextProvider(props: DrawContextProviderProps) {
  const { children } = props
  const { insertSpacer, registerEvent } = useOpeneoEventsContext()
  const { previewAreaOfInterest, setPreviewAreaOfInterest } = useOpeneoWorkflowContext() as any

  const { map } = useMapContext()
  const [isDrawing, setIsDrawing] = useBoolean(false)
  const [allowModify, setAllowModify] = useBoolean(false)
  const measureTooltipRef = useRef<HTMLDivElement>(null)

  useEffect(
    function drawPreviewAOI() {
      const drawnAOIVectorLayer = new OLVectorLayer({ className: DRAWN_AOI_VECTOR_LAYER_CLASS_NAME })
      let measureTooltipOverlay: Overlay

      // @ts-ignore
      if (!draw.listeners_.drawstart) {
        draw.on("drawstart", handleDrawingStart)
      }

      // @ts-ignore
      if (!draw.listeners_.drawend) {
        draw.on("drawend", onDrawEnd)
      }

      return function onDestroy() {
        draw.un("drawstart", handleDrawingStart)
        draw.un("drawend", onDrawEnd)
        map?.removeLayer(drawnAOIVectorLayer)
      }

      function handleDrawingStart(drawEvent: DrawEvent) {
        if (measureTooltipRef.current) {
          measureTooltipOverlay = new Overlay({
            element: measureTooltipRef.current,
            offset: [0, -45],
            positioning: "bottom-center",
            stopEvent: false,
            insertFirst: false,
            className: "ol-overlay-unstyled",
          })

          map?.addOverlay(measureTooltipOverlay)
          drawEvent.feature.getGeometry()?.on("change", handleGeometryChange)

          function handleGeometryChange(event: BaseEvent) {
            if (!measureTooltipRef.current) {
              return
            }

            const geometry = event.target

            const tooltipPos = geometry.getInteriorPoint().getCoordinates()
            measureTooltipRef.current.innerHTML = formatArea(geometry)
            measureTooltipRef.current.classList?.remove("hidden")
            measureTooltipOverlay.setPosition(tooltipPos)
          }
        }
      }

      function onDrawEnd(drawEvent: DrawEvent) {
        const { feature } = drawEvent
        setIsDrawing.off()
        map?.removeInteraction(draw)
        map?.removeOverlay(measureTooltipOverlay)

        const { geojsonFeature: drawnAreaGeoJson, source } = getGeojsonFeatureAndSourceFromEventFeature(feature)

        setPreviewAreaOfInterest(drawnAreaGeoJson)
        insertSpacer()
        registerEvent({
          type: "success",
          content: (
            <>
              Drew area of interest ({calculateSquareKms(drawnAreaGeoJson, 3)} km<sup>2</sup>)
            </>
          ),
          action: <ExecuteWorkflowConsoleAction />,
        })

        drawnAOIVectorLayer.setSource(source)
        map?.addLayer(drawnAOIVectorLayer)

        /* allow modify vector */
        /* disabled instead of applying to `modify` feature the measurement tool and AOI size limitation */
        // if (!allowModify) {
        //   map?.addInteraction(new Snap({ source }))
        //   map?.addInteraction(new Modify({ source }))
        //   setAllowModify.on()
        // }
      }
    },
    [allowModify, insertSpacer, map, registerEvent, setAllowModify, setIsDrawing, setPreviewAreaOfInterest]
  )

  useEffect(
    function modifyPreviewAOI() {
      let modify: Interaction | null = null

      if (allowModify) {
        map?.getInteractions().forEach((interaction) => {
          if (interaction instanceof Modify) {
            interaction.on("modifystart", onModifyStart)
            interaction.on("modifyend", onModifyEnd)
            modify = interaction
          }
        })
      }

      function onModifyStart(modifyEvent: ModifyEvent) {
        // TODO: Try preserve prev state before modification
      }

      function onModifyEnd(modifyEvent: ModifyEvent) {
        const feature = modifyEvent.features.getArray()[0] as Feature<Geometry>
        var polygonGeometry = modifyEvent.features.getArray()[0].getGeometry() as Polygon

        var size = getPolygonAreaInKm2(polygonGeometry)

        if (size > 10) {
          // TODO Revert modification here
        }

        const { geojsonFeature, source } = getGeojsonFeatureAndSourceFromEventFeature(feature)
        setPreviewAreaOfInterest(geojsonFeature)

        map?.getLayers().forEach((layer) => {
          if (layer?.getClassName() === DRAWN_AOI_VECTOR_LAYER_CLASS_NAME) {
            updateLayer(layer as Layer, source)
            updateModifyInteraction(source)
          }
        })
      }

      return function onDestroy() {
        map?.getInteractions().forEach((interaction) => {
          if (interaction instanceof Modify) {
            interaction.un("modifyend", onModifyEnd)
            map.removeInteraction(interaction)
          } else if (interaction instanceof Snap) {
            map.removeInteraction(interaction)
          }
        })
      }

      function updateLayer(layer: Layer, source: Source) {
        map?.removeLayer(layer)
        layer.setSource(source)
        map?.addLayer(layer)
      }

      function updateModifyInteraction(source: VectorSource) {
        if (modify) {
          map?.removeInteraction(modify)
        }
        map?.addInteraction(new Modify({ source }))
      }
    },
    [allowModify, map, setPreviewAreaOfInterest]
  )

  function startDrawing() {
    if (!isDrawing && !previewAreaOfInterest) {
      setIsDrawing.on()
      map?.addInteraction(draw)
    }
  }

  function removeDrawnArea() {
    setIsDrawing.off()
    setAllowModify.off()
    setPreviewAreaOfInterest(null)

    const interactionTypes = [Modify, Snap, Draw]

    map?.getInteractions().forEach((interaction) => {
      interactionTypes.forEach((interactionType) => {
        if (interaction instanceof interactionType) {
          map.removeInteraction(interaction)
        }
      })
    })
    map?.getLayers().forEach((layer) => {
      if (layer?.getClassName() === DRAWN_AOI_VECTOR_LAYER_CLASS_NAME) {
        map.removeLayer(layer)
      }
    })
  }

  function getGeojsonFeatureAndSourceFromEventFeature(eventFeature: Feature<Geometry>) {
    const featureProjection = get(WEB_MERCATOR_PROJECTION)!
    const geojsonFeature = new GeoJSON().writeFeatureObject(eventFeature, {
      featureProjection,
    })
    const source = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonFeature, {
        featureProjection,
      }),
    })
    return { geojsonFeature, source }
  }

  return (
    <DrawContext.Provider
      value={{
        isFeatureSelected: !!previewAreaOfInterest,
        isDrawing,
        startDrawing,
        removeDrawnArea,
      }}
    >
      {children}
      <MeasureTooltip ref={measureTooltipRef} />
    </DrawContext.Provider>
  )
}

export function useDrawContext() {
  const context = useContext(DrawContext)
  if (!context) {
    throw new Error("DrawContext was not initialized. Maybe component was not wrapped in provider")
  }

  return context
}

type DrawContextProviderProps = {
  children: ReactNode
}

type DrawContextResult = {
  isFeatureSelected: boolean
  isDrawing: boolean
  startDrawing: () => void
  removeDrawnArea: () => void
}
