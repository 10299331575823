import { Box, Code, Link, chakra } from "@chakra-ui/react"
import BaseReactMarkdown from "react-markdown"

const ReactMarkdown = chakra(BaseReactMarkdown)

const Markdown = ({ children, ...rest }) => {
  const componentHandlers = {
    code: ({ children, inline }) => {
      if (inline) {
        return (
          <Code color="red.700" fontSize="xs">
            {children}
          </Code>
        )
      } else {
        return (
          <Box p={4} mt={2} bg="gray.50" rounded="md">
            <Code color="red.700" fontSize="xs">
              {children}
            </Code>
          </Box>
        )
      }
    },
    a: ({ children, href }) => (
      <Link
        color="brand.700"
        textDecoration="underline"
        href={href}
        target="_blank"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {children}
      </Link>
    ),
    ul: ({ children }) => (
      <chakra.ul px={4} my={3}>
        {children}
      </chakra.ul>
    ),
    ol: ({ children }) => (
      <chakra.ol px={4} my={3}>
        {children}
      </chakra.ol>
    ),
    li: ({ children }) => <chakra.li my={2}>{children}</chakra.li>,
  }

  return (
    <ReactMarkdown fontSize="sm" components={componentHandlers} {...rest}>
      {children}
    </ReactMarkdown>
  )
}

export default Markdown
