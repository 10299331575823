import { useGeolocationControl, useZoomControl } from "components/common/open-layers/controls"

const useMapboxOLControlsController = () => {
  const { zoomIn, zoomOut } = useZoomControl()
  const { geoLocationEnabled, locateUser } = useGeolocationControl()

  return {
    geoLocationEnabled,
    locateUser,
    zoomIn,
    zoomOut,
  }
}

export { useMapboxOLControlsController }
