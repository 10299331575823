import { Box, Button, Flex, Grid, Link, Text, VStack, useBreakpointValue } from "@chakra-ui/react"
import { OrbConfig } from "api/models/OrbConfig"
import useGetAllowedBIllingFeatures from "api/useGetAllowedBIllingFeatures"
import { usePageTitle, withRoleRequired } from "hooks"
import mixpanel from "mixpanel-browser"
import TagManager from "react-gtm-module"
import { AiOutlinePlus } from "react-icons/ai"
import { NavLink } from "react-router-dom"

import { LoadingSpinner } from "components/common"
import FilterAndSortOrbs from "components/common/filter-and-sort-orbs/FilterAndSortOrbs"
import { GuidedTour } from "components/guided-tour"
import Section from "components/layout/section/Section"
import SectionHeader from "components/layout/section/SectionHeader"
import { useSidebarContext } from "components/layout/sidebar/SidebarContextProvider"
import OrbCard from "components/orb/OrbCard"
import PremiumButtonIndicator from "components/premium-indicators/PremiumButtonIndicator"
import { useEffectOnce } from "hooks/useEffectOnce"
import { SupportedEvents, useEvents } from "hooks/useEvents"
import { ORBIFY_USER_PERMISSION } from "services/userPermissions"

import useListController from "./listController"

const pageTitle = "Application Management Dashboard"

function List() {
  usePageTitle(pageTitle)

  const { isOpen } = useSidebarContext()
  const { handleSearch, isError, isLoading, orbs, searchString, setSearchString, setSortOption, sortOption } =
    useListController()

  const templateColumns = useBreakpointValue({
    base: 1,
    md: isOpen ? 1 : 2,
    lg: isOpen ? 2 : 3,
    xl: isOpen ? 3 : 4,
    "2xl": 4,
  })

  useEffectOnce(() => {
    mixpanel.track("Dashboard view", { page_title: pageTitle })
  })

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Box p={4}>
      <GuidedTour component="OrbsList" />

      <Section header={<ListHeader />}>
        <Flex justifyContent="flex-end">
          <FilterAndSortOrbs
            handleSearch={handleSearch}
            searchString={searchString}
            setSearchString={setSearchString}
            setSortOption={setSortOption}
            sortOption={sortOption}
            mb={10}
          />
        </Flex>
        <Grid
          data-id="orbs-list__overview"
          templateColumns={`repeat(${templateColumns}, 1fr)`}
          columnGap={6}
          rowGap={10}
          w="full"
          flexWrap="wrap"
        >
          {!isError && orbs.map((orb: OrbConfig) => <OrbCard key={orb.id} orb={orb} />)}
        </Grid>
      </Section>
    </Box>
  )
}

function ListHeader() {
  const { trackEvent } = useEvents()
  const { allowedBillingFeatures } = useGetAllowedBIllingFeatures()

  const showPrivateAppsFeatureBadge =
    allowedBillingFeatures && !allowedBillingFeatures.includes("number_of_active_apps")

  return (
    <SectionHeader
      title="Applications"
      description={
        <>
          Manage and setup your geospatial applications.
          <br />
          <Link color="brand.600" href={process.env.REACT_APP_SLACK_COMMUNITY_URL} isExternal>
            Learn more.
          </Link>
        </>
      }
    >
      <VStack spacing={16}>
        <Flex w="full" flexDirection="column">
          <PremiumButtonIndicator
            isPremium={showPrivateAppsFeatureBadge}
            info="Your current plan does not allow creating more apps"
          >
            <Button
              data-id="orbs-list__add-new-app"
              as={NavLink}
              to="/orbs/create"
              variant="solid"
              colorScheme="primary"
              onClick={handleAppCreationButtonClick}
              leftIcon={<AiOutlinePlus />}
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                textAlign: "center",
              }}
              isFullWidth
            >
              Create application
            </Button>
          </PremiumButtonIndicator>
          <Text mt={2} fontSize="xs" color="gray.500">
            Create new Orbify Geospatial Data Platform application
          </Text>
        </Flex>
      </VStack>
    </SectionHeader>
  )

  function handleAppCreationButtonClick(e: any) {
    if (showPrivateAppsFeatureBadge) {
      e.preventDefault()
    }

    mixpanel.track("Click on Start building New Application")

    const tagManagerArgs = {
      dataLayer: {
        event: "new_app_started",
        new_app_started: {
          from: "Homepage",
          from_page: `Orbify - ${pageTitle}`,
        },
      },
    }

    TagManager.dataLayer(tagManagerArgs)
    trackEvent(SupportedEvents.APP_CREATION_STARTED)
  }
}

export default withRoleRequired(List, ORBIFY_USER_PERMISSION)
