import * as yup from "yup"
import { useAuth0 } from "@auth0/auth0-react"
import { chakra } from "@chakra-ui/react"
import { Formik } from "formik"
import { SubmitButton, TextareaControl } from "formik-chakra-ui"
import ky from "ky"

import { LoadingSpinner } from "components/common"
import PriorityControl from "components/feedback/PriorityControl"
import { useUserPermissions } from "services/userPermissions"

export default function FeedbackForm(props) {
  const { onClose } = props
  const { isLoading, initialValues, validationSchema, handleSubmit } = useFeedbackFormController()

  function onSubmit(values) {
    handleSubmit(values).then(onClose)
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(formik) => (
        <chakra.form onSubmit={formik.handleSubmit}>
          <TextareaControl
            name="text"
            fontSize="xs"
            textareaProps={{
              fontSize: "sm",
              placeholder: "What are you trying to do? What would help you?",
              rows: 8,
            }}
          />
          <PriorityControl />
          <SubmitButton size="md" colorScheme="primary" mt={4} mb={2} isDisabled={!formik.isValid} isFullWidth>
            Send feedback
          </SubmitButton>
        </chakra.form>
      )}
    </Formik>
  )
}

function useFeedbackFormController() {
  const { isLoading: isLoadingUser, user } = useAuth0()
  const { isLoading: isLoadingPermissions, permissions } = useUserPermissions()
  const isLoading = isLoadingUser || isLoadingPermissions

  const initialValues = {
    text: "",
    priority: undefined,
    email: user.email,
    name: user.name,
    sendEmail: true,
  }

  const validationSchema = yup.object({
    text: yup.string().required("Please type in your message"),
  })

  function addContextInfo(text) {
    return `
      ${text}
  
  
      ----
      Browser (user-agent): ${window.navigator.userAgent}
      Browser URL: ${window.location.href}
      
      Auth user ID: ${user.sub} (${JSON.stringify(permissions)})
    `.replace(/^\s+/gm, "")
  }

  async function handleSubmit(values) {
    let updatedValues = { ...values, text: addContextInfo(values.text) }

    try {
      await ky.post(`${process.env.REACT_APP_PRODUCTLANE_API_BASE_URL}/feedback`, {
        json: updatedValues,
        headers: { Authorization: `Bearer ${process.env.REACT_APP_PRODUCTLANE_API_KEY}` },
      })
    } catch (error) {
      console.error("Failed to post feedback", error)
    }
  }

  return { isLoading, initialValues, validationSchema, handleSubmit }
}
