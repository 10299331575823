import {
  Button,
  FormControl,
  FormLabel,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
} from "@chakra-ui/react"
import { OrganisationBasicInfo } from "api/models/Organisation"
import useListOrganisations from "api/useListOrganisations"
import { queryClient } from "queryClient"
import { ChangeEvent, useState } from "react"
import { BiDotsVertical } from "react-icons/bi"
import { MdOutlineDriveFileMove } from "react-icons/md"
import { VscTrash } from "react-icons/vsc"
import { useMutation } from "react-query"

import { useNotifications } from "components/common/notifications"
import useOrbsApi from "services/orbs"
import { ORBIFY_ADMIN_PERMISSION, useUserPermissions } from "services/userPermissions"

import useDeletionModalController from "./orbDeletionModalController"

export default function OrbCardMenu(props: { orbId: number }) {
  const { orbId } = props
  const { permissions } = useUserPermissions()
  const isAdminUser = permissions?.includes(ORBIFY_ADMIN_PERMISSION)
  const { isDeleting, onHandleDelete } = useDeletionModalController(null, null, props.orbId)
  const moveModalDisclosure = useDisclosure()
  const { organisationsInfo } = useListOrganisations()
  const hasMultipleOrganizations = !!organisationsInfo && organisationsInfo?.organisations?.length > 1

  if (!isAdminUser && !hasMultipleOrganizations) {
    return null
  }

  return (
    <>
      <Menu isLazy>
        <MenuButton>
          <Icon as={BiDotsVertical} />
        </MenuButton>
        <MenuList>
          <MenuItem icon={<MdOutlineDriveFileMove />} onClick={moveModalDisclosure.onOpen}>
            Move to organisation…
          </MenuItem>
          {isAdminUser && (
            <>
              <MenuDivider />
              <MenuItem icon={<VscTrash />} onClick={onHandleDelete} isDisabled={isDeleting} color="red.500">
                Remove
              </MenuItem>
            </>
          )}
        </MenuList>
      </Menu>

      <MoveToOrganisationModal orbId={orbId} disclosure={moveModalDisclosure} />
    </>
  )
}

function MoveToOrganisationModal(props: { orbId: number; disclosure: { isOpen: boolean; onClose: () => void } }) {
  const {
    orbId,
    disclosure: { isOpen, onClose },
  } = props

  const { organisationsInfo } = useListOrganisations()

  const [selectedOrganisation, setSelectedOrganisation] = useState(0)
  const { showErrorNotification, showNotification } = useNotifications()
  const { changeOwnership } = useOrbsApi()
  const { mutateAsync } = useMutation(changeOwnership, {
    onSuccess: () => queryClient.invalidateQueries(["listOrbs"]),
  })
  const currentOrganisationId = organisationsInfo?.current_organisation_id
  const otherOrganisations = organisationsInfo?.organisations.filter(
    (organisation) => organisation.id !== currentOrganisationId
  )

  if (!organisationsInfo) return null

  function handleOrganisationSelect(event: ChangeEvent<HTMLSelectElement>) {
    setSelectedOrganisation(Number(event.target.value))
  }

  function handleChangeOrbOwnership() {
    mutateAsync({
      orbId,
      targetOrganisationId: otherOrganisations && otherOrganisations[selectedOrganisation]?.id,
    })
      .then(() =>
        showNotification(
          "Application has been moved to the indicated organization",
          "Switch to the organization to see the application.",
          "success"
        )
      )
      .catch((error) => showErrorNotification(error, "Failed to change orb ownership"))
      .finally(onClose)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton size="sm" />
        <ModalHeader fontSize="md">Change application ownership</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>Select organisation</FormLabel>
            <Select value={selectedOrganisation} onChange={handleOrganisationSelect}>
              {otherOrganisations?.map((organisation: OrganisationBasicInfo, index: number) => (
                <option key={organisation.id} value={index}>
                  {organisation.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" colorScheme="red" onClick={handleChangeOrbOwnership}>
            Move
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
