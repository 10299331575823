import { Flex, Icon, Text } from "@chakra-ui/react"
import { BiRectangle } from "react-icons/bi"
import { IoArrowRedoOutline } from "react-icons/io5"
import { MdBackHand } from "react-icons/md"

export default function DragAndDropHint(props: { isVisible: boolean }) {
  const { isVisible } = props

  if (!isVisible) {
    return null
  }

  return (
    <Flex w="full" justifyContent="center" alignItems="center">
      <Flex w="40%" mt={16}>
        <Flex position="relative" me={10}>
          <Icon as={BiRectangle} boxSize={16} color="primary.100" />
          <Icon as={MdBackHand} boxSize={12} color="primary.200" position="absolute" bottom={-3} right={-3} />
          <Icon as={IoArrowRedoOutline} boxSize={16} color="gray.100" position="absolute" top={-8} right={-14} />
        </Flex>
        <Text align="center" color="primary.400" mt={6}>
          Drag and drop elements directly into the workflow editor
        </Text>
      </Flex>
    </Flex>
  )
}
