import { Link } from "@chakra-ui/react"
import mixpanel from "mixpanel-browser"
import { FaSlack } from "react-icons/fa"

import SidebarItem from "components/layout/sidebar/SidebarItem"

const SidebarCommunityLink = () => {
  const label = "Get support"

  return (
    <SidebarItem
      as={Link}
      href={process.env.REACT_APP_SLACK_COMMUNITY_URL}
      target="_blank"
      icon={FaSlack}
      tooltip={label}
      onClick={handleAnalytics}
    >
      {label}
    </SidebarItem>
  )

  function handleAnalytics() {
    mixpanel.track("Click on Get Support")
  }
}

export { SidebarCommunityLink }
