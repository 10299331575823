import { Button, Flex, Heading, ModalBody, ModalFooter, ModalHeader, StackDivider, VStack } from "@chakra-ui/react"
import { Formik } from "formik"
import PropTypes from "prop-types"
import { useMemo } from "react"

import { Markdown } from "components/markdown"
import CollectionInfo from "components/openeo-editor/collection/CollectionInfo"
import { formatHeading } from "components/openeo-editor/lib/nodeUtils"
import { collectionType, parameterType, processType } from "components/openeo-editor/lib/propTypes"
import useFlowUtils from "components/openeo-editor/lib/useFlowUtils"
import { useMetadataModal } from "components/openeo-editor/metadata/MetadataModalContext"
import ParameterEntry from "components/openeo-editor/parameters/editor/ParameterEntry"
import ParameterEntryContextProvider from "components/openeo-editor/parameters/editor/ParameterEntryContext"

function ParameterEditor(props) {
  const { data, parameters } = props
  const { id, description } = data
  const { onClose } = useMetadataModal()
  const { isHandleConnected } = useFlowUtils()
  const isLoadCollectionProcess = ["load_collection", "load_feature_collection"].includes(data.values.process_id)

  const initialValues = parameters.reduce((result, parameter) => {
    if (isHandleConnected("target", data.id, parameter.name)) {
      return result
    }

    return {
      ...result,
      [parameter.name]: (data?.values || {})[parameter.name] || "",
    }
  }, {})

  const handleSubmit = (values) => {
    data.values = { ...data.values, ...values }
    onClose()
  }

  const collectionParametersTab = {
    name: "Parameters",
    body: <ParameterEditorBody data={data} parameters={parameters} />,
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formik) => (
        <>
          <ParameterEditorHeader id={id} description={isLoadCollectionProcess ? null : description} />

          <ModalBody overflowY="scroll" py={4}>
            {isLoadCollectionProcess ? (
              <CollectionInfo collectionId={data["db:id"]} additionalTabs={[collectionParametersTab]} />
            ) : (
              <ParameterEditorBody data={data} parameters={parameters} />
            )}
          </ModalBody>

          <ModalFooter>
            <Button size="sm" onClick={formik.submitForm} isLoading={formik.isSubmitting}>
              Save
            </Button>
          </ModalFooter>
        </>
      )}
    </Formik>
  )
}

ParameterEditor.propTypes = {
  data: PropTypes.oneOfType([processType, collectionType]).isRequired,
  parameters: PropTypes.arrayOf(parameterType).isRequired,
}

function ParameterEditorHeader(props) {
  const { id, description } = props
  const heading = useMemo(() => formatHeading(id), [id])

  return (
    <Flex as={ModalHeader} flexDirection="column" bg="gray.100" roundedTop="md">
      <Heading fontSize="lg" mb={2}>
        {heading}
      </Heading>
      {description && (
        <Markdown fontSize="xs" fontWeight="normal" color="gray.500">
          {description}
        </Markdown>
      )}
    </Flex>
  )
}

ParameterEditorHeader.propTypes = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

function ParameterEditorBody(props) {
  const { data, parameters } = props
  const { id } = data
  const editableParameters = parameters.filter(
    (parameter) => parameter.schema.type !== "object" && parameter.name !== "id"
  )

  return (
    <VStack spacing={2} divider={<StackDivider borderColor="gray.200" />}>
      {editableParameters.map((parameter, index) => (
        <ParameterEntryContextProvider key={index} data={data}>
          <ParameterEntry parentId={id} parameter={parameter} />
        </ParameterEntryContextProvider>
      ))}
    </VStack>
  )
}

ParameterEditorBody.propTypes = {
  data: PropTypes.object.isRequired,
  parameters: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ParameterEditor
