import { Badge } from "@chakra-ui/react"
import { OpenEOJobStatus } from "api/models/OpenEO"

export default function OrbOrderStatus(props: { status: OpenEOJobStatus }) {
  const { status } = props

  switch (status) {
    case "finished":
      return <Badge colorScheme="green">{status}</Badge>
    case "failed":
    case "error":
      return <Badge colorScheme="red">{status}</Badge>
    default:
      return <Badge>{status}</Badge>
  }
}
