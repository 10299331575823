export const gist_heat = [
  "#000000",
  "#1B0000",
  "#360000",
  "#520000",
  "#6E0000",
  "#880000",
  "#A30000",
  "#C00100",
  "#DB2500",
  "#F64900",
  "#FF6D00",
  "#FF9123",
  "#FFB76F",
  "#FFDBB7",
  "#FFFFFF",
]
