import { Link } from "@chakra-ui/react"
import mixpanel from "mixpanel-browser"
import { FaBook } from "react-icons/fa"

import SidebarItem from "components/layout/sidebar/SidebarItem"

export default function KnowledgeBaseLink() {
  const label = "Knowledge base"

  return (
    <SidebarItem
      as={Link}
      href={process.env.REACT_APP_KNOWLEDGE_BASE_URL}
      target="_blank"
      icon={FaBook}
      iconProps={{ color: "green.500" }}
      tooltip={label}
      onClick={handleAnalytics}
    >
      {label}
    </SidebarItem>
  )

  function handleAnalytics() {
    mixpanel.track("Click on Knowledge Base")
  }
}
