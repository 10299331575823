export const winter = [
  "#0000FF",
  "#0012F6",
  "#0024ED",
  "#0037E3",
  "#0049DB",
  "#005BD2",
  "#006DC8",
  "#0080BF",
  "#0092B6",
  "#00A4AD",
  "#00B6A4",
  "#00C89B",
  "#00DB92",
  "#00ED88",
  "#00FF80",
]
