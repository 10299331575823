import { useQuery } from "react-query"

import useOrganisationsQueries from "./queryFunctions/useOrganisationsQueries"

export default function useListOrganisations() {
  const { listOrganisationsQuery } = useOrganisationsQueries()
  const { queryKey, queryFn } = listOrganisationsQuery()
  const { isError, isFetched, isLoading, data } = useQuery(queryKey, queryFn)

  return {
    isError,
    isFetched,
    isLoading,
    organisationsInfo: data,
  }
}
