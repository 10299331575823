import { Box } from "@chakra-ui/react"
import { usePageTitle } from "hooks"

import Section from "components/layout/section/Section"
import SectionHeader from "components/layout/section/SectionHeader"

import CustomProcessAddNew from "./CustomProcessAddNew"
import CustomProcessesList from "./CustomProcessesList"

export default function CustomProcesses() {
  usePageTitle("Administration Panel - Custom Processes")

  return (
    <Box w="full" p={4}>
      <Section
        header={
          <SectionHeader title="Custom Processes">
            <CustomProcessAddNew />
          </SectionHeader>
        }
      >
        <CustomProcessesList />
        {/* <LoadMore hasNextPage={hasNextPage} fetchNextPage={fetchNextPage} isFetchingNextPage={isLoading} />  */}
      </Section>
    </Box>
  )
}
