export const copper = [
  "#000000",
  "#160E09",
  "#2C1C12",
  "#442B1B",
  "#5A3924",
  "#70472D",
  "#875536",
  "#9E6440",
  "#B47249",
  "#CB8052",
  "#E18E5B",
  "#F79C64",
  "#FFAB6D",
  "#FFB976",
  "#FFC77F",
]
