import { Alert, AlertDescription, AlertTitle, Flex } from "@chakra-ui/react"
import Editor, { loader } from "@monaco-editor/react"

import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"
import { useCodeEditorContext } from "components/openeo-editor/code-editor/CodeEditorContext"

import useGetCodeEditorFile from "./useGetCodeEditorFile"

export default function CodeEditor() {
  const { setCode } = useCodeEditorContext()
  const { isFetched, file } = useGetCodeEditorFile()

  function onCodeChange(newValue?: string) {
    setCode(newValue)
  }

  function handleOnEditorMount() {
    loader.config({
      paths: {
        vs: "https://cdn.jsdelivr.net/npm/monaco-editor@0.34.1/min/vs",
      },
    })
  }

  if (!isFetched) {
    return <LoadingSpinner />
  }

  return (
    <Flex w="full" h="full" pt={12}>
      {file.file_name ? (
        <Editor
          height="95%"
          path={`inmemory:///${file.file_name}`}
          language={file.language}
          value={file.data || ""}
          onMount={handleOnEditorMount}
          onChange={onCodeChange}
          loading={<LoadingSpinner />}
          options={{
            minimap: {
              scale: 2,
            },
            fontSize: "11px",
            lineHeight: "16px",
          }}
        />
      ) : (
        <Flex w="full" h="full" justifyContent="center" alignItems="center">
          <Alert w="lg" status="error" rounded="md" flexDirection="column">
            <AlertTitle>This workflow block has no modifiable files</AlertTitle>
            <AlertDescription>Please report this issue on our Slack Community support channel</AlertDescription>
          </Alert>
        </Flex>
      )}
    </Flex>
  )
}
