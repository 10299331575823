import PropTypes from "prop-types"
import { useMemo } from "react"
import { Handle, Position } from "react-flow-renderer"

import handleStyle from "components/openeo-editor/lib/handleStyle"
import { parameterType } from "components/openeo-editor/lib/propTypes"
import useFlowUtils from "components/openeo-editor/lib/useFlowUtils"
import { isSchemaMappedWithParameterControls } from "components/openeo-editor/parameters/controls/parameterTypeMapping"

function ParameterHandle(props) {
  const { position, baseOffset, parameter, target } = props
  const distanceFromTop = baseOffset + 14
  const lineHeight = 16.5

  const { isHandleConnected } = useFlowUtils()

  const hasConnection = isHandleConnected("target", target, parameter.name)
  const isConnectable = !isSchemaMappedWithParameterControls(parameter.schema)

  const borderColor = isConnectable ? "var(--chakra-colors-gray-600)" : "var(--chakra-colors-gray-100)"
  const background = useMemo(() => {
    if (isConnectable) {
      return hasConnection ? "var(--chakra-colors-green-300)" : "var(--chakra-colors-white)"
    } else {
      return "var(--chakra-colors-gray-50"
    }
  }, [isConnectable, hasConnection])

  return (
    <Handle
      id={parameter.name}
      type="target"
      position={Position.Left}
      isConnectable={isConnectable}
      style={{
        ...handleStyle,
        background,
        borderColor,
        left: 9,
        top: distanceFromTop + position * lineHeight,
      }}
    />
  )
}

ParameterHandle.propTypes = {
  position: PropTypes.number.isRequired,
  baseOffset: PropTypes.number.isRequired,
  parameter: parameterType.isRequired,
  target: PropTypes.any, // TODO: Target type
}

export default ParameterHandle
