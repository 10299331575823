export const Oranges = [
  "#FFF5EB",
  "#FEEDDB",
  "#FEE3C8",
  "#FDD6AE",
  "#FDC692",
  "#FDB373",
  "#FDA057",
  "#FD8C3B",
  "#F67824",
  "#EE6410",
  "#E05206",
  "#CB4302",
  "#AD3803",
  "#952F03",
  "#7F2704",
]
