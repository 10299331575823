import { Flex, Heading, chakra } from "@chakra-ui/react"

import { formatHeading } from "components/openeo-editor/lib/nodeUtils"
import { processType } from "components/openeo-editor/lib/propTypes"

function Process(props) {
  const {
    process: { id, title, label, summary },
  } = props
  const heading = formatHeading(title || label || id)

  return (
    <Flex flexDirection="column">
      <Heading fontFamily="mono" fontSize="xs" mb={1} wordBreak="break-all">
        {heading}
      </Heading>

      <chakra.p fontSize="0.875em" color="gray.700">
        {summary}
      </chakra.p>
    </Flex>
  )
}

Process.propTypes = {
  process: processType.isRequired,
}

export default Process
