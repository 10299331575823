import { Auth0Provider } from "@auth0/auth0-react"
import { ChakraProvider } from "@chakra-ui/react"
import { ErrorBoundary, LEVEL_WARN, Provider as RollbarProvider } from "@rollbar/react"
import { parse } from "query-string"
import { ReactNode } from "react"
import { HelmetProvider } from "react-helmet-async"
import { QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { BrowserRouter, Route, Switch } from "react-router-dom"

import BonusCodeHandler from "components/bonus-code-handler/BonusCodeHandler"
import NotificationContextProvider from "components/common/notifications"
import WorkflowEditor from "components/embed-workflow-editor/WorkflowEditor"
import GuidedTourContextProvider from "components/guided-tour/GuidedTourContext"
import { HubspotTracker } from "components/hubspot-tracker/HubspotTracker"
import Layout from "components/layout/Layout"
import SideBarContextProvider from "components/layout/sidebar/SidebarContextProvider"
import { MobileUserWarning } from "components/mobile-user-warning"
import NotFound from "components/not-found/NotFound"
import List from "components/orbs-view/List"
import PricingPage from "components/payments/PricingPage"
import TrackingConsent from "components/tracking-consent/TrackingConsent"

import Applications from "routes/Applications"
import AuthRoute from "routes/AuthRoute"
import MyAccount from "routes/account/MyAccount"
import Admin from "routes/admin/Admin"

import { BusinessEmailRequired } from "./components/business-email-required/BusinessEmailRequired"
import EnvironmentIndicator from "./components/environment-indicator/EnvironmentIndicator"
import { WelcomeScreen } from "./components/welcome-screen/WelcomeScreen"
import { queryClient } from "./queryClient"
import rollbar from "./services/rollbar"
import theme from "./theme"

export default function App() {
  return (
    <Providers>
      <BrowserRouter>
        <HubspotTracker />
        <MobileUserWarning />
        <BonusCodeHandler />
        <TrackingConsent />

        <Layout>
          <Switch>
            <AuthRoute exact path="/" component={WelcomeScreen} />
            <AuthRoute exact path="/business_email_required" component={BusinessEmailRequired} />
            <Route exact path="/pricing" component={PricingPage} />
            <AuthRoute path="/orbs" component={Applications} />
            <AuthRoute path="/account" component={MyAccount} />
            <AuthRoute path="/workflow-editor/:id" component={WorkflowEditor} />
            <AuthRoute path="/admin" component={Admin} />
            <AuthRoute path="*" component={List} />
          </Switch>
        </Layout>
      </BrowserRouter>

      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      <EnvironmentIndicator />
    </Providers>
  )
}

function Providers(props: { children: ReactNode }) {
  const { children } = props

  const email = parse(window.location.search)["email"]
  const encodedEmail = email ? encodeURIComponent(email as string) : null

  return (
    <RollbarProvider instance={rollbar}>
      <HelmetProvider>
        <ChakraProvider theme={theme} portalZIndex={5000}>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
            audience={process.env.REACT_APP_AUTH0_AUDIENCE}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
            redirectUri={window.location.origin}
            scope="openid profile email"
            useRefreshTokens={true}
            useCookiesForTransactions={true}
            login_hint={encodedEmail || ""}
          >
            <ErrorBoundary fallbackUI={NotFound} level={LEVEL_WARN}>
              <QueryClientProvider client={queryClient}>
                <NotificationContextProvider>
                  <GuidedTourContextProvider>
                    <SideBarContextProvider>{children}</SideBarContextProvider>
                  </GuidedTourContextProvider>
                </NotificationContextProvider>
              </QueryClientProvider>
            </ErrorBoundary>
          </Auth0Provider>
        </ChakraProvider>
      </HelmetProvider>
    </RollbarProvider>
  )
}
