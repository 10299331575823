import { Text, VStack, chakra } from "@chakra-ui/react"
import useGetPricingPlanList from "api/useGetPricingPlanList"
import mixpanel from "mixpanel-browser"
import { useLocation } from "react-router-dom"

import { LoadingSpinner } from "components/common"
import { useEffectOnce } from "hooks/useEffectOnce"

import PlansTable from "./pricingTables/PlansTable"

export default function PricingList() {
  const { isLoading, pricingPlans } = useGetPricingPlanList()
  const { pathname } = useLocation()

  const pageTitle = pathname === "/pricing" ? "Pricing" : "Account - Billing - Pricing"

  useEffectOnce(() => {
    mixpanel.track(`${pageTitle} view`, { page_title: pageTitle })
  })

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!pricingPlans || pricingPlans.length < 3) {
    return (
      <Text mt={10} textAlign="center" color="red.500" fontWeight="bold" fontSize="xl">
        There was error while loading pricing list
      </Text>
    )
  }

  return (
    <VStack
      border="1px solid gray.200"
      borderRadius={5}
      spacing={6}
      divider={<chakra.hr borderColor="gray.100" borderWidth={1} w="full" />}
      my={10}
      p={5}
      boxShadow="0 0 0 1px var(--chakra-colors-gray-200)"
    >
      <PlansTable pricingPlans={pricingPlans} />
    </VStack>
  )
}
