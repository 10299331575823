export const gist_earth = [
  "#000000",
  "#0C1976",
  "#184179",
  "#25657D",
  "#30817E",
  "#398B68",
  "#419552",
  "#5EA04B",
  "#82A954",
  "#A0B15A",
  "#B9B35F",
  "#C0A465",
  "#D5B194",
  "#E9CDC5",
  "#FDFBFB",
]
