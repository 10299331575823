import { useEffect } from "react"

import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"

function useKeyControls() {
  const { onSaveGraph, onExecute, previewAreaOfInterest } = useOpeneoWorkflowContext()

  const handleKeyDown = async ({ key, ctrlKey, shiftKey }) => {
    if (ctrlKey && key === "s") {
      await onSaveGraph()
    }

    if (ctrlKey && shiftKey && key === "X") {
      if (!previewAreaOfInterest) {
        return
      }

      await onExecute()
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  })
}

export default useKeyControls
