import { useQuery } from "react-query"

import useWorkflowQueries from "./queryFunctions/useWorkflowQueries"

export default function useShowWorkflow() {
  const { showWorkflowQuery } = useWorkflowQueries()
  const { queryKey, queryFn } = showWorkflowQuery()

  const { data, isLoading } = useQuery(queryKey, queryFn)

  return {
    isLoading,
    workflow: data,
  }
}
