import { Box } from "@chakra-ui/react"
import useGetCurrentPricingPlan from "api/useGetCurrentPricingPlan"

import Section from "components/layout/section/Section"
import SectionHeader from "components/layout/section/SectionHeader"

import CustomerPortal from "./CustomerPortal"
import PricingList from "./PricingList"

export default function BillingPage() {
  const { assignedPricingPlan, subscription } = useGetCurrentPricingPlan()
  const isUserSubscribed = !!assignedPricingPlan && subscription?.status !== "TRIAL"

  return (
    <Box p={4}>
      <Section
        header={
          <SectionHeader
            title="Billing"
            description="Get access to features you need. If you need more - contact sales"
          />
        }
      >
        {isUserSubscribed ? <CustomerPortal /> : <PricingList />}
      </Section>
    </Box>
  )
}
