import { Flex, Text, VStack } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { memo, useEffect, useMemo, useRef, useState } from "react"
import { useUpdateNodeInternals } from "react-flow-renderer"

import { collectionDataType, processDataType } from "components/openeo-editor/lib/propTypes"
import { useMetadataModal } from "components/openeo-editor/metadata/MetadataModalContext"
import ParameterEditor from "components/openeo-editor/parameters/editor/ParameterEditor"
import Parameter from "components/openeo-editor/parameters/parameter/Parameter"
import ParameterHandle from "components/openeo-editor/parameters/parameter/ParameterHandle"

const userParameterPrefix = "user_"

const deforestationBlockNames = ["deforestation", "deforestation_risk", "deforestation_measurements"]

function Parameters(props) {
  const { data } = props
  const containerRef = useRef(null)
  const [parameterHandles, setParameterHandles] = useState(null)
  const updateNodeInternals = useUpdateNodeInternals()
  const { onOpen, setContent } = useMetadataModal()
  const isDeforestationBlock = deforestationBlockNames.includes(data.values?.id)

  const parameters = useMemo(() => {
    const isLoadCollectionProcess = ["load_collection", "load_feature_collection"].includes(data.id)
    const isCodeEditingProcess = [
      "save_execute_code_standalone",
      "save_execute_long_running_code_standalone",
      "execute_code_standalone",
      "execute_ml_code_standalone",
    ].includes(data.values?.id)

    const filterParameter = (parameter) => {
      const isUserParameter = parameter.name.startsWith(userParameterPrefix)
      const isCollectionId = isLoadCollectionProcess && parameter.name === "id"
      const isCodeEditorCode = isCodeEditingProcess && parameter.name === "code"

      return !isUserParameter && !isCollectionId && !isCodeEditorCode
    }

    return data.parameters.filter(filterParameter)
  }, [data])

  const parameterValues = data.values || {}

  const handleEditConfiguration = () => {
    setContent(<ParameterEditor data={data} parameters={parameters} />)
    onOpen()
  }

  useEffect(() => {
    if (!containerRef.current) {
      // when containerRef is not mounted yet, the parameter handle offset
      // cannot be properly calculated, so we need to wait for it
      return null
    }

    const handles = parameters.map((parameter, index) => (
      <ParameterHandle
        key={index}
        position={index}
        target={data.id}
        baseOffset={containerRef.current.offsetTop}
        parameter={parameter}
      />
    ))

    setParameterHandles(handles)
  }, [data.id, parameters])

  useEffect(() => updateNodeInternals(data.id), [data.id, parameterHandles, updateNodeInternals])

  if (!parameters.length) {
    return null
  }

  if (isDeforestationBlock) {
    const inputParameter = { name: "Temporal filter" }

    return (
      <>
        {parameterHandles && parameterHandles[0]}
        <Flex ref={containerRef} bg="white" px={1} py={2} mt={2} rounded="sm">
          <VStack spacing={1} w="full">
            <Parameter target={data.id} parameter={inputParameter} value="" />
            <Text w="full" fontSize="xs" pt={2}>
              Use Orbify GDP application view to edit <code>{data.values.id}</code> block configuration
            </Text>
          </VStack>
        </Flex>
      </>
    )
  }

  return (
    <>
      {parameterHandles}

      <Flex ref={containerRef} bg="white" px={1} py={2} mt={2} rounded="sm" onClick={handleEditConfiguration}>
        <VStack spacing={1} w="full">
          {parameters.map((parameter, index) => (
            <Parameter key={index} target={data.id} parameter={parameter} value={parameterValues[parameter.name]} />
          ))}
        </VStack>
      </Flex>
    </>
  )
}

Parameters.propTypes = {
  data: PropTypes.oneOfType([processDataType, collectionDataType]).isRequired,
}

export default memo(Parameters)
