import { OrbConfig } from "api/models/OrbConfig"
import { PaginatedResponse } from "api/models/PaginatedResponse"
import buildUrl from "api/utils/buildUrl"

import { Query } from "lib/types/Query"
import useAuthenticatedApi from "services/api/authenticatedApi"

export function useOrbsQueries() {
  const api = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1") })

  return { getOrbsListQuery }

  function getOrbsListQuery({
    filterName = "",
    orderBy = "",
    page = 0,
    limit = 100,
    filterByCurrentOrganisation = true,
  }: OrbsListQueryOptions): Query<PaginatedResponse<OrbConfig>> {
    const orderByAsArray = orderBy !== "" ? orderBy.split("-").slice(0, 2) : ["", ""]

    const searchParams = new URLSearchParams([
      ["page", `${page}`],
      ["limit", `${limit}`],
      ["filter_by_name", filterName],
      ["filter_by_current_organisation", filterByCurrentOrganisation ? "true" : "false"],
      ["order_by", orderByAsArray[0]],
      ["order_by", orderByAsArray[1]],
    ])

    return {
      queryKey: ["listOrbs", filterName, orderBy, page, limit, filterByCurrentOrganisation],
      queryFn: function getOrbsList() {
        return api.get("internal/orbs/", { searchParams }).json()
      },
    }
  }
}

export interface OrbsListQueryOptions {
  filterName?: string
  orderBy?: string
  page?: number
  limit?: number
  filterByCurrentOrganisation?: boolean
}
