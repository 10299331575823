import { Flex } from "@chakra-ui/react"
import { useListOrbs } from "api/useListOrbs"
import { useAtomValue } from "jotai"
import { isProcessingBonusCodeAtom } from "state/atoms/bonusCode"

import { ApplicationsList } from "./ApplicationsList"
import { LoadingOverlay } from "./LoadingOverlay"
import { RedirectHandler } from "./RedirectHandler"

export function WelcomeScreen() {
  const { isLoading: isListingOrbs, orbs } = useListOrbs({
    orderBy: "created_at-desc",
    filterByCurrentOrganisation: false,
  })

  const isProcessingBonusCode = useAtomValue(isProcessingBonusCodeAtom)
  const isLoading = isListingOrbs || isProcessingBonusCode

  return (
    <Flex
      position="absolute"
      top={0}
      left={0}
      w="100vw"
      h="100vh"
      alignItems="center"
      justifyContent="center"
      bgImage="https://s3.eu-west-1.amazonaws.com/assets.orbify.app/homepage-hero-bg.png"
      bgSize="cover"
      zIndex={990}
    >
      <LoadingOverlay visible={isLoading} />
      {!isLoading && (
        <>
          <RedirectHandler orbs={orbs} />
          <ApplicationsList orbs={orbs} />
        </>
      )}
    </Flex>
  )
}
