import { Flex, Heading, Text } from "@chakra-ui/react"

import PricingList from "./PricingList"

export default function PricingPage() {
  return (
    <Flex w="full" h="full" p={4} flexDirection="column">
      <Heading mt={10} textAlign="center" fontSize="5xl">
        Orbify Pricing
      </Heading>

      <Text fontSize="xl" mt={5} mb={10} textAlign="center">
        Get access to features you need. If you need more - contact sales
      </Text>

      <PricingList />
    </Flex>
  )
}
