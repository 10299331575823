import { useRollbarContext } from "@rollbar/react"
import PropTypes from "prop-types"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const usePageTitle = (title, description) => {
  const location = useLocation()
  useRollbarContext(title ?? location.pathname)

  const newTitle = `Orbify - ${title ?? location.pathname}`
  const newDescription = description ?? newTitle
  useEffect(() => {
    document.title = newTitle
    document.querySelector('meta[name="description"]')?.setAttribute("content", newDescription)
  }, [newTitle, newDescription])
}

usePageTitle.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default usePageTitle
