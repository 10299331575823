import { OpenEOJobResultChartConfiguration } from "api/models/OpenEO"
// @ts-ignore
import Papa from "papaparse"

export function convertCSVFeaturesToApexChartData(
  features?: string | null,
  chart_configuration?: OpenEOJobResultChartConfiguration
) {
  if (!features || features === "\n" || typeof features !== "string") {
    return { isError: true, errors: [{ type: "GenericError", message: "Invalid CSV input", row: 0 }], series: [] }
  }

  const { data, errors, meta } = Papa.parse(features, {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: "greedy",
  }) as ParseResult

  if (!meta.fields)
    return {
      isError: true,
      errors: [{ type: "GenericError", message: "Cannot define headers of CSV", row: 0 }],
      series: [],
    }

  const xAxisColumn = chart_configuration?.x_axis || "system:time_start"

  if (!meta.fields.includes(xAxisColumn))
    return {
      isError: true,
      errors: [{ type: "GenericError", message: `Cannot find header ${xAxisColumn} in CSV`, row: 0 }],
      series: [],
    }

  const yAxisColumns = defineYAxisColumns(meta.fields, chart_configuration?.y_axis)

  const isError = !!errors.length

  const series = isError
    ? []
    : yAxisColumns.map((category) => ({
        name: category,
        data: data
          .map((entry) => {
            return entry[category] !== null ? { x: entry[xAxisColumn], y: entry[category] } : null
          })
          .filter((entry) => entry !== null),
      }))

  const xAxisType = isError
    ? undefined
    : chart_configuration?.x_axis_type || (data && defineXAxisType(data[0][xAxisColumn]))

  return {
    isError,
    errors,
    series,
    xAxisType,
  }
}

function defineXAxisType(dataSample: any) {
  const minDate = 31536000000 // Jan 01 1971 01:00:00 GMT+0100
  const numeric = new RegExp(/^\d+(?:[.,]\d+)?$/)

  const isNumericAxis = numeric.test(dataSample.toString())
  const isDatetimeAxis = parseInt(dataSample) > minDate

  return isDatetimeAxis ? "datetime" : isNumericAxis ? "numeric" : "category"
}

function defineYAxisColumns(fields: string[], yAxis?: string | string[]) {
  const excludedFieldsByDefault = [".geo", "system:index", "system:time_start"]

  if (!yAxis) return fields.filter((el) => !excludedFieldsByDefault.includes(el))

  if (typeof yAxis === "string") return [yAxis]

  return yAxis
}

type Entry = { [key: string]: any }

export type ErrorType = {
  type: string
  code?: string
  message?: string
  row: number
}

export type MetaType = {
  fields?: string[]
}

type ParseResult = {
  data: Entry[]
  errors: ErrorType[]
  meta: MetaType
}
