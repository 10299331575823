import { useBoolean } from "@chakra-ui/react"
import useGetWorkflows from "api/useGetWorkflows"
import mixpanel from "mixpanel-browser"
import { createContext, useContext, useEffect, useMemo, useState } from "react"
import TagManager from "react-gtm-module"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import { useNotifications } from "components/common/notifications"
import useFlowBuilder from "components/openeo-editor/lib/useFlowBuilder"
import useProcessGraphBuilder from "components/openeo-editor/lib/useProcessGraphBuilder"
import ShowProblematicElementAction from "components/orb-openeo-workflow/openeo-workflow-console/actions/ShowProblematicElementAction"
import { childrenType } from "lib/CustomPropTypes"
import useOpeneoApi from "services/api/openeoApi"

import { useOpeneoEventsContext } from "./openeo-workflow-console/OpeneoEventsContext"

const OpeneoWorkflowContext = createContext({})

function OpeneoWorkflowContextProvider(props) {
  const { children } = props

  const { id: orbId } = useParams()
  const queryClient = useQueryClient()

  const [currentWorkflow, setCurrentWorkflow] = useState(null)
  const [previewAreaOfInterest, setPreviewAreaOfInterest] = useState(null)
  const [previewJobId, setPreviewJobId] = useState(null)
  const [selectedLayersIds, setSelectedLayersIds] = useState(null)
  const [isErrorOccurred, setIsErrorOccurred] = useState(false)
  const [currentError, setCurrentError] = useState(null)
  const [isAddingElement, setIsAddingElement] = useBoolean(false)

  const { registerEvent, insertSpacer } = useOpeneoEventsContext()
  const [isCodeEditorOpen, setIsCodeEditorOpen] = useBoolean(false)

  const { buildProcessGraph } = useProcessGraphBuilder()

  const { isLoading: isLoadingWorkflows, workflows } = useGetWorkflows()

  const { isLoading: isBuildingElements, buildElements } = useFlowBuilder()

  const { isFetching: isGraphLoading, data: savedGraphData } = useQuery(
    ["getOpeneoGraph", currentWorkflow?.id],
    () => getGraph(currentWorkflow?.id),
    {
      enabled: !isBuildingElements,
    }
  )

  const [elements, setElements] = useState([])
  const [legends, setLegends] = useState([])

  useEffect(() => {
    if (isLoadingWorkflows) {
      return
    }

    setCurrentWorkflow(workflows[0])
  }, [isLoadingWorkflows, workflows])

  useEffect(
    function updateElementsWhenSavedGraphOrErrorUpdated() {
      const savedGraph = savedGraphData?.graph
      if (savedGraph) {
        setLegends(savedGraph.legends || [])
        setElements(buildElements(savedGraph, currentError))
      }
    },
    [buildElements, currentError, savedGraphData?.graph]
  )

  useEffect(() => {
    if (isGraphLoading) {
      return
    }
    const savedGraph = savedGraphData?.graph
    if (savedGraph && savedGraph.legends) {
      const nonContinuousLegends = savedGraph.legends.filter((legend) => legend.type !== "continuous")
      const continuousLegends = savedGraph.legends
        .filter((legend) => legend.type === "continuous")
        .map((legend) => convertLegacyContinuousLegend(legend))
      setLegends(nonContinuousLegends.concat(continuousLegends) || [])
    }
  }, [isGraphLoading, savedGraphData])

  const { showNotification, showErrorNotification } = useNotifications()
  const { getGraph, saveGraph, validateGraph, getResults, executeJob } = useOpeneoApi()
  const { isFetching: isResultsLoading, data: jobResults } = useQuery(
    ["getOpeneoJobResults", previewJobId],
    () => getResults(previewJobId),
    {
      onSuccess: (data) => {
        registerLogLines(data?.logs)
        showNotification("", "Execution completed", "success")
        registerEvent({
          type: "success",
          content: <strong>Execution completed</strong>,
          hint: "Select visible layers in the upper left corner of the map",
        })
      },
      onError: async (error) => {
        if (error.response?.status === 400) {
          const error_response = await error.response?.json()
          registerLogLines(error_response?.detail?.logs)
          const error_data =
            error_response?.detail?.results &&
            error_response.detail.results.find((result) => result.status === "failed")
          const errorTraceEnd = (error_data?.data.meta_access.error?.error_trace || []).at(-1)
          const errorLine = errorTraceEnd
            ? `Error in function: ${errorTraceEnd.name} line ${errorTraceEnd.lineno}`
            : null
          let errorMessage = error_data?.data.meta_access.error?.message || error_data?.data.meta_access.message
          errorMessage = errorLine ? `${errorLine}: ${errorMessage}` : errorMessage
          registerEvent({ type: "error", content: errorMessage })
          showErrorNotification(error, error_data?.name, errorMessage)
          setIsErrorOccurred(true)
        }
      },
      retry: (count, error) => {
        return ![400, 403].includes(error.response?.status) && count < 100
      },
      retryDelay: 5000,
      enabled: !!previewJobId,
    }
  )

  const { mutateAsync: saveGraphAsync } = useMutation(saveGraph.bind(null, currentWorkflow?.id), {
    onSuccess: () => queryClient.invalidateQueries(["getOpeneoGraph", currentWorkflow?.id]),
  })

  const { mutateAsync: validateGraphAsync } = useMutation(validateGraph.bind(null, currentWorkflow?.id))

  const { mutateAsync: executeJobAsync } = useMutation(executeJob.bind(null, currentWorkflow?.id), {
    onSuccess: () => queryClient.invalidateQueries(["getOpeneoJobResults", currentWorkflow?.id]),
  })

  const disabledProcessCategories = useMemo(() => {
    let categories = []
    if (elements.some((element) => element.id.startsWith("save_"))) categories.push("export")
    return categories
  }, [elements])

  async function onSaveGraph(legends, graphProp, validate = true, quiet = false) {
    let graph = graphProp || buildProcessGraph()

    if (legends) {
      graph = {
        ...graph,
        legends,
      }
    }

    if (validate) {
      let response = null
      try {
        await validateGraphAsync(graph)
      } catch (error) {
        response = await error.response.json()
        registerEvent({
          type: "warning",
          content: (
            <>
              <strong>{response.code}</strong>
              <br />
              {response.message}
            </>
          ),
          action: <ShowProblematicElementAction nodeId={response.node_id} />,
          hint: response.hint,
        })
      }
      setCurrentError(response)
    }

    await saveGraphAsync(graph)
    if (!quiet) {
      registerEvent({ type: "success", content: "Workflow has been saved" })
    }
    return true
  }

  async function onExecute() {
    insertSpacer()
    handleAnalytics("execution_started")
    mixpanel.track("Execute preview", {
      app_type: "no-code",
      orb_id: Number(orbId),
      workflow_id: Number(currentWorkflow?.id),
    })

    setSelectedLayersIds(null)
    setIsErrorOccurred(false)

    if (!(await onSaveGraph(legends, null, false, true))) return

    setPreviewJobId(null)
    try {
      const openeoJob = await executeJobAsync({ area_of_interest: previewAreaOfInterest.geometry })
      setCurrentError(null)
      setPreviewJobId(openeoJob.id)
      showNotification("", "Execution started", "info")
      registerEvent({ type: "info", content: "Execution started" })
    } catch (error) {
      const response = await error.response.json()
      setCurrentError(response)
      showNotification(
        "Your workflow is incorrect",
        "Problems have been marked in the editor. " +
          "Please correct them and try again. Despite the problems, the workflow was saved.",
        "error"
      )
      registerEvent({
        type: "error",
        content: (
          <>
            <strong>{response.code}</strong>
            <br />
            {response.message}
          </>
        ),
        action: <ShowProblematicElementAction nodeId={response.node_id} />,
        hint: response.hint,
      })
    }
  }

  const availableAssets = useMemo(() => {
    return !jobResults?.assets ? [] : jobResults.assets
  }, [jobResults?.assets])

  function registerLogLines(logs) {
    if (!logs) {
      return
    }

    registerEvent({
      type: "info",
      content: "Execution logs:",
    })

    logs.forEach((log) => {
      const logLevel = log.split(": ")[0]
      registerEvent({
        type: logLevel.toLowerCase(),
        content: log,
      })
    })
  }

  function handleAnalytics(event, details = {}) {
    const tagManagerArgs = {
      dataLayer: {
        event: `no_code_editor_${event}`,
        no_code_editor: details,
      },
    }

    TagManager.dataLayer(tagManagerArgs)
  }

  function convertLegacyContinuousLegend(legend) {
    if (!!legend.min && !!legend.max && legend.palette.every((item) => typeof item === "string")) {
      return {
        ...legend,
        palette: [
          { value: legend.min, color: legend.palette[0] },
          { value: legend.max, color: legend.palette[1] },
        ],
      }
    }
    return legend
  }

  return (
    <OpeneoWorkflowContext.Provider
      value={{
        isGraphLoading: isBuildingElements || isGraphLoading,
        onSaveGraph,
        onExecute,
        isResultsLoading: !isErrorOccurred && (isResultsLoading || (!jobResults && !!previewJobId)),
        availableAssets,
        selectedLayersIds,
        setSelectedLayersIds,
        previewJobId,
        legends,
        setLegends,
        elements,
        setElements,
        previewAreaOfInterest,
        setPreviewAreaOfInterest,
        currentError,
        disabledProcessCategories,
        isCodeEditorOpen,
        setIsCodeEditorOpen,
        isAddingElement,
        setIsAddingElement,
        workflows,
        currentWorkflow,
        setCurrentWorkflow,
        jobResults,
      }}
    >
      {children}
    </OpeneoWorkflowContext.Provider>
  )
}

function useOpeneoWorkflowContext() {
  return useContext(OpeneoWorkflowContext)
}

OpeneoWorkflowContextProvider.propTypes = {
  children: childrenType,
}

export { OpeneoWorkflowContextProvider, OpeneoWorkflowContext, useOpeneoWorkflowContext }
