import buildUrl from "api/utils/buildUrl"

import useAuthenticatedApi from "services/api/authenticatedApi"

const useAdminOrbsApi = () => {
  const api = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1/admin/orbs") })

  return {
    list: (searchOption, searchPlanStatus, searchText, pageParam) => {
      const orderBy = searchOption.split(",")
      const searchParams = new URLSearchParams([
        ["cursor", pageParam ?? 0],
        ["filter_by_name", searchText],
        ...(searchPlanStatus ? [["filter_by_plan_status", searchPlanStatus]] : []),
        ["order_by", orderBy[0]],
        ["order_by", orderBy[1]],
        ["limit", 20],
      ])
      return api
        .get("", {
          searchParams: searchParams,
        })
        .json()
    },
    closeOrb: (orbId) => api.put(`${orbId}/close`, { json: { close_after_days: 0 } }),
  }
}

export default useAdminOrbsApi
