import { Button, Flex, Text } from "@chakra-ui/react"
import mixpanel from "mixpanel-browser"

import { Header } from "components/common"
import { useNotifications } from "components/common/notifications"
import { useEffectOnce } from "hooks/useEffectOnce"
import useStripeApi from "services/api/stripe"

const pageTitle = "Account - Billing - Customer portal"

export default function CustomerPortal() {
  useEffectOnce(() => {
    mixpanel.track(`${pageTitle} view`, { page_title: pageTitle })
  })
  const { createCustomerPortalSession } = useStripeApi()
  const { showErrorNotification } = useNotifications()

  return (
    <Flex flexGrow={1} flexDirection="column" my={10} me={12}>
      <Header>
        <Header.Title fontSize="lg">Customer portal</Header.Title>
      </Header>
      <Text mb={2}>Use customer portal to manage your subscriptions, invoices and billing information.</Text>
      <Button size="sm" onClick={handleCreateCustomerPortalSession} isFullWidth>
        Open customer portal
      </Button>
    </Flex>
  )

  function handleCreateCustomerPortalSession() {
    createCustomerPortalSession()
      .then(({ redirect_to }) => window.location.replace(redirect_to))
      .catch((error) => showErrorNotification(error, "Failed to create customer portal session"))
  }
}
