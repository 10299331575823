import { IconProps } from "@chakra-ui/icons"
import { Flex, FlexProps, Icon, Tooltip, chakra } from "@chakra-ui/react"
import React, { ReactNode } from "react"
import { NavLink, useHistory } from "react-router-dom"

import useIsAdminRoute from "hooks/useIsAdminRoute"

import { useSidebarContext } from "./SidebarContextProvider"

export default function SidebarItem(props: SidebarItemProps) {
  const {
    as = NavLink,
    icon,
    to,
    exact,
    tooltip = null,
    disabled = false,
    children,
    iconProps,
    onClick,
    ...rest
  } = props
  const { isOpen } = useSidebarContext()
  const history = useHistory()
  const isAdmin = useIsAdminRoute()

  function isPathActive(path: string) {
    return history.location.pathname === path
  }

  const navlinkProps =
    as === NavLink
      ? {
          activeStyle: {
            color: isAdmin ? "var(--chakra-colors-red-500)" : "var(--chakra-colors-primary-900)",
            background: isAdmin ? "var(--chakra-colors-red-200)" : "var(--chakra-colors-gray-100)",
          },
        }
      : {}

  const flexProps = disabled
    ? { color: "gray.300", cursor: "not-allowed" }
    : {
        as,
        exact,
        to,
        color: isAdmin ? "red.700" : "black",
        cursor: "pointer",
        _hover: {
          color: isAdmin ? "red.900" : "primary.900",
          bgColor: isAdmin ? "red.100" : "gray.100",
          textDecoration: "none",
        },
      }

  return (
    <Flex
      role="group"
      w="full"
      fontSize="sm"
      position="relative"
      p={2}
      alignItems="center"
      justifyContent={isOpen ? "flex-start" : "center"}
      onClick={onClick}
      rounded="md"
      {...rest}
      {...flexProps}
      {...navlinkProps}
    >
      <SidebarItemIcon
        icon={icon}
        iconProps={iconProps}
        tooltip={tooltip}
        isActive={!disabled && !!to && isPathActive(to)}
      />
      {isOpen && <chakra.span whiteSpace="nowrap">{children}</chakra.span>}
    </Flex>
  )
}

function SidebarItemIcon(props: { icon: any; iconProps?: IconProps; tooltip: string | null; isActive: boolean }) {
  const { icon, iconProps, tooltip, isActive } = props
  const { isOpen } = useSidebarContext()
  const isAdmin = useIsAdminRoute()
  if (!icon) {
    return null
  }

  return (
    <Tooltip label={tooltip}>
      <Flex
        p={1}
        rounded="md"
        color={isActive ? "white" : isAdmin ? "red.500" : "primary.900"}
        bgColor={isActive ? (isAdmin ? "red.500" : "primary.900") : isAdmin ? "red.50" : "gray.200"}
        mr={isOpen ? 4 : 0}
        alignItems="center"
      >
        <Icon boxSize="12px" as={icon} {...iconProps} />
      </Flex>
    </Tooltip>
  )
}

interface SidebarItemProps extends FlexProps {
  icon: any
  to?: string
  exact?: boolean
  tooltip?: string
  disabled?: boolean
  children: ReactNode
  iconProps?: IconProps
}
