import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Icon, Text } from "@chakra-ui/react"
import { ReactNode } from "react"
import { IconType } from "react-icons"

export default function DrawerSection(props: DrawerSectionProps) {
  const { label, description, icon, children } = props

  return (
    <AccordionItem borderWidth={0} _last={{ borderWidth: 0 }}>
      <AccordionButton borderWidth={0}>
        <Flex my={2} flexGrow={1} alignItems="center">
          <Icon as={icon} color="gray.400" boxSize={6} me={4} />
          <Flex w="full" flexDirection="column" alignItems="flex-start">
            <Text color="primary.900" fontSize="sm" fontWeight="semibold">
              {label}
            </Text>
            <Text color="gray.400" fontSize="xs">
              {description}
            </Text>
          </Flex>
        </Flex>
        <AccordionIcon color="primary.900" />
      </AccordionButton>
      <AccordionPanel px={1} py={3}>
        {children}
      </AccordionPanel>
    </AccordionItem>
  )
}

type DrawerSectionProps = {
  label: string
  description: string
  icon: IconType
  children: ReactNode
}
