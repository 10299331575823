import { PaginatedResponse, PaginationOptions } from "api/models/PaginatedResponse"
import { CreateWorkflowRequest, ExtendedOrderExecution, Workflow } from "api/models/Workflows"
import buildUrl from "api/utils/buildUrl"
import { useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import { UseParamsType } from "components/workflow-creator/types"
import { Query } from "lib/types/Query"
import useAuthenticatedApi from "services/api/authenticatedApi"

export default function useWorkflowQueries() {
  const api = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1/internal/orbs") })
  const queryClient = useQueryClient()

  const { id: orbId } = useParams<UseParamsType>()

  return {
    createWorkflowMutation,
    showWorkflowQuery,
    listAllOrdersExecutionsQuery,
  }

  function createWorkflowMutation() {
    const mutationOptions = {
      onSuccess: () => queryClient.invalidateQueries(["showPreviewAreaOfInterest", orbId]),
    }

    return {
      mutationFn: areaOfInterestMutationFn,
      mutationOptions,
    }

    function areaOfInterestMutationFn(workflow: CreateWorkflowRequest): Promise<Workflow> {
      return api.post(`${orbId || workflow.orb_id}/workflow/create`, { json: workflow }).json()
    }
  }

  function showWorkflowQuery(): Query<Workflow> {
    return {
      queryKey: ["showWorkflow", orbId],
      queryFn: function showWorkflow() {
        return api.get(`${orbId}/workflow/`).json()
      },
    }
  }

  function listAllOrdersExecutionsQuery(pagination: PaginationOptions) {
    return {
      queryFn: function listAllOrdersExecutions(): Promise<PaginatedResponse<ExtendedOrderExecution>> {
        return api
          .get(
            `${orbId}/workflow/0/orders/execution`, // workflowId is ignored by the backend
            {
              searchParams: {
                cursor: pagination.cursor || "",
                desc: !!pagination.desc,
                limit: pagination.limit || 10,
              },
            }
          )
          .json()
      },
      queryKey: ["listAllOrdersExecutions"],
    }
  }
}
