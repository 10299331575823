import useSetEmbed from "state/useSetEmbed"

import OpeneoWorkflow from "components/orb-openeo-workflow/OpeneoWorkflow"
import { useEffectOnce } from "hooks/useEffectOnce"

export default function WorkflowEditor() {
  const { enableEmbed } = useSetEmbed()

  useEffectOnce(enableEmbed)

  return <OpeneoWorkflow />
}
