export const RdYlGn = [
  "#A50026",
  "#C82227",
  "#E34933",
  "#F57748",
  "#FCA55D",
  "#FECA79",
  "#FEE999",
  "#FEFFBE",
  "#E3F399",
  "#C3E67D",
  "#9DD569",
  "#70C164",
  "#39A758",
  "#128A49",
  "#006837",
]
