import { Alert, AlertDescription, AlertIcon, Box, Code, Flex, Image, Text, chakra } from "@chakra-ui/react"

import Header from "components/common/header"
import NewFeatureBadge from "components/new-feature-badge/NewFeatureBadge"

const stepsByComponent = {
  OrbsList: [
    {
      target: "[data-id='orbs-list__overview']",
      title: "Your applications",
      content: "Here you'll find a list all your applications - Orbs.",
      disableBeacon: true,
    },
    {
      target: "[data-id='orbs-list__add-new-app']",
      title: "Create a new application",
      content: "Start your journey with Orbify by creating a new application.",
    },
  ],
  OrbGeneralView: [
    {
      target: "[data-id='orb-general-view__overview']",
      title: "Your application settings",
      content: (
        <>
          <Alert status="info" fontSize="sm" p={2} mt={2} mb={6} rounded="md">
            <AlertIcon me={3} />
            <AlertDescription fontSize="sm" lineHeight="sm">
              <Text fontWeight="semibold" mb={2}>
                Your application is ready!
              </Text>
              Visit the application to view it as your application user. Copy the application URL to share it with
              others.
            </AlertDescription>
          </Alert>
          <Text>
            Use this view to review and update your application settings, such as:
            <chakra.ul ml={6} mt={2}>
              <li>name</li>
              <li>description</li>
              <li>template</li>
              <li>authorization</li>
            </chakra.ul>
          </Text>
        </>
      ),
      disableBeacon: true,
      placement: "center",
      styles: {
        options: { width: "30vw" },
      },
    },
    {
      target: "[data-id='orb-general-view__basic-settings']",
      title: "Basic settings",
      content: "Change application's name, description or URL",
    },
    {
      target: "[data-id='orb-general-view__template-settings']",
      title: "Template settings",
      content: "Change the look and feel of your application. The template defines application view for your users.",
    },
    {
      target: "[data-id='orb-general-view__authorization-settings']",
      title: "Authorization settings",
      content: "Decide if you want your application to be public or open only for invited users.",
    },
    {
      target: "[data-id='sidebar__orb-workflow']",
      title: "Workflow",
      content: (
        <>
          <Text>Workflow defines how the content for your application should be generated.</Text>
          <Text mt={2}>Use the editor to build, test and deploy your workflow.</Text>
        </>
      ),
      placement: "right",
    },
    {
      target: "[data-id='sidebar__orb-access-control']",
      title: "Access control",
      content: (
        <>
          <Text>Control your application users and resources.</Text>
          <Text mt={2}>Use access control to manage users and asset access rights</Text>
        </>
      ),
      placement: "right",
    },
    {
      target: "[data-id='orb-general-view__application']",
      title: "Application",
      content:
        "Visit the application to view it as your application user. Copy the application URL to share it with others.",
    },
  ],
  WorkflowPreviewEditor: [
    {
      target: "[data-id='workflow-properties__draw-controls']",
      title: "Select preview area",
      content: (
        <>
          <Text>You can preview the results of your application by drawing an area of interest.</Text>
          <Text mt={2} color={"gray.500"}>
            Be aware that it can be done only once.
          </Text>
        </>
      ),
      disableBeacon: true,
    },
    {
      target: "[data-id='workflow-properties__map-controls']",
      title: "Zoom Tools",
      content: "Use the zoom buttons in order to navigate the map or to find your current location.",
    },
    {
      target: "[data-id='workflow-properties__create-new-preview']",
      title: "Date Range",
      content: 'Order selected preview by clicking the "+" button and selecting the date range for your preview.',
    },
    {
      target: "[data-id='workflow-properties__preview-controls']",
      title: "Previews",
      content: (
        <>
          <Text>
            You can see the list of all your preview orders by clicking the previous button. Next to it you'll see the
            preview status.
          </Text>
          <Text mt={2} color={"gray.500"}>
            Depending of the complexity, it could take few minutes to get the results.
          </Text>
        </>
      ),
    },
    {
      target: "[data-id='workflow-properties__workflow-controls']",
      title: "Workflow",
      content: "Access, edit and customize your application workflow here.",
    },
    {
      target: "[data-id='workflow-properties__aoi-controls']",
      title: "Areas of interest",
      content:
        'All saved areas of interest are listed here. "Public areas of interest" category allows you to pre-define AOI for your users.',
    },
    {
      target: "[data-id='workflow-properties__open-application-controls']",
      title: "Open application",
      content: "Go to your web application and see the implemented changes.",
    },
  ],
  GraphEditor: [
    {
      target: "div.react-flow",
      title: "Workflow graph",
      content:
        "This is a graph of operators sequentially applied to raw satellite image, producing the desired output.",
      disableBeacon: true,
    },
    {
      target: ".chakra-modal__content [data-id='Read']",
      title: "Operator",
      content: (
        <>
          <Text>
            A single operator takes the product from linked input node, processes it and passes the output to the next
            operator in the graph.
          </Text>
          <Text mt={2}>All operators can have multiple outputs.</Text>
          <Text mt={2}>
            Each branch derived from the graph needs to end with <Code fontWeight="bold">Write</Code> operator.
          </Text>
        </>
      ),
    },
    {
      target: "[data-id='graph-editor--blocks-search']",
      title: "Search for blocks",
      content: "You can find the desired block by searching for the name or description.",
    },
    {
      target: ".chakra-modal__content .chakra-accordion button",
      title: "Available categories and operators",
      content: (
        <>
          <Text>Browser to all the available operators divided into categories.</Text>
          <Text mt={2}>
            Drag and drop the selected operator into the graph editor and connect it with existing operators.
          </Text>
        </>
      ),
    },
  ],
  WorkflowPreview: [
    {
      target: "[data-id='workflow-preview__overview']",
      title: "Preview of your application's workflow",
      content: "Here you can execute and display previews of your application's workflow execution.",
      disableBeacon: true,
    },
    {
      target: "button[data-id='workflow-preview__previews-list']",
      title: "Previews management",
      content: "Use the previews list to generate new and view existing workflow previews.",
    },
    {
      target: "button[data-id='toolbox__map-settings']",
      title: "Map settings",
      content: (
        <>
          <Text>Adjust your map settings and manage visible layers.</Text>
          <Text mt={2}>
            Changes applied here also affect the way your application users will see the ordered products.
          </Text>
        </>
      ),
    },
  ],
  OpeneoWorkflowConfigurator: [
    {
      target: "[data-id='workflow-configurator__overview']",
      content: (
        <Flex p={4} flexDirection="column">
          <Flex alignItems="center" mb={4}>
            <Header>
              <NewFeatureBadge featureCreatedAt="2022-08-26" position="absolute" top={-6} right={-6} />
              <Header.Title>Welcome to the Orbify editor!</Header.Title>
              <Header.SubTitle color="brand.700">
                Get up and running quickly with easy to build workflows.
              </Header.SubTitle>
            </Header>
            <Box px={4} my={4} w="70%">
              <Image
                src="https://s3.eu-west-1.amazonaws.com/assets.orbify.app/orbify-no-code-editor.gif"
                rounded="md"
                shadow="md"
              />
            </Box>
          </Flex>
          <Text>
            With just a few mouse clicks, you can select the needed components and upload any of your own code. Then you
            can select how to visualize your results, and your preferred UI. You’re set to go live now!
          </Text>
        </Flex>
      ),
      disableBeacon: true,
      placement: "center",
      styles: {
        options: { width: "50vw" },
      },
    },
    {
      target: "[data-id='workflow-configurator__editor']",
      title: "Editor view",
      content: (
        <>
          <Text mb={2}>This is your editor area. Use it to arrange the workflow.</Text>
          <Text>You can add or edit collections, processes and build connections between graph nodes</Text>
        </>
      ),
    },
    {
      target: "[data-id='workflow-configurator__open-drawer']",
      title: "Components menu",
      content: "Open the components menu to drag items into editor",
    },
    {
      target: "[data-id='workflow-configurator__drawer-components']",
      title: "Components",
      content: "Browse through collections and processes that can be used in the workflow",
    },
    {
      target: "[data-id='workflow-configurator__drawer-search']",
      title: "Search",
      content: "Use search to quickly find relevant assets",
    },
    {
      target: "[data-id='workflow-configurator__draw-area-of-interest']",
      title: "Select area of interest",
      content: "Draw your area of interest on the map",
    },
    {
      target: "[data-id='workflow-configurator__execute']",
      title: "Execute workflow",
      content: "Get results of your workflow instantly by executing it on selected area of interest",
    },
  ],
}

export default stepsByComponent
