export const brg = [
  "#0000FF",
  "#2400DB",
  "#4800B7",
  "#6E0091",
  "#92006D",
  "#B60049",
  "#DA0025",
  "#FE0100",
  "#DA2500",
  "#B64900",
  "#926D00",
  "#6E9100",
  "#48B700",
  "#24DB00",
  "#00FF00",
]
