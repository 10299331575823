import PropTypes from "prop-types"

export const featureType = PropTypes.shape({
  id: PropTypes.string,
  bbox: PropTypes.array,
  type: PropTypes.string,
  coordinates: PropTypes.array,
  properties: PropTypes.object,
})

export const areaOfInterest = PropTypes.shape({
  name: PropTypes.string,
  area_type: PropTypes.string,
  feature: featureType,
})

export const orderItemType = PropTypes.shape({
  type: PropTypes.string.isRequired,
  order_id: PropTypes.number,
  area_of_interest_id: PropTypes.number,
})

export const geeLegendTypes = PropTypes.oneOfType([
  PropTypes.shape({ label: PropTypes.string }),
  PropTypes.shape({ _type: PropTypes.string, image: PropTypes.string }),
])

export const workflowExecution = PropTypes.shape({
  vector_data: PropTypes.string,
  geojson_url: PropTypes.string,
  map_tiles_url: PropTypes.string,
  gee_urls: PropTypes.array,
  gee_legend: geeLegendTypes,
})

export const workflowExecutionBlockType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  block_id: PropTypes.string,
  next_tasks: PropTypes.array,
  previous_task_id: PropTypes.number,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
})

export const workflowExecutionResultType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  created_at: PropTypes.string,
  order_item: orderItemType,
  status: PropTypes.string,
})

export const fileType = PropTypes.shape({
  name: PropTypes.string,
  language: PropTypes.string,
  data: PropTypes.string,
  readOnly: PropTypes.bool,
})

export const codeSnippetType = PropTypes.shape({
  file_name: PropTypes.string,
  language: PropTypes.string,
  data: PropTypes.string,
})

export const refType = PropTypes.shape({
  current: PropTypes.any,
})

export const disclosureType = PropTypes.shape({
  getButtonProps: PropTypes.func,
  getDisclosureProps: PropTypes.func,
  isControlled: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onToggle: PropTypes.func,
})

export const childrenType = PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired

export const orbConfigurationType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  invite_only: PropTypes.bool.isRequired,
  template: PropTypes.string.isRequired,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
})

export const templateDefinitionType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
})

export const viewPortType = PropTypes.shape({
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  zoom: PropTypes.number.isRequired,
  maxBounds: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
})
