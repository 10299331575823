import useAdminOrbManagementQueries from "api/queryFunctions/useAdminOrbManagementQueries"
import { useMutation } from "react-query"

export default function useAdminAssignPricingPlanExternallyPaidToOrb() {
  const { assignOrbPricingPlanExternallyPaidMutation } = useAdminOrbManagementQueries()
  const { mutationFn, mutationOptions } = assignOrbPricingPlanExternallyPaidMutation()

  const {
    isLoading,
    isError,
    error,
    mutateAsync: assignPricingPlanExternallyPaid,
  } = useMutation(mutationFn, mutationOptions)

  return { isLoading, isError, error, assignPricingPlanExternallyPaid }
}
