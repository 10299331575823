import { useDecodedAuthToken } from "services/useDecodedAuthToken"

const ORBIFY_USER_PERMISSION = "orbify:user"
const ORBIFY_ADMIN_PERMISSION = "orbify:admin"

const useUserPermissions = () => {
  const { isLoading, permissions } = useDecodedAuthToken()

  return {
    isLoading,
    permissions,
  }
}

export { useUserPermissions, ORBIFY_USER_PERMISSION, ORBIFY_ADMIN_PERMISSION }
