import { useDisclosure } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { useMutation, useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"

import { useNotifications } from "components/common/notifications"
import useAdminOrganisationsApi from "services/api/admin/adminOrganisations"

const useImpersonateController = (organisation) => {
  const { showNotification, showErrorNotification } = useNotifications()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const history = useHistory()
  const { impersonate } = useAdminOrganisationsApi()
  const queryClient = useQueryClient()

  const { mutateAsync, isLoading } = useMutation(impersonate.bind(null, organisation.id), {
    onSuccess: () => {
      queryClient.invalidateQueries()
      history.replace("/")
      onClose()
    },
  })

  const onConfirm = () => {
    mutateAsync()
      .then(() => {
        showNotification("", "Impersonated successfully", "success")
        onClose()
      })
      .catch(async (error) => {
        let message = error.message
        try {
          const response = await error.response.json()
          message = response.detail
        } catch {}
        showErrorNotification(error, "Failed to impersonate", message)
      })
  }

  return { onOpen, isOpen, onClose, onConfirm, disableButton: isLoading }
}

useImpersonateController.propTypes = {
  organisation: PropTypes.object.isRequired,
}

export { useImpersonateController }
