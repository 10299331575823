import { useDisclosure } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { useMutation, useQueryClient } from "react-query"

import { AdminOrbsEntry } from "components/admin/gdp-demos/types"
import { useNotifications } from "components/common/notifications"
import useAdminOrbsApi from "services/api/admin/adminOrbs"

const useCloseOrbController = (orb: AdminOrbsEntry) => {
  const { showNotification, showErrorNotification } = useNotifications()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { closeOrb } = useAdminOrbsApi()
  const queryClient = useQueryClient()

  const { mutateAsync, isLoading } = useMutation(closeOrb.bind(null, orb.id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["showAdminOrbs"])
      onClose()
    },
  })

  const onConfirm = () => {
    mutateAsync()
      .then(() => {
        showNotification("", "Closed Orb successfully", "success")
        onClose()
      })
      .catch(async (error) => {
        let message = error.message
        try {
          const response = await error.response.json()
          message = response.detail
        } catch {}
        showErrorNotification(error, "Failed to close orb", message)
      })
  }

  return { onOpen, isOpen, onClose, onConfirm, disableButton: isLoading }
}

useCloseOrbController.propTypes = {
  organisation: PropTypes.object.isRequired,
}

export { useCloseOrbController }
