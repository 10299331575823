import { Box, Flex, IconButton, Input, Select, Tooltip, chakra } from "@chakra-ui/react"
import { ChangeEvent, FormEvent, useState } from "react"
import { MdSearch } from "react-icons/md"

export default function AdminGdpDemosFilter(props: props) {
  const { planStatuses, sortOptions, handleSearch } = props
  const [currentSortOption, setCurrentSortOption] = useState(sortOptions[0].value)
  const [currentPlanStatus, setCurrentPlanStatus] = useState(planStatuses[0].value)
  const [searchText, setSearchText] = useState("")

  function onSubmit(event: FormEvent) {
    event.preventDefault()
    handleSearch(currentSortOption, currentPlanStatus, searchText)
  }

  function handlePlanStatusChange(event: ChangeEvent<HTMLSelectElement>) {
    setCurrentPlanStatus(event.target.value)
    handleSearch(currentSortOption, event.target.value, searchText)
  }

  function handleSortOptionChange(event: ChangeEvent<HTMLSelectElement>) {
    setCurrentSortOption(event.target.value)
    handleSearch(event.target.value, currentPlanStatus, searchText)
  }

  function handleSearchTextChange(event: ChangeEvent<HTMLInputElement>) {
    setSearchText(event.target.value)
  }

  return (
    <chakra.form w="100%" onSubmit={onSubmit}>
      <Flex w="full">
        <Select size="sm" roundedLeft="md" value={currentPlanStatus} w="200px" onChange={handlePlanStatusChange}>
          {planStatuses.map(({ name, value }) => (
            <option key={value} value={value}>
              {name}
            </option>
          ))}
        </Select>
        <Select size="sm" roundedLeft="md" value={currentSortOption} w="200px" onChange={handleSortOptionChange}>
          {sortOptions.map(({ name, value }) => (
            <option key={value} value={value}>
              {name}
            </option>
          ))}
        </Select>
        <Box w="full"></Box>
        <Input size="sm" w="200px" ml="-1px" borderRadius={0} value={searchText} onChange={handleSearchTextChange} />
        <Tooltip label="Search">
          <IconButton
            type="submit"
            size="sm"
            colorScheme="primary"
            icon={<MdSearch />}
            aria-label="Search"
            borderLeftRadius={0}
          />
        </Tooltip>
      </Flex>
    </chakra.form>
  )
}

export type AdminGdpDemosFilterOption = {
  name: string
  value: string
}

type props = {
  sortOptions: AdminGdpDemosFilterOption[]
  planStatuses: AdminGdpDemosFilterOption[]
  handleSearch: (option: string, planStatus: string, text: string) => void
}
