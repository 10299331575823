import { Button, Menu, MenuButton, MenuItem, MenuItemOption, MenuList, MenuOptionGroup } from "@chakra-ui/react"
import PropTypes from "prop-types"

function MultiSelect(props) {
  const {
    label,
    options,
    icon,
    selectedOptions,
    setSelectedOptions,
    emptyOptionsText = `No ${label.toLowerCase()} available`,
  } = props

  if (!options || !selectedOptions) return null

  const buttonLabel = `${label}${selectedOptions.length > 0 ? ` (${selectedOptions.length})` : ""}`

  return (
    <Menu closeOnSelect={false}>
      <MenuButton as={Button} variant="secondary" leftIcon={icon} size="xs">
        {buttonLabel}
      </MenuButton>

      <MenuList>
        <MenuOptionGroup title={label} type="checkbox" defaultValue={selectedOptions} onChange={setSelectedOptions}>
          {options.map(({ id, label }) => (
            <MenuItemOption key={id} value={id} fontSize="sm">
              {label}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
        {!options.length && (
          <MenuItem fontSize="sm" isDisabled>
            {emptyOptionsText}
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  )
}

MultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  icon: PropTypes.element.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
  setSelectedOptions: PropTypes.func.isRequired,
  emptyOptionsText: PropTypes.string,
}

export default MultiSelect
