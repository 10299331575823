import { Badge, Button, Flex, HStack, VStack, chakra, useDisclosure } from "@chakra-ui/react"
import { OrbConfig } from "api/models/OrbConfig"
import useGetAllowedBIllingFeatures from "api/useGetAllowedBIllingFeatures"
import { usePageTitle, withRoleRequired } from "hooks"
import { IoMailOutline, IoSettingsOutline } from "react-icons/io5"

import usePlatformAccessControlController from "components/account/access-control/platformAccessControlController"
import { LoadingSpinner } from "components/common"
import AccessControlTabs from "components/common/access-control/AccessControlTabs"
import InvitationModal from "components/common/access-control/InvitationModal"
import FilterAndSortOrbs from "components/common/filter-and-sort-orbs/FilterAndSortOrbs"
import Section from "components/layout/section/Section"
import SectionHeader from "components/layout/section/SectionHeader"
import OrbLogo from "components/orb/orb-logo/OrbLogo"
import PremiumButtonIndicator from "components/premium-indicators/PremiumButtonIndicator"
import { ORBIFY_USER_PERMISSION } from "services/userPermissions"

function PlatformAccessControl() {
  usePageTitle("Account - Access Control")

  const { handleSearch, isError, isLoading, orbs, searchString, setSearchString, setSortOption, sortOption } =
    usePlatformAccessControlController()

  const { allowedToManageAppUsers } = useGetAllowedBIllingFeatures()

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isError || !orbs.length) {
    throw new Error("Failed to check platform access control settings")
  }

  return (
    <Flex p={4} flexDirection="column">
      {!allowedToManageAppUsers && (
        <Badge position="absolute" colorScheme="red" rounded="base" fontSize="2xs">
          Premium
        </Badge>
      )}
      <Section header={<SectionHeader title="Access control" description="Setup access to your applications" />}>
        <Flex justifyContent="flex-end">
          <FilterAndSortOrbs
            handleSearch={handleSearch}
            searchString={searchString}
            setSearchString={setSearchString}
            setSortOption={setSortOption}
            sortOption={sortOption}
            mb={10}
          />
        </Flex>
      </Section>

      <VStack spacing={4} divider={<chakra.hr w="full" />} pb={16}>
        {orbs.map((orb: OrbConfig) => (
          <Section key={orb.id} header={<OrbAccessControlHeader orb={orb} />}>
            <AccessControlTabs id={orb.id} allowAllColumns />
          </Section>
        ))}
      </VStack>
    </Flex>
  )
}

function OrbAccessControlHeader(props: OrbAccessControlHeaderProps) {
  const { orb } = props
  const invitationModal = useDisclosure()
  const { allowedToManageAppUsers } = useGetAllowedBIllingFeatures()

  return (
    <SectionHeader size="sm" title={orb.name} icon={<OrbLogo orb={orb} size="xs" rounded="base" me={2} />}>
      <HStack alignItems="flex-start" mt={-6} mb={2}>
        {orb.invite_only && (
          <Badge colorScheme="primary" rounded="base" fontSize="2xs">
            Invite only
          </Badge>
        )}
        {orb.public && (
          <Badge colorScheme="primary" rounded="base" fontSize="2xs">
            Public
          </Badge>
        )}
      </HStack>
      <VStack spacing={1}>
        <Button as="a" href={`/orbs/view/${orb.id}`} w="full" size="xs" leftIcon={<IoSettingsOutline />}>
          Application settings
        </Button>
        <PremiumButtonIndicator
          isPremium={!allowedToManageAppUsers}
          info="Your current plan does not allow managing users"
          wrapperOptions={{ w: "full" }}
          badgeSize="xs"
        >
          <Button w="full" size="xs" leftIcon={<IoMailOutline />} onClick={invitationModal.onOpen} isFullWidth>
            Invite user
          </Button>
        </PremiumButtonIndicator>
        {/* TODO: This seems quite ineffective  - we're rendering a modal for every single orb this way */}
        <InvitationModal {...invitationModal} id={orb.id} />
      </VStack>
    </SectionHeader>
  )
}

type OrbAccessControlHeaderProps = {
  orb: OrbConfig
}

export default withRoleRequired(PlatformAccessControl, ORBIFY_USER_PERMISSION)
