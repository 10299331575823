import { SmallCloseIcon } from "@chakra-ui/icons"
import { Alert, AlertDescription, Flex, IconButton, VStack } from "@chakra-ui/react"
import useGetOrbExtents from "api/useGetOrbExtents"
import { Dispatch, SetStateAction } from "react"

export default function CurrentSpatialRestrictionsForUser(props: CurrentSpatialRestrictionsForUserProps) {
  const { isLoading: isLoadingExtents, extents } = useGetOrbExtents()
  const { extentIds, setExtentIds } = props

  function getExtentName(extentId: number) {
    return extents?.find((extent) => extent.id === extentId)?.name
  }

  function handleRemoveExtent(extentId: number) {
    if (!extentIds) {
      return
    }

    setExtentIds(extentIds.filter((id) => id !== extentId))
  }

  if (isLoadingExtents) {
    return null
  }

  if (!extentIds?.length) {
    return (
      <Alert status="info" rounded="md">
        <AlertDescription>User has no spatial access restrictions</AlertDescription>
      </Alert>
    )
  }

  return (
    <VStack spacing={2} my={2}>
      {extentIds.map((extentId, index) => (
        <Flex key={index} w="full" justifyContent="space-between" alignItems="center">
          {getExtentName(extentId)}
          <IconButton
            size="sm"
            variant="ghost"
            aria-label="Remove extent"
            icon={<SmallCloseIcon />}
            onClick={() => handleRemoveExtent(extentId)}
          />
        </Flex>
      ))}
    </VStack>
  )
}

type CurrentSpatialRestrictionsForUserProps = {
  extentIds?: number[]
  setExtentIds: Dispatch<SetStateAction<number[]>>
}
