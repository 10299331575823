export const inferno = [
  "#000004",
  "#0D0829",
  "#280B53",
  "#490B6A",
  "#65156E",
  "#82206C",
  "#9F2A63",
  "#BC3754",
  "#D44842",
  "#E8602D",
  "#F57D15",
  "#FB9D07",
  "#FAC228",
  "#F3E55D",
  "#FCFFA4",
]
