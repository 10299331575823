import { User } from "api/models/User"
import buildUrl from "api/utils/buildUrl"
import useAuthedApiWhenLoggedIn from "api/utils/useAuthedApiWhenLoggedIn"

import { Query } from "lib/types/Query"

export default function useAdminUsersQueries() {
  const api = useAuthedApiWhenLoggedIn({ baseUrl: buildUrl("/api/v1/admin/users") })

  return {
    getAllUsersQuery,
  }

  function getAllUsersQuery(): Query<User[]> {
    return {
      queryKey: ["admin", "list-users"],
      queryFn: function listAdminUsers() {
        return api.get("").json()
      },
    }
  }
}
