import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import { User } from "api/models/User"
import useEditOrbUser from "api/useEditOrbUser"
import { useState } from "react"

import { useNotifications } from "components/common/notifications"

import AddSpatialRestriction from "./AddSpatialRestriction"
import CurrentSpatialRestrictionsForUser from "./CurrentSpatialRestrictionsForUser"

export default function UserExtentsEditorModal(props: UserExtentsEditorModalProps) {
  const { isOpen, onClose, user } = props
  const { isLoading, editUser } = useEditOrbUser()
  const [selectedExtentIds, setSelectedExtentIds] = useState<number[]>(user.extents?.map((extent) => extent.id) || [])

  const { showErrorNotification } = useNotifications()

  function handleAddExtent(extentId: number) {
    setSelectedExtentIds((existing) => [...existing, extentId])
  }

  async function handleEditUser() {
    try {
      await editUser({
        ...user,
        extents: selectedExtentIds,
      })
    } catch (error: any) {
      showErrorNotification(error, "Failed to update user")
    }
    onClose()
  }

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton size="sm" />
        <ModalHeader fontSize="md">User spatial access permissions</ModalHeader>
        <ModalBody>
          <Alert status="warning" rounded="md" mb={4}>
            <AlertIcon />
            <AlertDescription>
              Assigning spatial extent permission to user will <strong>restrict</strong> the area in which user can
              execute and view exposesd workflow results and datasets
            </AlertDescription>
          </Alert>
          <CurrentSpatialRestrictionsForUser extentIds={selectedExtentIds} setExtentIds={setSelectedExtentIds} />
          <AddSpatialRestriction onAdd={handleAddExtent} />
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button size="sm" onClick={onClose}>
            Cancel
          </Button>
          <Button size="sm" colorScheme="green" onClick={handleEditUser} isLoading={isLoading}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type UserExtentsEditorModalProps = {
  isOpen: boolean
  onClose: () => void
  user: User
}
