import { useAuth0 } from "@auth0/auth0-react"
import { useAtom, useSetAtom } from "jotai"
import { atomWithStorage } from "jotai/utils"
import { parse } from "query-string"
import { useEffect } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useHistory, useLocation } from "react-router-dom"
import { isProcessingBonusCodeAtom } from "state/atoms/bonusCode"

import { useNotifications } from "components/common/notifications"
import useUserApi from "services/api/user"

const BONUS_CODE_LS_KEY = "ORBIFY_BONUS_CODE"
const BONUS_CODE_SEARCH_PARAM_NAME = "bcode"

const bonusCodeAtom = atomWithStorage(BONUS_CODE_LS_KEY, undefined)

export default function OnBoardingAuthGate() {
  return <BonusCodeHandler />
}

const BonusCodeHandler = () => {
  const location = useLocation()
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0()
  const history = useHistory()
  const { showErrorNotification } = useNotifications()
  const queryClient = useQueryClient()
  const { useBonusCode } = useUserApi()
  const setIsProcessingBonusCode = useSetAtom(isProcessingBonusCodeAtom)
  const [bonusCode, setBonusCode] = useAtom(bonusCodeAtom)

  const { mutateAsync, isLoading: isCodeLoading, isSuccess: isCodeSuccess } = useMutation(useBonusCode)

  useEffect(
    () => {
      const searchParams = parse(location.search, { ignoreQueryPrefix: true })
      if (searchParams[BONUS_CODE_SEARCH_PARAM_NAME]) {
        setBonusCode(searchParams[BONUS_CODE_SEARCH_PARAM_NAME])
      }

      setIsProcessingBonusCode(!!bonusCode)

      // if we're still authenticating, the code is being pushed
      // to the API or simply user is not authenticated,
      // there's nothing to do here
      if (!bonusCode || isLoading || !isAuthenticated || isCodeLoading || isCodeSuccess) return

      localStorage.removeItem(BONUS_CODE_LS_KEY)
      mutateAsync(bonusCode)
        .then(async (response) => {
          await queryClient.invalidateQueries(["listOrbs"])
          setIsProcessingBonusCode(false)
        })
        .catch(async (error) => {
          let message = error.message
          try {
            const response = await error.response.json()
            message = response.detail
          } catch {}
          history.replace("/")
          showErrorNotification(error, "Failed to apply bonus code: ", message)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isLoading,
      isAuthenticated,
      location.search,
      mutateAsync,
      showErrorNotification,
      isCodeLoading,
      getAccessTokenSilently,
      history,
      isCodeSuccess,
      queryClient,
    ]
  )

  return null
}
