import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, SimpleGrid, Text } from "@chakra-ui/react"
import { MdOutlineShowChart } from "react-icons/md"
import { VscAdd, VscListUnordered } from "react-icons/vsc"

import LegendPreview from "./LegendPreview"
import { LegendType } from "./types"

export default function LegendsSummary(props: LegendsSummaryProps) {
  const { legends, setLegends, setLegend, setMode } = props
  const hasLegends = !!legends.length

  return (
    <Flex w="full" flexDirection="column">
      <Flex w="full" justifyContent="flex-end">
        <Menu>
          <MenuButton as={Button} size="sm" leftIcon={<VscAdd />} mb={4}>
            Add new legend
          </MenuButton>
          <MenuList>
            <MenuItem fontSize="md" icon={<VscListUnordered />} onClick={() => setMode("basicForm")}>
              New basic legend
            </MenuItem>
            <MenuItem fontSize="md" icon={<MdOutlineShowChart />} onClick={() => setMode("continuousForm")}>
              New continuous legend
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      {hasLegends ? (
        <SimpleGrid columns={3} spacing={4}>
          {legends.map((legend, index) => (
            <Flex key={index}>
              <LegendPreview
                index={index}
                legend={legend}
                legends={legends}
                setLegend={setLegend}
                setLegends={setLegends}
                setMode={setMode}
              />
            </Flex>
          ))}
        </SimpleGrid>
      ) : (
        <Flex w="full" p={6} justifyContent="center" alignItems="center">
          <Text fontSize="lg" fontWeight="semibold" color="gray.400">
            No configured legends
          </Text>
        </Flex>
      )}
    </Flex>
  )
}

type LegendsSummaryProps = {
  legends: LegendType[]
  setLegends: (legends: LegendType[]) => void
  setLegend: (legend: LegendType) => void
  setMode: (mode: string) => void
}
