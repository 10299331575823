import { useRadioGroup } from "@chakra-ui/react"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"
import { useImperativeHandle, useRef } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import useTemplatesApi from "services/api/templatesApi"

function useTemplateSelectorController(ref) {
  const { id: orbId } = useParams()
  const { listTemplates } = useTemplatesApi()
  const { data: templates, isLoading, isError } = useQuery(["listTemplates", orbId], () => listTemplates(orbId))

  const { initialValues, setFieldValue, setSubmitting, submitForm, values } = useFormikContext()

  const previousValue = useRef(values.template)

  const handleChange = (template) => {
    if (previousValue === template) {
      return
    }

    setFieldValue("template", template)
    submitForm().then(() => setSubmitting(false))
  }

  const { getRootProps, getRadioProps, setValue } = useRadioGroup({
    name: "template",
    defaultValue: initialValues.template,
    onChange: handleChange,
  })

  const group = getRootProps()

  useImperativeHandle(ref, () => ({
    handleCancel() {
      setValue(previousValue.current)
    },
  }))

  return {
    isLoading,
    isError,
    templates,
    group,
    getRadioProps,
  }
}

useTemplateSelectorController.propTypes = {
  ref: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
}

export default useTemplateSelectorController
