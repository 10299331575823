import { Button, useDisclosure } from "@chakra-ui/react"
import useAdminCreateCustomProcess from "api/useAdminCreateCustomProcess"
import { AiOutlinePlus } from "react-icons/ai"

import CustomProcessEditor from "./CustomProcessEditor"

export default function CustomProcessAddNew() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isLoading, createCustomProcess } = useAdminCreateCustomProcess()
  const initialValues = {
    process_id: "",
    remote_access: {
      repository: "openeo-processes",
      github_username: "OrbifyInc",
      github_profile: "github",
    },
  }
  const header = "Add new custom process"

  return (
    <>
      <Button size="sm" colorScheme="primary" onClick={onOpen} leftIcon={<AiOutlinePlus />}>
        Add custom process
      </Button>
      <CustomProcessEditor
        initialValues={initialValues}
        header={header}
        isOpen={isOpen}
        onClose={onClose}
        isLoading={isLoading}
        handleSaveCustomProcess={createCustomProcess}
      />
    </>
  )
}
