import { useCallback } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import { ProcessDataType } from "components/openeo-editor/types"
import useOpeneoApi from "services/api/openeoApi"
import { QueryParams } from "services/types"

export default function useProcesses() {
  const { id: orbId } = useParams<QueryParams>()
  const { listProcesses } = useOpeneoApi()
  const { isLoading, isError, data } = useQuery(["listProcesses"], () => listProcesses(orbId), { staleTime: 60000 })

  const getProcessById = useCallback(
    (id) => {
      if (!data?.processes) {
        return null
      }

      return (data?.processes as ProcessDataType[]).find((process) => process.id === id)
    },
    [data]
  )

  function getProcessId(id: string, type: string) {
    switch (type) {
      case "image":
        return "load_image"
      case "collection":
        return "load_collection"
      case "feature":
      case "featureCollection":
        return "load_feature_collection"
      default:
        return id
    }
  }

  function getNodeData(id: string, type: string) {
    const processId = getProcessId(id, type)
    const processDefinition = getProcessById(processId)
    const process = {
      ...processDefinition,
      values: { ...(processDefinition?.values || {}), process_id: processId, id },
    }

    return process || {}
  }

  return { isLoading, isError, processes: data?.processes, getProcessById, getNodeData }
}
