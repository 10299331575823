import { Alert, AlertIcon, useDisclosure } from "@chakra-ui/react"
import useResetWorkflowGraph from "api/useResetWorkflowGraph"
import { ReactNode } from "react"

import { useNotifications } from "components/common/notifications"
import { SafeConfirmModal } from "components/common/safe-confirm-modal"

export function ResetWorkflowModal({ workflowId, children }: Props) {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { isLoading, resetGraph } = useResetWorkflowGraph()
  const { showNotification } = useNotifications()

  async function handleResetGraph() {
    try {
      await resetGraph(null)
    } catch (error: any) {
      showNotification(error, "Failed to reset workflow graph", "error")
    } finally {
      onClose()
    }
  }

  return (
    <>
      {children({ onOpen })}

      <SafeConfirmModal
        modalDisclosure={{ isOpen, onClose, onOpen }}
        onConfirm={handleResetGraph}
        disableButton={isLoading}
        textConfirmation=""
        additionalInfo={
          <Alert status="error" rounded="md">
            <AlertIcon />
            This will bring the workflow configuration back to the original state
          </Alert>
        }
        actionLabel="Reset"
        header="Reset workflow"
        requireConfirmation={false}
      />
    </>
  )
}

type ChildrenProps = {
  onOpen: () => void
}

interface Props {
  workflowId: number
  children: (props: ChildrenProps) => ReactNode
}
