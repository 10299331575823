import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react"
import useAdminAssignPricingPlanExternallyPaidToOrb from "api/useAdminAssignPricingPlanExternallyPaidToOrb"
import useAdminAssignPricingPlanTrialToOrb from "api/useAdminAssignPricingPlanTrialToOrb"
import useAdminGetOrbAdmins from "api/useAdminGetOrbAdmins"
import useGetOrbPricingPlans from "api/useGetOrbPricingPlans"
import { useState } from "react"
import { VscCheck } from "react-icons/vsc"

import { AdminOrbsEntry } from "components/admin/gdp-demos/types"
import { useNotifications } from "components/common/notifications"

export default function AssignExternallyPaidSubscriptionModal({
  orb,
  isOpen,
  onOpen,
  onClose,
}: AssignExternallyPaidSubscriptionModalProps) {
  const { showErrorNotification } = useNotifications()

  const { admins } = useAdminGetOrbAdmins(orb.id)

  const { isLoading: pricingPlansLoading, pricingPlans } = useGetOrbPricingPlans()
  const { assignPricingPlanExternallyPaid } = useAdminAssignPricingPlanExternallyPaidToOrb()
  const { assignPricingPlanTrial } = useAdminAssignPricingPlanTrialToOrb()

  const [selectedUserId, setSelectedUserId] = useState(null)
  const [selectedPricingPlanId, setSelectedPricingPlanId] = useState(null)
  const [selectedType, setSelectedType] = useState<string>("TRIAL")

  const orbHasAdmins = !!admins?.length
  const orbHasAvailablePricingPlans = !pricingPlansLoading && !!pricingPlans?.length

  async function handleSubmit() {
    if (!selectedUserId || !selectedPricingPlanId) {
      return
    }
    try {
      const variables = {
        orbId: orb.id,
        user_id: selectedUserId,
        pricing_plan_name: selectedPricingPlanId,
      }
      if (selectedType === "EXTERNALLY_PAID") {
        await assignPricingPlanExternallyPaid(variables)
      } else {
        await assignPricingPlanTrial(variables)
      }
    } catch (error: any) {
      const message = await error.response?.json()
      showErrorNotification(error, "Failed to assign externally paid pricing plan", message?.detail)
    }
  }

  return (
    <Modal size="md" isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay backdropFilter="blur(1px)" />
      <ModalContent>
        <ModalCloseButton size="sm" />
        <ModalHeader>
          <Text fontSize="lg" fontWeight="bold">
            {orb.name}
          </Text>
          <Text fontSize="md" fontWeight="normal" color="red.300">
            Assign externally paid subscription
          </Text>
        </ModalHeader>

        <ModalBody>
          <SimpleGrid columns={2} spacing={6}>
            {orbHasAdmins ? (
              <Select
                placeholder="Select an orb admin..."
                size="sm"
                roundedLeft="md"
                w="200px"
                name="email"
                onChange={(event: any) => setSelectedUserId(event.target.value)}
              >
                {admins?.map((admin) => (
                  <option key={admin.user_id} value={admin.user_id}>
                    {admin.email}
                  </option>
                ))}
              </Select>
            ) : (
              <Text>
                The orb <Text as="b">{orb.name}</Text> does not have admin users.
              </Text>
            )}
            {orbHasAvailablePricingPlans ? (
              <Select
                placeholder="Select a pricing plan..."
                size="sm"
                roundedLeft="md"
                w="200px"
                name="pricingPlanId"
                onChange={(event: any) => setSelectedPricingPlanId(event.target.value)}
              >
                {pricingPlans.map((plan) => (
                  <option key={plan.id} value={plan.name}>
                    {plan.name}
                  </option>
                ))}
              </Select>
            ) : (
              <Text>The orb {orb.name} does not have available pricing plans.</Text>
            )}
          </SimpleGrid>
          <RadioGroup defaultValue="2" mt={3} onChange={setSelectedType} value={selectedType}>
            <Stack spacing={5} direction="row">
              <Radio size="sm" value="EXTERNALLY_PAID">
                Externally Paid
                <br />
                (30 days, auto-renew)
              </Radio>
              <Radio size="sm" value="TRIAL">
                Trial
                <br />
                (14 days)
              </Radio>
            </Stack>
          </RadioGroup>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button size="sm" variant="solid" colorScheme="gray" onClick={onClose}>
            Cancel
          </Button>
          <Button
            rightIcon={<VscCheck />}
            size="sm"
            variant="solid"
            colorScheme="green"
            type="submit"
            onClick={handleSubmit}
            isDisabled={!selectedUserId || !selectedPricingPlanId || !orbHasAdmins || !orbHasAvailablePricingPlans}
          >
            Assign
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type AssignExternallyPaidSubscriptionModalProps = {
  orb: AdminOrbsEntry
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}
