import { FeatureKey } from "api/models/Billing"
import { AiOutlineFunction } from "react-icons/ai"
import { GiLogicGateAnd } from "react-icons/gi"
import { HiOutlineCog } from "react-icons/hi"
import { IoCodeSlashOutline, IoColorPaletteOutline, IoFunnelOutline, IoImagesOutline } from "react-icons/io5"

import { BaseProcessType } from "components/openeo-editor/types"

import { CategoryMeta } from "./collectionCategories"

export interface ProcessCategory extends CategoryMeta {
  processes: BaseProcessType[]
}

export default function categorizeProcesses(processes: BaseProcessType[], allowedBillingFeatures?: FeatureKey[]) {
  let genericProcesses = [...processes]

  const processCategories: { [category: string]: CategoryMeta } = {
    Datasets: {
      icon: IoImagesOutline,
      description: "Apply operations on selected datasets",
      keywords: ["datasets"],
    },
    Visualisation: {
      icon: IoColorPaletteOutline,
      description: "Visualise workflow results",
      keywords: ["visualise"],
    },
    Math: {
      icon: AiOutlineFunction,
      description: "Perform arithmetic operations on processed data",
      keywords: ["math"],
    },
    Logic: {
      icon: GiLogicGateAnd,
      description: "Apply logical operations on workflow data",
      keywords: ["logic"],
    },
    Reducers: {
      icon: IoFunnelOutline,
      description: "Execute reducers on datasets to produce pixel-by-pixel or geometry related calculations",
      keywords: ["reducers"],
    },
    Code: {
      icon: IoCodeSlashOutline,
      description: "Execute custom Python code blocks",
      keywords: ["code"],
      showPaidFeatureBadge:
        allowedBillingFeatures &&
        !allowedBillingFeatures.includes("python_code_editor_and_access_to_google_earth_engine"),
    },
  }

  const categorizedProcesses = Object.entries(processCategories).reduce(
    (categorized: { [category: string]: ProcessCategory }, [categoryName, categoryMeta]) => {
      const matchingProcesses = processes.filter((process) =>
        process.categories.some((category) => categoryName.toLowerCase().includes(category.toLowerCase()))
      )

      categorized[categoryName] = {
        ...categoryMeta,
        processes: matchingProcesses,
      }

      genericProcesses = genericProcesses.filter((process) => !matchingProcesses.includes(process))

      return categorized
    },
    {}
  )

  return {
    Generic: {
      icon: HiOutlineCog,
      description: "",
      keywords: [],
      processes: genericProcesses,
    },
    ...categorizedProcesses,
  }
}
