import { useListOrbs } from "api/useListOrbs"
import { ChangeEvent, useState } from "react"

export default function usePlatformAccessControlController() {
  const [searchString, setSearchString] = useState("")
  const [searchText, setSearchText] = useState("")
  const [sortOption, setSortOption] = useState("")
  const { isLoading, isError, orbs } = useListOrbs({ filterName: searchText, orderBy: sortOption })

  function handleSearch(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault()
    setSearchText(searchString)
    setSortOption(sortOption)
  }

  return { handleSearch, isError, isLoading, orbs, searchString, setSearchString, setSortOption, sortOption }
}
