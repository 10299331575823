import { PaginatedResponse } from "api/models/PaginatedResponse"
import { UpdateUser, User, UserRole } from "api/models/User"
import buildUrl from "api/utils/buildUrl"
import { useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import useAuthenticatedApi from "services/api/authenticatedApi"
import { QueryParams } from "services/types"

export default function useOrbUsersQueries() {
  const api = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1/external/orbs/users") })
  const queryClient = useQueryClient()

  const { id: orbId } = useParams<QueryParams>()

  return {
    listRolesQuery,
    listUsersQuery,
    editUserMutation,
    deleteUserMutation,
  }

  function listRolesQuery() {
    const queryKey = ["list", "roles"]

    return {
      queryKey,
      queryFn: function listRoles(): Promise<UserRole[]> {
        return api.get("roles").json()
      },
    }
  }

  function listUsersQuery(orbId: string | number, invitesOnly: boolean, pagination: any) {
    const options = { enabled: !!orbId }
    const queryKey = ["listOrbUsers", orbId, invitesOnly, pagination]

    return {
      queryKey,
      queryFn: function listUsers(): Promise<PaginatedResponse<User>> {
        return api
          .get(`${orbId}/`, {
            searchParams: {
              invites_only: invitesOnly,
              cursor: pagination.cursor || "",
              desc: pagination.desc,
              limit: pagination.limit,
            },
          })
          .json()
      },
      options,
    }
  }

  function editUserMutation() {
    const mutationOptions = {
      onSuccess: () => queryClient.invalidateQueries(["listOrbUsers", orbId]),
    }

    return {
      mutationFn: function editUser(user: UpdateUser): Promise<User> {
        const payload = { roles: user.roles?.map((role) => role.name), extents: user.extents }

        return api.post(`${orbId}/edit/${user.id}`, { json: payload }).json()
      },
      mutationOptions,
    }
  }

  function deleteUserMutation() {
    const mutationOptions = {
      onSuccess: () => queryClient.invalidateQueries(["listOrbUsers", orbId]),
    }

    return {
      mutationFn: function deleteUser(userId: number): Promise<boolean> {
        return api.delete(`${orbId}/delete/${userId}`).json()
      },
      mutationOptions,
    }
  }
}
