import { useAuth0 } from "@auth0/auth0-react"
import {
  Avatar,
  Box,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Portal,
  Skeleton,
  Tooltip,
  chakra,
  useDisclosure,
} from "@chakra-ui/react"
import useListOrganisations from "api/useListOrganisations"
import mixpanel from "mixpanel-browser"
import TagManager from "react-gtm-module"
import { BsCreditCard2Back } from "react-icons/bs"
import { FaExchangeAlt } from "react-icons/fa"
import { MdApps, MdOutlineAdminPanelSettings, MdOutlineManageAccounts } from "react-icons/md"
import { RiLogoutBoxRLine, RiUser3Line } from "react-icons/ri"
import { NavLink as RouterLink } from "react-router-dom"

import OrganisationSwitcher from "components/organisation-switcher/OrganisationSwitcher"
import { ORBIFY_ADMIN_PERMISSION, ORBIFY_USER_PERMISSION, useUserPermissions } from "services/userPermissions"

export default function UserMenu() {
  const { user, logout } = useAuth0()
  const { isLoading, permissions } = useUserPermissions()
  const { onOpen, onClose } = useDisclosure()
  const organisationSwitcherDisclosure = useDisclosure()
  const { organisationsInfo } = useListOrganisations()
  const currentOrganisation = organisationsInfo?.organisations.find(
    (organisation) => organisation.id === organisationsInfo.current_organisation_id
  )
  const currentOrganisationName =
    !currentOrganisation || currentOrganisation?.name.startsWith("auth0") ? "My organisation" : currentOrganisation.name

  const hasOrganizations = !!organisationsInfo && organisationsInfo?.organisations?.length > 1

  if (!user) return null

  return (
    <Box flexShrink={1} pb={0}>
      <OrganisationSwitcher organisationSwitcherDisclosure={organisationSwitcherDisclosure} />
      <Menu w="100%">
        <Tooltip label="Preferences">
          <Avatar
            as={MenuButton}
            src={user.picture}
            icon={<Icon as={RiUser3Line} color="white" />}
            boxSize={6}
            onClick={onOpen}
            bgColor="brand.900"
            borderColor="brand.900"
            borderWidth={1}
          />
        </Tooltip>
        <Portal>
          <MenuList fontSize="sm" w="sm" pt={0} onClose={onClose}>
            <Box align="left" bg="primary.900" color="white" roundedTop="md" p={4}>
              <chakra.span display="block" fontSize="md" fontWeight="bold" pb={2}>
                {currentOrganisationName}
              </chakra.span>
              <chakra.span display="block" fontSize="sm" color="brand.100">
                {user.email}
              </chakra.span>
            </Box>
            <Skeleton isLoaded={!isLoading}>
              {permissions.includes(ORBIFY_ADMIN_PERMISSION) && (
                <>
                  <MenuGroup title="Orbify Platform" color="red">
                    <MenuItem as={RouterLink} icon={<MdOutlineAdminPanelSettings />} to="/admin">
                      Admin panel
                    </MenuItem>
                  </MenuGroup>

                  <MenuDivider />
                </>
              )}
            </Skeleton>

            <MenuGroup title="My account">
              <MenuItem as={RouterLink} icon={<MdOutlineManageAccounts />} to="/account">
                Settings
              </MenuItem>
              <MenuItem as={RouterLink} icon={<MdApps />} to="#" isDisabled={true}>
                Authorized applications
              </MenuItem>
            </MenuGroup>

            <MenuDivider />

            <Skeleton isLoaded={!isLoading}>
              {permissions.includes(ORBIFY_USER_PERMISSION) && (
                <>
                  <MenuGroup title="My organisations">
                    {(permissions.includes(ORBIFY_ADMIN_PERMISSION) || hasOrganizations) && (
                      <MenuItem icon={<FaExchangeAlt />} onClick={() => organisationSwitcherDisclosure.onOpen()}>
                        Switch organisation
                      </MenuItem>
                    )}
                    <MenuItem as={RouterLink} icon={<BsCreditCard2Back />} to="/account/usage">
                      Platform usage
                    </MenuItem>
                  </MenuGroup>
                  <MenuDivider />
                </>
              )}
            </Skeleton>
            <MenuItem icon={<RiLogoutBoxRLine />} onClick={onLogout}>
              Sign out
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  )

  async function onLogout() {
    await handleAnalyticsSessionEnd()
    logout()
  }

  function handleAnalyticsSessionEnd() {
    mixpanel.track("Session end")

    const tagManagerArgs = {
      dataLayer: {
        event: "session_end",
      },
    }
    TagManager.dataLayer(tagManagerArgs)
  }
}
