import { useEffect, useState } from "react"

const usePermission = (name, fallbackValue = false) => {
  const [allowed, setAllowed] = useState(null)
  const hasPermissionsApi = navigator.permissions !== undefined

  useEffect(() => {
    if (hasPermissionsApi) {
      navigator.permissions.query({ name }).then((status) => {
        const { state } = status
        setAllowed(state !== "denied")
      })
    } else {
      setAllowed(fallbackValue)
    }
  }, [hasPermissionsApi, fallbackValue, name])

  return allowed
}

export { usePermission }
