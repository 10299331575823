import { Stat, StatLabel, StatNumber } from "@chakra-ui/react"
import useGetCurrentPricingPlan from "api/useGetCurrentPricingPlan"

import { formatMoneyValue } from "lib/utils"

export default function Subscription() {
  const { assignedPricingPlan } = useGetCurrentPricingPlan()

  if (!assignedPricingPlan) return null

  const { discounted_price, currency } = assignedPricingPlan
  const subscriptionPrice = formatMoneyValue(discounted_price, currency)

  return (
    <Stat>
      <StatLabel fontWeight="semibold">Current subscription price</StatLabel>
      <StatNumber>{subscriptionPrice}</StatNumber>
    </Stat>
  )
}
