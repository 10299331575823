import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react"
import { ReactNode } from "react"

export default function CustomProcessSchema({ schema, process_id, children }: CustomProcessSchemaProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {children({ onOpen })}

      <Modal size="2xl" isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton size="sm" />
          <ModalHeader>Custom process: {process_id}</ModalHeader>
          <ModalBody>
            <Flex fontSize="sm" overflow="scroll">
              <pre>{JSON.stringify(schema, null, 4)}</pre>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

type ChildrenProps = {
  onOpen: () => void
}

interface CustomProcessSchemaProps {
  process_id: string
  schema: any
  children: (props: ChildrenProps) => ReactNode
}
