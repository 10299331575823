export const RdBu = [
  "#67001F",
  "#9C1127",
  "#C13639",
  "#DB6B55",
  "#F09C7B",
  "#F9C4A9",
  "#FBE3D4",
  "#F6F7F7",
  "#DBEAF2",
  "#B6D7E8",
  "#87BEDA",
  "#4F9BC7",
  "#2F79B5",
  "#195696",
  "#053061",
]
