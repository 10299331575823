import { HStack, Text } from "@chakra-ui/react"
import { OpenEOInternalResult } from "api/models/OpenEO"

export default function OrbOrderErrors(props: { results: OpenEOInternalResult[] }) {
  const { results } = props

  return (
    <HStack spacing={2}>
      {results.map((result, index) => (
        <OrbOrderError key={index} result={result} />
      ))}
    </HStack>
  )
}

function OrbOrderError(props: { result: OpenEOInternalResult }) {
  const { result } = props
  const isFailInfo = result.data.format === "fail_info"
  const message = result.data.meta_access?.message

  if (!isFailInfo || !message) {
    return null
  }

  return <Text color="red.500">{message}</Text>
}
