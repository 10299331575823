import { Flex, useBoolean } from "@chakra-ui/react"

import WorkflowChartsGrid from "./WorkflowChartsGrid"
import WorkflowChartsToggleButton from "./WorkflowChartsToggleButton"
import useChartAssets from "./chart/useChartAssets"

export default function WorkflowCharts() {
  const [isExpanded, setIsExpanded] = useBoolean(false)
  const { chartAssets } = useChartAssets()

  if (!chartAssets.length) {
    return null
  }

  return (
    <Flex
      position="absolute"
      w={isExpanded ? "full" : "auto"}
      h={isExpanded ? "40%" : "auto"}
      minH={isExpanded ? "350px" : "auto"}
      pl={isExpanded ? 4 : 0}
      pr={4}
      bottom={6}
      right={0}
      zIndex={99}
    >
      <Flex
        w="full"
        h="full"
        rounded="md"
        shadow="lg"
        bgColor={isExpanded ? "white" : "gray.100"}
        justifyContent="flex-start"
        alignItems="flex-start"
        flexDirection="column"
      >
        <WorkflowChartsToggleButton isExpanded={isExpanded} onToggle={setIsExpanded.toggle} />
        {isExpanded && <WorkflowChartsGrid />}
      </Flex>
    </Flex>
  )
}
