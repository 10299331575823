import { useMutation } from "react-query"

import useAPIKeysQueries from "./queryFunctions/useAPIKeysQueries"

export default function useCreateAPIKey() {
  const { createAPIKeyMutation } = useAPIKeysQueries()
  const { mutationFn, mutationOptions } = createAPIKeyMutation()

  const { isLoading, mutateAsync: createAPIKey } = useMutation(mutationFn, mutationOptions)

  return { isLoading, createAPIKey }
}
