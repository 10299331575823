import { Box, Flex } from "@chakra-ui/react"

import Section from "components/layout/section/Section"
import SectionHeader from "components/layout/section/SectionHeader"
import OrbOrders from "components/orb-orders/OrbOrders"

export default function OrbOrdersView() {
  return (
    <Box p={4}>
      <Section
        header={
          <SectionHeader
            title="Orders"
            description="List of workflow execution orders placed in the user view of the application"
          />
        }
      >
        <Flex w="full" my={10} flexDirection="column">
          <OrbOrders />
        </Flex>
      </Section>
    </Box>
  )
}
