import { atom, useAtom } from "jotai"

export const openeoEditorDrawerAtom = atom<boolean>(false)

export function useOpenEOEditorDrawerState() {
  const [isDrawerOpen, setIsDrawerOpen] = useAtom(openeoEditorDrawerAtom)

  function onDrawerOpen() {
    setIsDrawerOpen(true)
  }

  function onDrawerClose() {
    setIsDrawerOpen(false)
  }

  return {
    isDrawerOpen,
    onDrawerOpen,
    onDrawerClose,
  }
}
