export const OrRd = [
  "#FFF7EC",
  "#FEEFD8",
  "#FEE5C3",
  "#FDD9AA",
  "#FDCD96",
  "#FDBF88",
  "#FDA872",
  "#FC8C59",
  "#F4764F",
  "#EC5D42",
  "#DE3F2B",
  "#CD2316",
  "#B80604",
  "#9C0000",
  "#7F0000",
]
