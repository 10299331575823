import Color from "ac-colors/index"

const MIN_SIMILARITY_COLOR_PERCEPTIBLE_THROUGH_CLOSE_OBSERVATION = 3

function calculateColorSimilarity(hexColor1, hexColor2) {
  // https://stackoverflow.com/questions/13586999/color-difference-similarity-between-two-values-with-js
  const labColor1 = new Color({ color: hexColor1, type: "hex" }).lab
  const labColor2 = new Color({ color: hexColor2, type: "hex" }).lab

  const deltaL = labColor1[0] - labColor2[0]
  const deltaA = labColor1[1] - labColor2[1]
  const deltaB = labColor1[2] - labColor2[2]

  const c1 = Math.sqrt(labColor1[1] ** 2 + labColor1[2] ** 2)
  const c2 = Math.sqrt(labColor2[1] ** 2 + labColor2[2] ** 2)
  const deltaC = c1 - c2

  let deltaH = deltaA ** 2 + deltaB ** 2 - deltaC ** 2
  deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH)

  const sc = 1.0 + 0.045 * c1
  const sh = 1.0 + 0.015 * c1

  const deltaLKlsl = deltaL
  const deltaCkcsc = deltaC / sc
  const deltaHkhsh = deltaH / sh
  const similarity = deltaLKlsl ** 2 + deltaCkcsc ** 2 + deltaHkhsh ** 2
  return similarity < 0 ? 0 : Math.sqrt(similarity)
}

export function areColorsSimilar(hexColor1, hexColor2) {
  const areValidColors = [hexColor1, hexColor2].every((color) => {
    const reg = /^#([0-9a-f]{3}){1,2}$/i
    return !!color && typeof color === "string" && reg.test(color)
  })
  if (!areValidColors) return false

  return calculateColorSimilarity(hexColor1, hexColor2) <= MIN_SIMILARITY_COLOR_PERCEPTIBLE_THROUGH_CLOSE_OBSERVATION
}
