import buildUrl from "api/utils/buildUrl"

import useAuthenticatedApi from "./authenticatedApi"

const useStripeApi = () => {
  const api = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1/internal/account/stripe") })

  return {
    createCheckoutSession: (planName) => {
      const request = {
        subscription_plan_name: planName,
      }

      return api.post("create-checkout-session", { json: request }).json()
    },
    createCustomerPortalSession: () => api.post("create-customer-portal-session").json(),
  }
}

export default useStripeApi
