export const YlGn = [
  "#FFFFE5",
  "#FAFDCC",
  "#F3FAB6",
  "#E1F3A9",
  "#CCEA9D",
  "#B3E091",
  "#97D385",
  "#77C679",
  "#58B669",
  "#3DA559",
  "#2C8F4B",
  "#197C40",
  "#056C39",
  "#005931",
  "#004529",
]
