import { Tooltip, chakra } from "@chakra-ui/react"
import PropTypes from "prop-types"

import { childrenType } from "lib/CustomPropTypes"

function ParameterRequiredIndicator(props) {
  const { children, optional = false } = props

  if (optional) {
    return children
  }

  return (
    <Tooltip label="Parameter required" shouldWrapChildren>
      <>
        {children}
        <chakra.sup color="gray.500">*</chakra.sup>
      </>
    </Tooltip>
  )
}

ParameterRequiredIndicator.propTypes = {
  optional: PropTypes.bool,
  children: childrenType,
}

export default ParameterRequiredIndicator
