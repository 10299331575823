export const hsv = [
  "#FF0000",
  "#FF6A00",
  "#FFD500",
  "#B9FF00",
  "#4FFF00",
  "#00FF1B",
  "#00FF86",
  "#00FFF6",
  "#009EFF",
  "#0033FF",
  "#3700FF",
  "#A100FF",
  "#FF00ED",
  "#FF0082",
  "#FF0018",
]
