import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Portal, Text } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { VscSymbolColor } from "react-icons/vsc"

import { colorMaps } from "lib/color-maps"

function InsertPaletteButton(props) {
  const { onInsertColorMap } = props

  return (
    <Menu>
      <MenuButton as={Button} variant="secondary" size="xs" leftIcon={<VscSymbolColor />} me={2}>
        Insert colormap
      </MenuButton>
      <Portal>
        <MenuList h="md" overflowY="scroll">
          {Object.entries(colorMaps).map(([name, colors]) => {
            return (
              <MenuItem
                key={name}
                alignItems="flex-start"
                flexDirection="column"
                borderBottomWidth="1px"
                borderColor="gray.200"
                py={2}
                onClick={() => onInsertColorMap(colors)}
              >
                <Text fontSize="2xs" fontWeight="bolder" color="gray.400">
                  {name}
                </Text>
                <Box w="full" h="10px" bgGradient={`linear(to-r, ${colors.join(",")})`} />
              </MenuItem>
            )
          })}
        </MenuList>
      </Portal>
    </Menu>
  )
}

InsertPaletteButton.propTypes = {
  onInsertColorMap: PropTypes.func.isRequired,
}

export default InsertPaletteButton
