import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import { FormikValues } from "formik"
import { useEffect, useState } from "react"
import { MdOutlineLegendToggle } from "react-icons/md"
import { VscCheck } from "react-icons/vsc"

import ContinuousLegendForm from "components/openeo-editor/legend/ContinuousLegendForm"
import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"

import BasicLegendForm from "./BasicLegendForm"
import LegendsSummary from "./LegendsSummary"
import { BasicLegendType, ContinuousLegendType, LegendType } from "./types"

export default function ConfigureLegendModal(props: ConfigureLegendModalProps) {
  const { isOpen, onClose } = props

  const { onSaveGraph, legends: apiLegends, isGraphLoading } = useOpeneoWorkflowContext() as any
  const [legends, setLegends] = useState<Array<LegendType>>(apiLegends || [])
  const [legend, setLegend] = useState<LegendType | null>(null)
  const [mode, setMode] = useState<string>("summary") // "basicForm", "continuousForm"

  useEffect(() => {
    if (isGraphLoading) {
      return
    }

    setLegends(apiLegends || [])
  }, [isGraphLoading, apiLegends])

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay backdropFilter="blur(1px)" />
      <ModalContent data-testid="modal-content">
        <ModalCloseButton size="sm" />
        <ModalHeader fontSize="lg">
          <Icon as={MdOutlineLegendToggle} boxSize={5} mr={4} mb={1} />
          Legends
        </ModalHeader>

        <ModalBody>
          <Alert status="info" variant="subtle" rounded="md" mb={10}>
            <AlertIcon />
            <AlertDescription>
              Configure legends presented with your workflow results. You can use static text entries as well as
              interpolate legend text by injecting variables computed in your application's workflow.
            </AlertDescription>
          </Alert>
          {mode === "summary" && (
            <LegendsSummary legends={legends} setLegends={setLegends} setLegend={setLegend} setMode={setMode} />
          )}
          {mode === "basicForm" && <BasicLegendForm legend={legend as BasicLegendType} setLegend={handleSaveLegend} />}
          {mode === "continuousForm" && (
            <ContinuousLegendForm legend={legend as ContinuousLegendType} setLegend={handleSaveLegend} />
          )}
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button size="sm" variant="solid" colorScheme="gray" onClick={handleCancelClick}>
            Cancel
          </Button>
          {mode === "summary" ? (
            <Button rightIcon={<VscCheck />} size="sm" variant="solid" colorScheme="green" onClick={handleSaveClick}>
              Save
            </Button>
          ) : (
            <Button
              type="submit"
              form="entryForm"
              rightIcon={<VscCheck />}
              size="sm"
              variant="solid"
              colorScheme="green"
            >
              Save
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )

  function handleSaveLegend(values: FormikValues) {
    setLegend(null)
    setLegends([...legends, values as LegendType])
    setMode("summary")
  }

  function handleCancelClick() {
    if (mode === "summary") {
      onClose()
    } else {
      if (legend) {
        setLegends([...legends, legend])
      }

      setMode("summary")
    }
  }

  function handleSaveClick() {
    onSaveGraph(legends)
    setLegend(null)
    onClose()

    setMode("summary")
  }
}

type ConfigureLegendModalProps = {
  isOpen: boolean
  onClose: () => void
}
