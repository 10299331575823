import { useBoolean } from "@chakra-ui/react"
import { createContext, useContext } from "react"

import { childrenType } from "lib/CustomPropTypes"

const ToolboxContext = createContext()
const useToolboxContext = () => useContext(ToolboxContext)

const ToolboxContextProvider = ({ children }) => {
  const [autoOpenCreatePreview, setAutoOpenCreatePreview] = useBoolean()
  const [shouldOpenPreviewsList, setShouldOpenPreviewsList] = useBoolean()

  return (
    <ToolboxContext.Provider
      value={{ autoOpenCreatePreview, setAutoOpenCreatePreview, shouldOpenPreviewsList, setShouldOpenPreviewsList }}
    >
      {children}
    </ToolboxContext.Provider>
  )
}

ToolboxContextProvider.propTypes = {
  children: childrenType,
}

export { ToolboxContextProvider as default, useToolboxContext }
