export const summer = [
  "#008066",
  "#128866",
  "#249266",
  "#379B66",
  "#49A466",
  "#5BAD66",
  "#6DB666",
  "#80C066",
  "#92C866",
  "#A4D266",
  "#B6DB66",
  "#C8E366",
  "#DBED66",
  "#EDF666",
  "#FFFF66",
]
