import { useMutation } from "react-query"

import useAdminOrganisationsQueries from "./queryFunctions/useAdminOrganisationsQueries"

export default function useAdminCreateOrganisation() {
  const { organisationMutation } = useAdminOrganisationsQueries()
  const { mutationFn, mutationOptions } = organisationMutation()

  const { isLoading, mutateAsync: createOrganisation } = useMutation(mutationFn, mutationOptions)

  return { isLoading, createOrganisation }
}
