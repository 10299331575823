import { useDisclosure } from "@chakra-ui/react"
import { createContext, useContext, useState } from "react"

import { childrenType } from "lib/CustomPropTypes"

const MetadataModalContext = createContext({})

function useMetadataModal() {
  return useContext(MetadataModalContext)
}

function MetadataModalContextProvider(props) {
  const { children } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [content, setContent] = useState()

  const handleClose = () => {
    setContent(null)
    onClose()
  }

  return (
    <MetadataModalContext.Provider value={{ isOpen, onOpen, onClose: handleClose, content, setContent }}>
      {children}
    </MetadataModalContext.Provider>
  )
}

MetadataModalContextProvider.propTypes = {
  children: childrenType,
}

export { MetadataModalContext, MetadataModalContextProvider, useMetadataModal }
