import { useQuery } from "react-query"

import useWorkflowExternalQueries from "./queryFunctions/useWorkflowExternalQueries"

export default function useGetWorkflows() {
  const { getWorkflowsQuery } = useWorkflowExternalQueries()
  const { queryKey, queryFn } = getWorkflowsQuery()

  const { data, isLoading } = useQuery(queryKey, queryFn)

  return {
    isLoading,
    workflows: data,
  }
}
