import { useBoolean } from "@chakra-ui/react"
import dayjs from "dayjs"
import { ReactNode, createContext, useContext, useState } from "react"
import { v4 as uuidv4 } from "uuid"

import { EnrichedOpeneoEvent, OpeneoEvent } from "components/orb-openeo-workflow/types"

const OpeneoEventsContext = createContext<OpeneoEventsContextResult | null>(null)

function OpeneoEventsContextProvider(props: OpeneoEventsContextProviderProps) {
  const { children } = props
  const [isConsoleOpen, setIsConsoleOpen] = useBoolean(true)
  const [events, setEvents] = useState<EnrichedOpeneoEvent[]>([])

  function registerEvent(event: OpeneoEvent) {
    const newEvent = { ...event, date: dayjs.utc(), id: uuidv4() }
    setEvents((events) => [...events, newEvent])
  }

  function insertSpacer() {
    const newEvent = { type: "spacer", content: "" } as OpeneoEvent
    registerEvent(newEvent)
  }

  function clearEvents() {
    setEvents([])
  }

  return (
    <OpeneoEventsContext.Provider
      value={{
        registerEvent,
        insertSpacer,
        clearEvents,
        isConsoleOpen,
        toggleConsole: setIsConsoleOpen.toggle,
        events,
      }}
    >
      {children}
    </OpeneoEventsContext.Provider>
  )
}

function useOpeneoEventsContext() {
  const context = useContext(OpeneoEventsContext)
  if (!context) {
    throw new Error("OpeneoEventsContext was not initialized. Maybe component was not wrapped in provider")
  }
  return context
}

type OpeneoEventsContextProviderProps = {
  children: ReactNode
}

type OpeneoEventsContextResult = {
  registerEvent: (event: OpeneoEvent) => void
  insertSpacer: () => void
  clearEvents: () => void
  isConsoleOpen: boolean
  toggleConsole: () => void
  events: EnrichedOpeneoEvent[]
}

export { OpeneoEventsContextProvider, useOpeneoEventsContext }
