import { Flex, IconButton, Input, Select, Tooltip, chakra } from "@chakra-ui/react"
import { ChangeEvent, FormEvent, useState } from "react"
import { MdSearch } from "react-icons/md"

export default function AdminOrganisationFilter(props: AdminOrganisationFilterProps) {
  const { options, handleSearch } = props
  const [currentOption, setCurrentOption] = useState(options[0].value)
  const [searchText, setSearchText] = useState("")

  function onSubmit(event: FormEvent) {
    event.preventDefault()
    handleSearch(currentOption, searchText)
  }

  function handleOptionChange(event: ChangeEvent<HTMLSelectElement>) {
    setCurrentOption(event.target.value)
  }

  function handleSearchTextChange(event: ChangeEvent<HTMLInputElement>) {
    setSearchText(event.target.value)
  }

  return (
    <chakra.form w="60%" onSubmit={onSubmit}>
      <Flex w="full">
        <Select size="sm" roundedLeft="md" value={currentOption} w="200px" onChange={handleOptionChange}>
          {options.map(({ name, value }) => (
            <option key={value} value={value}>
              {name}
            </option>
          ))}
        </Select>
        <Input size="sm" w="full" ml="-1px" borderRadius={0} value={searchText} onChange={handleSearchTextChange} />
        <Tooltip label="Search">
          <IconButton
            type="submit"
            size="sm"
            colorScheme="primary"
            icon={<MdSearch />}
            aria-label="Search"
            borderLeftRadius={0}
          />
        </Tooltip>
      </Flex>
    </chakra.form>
  )
}

export type AdminOrganisationFilterOption = {
  name: string
  value: string
}

type AdminOrganisationFilterProps = {
  options: AdminOrganisationFilterOption[]
  handleSearch: (option: string, text: string) => void
}
