import { colors } from "./colors"

const options = {
  arrowColor: colors.brand["50"],
  backgroundColor: colors.brand["50"],
  primaryColor: colors.brand["700"],
  textColor: colors.brand["700"],
  beaconSize: 40,
  spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
  zIndex: 10000,
}

const buttonNext = {
  borderRadius: "var(--chakra-radii-md)",
  fontSize: "var(--chakra-fontSizes-sm)",
  fontWeight: "var(--chakra-fontWeights-semibold)",
  paddingInlineStart: "var(--chakra-space-3)",
  paddingInlineEnd: "var(--chakra-space-3)",
}

const tooltip = {
  fontSize: "0.875rem",
}

const tooltipTitle = {
  fontSize: "1rem",
  fontWeight: "bold",
}

const tooltipContent = {
  padding: "10px 0 0 0",
}

const tooltipContainer = {
  textAlign: "left",
}

export const guidedTourStyles = {
  options,
  buttonNext,
  tooltip,
  tooltipTitle,
  tooltipContent,
  tooltipContainer,
}
