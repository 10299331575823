import { Flex, VStack } from "@chakra-ui/react"
import { FieldArray, useField } from "formik"

import { parameterType, schemaType } from "components/openeo-editor/lib/propTypes"
import {
  BandNameEntry,
  BandNameEntryButton,
} from "components/openeo-editor/parameters/controls/types/array/BandNameEntry"

function ArrayBandNamesType(props) {
  const { parameter, schema } = props
  const [field, , { setValue }] = useField(parameter.name)

  const hasEntries = field.value && field.value.length > 0

  return (
    <FieldArray
      name={field.name}
      render={({ insert, remove, push }) => (
        <VStack spacing={1} alignItems="flex-start">
          {hasEntries &&
            field.value.map((entry, index) => (
              <BandNameEntry
                key={index}
                name={field.name}
                index={index}
                onInsert={insert}
                onRemove={remove}
                isAddingEnabled={schema.maxItems ? field.value.length < schema.maxItems : true}
              />
            ))}
          <Flex w="full">
            {!hasEntries && <BandNameEntryButton onPush={push} hasEntries={hasEntries} setValue={setValue} />}
          </Flex>
        </VStack>
      )}
    />
  )
}

ArrayBandNamesType.propTypes = {
  parameter: parameterType.isRequired,
  schema: schemaType.isRequired,
}

export { ArrayBandNamesType }
