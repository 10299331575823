import { Spinner } from "@chakra-ui/react"
import { IoCardOutline, IoLinkSharp, IoPeopleOutline, IoPersonSharp, IoStatsChartSharp } from "react-icons/io5"
import { useRouteMatch } from "react-router-dom"

import { useSidebarContext } from "components/layout/sidebar/SidebarContextProvider"
import SidebarItem from "components/layout/sidebar/SidebarItem"
import SidebarSection from "components/layout/sidebar/SidebarSection"
import { ORBIFY_USER_PERMISSION, useUserPermissions } from "services/userPermissions"

export default function MyAccountSidebar() {
  const match = useRouteMatch("/account")
  const { isOpen } = useSidebarContext()

  return (
    <>
      <SidebarSection isOpen={isOpen} title="Account">
        <SidebarItem exact to={match?.url} icon={IoPersonSharp} tooltip="Account settings">
          Settings
        </SidebarItem>
        <SidebarItem to={`${match?.url}/apps`} icon={IoLinkSharp} tooltip="Authorized applications" disabled={true}>
          Authorized apps
        </SidebarItem>
      </SidebarSection>
      <MyAccountSidebarAdminSection />
    </>
  )
}

function MyAccountSidebarAdminSection() {
  const match = useRouteMatch("/account")
  const { isLoading, permissions } = useUserPermissions()
  const { isOpen } = useSidebarContext()

  if (isLoading) {
    return <Spinner size="sm" color="primary.700" mx="auto" my={3} />
  }

  if (!permissions.includes(ORBIFY_USER_PERMISSION)) {
    return null
  }
  return (
    <SidebarSection isOpen={isOpen} title="Organisation">
      <SidebarItem to={`${match?.url}/access-control`} icon={IoPeopleOutline} tooltip="Access control">
        Access control
      </SidebarItem>

      <SidebarItem exact to={`${match?.url}/usage`} icon={IoStatsChartSharp} tooltip="Platform usage">
        Platform usage
      </SidebarItem>

      <SidebarItem exact to={`${match?.url}/billing`} icon={IoCardOutline} tooltip="Organisation billing">
        Billing
      </SidebarItem>
    </SidebarSection>
  )
}
