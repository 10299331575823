import { useQuery } from "react-query"

import { DetailedPricingPlan } from "./models/Billing"
import useBillingQueries from "./queryFunctions/useBillingQueries"

export default function useGetPricingPlanList(): useGetPricingPlanListResult {
  const { getPricingTableQuery } = useBillingQueries()
  const { queryKey, queryFn, options } = getPricingTableQuery()

  const { data, isLoading } = useQuery(queryKey, queryFn, options)

  return {
    isLoading,
    pricingPlans: data,
  }
}

type useGetPricingPlanListResult = {
  isLoading: boolean
  pricingPlans?: DetailedPricingPlan[]
}
