import { useMutation } from "react-query"

import useAdminCustomProcessesQueries from "./queryFunctions/useAdminCustomProcessesQueries"

export default function useAdminDeleteCustomProcess() {
  const { deleteCustomProcessesMutation } = useAdminCustomProcessesQueries()
  const { mutationFn, mutationOptions } = deleteCustomProcessesMutation()

  const { isLoading, mutateAsync: deleteCustomProcess } = useMutation(mutationFn, mutationOptions)

  return { isLoading, deleteCustomProcess }
}
