export function calculateRequiredPrecision(number: number) {
  const precision = Math.abs(Math.floor(Math.log10(Math.abs(number))))
  return precision > 10 ? 10 : precision
}

export function calculateAverage(numbers: number[]) {
  if (!numbers.length) {
    return 0
  }

  const sum = numbers.reduce((a, b) => a + b, 0)
  return (sum / numbers.length || 0).toFixed(10)
}

export function formatNumber(number?: number | null, precision: number = 10) {
  if (number === undefined || number === null) {
    return null
  }

  const fixedNumber = number.toFixed(precision)
  return Number(fixedNumber.replace(/\.?0+$/g, ""))
}

export function floorNumber(number: number, precision: number = 10) {
  const power = Math.pow(10, precision)
  return Math.floor(number * power) / power
}

export function ceilNumber(number: number, precision: number = 10) {
  const power = Math.pow(10, precision)
  return Math.ceil(number * power) / power
}
