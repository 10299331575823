import CollectionNode from "components/openeo-editor/node-types/CollectionNode"
import ProcessNode from "components/openeo-editor/node-types/ProcessNode"

const nodeTypes = {
  collection: CollectionNode,
  image: CollectionNode,
  featureCollection: CollectionNode,
  process: ProcessNode,
}

export default nodeTypes
