import buildUrl from "api/utils/buildUrl"
import TileState from "ol/TileState"
import XYZ from "ol/source/XYZ"
import PropTypes from "prop-types"
import { memo } from "react"

import { TileLayer } from "components/common/open-layers/layers"
import { geeLegendTypes } from "lib/CustomPropTypes"

function MapTilesMapLayer(props) {
  const { accessToken, id, geeLegend, mapTilesUrl } = props
  if (!accessToken || !mapTilesUrl) return null

  const isNeededToBuildUrl = !mapTilesUrl.startsWith("http")

  const source = new XYZ({
    url: isNeededToBuildUrl ? buildUrl(mapTilesUrl) : mapTilesUrl,
    zIndex: 0,
    crossOrigin: "",
  })

  source.setTileLoadFunction(loadTile)

  function loadTile(tile, src) {
    const xhr = new XMLHttpRequest()

    xhr.responseType = "blob"

    xhr.addEventListener("loadend", function () {
      const data = this.response
      if (data !== undefined) {
        tile.getImage().src = URL.createObjectURL(data)
      } else {
        tile.setState(TileState.ERROR)
      }
    })

    xhr.addEventListener("error", function () {
      tile.setState(TileState.ERROR)
    })

    xhr.open("GET", src)
    if ((isNeededToBuildUrl || accessToken) && !src.includes("earthengine")) {
      xhr.setRequestHeader("Authorization", `Bearer ${accessToken}`)
    }

    xhr.send()
  }

  return <TileLayer source={source} geeLegend={geeLegend} properties={id} />
}

MapTilesMapLayer.propTypes = {
  accessToken: PropTypes.string,
  isNeededToBuildUrl: PropTypes.bool,
  geeLegend: geeLegendTypes,
  mapTilesUrl: PropTypes.string.isRequired,
}

export default memo(MapTilesMapLayer)
