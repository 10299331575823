export const YlOrRd = [
  "#FFFFCC",
  "#FFF5B3",
  "#FFEA9B",
  "#FEDE82",
  "#FECE6A",
  "#FEB852",
  "#FEA245",
  "#FD8C3C",
  "#FC6832",
  "#F84628",
  "#EA2920",
  "#D9131F",
  "#C20325",
  "#A20026",
  "#800026",
]
