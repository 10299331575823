import { useMutation } from "react-query"

import useBillingQueries from "./queryFunctions/useBillingQueries"

export default function useCreateCheckoutSession() {
  const { createCheckoutSessionMutation } = useBillingQueries()
  const { mutationFn, mutationOptions } = createCheckoutSessionMutation()

  const { isLoading, mutateAsync: createCheckoutSession } = useMutation(mutationFn, mutationOptions)

  return { isLoading, createCheckoutSession }
}
