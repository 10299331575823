import { Button, Flex } from "@chakra-ui/react"
import { VscCode } from "react-icons/vsc"

import { useCodeEditorContext } from "components/openeo-editor/code-editor/CodeEditorContext"
import { ProcessDataType } from "components/openeo-editor/types"
import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"

export default function CodeEditorButton(props: CodeEditorButtonProps) {
  const { data } = props
  const isCodeEditingProcess = [
    "save_execute_code_standalone",
    "save_execute_long_running_code_standalone",
    "execute_code_standalone",
    "execute_ml_code_standalone",
  ].includes(data.values?.id)
  const { setIsCodeEditorOpen } = useOpeneoWorkflowContext() as any
  const { setCodeSnippetUuid } = useCodeEditorContext()

  function parseUuid(formValue: string | { [key: string]: any }) {
    const parsedFormValue: { [key: string]: any } = typeof formValue === "string" ? JSON.parse(formValue) : formValue
    return parsedFormValue["value"]
  }

  async function handleOpenCodeEditor() {
    const code = data.values?.code

    setCodeSnippetUuid(parseUuid(code))
    setIsCodeEditorOpen.on()
  }

  if (!isCodeEditingProcess) {
    return null
  }

  return (
    <Flex w="full" mt={2} mb={1}>
      <Button w="full" size="xs" colorScheme="green" leftIcon={<VscCode />} onClick={handleOpenCodeEditor}>
        Edit code
      </Button>
    </Flex>
  )
}

type CodeEditorButtonProps = {
  data: ProcessDataType
}
