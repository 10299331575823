import { Icon } from "@chakra-ui/icons"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react"
import { useStoredDisclosure } from "hooks"
import { useRef } from "react"
import { MdOutlinePhonelinkRing } from "react-icons/md"

function MobileUserWarning() {
  const ref = useRef()
  const isMobileDevice = useBreakpointValue({ base: true, md: false })
  const { isOpen, onClose } = useStoredDisclosure({ defaultIsOpen: true, key: "mobile-user-warning" })

  if (!isMobileDevice) {
    return null
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      leastDestructiveRef={ref}
      zIndex={9999}
    >
      <AlertDialogOverlay bg="blackAlpha.700">
        <AlertDialogContent>
          <AlertDialogHeader
            as={Flex}
            my={4}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            fontSize="xl"
            color="brand.700"
          >
            <Icon boxSize="70px" as={MdOutlinePhonelinkRing} transform="rotate(25deg)" mb={4} />
            <Text align="center">We're happy to see you using Orbify on mobile!</Text>
          </AlertDialogHeader>
          <AlertDialogBody align="center">
            <VStack spacing={2} my={4}>
              <Text>
                We're constantly working on improving our platform. Mobile support is on top of our priority list.
              </Text>
              <Text>
                Right now, we would like to ask you to switch to a desktop browser for the best possible experience.
              </Text>
            </VStack>
          </AlertDialogBody>
          <AlertDialogFooter mt={4}>
            <Button size="sm" ref={ref} variant="link" onClick={onClose} isFullWidth>
              I want to continue on mobile
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default MobileUserWarning
