export const PuBuGn = [
  "#FFF7FB",
  "#F4EBF5",
  "#E8E0EF",
  "#D8D6E9",
  "#C4CBE3",
  "#ACC0DD",
  "#8CB5D6",
  "#66A9CF",
  "#4B9AC6",
  "#2E8EB8",
  "#11859A",
  "#027B7D",
  "#016F5F",
  "#015B4A",
  "#014636",
]
