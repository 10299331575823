export const Set3 = [
  "#8DD3C7",
  "#FFFFB3",
  "#BEBADA",
  "#BEBADA",
  "#FB8072",
  "#80B1D3",
  "#FDB462",
  "#B3DE69",
  "#B3DE69",
  "#FCCDE5",
  "#D9D9D9",
  "#BC80BD",
  "#BC80BD",
  "#CCEBC5",
  "#FFED6F",
]
