import { useMutation } from "react-query"

import useAdminCustomProcessesQueries from "./queryFunctions/useAdminCustomProcessesQueries"

export default function useAdminUpdateCustomProcess() {
  const { updateCustomProcessesMutation } = useAdminCustomProcessesQueries()
  const { mutationFn, mutationOptions } = updateCustomProcessesMutation()

  const { isLoading, mutateAsync: updateCustomProcess } = useMutation(mutationFn, mutationOptions)

  return { isLoading, updateCustomProcess }
}
