export const PuBu = [
  "#FFF7FB",
  "#F4EEF6",
  "#E8E4F0",
  "#D8D7E9",
  "#C4CBE3",
  "#ACC0DD",
  "#91B5D6",
  "#73A9CF",
  "#509AC6",
  "#2F8BBE",
  "#1379B5",
  "#056AA6",
  "#045D92",
  "#034B76",
  "#023858",
]
