import { Flex } from "@chakra-ui/react"
import { BsLayers } from "react-icons/bs"

import MultiSelect from "components/common/multi-select/MultiSelect"
import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"
import useLayerAssets from "components/orb-openeo-workflow/useLayerAssets"
import MapControlsOL from "components/orb-workflow/toolbox/map-controls/MapControlsOL"

function OpeneoWorkflowMapToolbox() {
  const { isLoading, mapLayers } = useLayerAssets()
  const { selectedLayersIds, setSelectedLayersIds } = useOpeneoWorkflowContext()

  if (isLoading) {
    return null
  }

  return (
    <Flex position="absolute" top={2} left={2} zIndex={9} alignItems="flex-end">
      <MapControlsOL
        buttonsProps={{
          variant: "solid",
          colorScheme: "gray",
          color: "primary.900",
          borderColor: "primary.400",
          borderWidth: "1px",
          me: 1,
          size: "xs",
        }}
        ms={{ base: 7, md: 0 }}
      />
      <MultiSelect
        label="Layers"
        options={mapLayers}
        selectedOptions={selectedLayersIds}
        setSelectedOptions={setSelectedLayersIds}
        icon={<BsLayers />}
      />
    </Flex>
  )
}

export default OpeneoWorkflowMapToolbox
