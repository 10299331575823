import { useMutation, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import { useNotifications } from "components/common/notifications"
import { useCodeEditorContext } from "components/openeo-editor/code-editor/CodeEditorContext"
import useGetCodeEditorFile from "components/openeo-editor/code-editor/useGetCodeEditorFile"
import useOrbCodeSnippetsApi from "services/api/orbCodeSnippetsApi"
import { QueryParams } from "services/types"

export default function useSaveCode() {
  const { file } = useGetCodeEditorFile()
  const { id: orbId } = useParams<QueryParams>()
  const { codeSnippetUuid } = useCodeEditorContext()
  const { showNotification } = useNotifications()

  const queryClient = useQueryClient()
  const { saveCodeSnippet } = useOrbCodeSnippetsApi()
  const { mutateAsync, isLoading: isSavingCode } = useMutation(saveCodeSnippet)

  async function handleCreateCode(uuid: string, data: string) {
    await mutateAsync({
      orbId,
      codeSnippetUuid: uuid,
      codeSnippet: { data, file_name: "handler.py", language: "python" },
    })
  }

  async function handleSaveCode() {
    if (!orbId || !codeSnippetUuid) {
      return
    }

    mutateAsync({ orbId, codeSnippetUuid, codeSnippet: file }).then(() => {
      queryClient.invalidateQueries(["getCodeSnippet"])
      queryClient.invalidateQueries(["showFileContentAndHeaders"])
      showNotification("", "File saved", "success")
    })
  }

  return {
    isSavingCode,
    handleCreateCode,
    handleSaveCode,
  }
}
