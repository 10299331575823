import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react"
import { User } from "api/models/User"
import useEditOrbUser from "api/useEditOrbUser"
import useGetOrbRoles from "api/useGetOrbRoles"
import { Select } from "chakra-react-select"
import { ReactNode, useState } from "react"

import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"
import { useNotifications } from "components/common/notifications"

export default function UserRolesEditor(props: UserRolesEditorProps) {
  const { user, children } = props
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {children({ onOpen })}
      {isOpen && <UserRolesEditorModal isOpen={isOpen} onClose={onClose} user={user} />}
    </>
  )
}

function UserRolesEditorModal(props: UserRolesEditorModalProps) {
  const { isOpen, onClose, user } = props
  const { showErrorNotification } = useNotifications()
  const { isLoading, editUser } = useEditOrbUser()
  const { isLoading: isRolesLoading, roles } = useGetOrbRoles()
  const [currentRoles, setCurrentRoles] = useState(
    user.roles?.map((role) => ({
      label: role.description,
      value: role.name,
    })) || []
  )
  const options = roles?.map((role) => ({ label: role.description, value: role.name })) || []

  function handleRolesChange(value: any) {
    setCurrentRoles(value)
  }

  async function handleEditUser() {
    try {
      await editUser({
        ...user,
        extents: user.extents?.map((extent) => extent.id) || [],
        roles: currentRoles.map((role) => ({ name: role.value, description: role.label })),
      })
    } catch (error: any) {
      showErrorNotification(error, "Failed to update user")
    }
    onClose()
  }

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton size="sm" />
        <ModalHeader fontSize="md">User roles</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>Assign user roles</FormLabel>
            {isRolesLoading ? (
              <LoadingSpinner />
            ) : (
              <Select size="sm" options={options} isMulti value={currentRoles} onChange={handleRolesChange} />
            )}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" colorScheme="green" onClick={handleEditUser} isLoading={isLoading}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type ChildrenProps = {
  onOpen: () => void
}

type UserRolesEditorProps = {
  user: User
  children: (props: ChildrenProps) => ReactNode
}

type UserRolesEditorModalProps = {
  isOpen: boolean
  onClose: () => void
  user: User
}
