import { BaseCollectionType, BaseProcessType } from "components/openeo-editor/types"

export function filterCollection(collections: BaseCollectionType[], searchTerm?: string) {
  function filterCollectionItem(item: BaseCollectionType) {
    const { id, title, description } = item

    return hasMatches([id, title, description], searchTerm)
  }

  return (collections || []).filter(filterCollectionItem)
}

export function filterProcesses(processes: BaseProcessType[], searchTerm: string, input: boolean, showHidden: boolean) {
  function filterProcessItemByType(process: BaseProcessType) {
    const { categories } = process

    return input ? categories.includes("input") : !categories.includes("input")
  }

  function filterHidden(process: any) {
    return showHidden || !process.hidden
  }

  const filterProcessItem = (item: BaseProcessType) => {
    const { id, title, description, summary, categories } = item

    return hasMatches([id, title, description, summary, categories.join(" ")], searchTerm)
  }

  return (processes || []).filter(filterProcessItemByType).filter(filterProcessItem).filter(filterHidden)
}

function hasMatches(candidates: Array<string | undefined | null>, searchTerm?: string) {
  if (!searchTerm || !searchTerm.length) {
    return true
  }

  return candidates.filter(Boolean).some((candidate) => candidate!.toLowerCase().includes(searchTerm.toLowerCase()))
}
