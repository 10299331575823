import { Box, Flex } from "@chakra-ui/react"
import { usePageTitle } from "hooks"

import AdminGdpDemosList from "components/admin/gdp-demos/AdminGdpDemosList"
import AdminGdpDemosFilter from "components/admin/gdp-demos/AdminGpsDemosFilter"
import useAdminGDPDemosController from "components/admin/gdp-demos/adminGdpDemosController"
import { LoadMore } from "components/common"
import Section from "components/layout/section/Section"
import SectionHeader from "components/layout/section/SectionHeader"

export default function AdminGdpDemos() {
  usePageTitle("Administration Panel - GDP demos management")

  const { sortOptions, planStatuses, handleSearch, isLoading, orbs, hasNextPage, fetchNextPage } =
    useAdminGDPDemosController()

  return (
    <Box p={4}>
      <Section header={<SectionHeader title="GDP Demos management"></SectionHeader>}>
        <Flex w="full" my={10} flexDirection="column">
          <Flex w="full" justifyContent="flex-end" mb={6}>
            <AdminGdpDemosFilter planStatuses={planStatuses} sortOptions={sortOptions} handleSearch={handleSearch} />
          </Flex>
          <AdminGdpDemosList orbs={orbs} isLoading={isLoading} />
          <LoadMore hasNextPage={hasNextPage} fetchNextPage={fetchNextPage} isFetchingNextPage={isLoading} />
        </Flex>
      </Section>
    </Box>
  )
}
