import { Flex, IconButton, LinkBox, LinkOverlay, Tooltip } from "@chakra-ui/react"
import { VscClose } from "react-icons/vsc"

import { BasicLegendBody, BasicLegendTitle } from "./BasicLegend"
import { ContinuousLegendBody, ContinuousLegendTitle } from "./ContinuousLegend"
import { BasicLegendType, ContinuousLegendType, LegendType } from "./types"

export default function LegendPreview(props: LegendPreviewProps) {
  const { index, legend, legends, setLegend, setLegends, setMode } = props

  function handleEditLegend() {
    const editLegend = legends.splice(index, 1)[0]
    setLegend(editLegend)

    setMode(legend.type === "basic" ? "basicForm" : "continuousForm")
  }

  function handleRemoveLegend() {
    const newLegends = [...legends]
    newLegends.splice(index, 1)

    setLegends(newLegends)
  }

  if (!legend) {
    return null
  }

  return (
    <Flex w="full" position="relative" role="group" _hover={{ cursor: "pointer" }}>
      <Tooltip label="Remove legend">
        <IconButton
          boxSize={6}
          minW={6}
          rounded="full"
          shadow="md"
          colorScheme="red"
          variant="solid"
          icon={<VscClose />}
          aria-label="Remove"
          position="absolute"
          onClick={handleRemoveLegend}
          top={-2}
          right={-2}
          zIndex={10}
          visibility="hidden"
          _groupHover={{ visibility: "visible" }}
        />
      </Tooltip>

      <LinkBox w="full">
        <LinkOverlay onClick={handleEditLegend}>
          {legend.type === "basic" ? (
            <BasicLegendPreview legend={legend as BasicLegendType} />
          ) : (
            <ContinuousLegendPreview legend={legend as ContinuousLegendType} />
          )}
        </LinkOverlay>
      </LinkBox>
    </Flex>
  )
}

function BasicLegendPreview(props: { legend: BasicLegendType }) {
  const { legend } = props

  return (
    <Flex
      flexDirection="column"
      w="full"
      borderColor="gray.100"
      borderWidth={1}
      p={3}
      shadow="base"
      _groupHover={{ shadow: "md" }}
    >
      <BasicLegendTitle legend={legend} />
      <BasicLegendBody legend={legend} />
    </Flex>
  )
}

function ContinuousLegendPreview(props: { legend: ContinuousLegendType }) {
  const { legend } = props

  return (
    <Flex
      flexDirection="column"
      w="full"
      borderColor="gray.100"
      borderWidth={1}
      p={3}
      shadow="base"
      _groupHover={{ shadow: "md" }}
    >
      <ContinuousLegendTitle legend={legend} />
      <ContinuousLegendBody legend={legend} />
    </Flex>
  )
}

type LegendPreviewProps = {
  index: number
  legend: LegendType
  legends: LegendType[]
  setLegend: (legend: LegendType) => void
  setLegends: (legends: LegendType[]) => void
  setMode: (mode: string) => void
}
