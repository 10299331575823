import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"

function useComputedVariablesModalController() {
  const { isResultsLoading, availableAssets } = useOpeneoWorkflowContext()

  const computedVariables =
    !isResultsLoading && availableAssets && availableAssets.length > 0 ? availableAssets[0].computedVariables : {}

  return {
    computedVariables,
  }
}

useComputedVariablesModalController.propTypes = {}

export default useComputedVariablesModalController
