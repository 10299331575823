import { useMutation } from "react-query"

import useAdminOrganisationsQueries from "./queryFunctions/useAdminOrganisationsQueries"

export default function useAdminUpdateOrganisationMembers() {
  const { updateOrganisationMembersMutation } = useAdminOrganisationsQueries()
  const { mutationFn, mutationOptions } = updateOrganisationMembersMutation()

  const { isLoading, mutateAsync: updateOrganisationMembers } = useMutation(mutationFn, mutationOptions)

  return { isLoading, updateOrganisationMembers }
}
