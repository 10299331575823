import { Flex, Text } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { useMemo } from "react"

import { parameterType } from "components/openeo-editor/lib/propTypes"
import useFlowUtils from "components/openeo-editor/lib/useFlowUtils"
import ParameterContent from "components/openeo-editor/parameters/parameter/ParameterContent"
import ParameterName from "components/openeo-editor/parameters/parameter/ParameterName"
import { parseValue } from "components/openeo-editor/parameters/parameter/utils"

function Parameter(props) {
  const { target, parameter, value } = props
  const { isHandleConnected } = useFlowUtils()

  const content = useMemo(() => {
    let parameterValue = value || parameter.default
    if (isHandleConnected("target", target, parameter.name)) {
      try {
        parameterValue = JSON.parse(parameterValue)?.from_node
      } catch {}
    }

    return parseValue(parameterValue)
  }, [value, target, parameter, isHandleConnected])

  return (
    <Flex w="full" alignItems="center" color="gray.500" fontSize="0.875em" justifyContent="space-between" pl={4}>
      <ParameterName>
        <Text fontSize="0.875em" fontWeight="bold">
          {parameter.name}
        </Text>
      </ParameterName>
      <ParameterContent>{content}</ParameterContent>
    </Flex>
  )
}

Parameter.propTypes = {
  target: PropTypes.string.isRequired,
  parameter: parameterType.isRequired,
  value: PropTypes.any,
}

export default Parameter
