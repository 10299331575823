import { useAuth0 } from "@auth0/auth0-react"
import { CloseButton, Flex, Heading, Spacer, Text, useToast } from "@chakra-ui/react"
import { useEffect } from "react"
import useGetEmbed from "state/useGetEmbed"

const TrackingConsent = () => {
  const { isAuthenticated } = useAuth0()
  const isEmbed = useGetEmbed()
  const toast = useToast()

  useEffect(() => {
    const toastId = "trackingConsentToast"
    const cookieName = "analytics_consent"

    const handleClose = () => {
      toast.close(toastId)
      document.cookie = `${cookieName}=true; path=/; max-age=31536000`
    }

    const existAnalyticsConsentCookie = document.cookie?.split(";").some((item) => item.trim().startsWith(cookieName))

    !toast.isActive(toastId) &&
      isAuthenticated &&
      !isEmbed &&
      !existAnalyticsConsentCookie &&
      toast({
        id: toastId,
        duration: null,
        position: "bottom-left",
        title: "Consent Required",
        description: "Consent required to save analytics related with the use of the app",
        render: ({ id }) => (
          <Flex id={id} p={2} bgColor="orange.100" flexDirection="column" rounded="md" shadow="md">
            <Flex>
              <Heading fontSize="l" p={2}>
                Consent Required
              </Heading>
              <Spacer />
              <CloseButton onClick={handleClose} />
            </Flex>

            <Text fontSize="sm" px={2} pb={2}>
              We are using technologies to collect data related with the use of the application to improve our services.
              Using the application, you provide your consent to collect this data.
            </Text>
          </Flex>
        ),
      })
  }, [toast, isAuthenticated, isEmbed])

  return null
}

export default TrackingConsent
