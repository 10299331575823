import { Alert, AlertDescription, AlertIcon, chakra } from "@chakra-ui/react"

function UnsupportedParameterTypeControl(props) {
  const { type } = props

  return (
    <Alert status="error" fontSize="xs" p={1}>
      <AlertIcon w={4} h={4} />
      <AlertDescription>
        Parameter of type{" "}
        <chakra.span color="red.500" fontFamily="mono">
          {type}
        </chakra.span>{" "}
        is not supported
      </AlertDescription>
    </Alert>
  )
}

export default UnsupportedParameterTypeControl
