import { withAuthenticationRequired } from "@auth0/auth0-react"
import { Route } from "react-router-dom"

import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"

export default function AuthRoute(props: any) {
  const { component: Component, ...rest } = props

  const authedComponent = withAuthenticationRequired(Component, { onRedirecting: () => <LoadingSpinner /> })

  return <Route {...rest} component={authedComponent} />
}
