import { transparentize } from "@chakra-ui/theme-tools"
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style"
import theme from "theme"

const baseGeometryStyles = {
  Circle: ({ color = "magenta", width = "1", fillColor = "rgba(0, 0, 255, 0.1)", radius = 10 }) =>
    new Style({
      image: new CircleStyle({
        radius,
        fill: new Fill({
          color: fillColor,
        }),
        stroke: new Stroke({
          color,
          width,
        }),
      }),
    }),
  Line: ({ color = "blue", width = "1", fillColor = "rgba(0, 0, 255, 0.1)" }) =>
    new Style({
      stroke: new Stroke({
        color,
        width,
      }),
      fill: new Fill({
        color: fillColor,
      }),
    }),
  Polygon: ({ color = "blue", width = "1", fillColor = "rgba(0, 0, 255, 0.1)" }) =>
    new Style({
      stroke: new Stroke({
        color,
        width,
      }),
      fill: new Fill({
        color: fillColor,
      }),
    }),
}

/* To cover all options  https://openlayers.org/en/latest/apidoc/module-ol_geom_SimpleGeometry-SimpleGeometry.html */
const geometryStyles = {
  Circle: baseGeometryStyles.Circle,
  LinearRing: baseGeometryStyles.Line, //TODO to confirm how to display it
  LineString: baseGeometryStyles.Line,
  MultiLineString: baseGeometryStyles.Line,
  MultiPoint: baseGeometryStyles.Circle,
  MultiPolygon: baseGeometryStyles.Polygon,
  Point: baseGeometryStyles.Circle,
  Polygon: baseGeometryStyles.Polygon,
}

const controlsStyles = {
  Position: new Style({
    image: new CircleStyle({
      radius: 6,
      fill: new Fill({
        color: "#3399CC",
      }),
      stroke: new Stroke({
        color: "#fff",
        width: 2,
      }),
    }),
  }),
  Accuracy: new Style({
    stroke: null,
    fill: new Fill({
      color: "rgba(0, 0, 255, 0.1)",
    }),
  }),
}

const areaOfInterestStyles = {
  previewAreaOfInterestStyle: new Style({
    stroke: new Stroke({
      color: theme.colors.brand["700"],
      width: 1,
    }),
    fill: new Fill({
      color: transparentize(theme.colors.brand["100"], 0.3)(),
    }),
  }),

  publicAreaOfInterestStyle: new Style({
    stroke: new Stroke({
      color: theme.colors.brand["700"],
      width: 1,
    }),
    fill: new Fill({
      color: transparentize("green", 0.3)(),
    }),
  }),
}

const observationExplorerStyle = new Style({
  stroke: new Stroke({
    color: theme.colors.brand["700"],
    lineDash: [2],
    width: 1,
  }),
  fill: new Fill({
    color: transparentize(theme.colors.brand["700"], 0.2)(),
  }),
})

const styles = {
  ...geometryStyles,
  ...controlsStyles,
  ...areaOfInterestStyles,
  observationExplorerStyle,
}

export default styles
