import generateLayout from "components/openeo-editor/lib/flowLayoutGenerator"

class ProcessGraphToFlowBuilder {
  constructor(graph, getCollectionById, getProcessById) {
    this.process_graph = graph.process_graph
    this.getCollectionById = getCollectionById
    this.getProcessById = getProcessById
  }

  get elements() {
    let nodes = []
    let edges = []

    if (this.process_graph) {
      nodes = this.buildNodes()
      edges = this.buildEdges()
    }

    return generateLayout([...nodes, ...edges])
  }

  buildNodes() {
    return Object.entries(this.process_graph).map(([id, node]) => ({
      id: id,
      type: this.getNodeType(node),
      data: this.getData(id, node),
    }))
  }

  getData(nodeId, node) {
    let id
    let process_id
    let data = {}

    if (["featureCollection", "collection", "image"].includes(this.getNodeType(node))) {
      const collection = this.getCollectionById(node.arguments.id)
      id = node.arguments.id
      process_id = node.process_id || "load_collection"
      data = { ...data, ...collection, label: nodeId }
    } else {
      id = node.process_id
      process_id = node.process_id
    }

    const process = this.getProcessById(node.process_id)
    const args = Object.keys(node.arguments).reduce((obj, key) => {
      const value = node.arguments[key]
      obj[key] = value === null ? value : this.stringifyValue(node.arguments[key])
      return obj
    }, {})
    data = { ...data, ...process, values: { ...args, id, process_id: process_id } }

    return { ...data, id: nodeId }
  }

  buildEdges() {
    return Object.entries(this.process_graph)
      .map(([target, node]) => this.buildEdge(target, node))
      .flat()
  }

  buildEdge(target, node) {
    const edgeLinks = this.getEdgeLinks(node?.arguments)
    return edgeLinks.map(([targetHandle, { from_node: source }]) => ({
      source,
      sourceHandle: null,
      target,
      targetHandle,
      arrowHeadType: "arrowclosed",
      id: `reactflow__edge-${source}null-${target}${targetHandle}`,
      type: "removableEdge",
    }))
  }

  getNodeType(node) {
    switch (node.process_id) {
      case "load_image":
        return "image"
      case "load_collection":
        return "collection"
      case "load_feature_collection":
        return "featureCollection"
      default:
        return "process"
    }
  }

  getEdgeLinks(nodeArguments) {
    return Object.entries(nodeArguments || {}).reduce((args, [key, value]) => {
      if (value?.hasOwnProperty("from_node")) {
        args.push([key, value])
      } else if (Array.isArray(value) && value.every((entry) => entry.hasOwnProperty("from_node"))) {
        args.push(...value.map((entry) => [key, entry]))
      } else if (key === "context" && !!value) {
        args.push(...Object.values(value).map((entry) => [key, entry]))
      }

      return args
    }, [])
  }

  stringifyValue(value) {
    if (Array.isArray(value) || typeof value === "string") {
      return value
    } else {
      return JSON.stringify(value)
    }
  }
}

export default ProcessGraphToFlowBuilder
