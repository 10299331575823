export const bwr = [
  "#0000FF",
  "#2424FF",
  "#4848FF",
  "#6E6EFF",
  "#9292FF",
  "#B6B6FF",
  "#DADAFF",
  "#FFFEFE",
  "#FFDADA",
  "#FFB6B6",
  "#FF9292",
  "#FF6E6E",
  "#FF4848",
  "#FF2424",
  "#FF0000",
]
