import mixpanel from "mixpanel-browser"
import TagManager from "react-gtm-module"

import steps from "./steps"

interface WorkflowCreatorAnalyticsProps {
  currentSelectionName?: string
  currentSelectionAppType?: "snap" | "gee" | "openeo"
  isLastStep: boolean
  typeOfCurrentWorkflowCards: keyof typeof steps
}

function handleWorkflowCreatorAnalytics(props: WorkflowCreatorAnalyticsProps): void {
  const { currentSelectionName, typeOfCurrentWorkflowCards } = props
  if (!currentSelectionName || !typeOfCurrentWorkflowCards) return

  gtmRecordCardSelection(props)
  mixpanelRecordCardSelection(props)
}

function gtmRecordCardSelection(props: WorkflowCreatorAnalyticsProps): void {
  const { currentSelectionName, currentSelectionAppType: appType, isLastStep, typeOfCurrentWorkflowCards: type } = props

  let tagManagerArgs
  tagManagerArgs = {
    dataLayer: {
      event: `${type}_selected`,
      [`${type}_selected`]: { name: currentSelectionName },
    },
  }
  TagManager.dataLayer(tagManagerArgs)

  if (isLastStep) {
    const event = "workflow_creator_finished"

    tagManagerArgs = {
      dataLayer: {
        event,
        [event]: {
          app_type: appType,
        },
      },
    }
    TagManager.dataLayer(tagManagerArgs)
  }
}

function mixpanelRecordCardSelection(props: WorkflowCreatorAnalyticsProps): void {
  const { currentSelectionName, currentSelectionAppType: appType, isLastStep, typeOfCurrentWorkflowCards: type } = props

  const eventProperty = {
    category: {
      eventName: "Choosing category",
      eventPropertiesName: "category",
    },
    workflowTemplate: {
      eventName: "Choosing the problem",
      eventPropertiesName: "template",
    },
  }

  mixpanel.track(eventProperty[type].eventName, { [eventProperty[type].eventPropertiesName]: currentSelectionName })
  if (isLastStep) mixpanel.track("Workflow creator finished", { app_type: appType })
}

export { handleWorkflowCreatorAnalytics }
