import { Button } from "@chakra-ui/react"
import { Polygon } from "@turf/turf"
import { NominatimSearchResult } from "api/models/Nominatim"
import { VscPlay } from "react-icons/vsc"

import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"
import useFetchAreasOfInterest from "components/orb-workflow/hooks/useFetchAreasOfInterest"

export default function AddToMyAreasButton(props: AddToMyAreasButtonProps) {
  const { searchResult, clearSearchResult } = props

  // @ts-ignore TODO: Typing openeo workflow context can be whole separate task to do
  const { setPreviewAreaOfInterest } = useOpeneoWorkflowContext()
  const { previewAreaOfInterest } = useFetchAreasOfInterest()

  const isVisible = !!searchResult?.geojson
  const shouldHideButton = previewAreaOfInterest

  if (!isVisible || shouldHideButton) {
    return null
  }

  return (
    <Button mt={4} variant="solid" colorScheme="green" size="sm" rounded="lg" leftIcon={<VscPlay />} onClick={onClick}>
      Use for execution
    </Button>
  )

  function onClick() {
    setPreviewFeature()
    clearSearchResult()
  }

  function setPreviewFeature() {
    const feature = {
      type: "Feature",
      geometry: searchResult.geojson as Polygon,
      properties: {
        description: searchResult.display_name,
      },
    }

    setPreviewAreaOfInterest(feature)
  }
}

type AddToMyAreasButtonProps = {
  searchResult: NominatimSearchResult
  clearSearchResult: () => void
}
