import { Flex } from "@chakra-ui/react"
import { ACTIONS, EVENTS, STATUS } from "react-joyride"
import SplitPane from "react-split-pane"

import { GuidedTour } from "components/guided-tour"
import { useOpenEOEditorDrawerState } from "components/openeo-editor/drawer/atoms"
import { useOpeneoEventsContext } from "components/orb-openeo-workflow/openeo-workflow-console/OpeneoEventsContext"
import OpeneoWorkflowEditor from "components/orb-openeo-workflow/openeo-workflow-editor/OpeneoWorkflowEditor"
import OpeneoWorkflowSidebar from "components/orb-openeo-workflow/openeo-workflow-sidebar/OpeneoWorkflowSidebar"
import OpeneoWorkflowToolbox from "components/orb-openeo-workflow/openeo-workflow-toolbox/OpeneoWorkflowToolbox"

function OpeneoWorkflowConfigurator() {
  const { isConsoleOpen } = useOpeneoEventsContext()

  return (
    <Flex
      w="full"
      h="full"
      flexDirection="column"
      data-id="workflow-configurator__overview"
      overflow="hidden"
      position="relative"
    >
      <OpeneoWorkflowConfiguratorGuidedTour />
      <OpeneoWorkflowToolbox />
      <Flex>
        <SplitPane
          minSize={50}
          maxSize={-150}
          size={isConsoleOpen ? "80%" : "100%"}
          step={15}
          split="vertical"
          allowResize
        >
          <OpeneoWorkflowEditor />
          <OpeneoWorkflowSidebar />
        </SplitPane>
      </Flex>
    </Flex>
  )
}

function OpeneoWorkflowConfiguratorGuidedTour() {
  const { isDrawerOpen, onDrawerOpen, onDrawerClose } = useOpenEOEditorDrawerState()

  const handleJoyrideCallback = (data, setRun, setStepIndex, onComplete) => {
    const { action, index, status, type } = data
    const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1)

    const run = () => setTimeout(() => setRun(true), 400)

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if (
        (isDrawerOpen && ![2, 3, 4].includes(index)) ||
        (index === 3 && action === ACTIONS.PREV) ||
        (index === 4 && action === ACTIONS.NEXT)
      ) {
        setRun(false)
        onDrawerClose()
        setStepIndex(nextStepIndex)
        run()
      } else if ([2, 3].includes(index) || (index === 4 && action === ACTIONS.PREV)) {
        setRun(false)
        onDrawerOpen()
        setStepIndex(nextStepIndex)
        run()
      } else {
        setStepIndex(nextStepIndex)
      }
    }

    if ([ACTIONS.STOP, ACTIONS.CLOSE].includes(action) || (action === ACTIONS.NEXT && status === STATUS.FINISHED)) {
      onComplete()
    }
  }

  return <GuidedTour component="OpeneoWorkflowConfigurator" callback={handleJoyrideCallback} />
}

export default OpeneoWorkflowConfigurator
