import { HStack } from "@chakra-ui/react"
import { VscComment, VscFold, VscListSelection, VscUnfold } from "react-icons/vsc"

import ToolboxButton from "components/common/toolbox-button/ToolboxButton"

import useCodeEditorToolbarController from "./codeEditorToolbarController"

export default function CodeEditorToolbar() {
  const { handleToggleComment, handleFoldAll, handleUnfoldAll, isFormattingDocument, handleFormatDocument } =
    useCodeEditorToolbarController()

  return (
    <HStack justifyContent="center" spacing={2} w="full">
      <ToolboxButton
        // @ts-ignore
        icon={VscComment}
        label="Toggle comment"
        tooltipLabel="Toggle comment"
        onClick={handleToggleComment}
      />

      <HStack spacing={0}>
        <ToolboxButton
          // @ts-ignore
          icon={VscFold}
          label="Fold all sections"
          onClick={handleFoldAll}
          roundedRight={0}
        />
        <ToolboxButton
          // @ts-ignore
          icon={VscUnfold}
          label="Unfold all sections"
          onClick={handleUnfoldAll}
          roundedLeft={0}
        />
      </HStack>

      <ToolboxButton
        // @ts-ignore
        icon={VscListSelection}
        label="Format document"
        onClick={handleFormatDocument}
        isLoading={isFormattingDocument}
      />
    </HStack>
  )
}
