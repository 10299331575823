export const binary = [
  "#FFFFFF",
  "#EDEDED",
  "#DBDBDB",
  "#C8C8C8",
  "#B6B6B6",
  "#A4A4A4",
  "#929292",
  "#7F7F7F",
  "#6D6D6D",
  "#5B5B5B",
  "#494949",
  "#373737",
  "#242424",
  "#121212",
  "#000000",
]
