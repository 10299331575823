import { IconButton, Tooltip } from "@chakra-ui/react"
import mixpanel from "mixpanel-browser"
import { useCallback } from "react"
import { RiShape2Line } from "react-icons/ri"

import { useDrawContext } from "components/common/open-layers/DrawContextProvider"

export default function DrawAreaOfInterestOnMapAction() {
  const { isFeatureSelected, isDrawing, startDrawing } = useDrawContext()
  const isDisabled = isFeatureSelected || isDrawing

  const handleDrawAreaOfInterest = useCallback(
    function () {
      mixpanel.track("Start drawing preview AOI", {})
      startDrawing()
    },
    [startDrawing]
  )

  return (
    <Tooltip label="Start drawing area of interest" placement="left-start">
      <IconButton
        aria-label="Start drawing area of interest"
        size="xs"
        icon={<RiShape2Line />}
        onClick={handleDrawAreaOfInterest}
        isDisabled={isDisabled}
      />
    </Tooltip>
  )
}
