import { WarningIcon } from "@chakra-ui/icons"
import { Flex, chakra } from "@chakra-ui/react"

export default function AdminWarning() {
  return (
    <Flex
      color="red.500"
      bgColor="blackAlpha.300"
      backdropFilter="blur(5px)"
      px={6}
      py={1}
      position="absolute"
      top={0}
      left="50%"
      transform="translate(-50%, 0)"
      zIndex={999}
      fontSize="sm"
      roundedBottom="md"
      alignItems="center"
      shadow="base"
    >
      <WarningIcon me={2} />
      You're currently in the
      <chakra.span mx={1}>admin</chakra.span>
      section
    </Flex>
  )
}
