import useEventsApi from "services/api/events"
import Rollbar from "services/rollbar"

export const SupportedEvents = {
  APP_CREATION_STARTED: "app_creation_started",
}

const useEvents = () => {
  const { track } = useEventsApi()

  const trackEvent = (name, parameters) => {
    track(name, parameters).catch(async (error) => {
      let message = error.message

      try {
        const response = await error.response.json()
        message = response.detail
      } catch {}

      console.error(`Cannot track the event '${name}'.`, message)
      Rollbar.error(`Cannot track the event '${name}'.`, message)
    })
  }

  return {
    trackEvent,
  }
}

export { useEvents }
