export const Paired = [
  "#A6CEE3",
  "#1F78B4",
  "#B2DF8A",
  "#B2DF8A",
  "#33A02C",
  "#FB9A99",
  "#E31A1C",
  "#FDBF6F",
  "#FDBF6F",
  "#FF7F00",
  "#CAB2D6",
  "#6A3D9A",
  "#6A3D9A",
  "#FFFF99",
  "#B15928",
]
