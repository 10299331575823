import { useMapContext } from "../MapContextProvider"

const useZoomControl = () => {
  const { map } = useMapContext()
  const view = map?.getView()

  const zoom = (amount) => {
    const zoom = view?.getZoom()
    view?.animate({ zoom: zoom + amount })
  }

  return {
    zoomIn: () => zoom(+1),
    zoomOut: () => zoom(-1),
  }
}

export default useZoomControl
