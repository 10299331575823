import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { decode } from "html-entities"
import PropTypes from "prop-types"

import Markdown from "components/markdown/Markdown"

function BandsTable(props) {
  const { bands } = props
  const { columns, bandsList } = bands

  return (
    <Table size="xs" variant="simple">
      <Thead fontSize="xs">
        <Tr>
          {columns.map((column, index) => (
            <Th key={index}>{column}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody fontSize="sm">
        {bandsList.map((entry, index) => (
          <Tr key={index}>
            {columns.map((column, index) => (
              <BandTd key={index} data={entry[column]} column={column} />
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

BandsTable.propTypes = {
  bands: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    bandsList: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
}

function BandTd(props) {
  const { column, data } = props

  switch (column) {
    case "name":
      return (
        <Td color="brand.700" fontWeight="semibold" fontFamily="monospace">
          {data}
        </Td>
      )
    case "min":
    case "max":
      return (
        <Td px={1} isNumeric>
          {data && String(data)}
        </Td>
      )
    case "description":
      return (
        <Td>
          <Markdown>{decode(data)}</Markdown>
        </Td>
      )
    default:
      return <Td px={1}>{decode(data)}</Td>
  }
}

export default BandsTable
