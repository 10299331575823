import { Box, Flex, Heading, Text, VStack, chakra } from "@chakra-ui/react"
import { usePageTitle, withRoleRequired } from "hooks"
import mixpanel from "mixpanel-browser"
import { IoStatsChartSharp } from "react-icons/io5"

import { LoadingSpinner } from "components/common"
import Section from "components/layout/section/Section"
import SectionHeader from "components/layout/section/SectionHeader"
import { useEffectOnce } from "hooks/useEffectOnce"
import { ORBIFY_USER_PERMISSION } from "services/userPermissions"

import Subscription from "./Subscription"
import UsageChart from "./UsageChart"
import usePlatformUsageController from "./platformUsageController"
import { SectionChartProps } from "./types"

const pageTitle = "Account - Platform usage"

function PlatformUsage() {
  usePageTitle(pageTitle)

  useEffectOnce(() => {
    mixpanel.track(`${pageTitle} view`, { page_title: pageTitle })
  })

  const { isLoading, costsChartData, usageChartData } = usePlatformUsageController()

  if (isLoading) return <LoadingSpinner />

  return (
    <Box p={4}>
      <Section
        dataId="platform-usage"
        header={
          <SectionHeader title="Platform usage" description="Detailed platform usage">
            <Subscription />
          </SectionHeader>
        }
      >
        <VStack
          data-id="platform-usage__content"
          spacing={6}
          divider={<chakra.hr borderColor="gray.100" borderWidth={1} w="full" />}
          pt={9}
          alignItems="self-start"
        >
          <SectionChart data={costsChartData} chartType={"COSTS"} />
          <SectionChart data={usageChartData} chartType={"USAGE"} />
        </VStack>
      </Section>
    </Box>
  )
}

function SectionChart(pros: SectionChartProps) {
  const { data, chartType } = pros
  const title = chartType.toLowerCase()
  const description = `Detailed platform ${title} for the last three months`

  return (
    <>
      <Flex w={"30%"} minW="200px" me={10} flexDirection="column" verticalAlign="center">
        <Heading
          as={Flex}
          size="sm"
          color="primary.900"
          fontWeight="semibold"
          mb={2}
          alignItems="center"
          textTransform="capitalize"
        >
          <Box p={1} color="primary.900" bgColor="primary.100" display="inline-block" me={2} rounded="md">
            <IoStatsChartSharp />
          </Box>
          {title}
        </Heading>

        <Text fontSize="sm" color="black">
          {description}
        </Text>
      </Flex>

      <UsageChart chartData={data} chartType={chartType} />
    </>
  )
}

export default withRoleRequired(PlatformUsage, ORBIFY_USER_PERMISSION)
