import { truncateText } from "services/utils"

export function parseValue(value) {
  switch (typeof value) {
    case "object":
      return parseObjectValue(value)
    case "string":
      return parseTextValue(value)
    default:
      return value
  }
}

export function parseTextValue(value) {
  try {
    JSON.parse(value)
    return "…"
  } catch {
    // values.data is not always a stringified object, so parsing failure is expected
  }

  return truncateText(value, 25)
}

export function parseObjectValue(value) {
  if (!value) {
    return null
  }

  if (Array.isArray(value)) {
    return parseTextValue(value.map(parseValue).join(", "))
  }

  try {
    const objValue = JSON.parse(value)
    if (objValue.from_node) {
      return objValue.from_node
    }
  } catch {
    // values.data is not always a stringified object, so parsing failure is expected
  }

  return parseTextValue(value?.from_node || "…")
}
