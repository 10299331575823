import { useQuery } from "react-query"

import { OrbConfig } from "./models/OrbConfig"
import { OrbsListQueryOptions, useOrbsQueries } from "./queryFunctions/useOrbsQueries"

export function useListOrbs(options: OrbsListQueryOptions) {
  const { getOrbsListQuery } = useOrbsQueries()
  const { queryKey, queryFn } = getOrbsListQuery(options)

  const { isLoading, isError, data } = useQuery(queryKey, queryFn)

  return {
    isLoading,
    isError,
    orbs: data?.results || ([] as OrbConfig[]),
  }
}
