export type OpenEOInternalResultStatus = "running" | "finishing" | "finished" | "failed" | "error"
export type OpenEOJobStatus = OpenEOInternalResultStatus | "created" | "queued" | "canceled"

export type OpenEOInternalData = {
  format: string
  meta_access: { [key: string]: any }
  expiration_date?: string
}

export type OpenEOResultMetaData = {
  visualization?: { [key: string]: any }
  unit?: { [key: string]: any }
  attribution?: { [key: string]: any }
  computed_object?: { [key: string]: any }
  computed_variables?: { [key: string]: any }
}

export type OpenEOInternalResult = {
  status: OpenEOInternalResultStatus
  data: OpenEOInternalData
  name: string
  metadata?: OpenEOResultMetaData
  temp_data?: OpenEOInternalData
}

export type OpenEOInternalResponse = {
  results: OpenEOInternalResult[]
}

export type OpenEOJobResultAssetComputedVariables = { [name: string]: any }

export type OpenEOJobResultChartConfiguration = { [name: string]: any }
export type OpenEOJobResultDataLayerProperties = { [name: string]: any }

export type OpenEOJobResultAssetMetadata = {
  computed_variables: OpenEOJobResultAssetComputedVariables
  chart_configuration?: OpenEOJobResultChartConfiguration
  data_layer_properties?: OpenEOJobResultDataLayerProperties
}

export const MAP_LAYER_ASSETS = ["maptiles", "geojson", "csv", "image", "mvt", "fgb", "geotiff", "cmems", "ecmwf"]
export const DATA_LAYER_ASSETS = ["data_layer"]
export const CHART_ASSETS = ["chart"]

export type OpenEOJobResultAsset = {
  id: string
  interpolate?: boolean
  title: string
  name: string
  group?: string
  href: string
  api_url: string
  type:
    | "maptiles"
    | "geojson"
    | "csv"
    | "image"
    | "mvt"
    | "fgb"
    | "chart"
    | "geotiff"
    | "cmems"
    | "ecmwf"
    | "legend"
    | "data_layer"
  metadata?: OpenEOJobResultAssetMetadata
}

export type OpenEOJobResult = {
  assets: { [key: string]: OpenEOJobResultAsset }
}

export type OpeneoWorkflowContextResult = {
  availableAssets: OpenEOJobResultAsset[]
  selectedLayersIds: string[]
  setSelectedLayersIds: (value: string[] | null) => {}
  previewJobId: number
  isResultsLoading: boolean
}
