export const PuRd = [
  "#F7F4F9",
  "#EEE9F3",
  "#E5DCEC",
  "#D9C4E0",
  "#D1AED4",
  "#CB99CA",
  "#D280BD",
  "#DF64AF",
  "#E4429A",
  "#E32682",
  "#D51965",
  "#BF0D51",
  "#9F0245",
  "#830033",
  "#67001F",
]
