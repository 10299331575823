import {
  Accordion,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  ExpandedIndex,
  Flex,
} from "@chakra-ui/react"
import { useEffect, useMemo, useState } from "react"
import { MdOutlineCollections } from "react-icons/md"
import { VscServerProcess, VscSymbolParameter } from "react-icons/vsc"

import CollectionList from "components/openeo-editor/collection-list/CollectionList"
import useCollections from "components/openeo-editor/lib/useCollections"
import useProcesses from "components/openeo-editor/lib/useProcesses"
import ProcessList from "components/openeo-editor/process-list/ProcessList"
import { FEATURES, useUserFeatureFlags } from "services/feature-flags"

import DragAndDropHint from "./DragAndDropHint"
import DrawerSearch from "./DrawerSearch"
import DrawerSection from "./DrawerSection"
import { useOpenEOEditorDrawerState } from "./atoms"
import { filterCollection, filterProcesses } from "./filters"

export default function OpenEOEditorDrawer() {
  const { isFeatureEnabled } = useUserFeatureFlags()
  const isShowHiddenProcessesEnabled = isFeatureEnabled(FEATURES.SHOW_HIDDEN_PROCESSES)
  const { isDrawerOpen, onDrawerClose } = useOpenEOEditorDrawerState()
  const [searchTerm, setSearchTerm] = useState("")
  const [openAccordions, setOpenAccordions] = useState<ExpandedIndex>([])
  const { isLoading: isCollectionsLoading, isError: isCollectionsError, collections } = useCollections()
  const { isLoading: isProcessesLoading, isError: isProcessesError, processes } = useProcesses()

  const filteredCollections = useMemo(() => {
    return filterCollection(collections, searchTerm)
  }, [collections, searchTerm])

  const filteredProcesses = useMemo(() => {
    return filterProcesses(processes, searchTerm, false, isShowHiddenProcessesEnabled)
  }, [processes, searchTerm, isShowHiddenProcessesEnabled])

  const filteredInputProcesses = useMemo(() => {
    return filterProcesses(processes, searchTerm, true, isShowHiddenProcessesEnabled)
  }, [processes, searchTerm, isShowHiddenProcessesEnabled])

  useEffect(() => {
    if (searchTerm.length === 0) {
      setOpenAccordions([])
    } else {
      setOpenAccordions(
        [filteredCollections, filteredProcesses, filteredInputProcesses]
          .map((elements: any[], index) => (elements.length > 0 ? index : null))
          .filter((element) => element !== null) as number[]
      )
    }
  }, [filteredCollections, filteredProcesses, filteredInputProcesses, searchTerm])

  function handleSearchTermChange(searchTerm: string) {
    setSearchTerm(searchTerm)
  }

  function handleAccordionsChange(expandedIndexes: number[]) {
    setOpenAccordions(expandedIndexes)
  }

  return (
    <Drawer size="xl" variant="alwaysOpen" isOpen={isDrawerOpen} placement="left" onClose={onDrawerClose}>
      <DrawerContent h="65vh" m={3} shadow="xl" rounded="md">
        <DrawerCloseButton size="sm" />

        <DrawerHeader px={1} bg="primary.50" roundedTop="md">
          <Flex w="80%" mx="auto">
            <DrawerSearch onSearchTermChange={handleSearchTermChange} />
          </Flex>
        </DrawerHeader>

        <DrawerBody px={0}>
          <Accordion
            allowMultiple
            allowToggle
            index={openAccordions}
            onChange={handleAccordionsChange}
            data-id="workflow-configurator__drawer-components"
          >
            <DrawerSection
              label="Datasets"
              description="Browse all available data collections"
              icon={MdOutlineCollections}
            >
              <CollectionList
                filteredCollections={filteredCollections}
                isLoading={isCollectionsLoading}
                isError={isCollectionsError}
              />
            </DrawerSection>
            <DrawerSection
              label="Processes"
              description="Use processes to transform data collections"
              icon={VscServerProcess}
            >
              <ProcessList
                filteredProcesses={filteredProcesses}
                isLoading={isProcessesLoading}
                isError={isProcessesError}
                categorize
              />
            </DrawerSection>
            <DrawerSection
              label="User parameters"
              description="Control your workflow by adding user provided parameters"
              icon={VscSymbolParameter}
            >
              <ProcessList
                filteredProcesses={filteredInputProcesses}
                isLoading={isProcessesLoading}
                isError={isProcessesError}
              />
            </DrawerSection>
          </Accordion>
          <DragAndDropHint isVisible={!(openAccordions as number[]).length} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
