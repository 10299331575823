import TileLayer from "ol/layer/WebGLTile"
import PropTypes from "prop-types"
import { useEffect } from "react"

import { useMapContext } from "../MapContextProvider"

const GeoTIFFLayer = ({ source, style, zIndex = 0, className = "ol-geotiff-layer" }) => {
  const { map } = useMapContext()

  useEffect(() => {
    if (!map) return

    const tileLayer = new TileLayer({
      className,
      source,
      style,
      cacheSize: 512 * 50, // TODO: necessary only for examples due to the size of the file. Check if necessary after BE implementation
      extent: source.getView().extent,
    })

    tileLayer.setZIndex(zIndex)
    map.addLayer(tileLayer)

    return () => {
      if (map) {
        map.removeLayer(tileLayer)
      }
    }
  }, [className, map, source, style, zIndex])
  return null
}

GeoTIFFLayer.propTypes = {
  className: PropTypes.string,
  source: PropTypes.object.isRequired,
  style: PropTypes.object,
  zIndex: PropTypes.number,
}

export default GeoTIFFLayer
