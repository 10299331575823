import { Input, Select } from "@chakra-ui/react"
import { useField } from "formik"
import PropTypes from "prop-types"

import { parameterType, schemaType } from "components/openeo-editor/lib/propTypes"

function StringType(props) {
  const { parameter, type, schema } = props
  const isEnum = !!schema.enum
  const Component = isEnum ? EnumStringType : BasicStringType

  return <Component parameter={parameter} type={type} schema={schema} />
}

StringType.propTypes = {
  parameter: parameterType.isRequired,
  type: PropTypes.string.isRequired,
  schema: schemaType.isRequired,
}

function BasicStringType(props) {
  const { parameter } = props
  const [field] = useField(parameter.name)

  return <Input {...field} size="xs" />
}

BasicStringType.propTypes = {
  parameter: parameterType.isRequired,
}

function EnumStringType(props) {
  const { parameter, schema } = props

  return (
    <Select size="xs" defaultValue={parameter.default}>
      {schema.enum.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </Select>
  )
}

EnumStringType.propTypes = {
  parameter: parameterType.isRequired,
  schema: schemaType.isRequired,
}

export { StringType }
