import { createContext, useContext, useState } from "react"

import { childrenType } from "lib/CustomPropTypes"

const GuidedTourContext = createContext(null)

export default function GuidedTourContextProvider(props) {
  const { children } = props
  const [run, setRun] = useState(false)
  const [hasSteps, setHasSteps] = useState(false)

  return (
    <GuidedTourContext.Provider value={{ run, setRun, hasSteps, setHasSteps }}>{children}</GuidedTourContext.Provider>
  )
}

export function useGuidedTourContext() {
  const context = useContext(GuidedTourContext)
  if (!context) {
    throw new Error("GuidedTourContext was not initialized. Maybe component was not wrapped in provider")
  }

  return context
}

GuidedTourContextProvider.propTypes = {
  children: childrenType,
}
