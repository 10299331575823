import { Alert, AlertIcon, AlertTitle, Box, Flex, ModalBody, ModalHeader, VStack } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { CgImport } from "react-icons/cg"

import { Section } from "components/common"
import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"
import ToolboxButton from "components/common/toolbox-button/ToolboxButton"
import CollectionImage from "components/openeo-editor/collection/CollectionImage"
import CollectionInfo from "components/openeo-editor/collection/CollectionInfo"
import useFlowEditorController from "components/openeo-editor/flow/flowEditorController"
import useCollectionDetails from "components/openeo-editor/lib/useCollectionDetails"
import STACLicense from "components/stac-browser/STACLicense"
import STACProvider from "components/stac-browser/STACProvider"

export default function CollectionMetadata(props) {
  const { collectionTitle, collectionId, onModalClose } = props
  const { isLoading, collection } = useCollectionDetails(collectionId)
  const { onInsertElementById } = useFlowEditorController()

  if (isLoading) {
    return <LoadingSpinner />
  }

  const onAddToGraph = () => {
    onModalClose()
    onInsertElementById(collection.id, collection["gee:type"] === "table" ? "featureCollection" : "collection")
  }

  if (!collection) {
    return (
      <>
        <ModalHeader>{collectionTitle || "Error"}</ModalHeader>
        <ModalBody p="xl">
          <Alert rounded="md" variant="subtle" status="error" mb={18}>
            <AlertIcon />
            <AlertTitle>Failed to load collection metadata</AlertTitle>
          </Alert>
        </ModalBody>
      </>
    )
  }
  return (
    <>
      <ModalHeader>{collection.title}</ModalHeader>
      <ModalBody overflowY="scroll">
        <Flex w="full">
          <Flex flexDirection="column" flexShrink={0} w="20%" pr={6}>
            <Box w="full" h="auto" rounded="md" mb={2}>
              <CollectionImage collection={collection} boxSize="full" />
            </Box>

            <VStack spacing={6}>
              <STACLicense collection={collection} />

              <Section w="full">
                <Section.Title fontSize="sm">Providers</Section.Title>
                {collection.providers.map((provider, index) => (
                  <STACProvider key={index} provider={provider} />
                ))}
              </Section>

              <ToolboxButton
                variant="outline"
                icon={CgImport}
                isWide
                label="Add to the graph"
                size="lg"
                onClick={onAddToGraph}
              />
            </VStack>
          </Flex>
          <Flex w="80%" maxW="80%" flexDirection="column" overflow="wrap">
            <CollectionInfo collectionId={collectionId} />
          </Flex>
        </Flex>
      </ModalBody>
    </>
  )
}

CollectionMetadata.propTypes = {
  collectionTitle: PropTypes.string,
  collectionId: PropTypes.number.isRequired,
  onModalClose: PropTypes.func.isRequired,
}
