import { Badge, Box, Flex, HStack, Heading, Icon, Image, LinkBox, LinkOverlay, Text, Tooltip } from "@chakra-ui/react"
import { PillPity } from "pill-pity"
import PropTypes from "prop-types"
import { useState } from "react"
import { VscCode } from "react-icons/vsc"

import NewFeatureBadge from "components/new-feature-badge/NewFeatureBadge"

import {
  cardImageStyles,
  cardNoImageStyles,
  workflowCreatorCardStyles,
  workflowCreatorSelectedCardStyles,
} from "./WorkflowCardStyles"

function WorkflowCard(props) {
  const { isSelected, workflowCard, selectionHandler } = props

  const tooltipLabel = "Double click to select and continue"

  return (
    <Tooltip label={tooltipLabel} hasArrow>
      <LinkBox
        className="workflowCard"
        {...(isSelected ? workflowCreatorSelectedCardStyles : workflowCreatorCardStyles)}
      >
        {!isSelected && <Box position="absolute" w="full" h="full" bgColor="rgba(255, 255, 255, 0.3)" />}

        <NewFeatureBadge featureCreatedAt={workflowCard.created_at} position="absolute" top={-2} right={-3} />

        <CardImage {...workflowCard.meta}>
          <Flex w="full" bg="rgba(0, 0, 0, 0.55)" px={2} py={4} zIndex={1}>
            <Heading fontSize="lg" color="white">
              <LinkOverlay href="#" onClick={(e) => selectionHandler(workflowCard, e)}>
                {workflowCard.name}
              </LinkOverlay>
            </Heading>
          </Flex>
          <CodingRequiredIndicator workflowCard={workflowCard} />
        </CardImage>

        <Flex flexGrow={1}>
          <LinkOverlay
            data-testid="workflowCard"
            tabIndex="-1"
            href="#"
            onClick={selectionHandler.bind(this, workflowCard)}
          >
            <Text p={2} fontSize="sm">
              {workflowCard.description}
            </Text>
          </LinkOverlay>
        </Flex>

        <Flex minH="10%" fontSize="xs" px={2} flexDirection="column">
          {workflowCard.author && <Text color="gray.400">By {workflowCard.author}</Text>}
          {workflowCard.meta?.tags && (
            <HStack spacing={1} py={2} maxW="100%" wrap="wrap">
              {workflowCard.meta.tags.map((tag, index) => (
                <Badge key={index} variant="secondary" fontSize="2xs" my={1}>
                  {tag}
                </Badge>
              ))}
            </HStack>
          )}
        </Flex>
      </LinkBox>
    </Tooltip>
  )
}

function CardImage(props) {
  const { image, pattern, children } = props

  const [isImageAvailable, setIsImageAvailable] = useState(!!image)

  if (!isImageAvailable) {
    return (
      <PillPity className="cardImage--noImage" pattern={pattern || "floating-cogs"} {...cardNoImageStyles}>
        {children}
      </PillPity>
    )
  }

  return (
    <Flex className="cardImage" {...cardImageStyles}>
      <Box w="100%" h="100%" maxH="100%" pos="absolute">
        <Image
          w="100%"
          maxH="100%"
          src={image}
          onError={() => setIsImageAvailable(false)}
          objectFit="cover"
          borderTopRadius="md"
        />
      </Box>
      {children}
    </Flex>
  )
}

function CodingRequiredIndicator(props) {
  const {
    workflowCard: { id = "" },
  } = props

  const isCodingRequired = id === "input-google-earth-engine" || id.startsWith("input-gee-")

  if (!isCodingRequired) {
    return null
  }

  return (
    <Flex
      fontSize="sm"
      fontWeight="semibold"
      color="white"
      bgColor="orange.400"
      rounded="md"
      shadow="dark-lg"
      alignItems="center"
      py={1}
      px={2}
      position="absolute"
      top={4}
      left={2}
    >
      <Icon as={VscCode} boxSize={4} me={3} />
      Code editor
    </Flex>
  )
}

WorkflowCard.propTypes = {
  isSelected: PropTypes.bool,
  workflowCard: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    meta: PropTypes.shape({
      tags: PropTypes.arrayOf(PropTypes.string),
      image: PropTypes.string,
      icon: PropTypes.string,
    }),
    author: PropTypes.string,
  }),
  selectionHandler: PropTypes.func.isRequired,
}

export default WorkflowCard
