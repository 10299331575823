export const ocean = [
  "#008000",
  "#006412",
  "#004A24",
  "#002D37",
  "#001249",
  "#00095B",
  "#00246D",
  "#004080",
  "#005B92",
  "#0076A4",
  "#2492B6",
  "#5AACC8",
  "#93C9DB",
  "#C9E4ED",
  "#FFFFFF",
]
