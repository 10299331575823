export const magma = [
  "#000004",
  "#0C0926",
  "#221150",
  "#420F75",
  "#5F187F",
  "#7B2382",
  "#982D80",
  "#B73779",
  "#D3436E",
  "#EB5760",
  "#F8765C",
  "#FD9869",
  "#FEBB81",
  "#FDDC9E",
  "#FCFDBF",
]
