export const gist_rainbow = [
  "#FF0029",
  "#FF3800",
  "#FF9900",
  "#FEFF00",
  "#9DFF00",
  "#3BFF00",
  "#00FF26",
  "#00FF8C",
  "#00FFED",
  "#00B0FF",
  "#004EFF",
  "#1400FF",
  "#7B00FF",
  "#DD00FF",
  "#FF00BF",
]
