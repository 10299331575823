export const PRGn = [
  "#40004B",
  "#661E73",
  "#844793",
  "#9F78B1",
  "#BC9ECA",
  "#D7C0DD",
  "#EBDEEC",
  "#F6F7F6",
  "#E1F2DD",
  "#C3E7BD",
  "#9CD597",
  "#66B56B",
  "#358E48",
  "#13692F",
  "#00441B",
]
