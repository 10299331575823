export const tab20 = [
  "#1F77B4",
  "#AEC7E8",
  "#FFBB78",
  "#2CA02C",
  "#98DF8A",
  "#FF9896",
  "#9467BD",
  "#8C564B",
  "#C49C94",
  "#E377C2",
  "#7F7F7F",
  "#C7C7C7",
  "#BCBD22",
  "#17BECF",
  "#9EDAE5",
]
