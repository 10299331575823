import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons"
import { Flex, IconButton, Tooltip } from "@chakra-ui/react"
import PropTypes from "prop-types"

const PaginatorActions = {
  PREVIOUS: "prev_cursor",
  NEXT: "next_cursor",
}

const Paginator = ({ action, cursors, size = "sm" }) => {
  return (
    <Flex justifyContent="space-between" m={4} alignItems="center">
      <Tooltip label="Previous page">
        <IconButton
          variant="outline"
          size={size}
          aria-label="Previous page"
          disabled={cursors && cursors[PaginatorActions.PREVIOUS] === null}
          onClick={() => action(PaginatorActions.PREVIOUS)}
          icon={<ChevronLeftIcon />}
        />
      </Tooltip>
      <Tooltip label="Next page">
        <IconButton
          variant="outline"
          size={size}
          aria-label="Next page"
          disabled={cursors && cursors[PaginatorActions.NEXT] === null}
          onClick={() => action(PaginatorActions.NEXT)}
          icon={<ChevronRightIcon />}
        />
      </Tooltip>
    </Flex>
  )
}

Paginator.propTypes = {
  action: PropTypes.func,
  cursors: PropTypes.shape({
    prev_cursor: PropTypes.string,
    next_cursor: PropTypes.string,
  }),
  size: PropTypes.string,
}

export default Paginator
