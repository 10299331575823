export const Pastel2 = [
  "#B3E2CD",
  "#B3E2CD",
  "#FDCDAC",
  "#CBD5E8",
  "#CBD5E8",
  "#CBD5E8",
  "#F4CAE4",
  "#E6F5C9",
  "#E6F5C9",
  "#E6F5C9",
  "#FFF2AE",
  "#F1E2CC",
  "#F1E2CC",
  "#F1E2CC",
  "#CCCCCC",
]
