import { FormControl, FormHelperText, Select, chakra } from "@chakra-ui/react"
import { useEffect, useMemo, useState } from "react"

import { parameterType, schemaType } from "components/openeo-editor/lib/propTypes"
import UnsupportedParameterTypeControl from "components/openeo-editor/parameters/controls/UnsupportedParameterTypeControl"
import { getParameterTypeMapping } from "components/openeo-editor/parameters/controls/parameterTypeMapping"

function ParameterTypeControl(props) {
  const { parameter, selectedSchema } = props
  const { type, subtype, items } = selectedSchema
  const types = useMemo(() => (Array.isArray(type) ? type : [type]), [type])
  const [selectedType, setSelectedType] = useState(types[0])

  const Component = useMemo(
    () => getParameterTypeMapping(selectedType, subtype, items) || UnsupportedParameterTypeControl,
    [selectedType, subtype, items]
  )

  useEffect(() => setSelectedType(types[0]), [types])

  return (
    <>
      {types.length > 1 && (
        <Select size="xs" mb={2} onChange={({ target: { value } }) => setSelectedType(value)}>
          {types.map((type, index) => (
            <chakra.option key={index}>{type}</chakra.option>
          ))}
        </Select>
      )}
      <FormControl>
        <Component parameter={parameter} type={selectedType} schema={selectedSchema} />
        <FormHelperText fontSize="xs">{selectedSchema.description}</FormHelperText>
      </FormControl>
    </>
  )
}

ParameterTypeControl.propTypes = {
  parameter: parameterType.isRequired,
  selectedSchema: schemaType.isRequired,
}

export default ParameterTypeControl
