export const Purples = [
  "#FCFBFD",
  "#F5F3F8",
  "#ECEBF4",
  "#E0DFEE",
  "#D1D2E7",
  "#C0C1DE",
  "#AFAED4",
  "#9E9AC8",
  "#8D89C0",
  "#7D77B7",
  "#705EAA",
  "#64459E",
  "#572C92",
  "#4B1687",
  "#3F007D",
]
