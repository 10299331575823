import { Flex, Kbd, Text } from "@chakra-ui/react"
import useGetAllowedBIllingFeatures from "api/useGetAllowedBIllingFeatures"
import useGetCurrentPricingPlan from "api/useGetCurrentPricingPlan"
import { useAtom } from "jotai"
import { VscPlay } from "react-icons/vsc"

import ToolboxButton from "components/common/toolbox-button/ToolboxButton"
import useSaveCode from "components/openeo-editor/code-editor/useSaveCode"
import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"
import PremiumButtonIndicator from "components/premium-indicators/PremiumButtonIndicator"

import { useOpenEOEditorDrawerState } from "../../openeo-editor/drawer/atoms"
import { executeButtonClickedAtom } from "./openeoWorkflowToolboxAtoms"

export default function ExecuteWorkflowButton() {
  const { onExecute, previewAreaOfInterest } = useOpeneoWorkflowContext() as any
  const { onDrawerClose } = useOpenEOEditorDrawerState()
  const { handleSaveCode } = useSaveCode()
  const [, setExecuteButtonClicked] = useAtom(executeButtonClickedAtom)
  const { assignedPricingPlan } = useGetCurrentPricingPlan()
  const { allowedBillingFeatures } = useGetAllowedBIllingFeatures()
  const showPremiumIndicator = !allowedBillingFeatures?.includes("included_resources")
  const isUserSubscribed = !!assignedPricingPlan && assignedPricingPlan?.name !== "FREE"

  async function handleClick() {
    onDrawerClose()
    if (previewAreaOfInterest) {
      await handleSaveCode()
      onExecute()
    } else {
      setExecuteButtonClicked(true)
    }
  }

  return (
    <PremiumButtonIndicator
      isPremium={showPremiumIndicator}
      isOpen={isUserSubscribed}
      info={
        <Flex w="full" flexDirection="column">
          <Text fontWeight="bold" fontSize="md" mb={2}>
            Allowed execution limit exceeded
          </Text>
          <Text>We're sorry, but your organization has exceeded the processing limit for the current plan.</Text>
          <Text>
            To continue processing, please consider switching to a higher plan or contact us for further assistance.
          </Text>
        </Flex>
      }
    >
      <ToolboxButton
        // @ts-ignore
        px={4}
        variant="solid"
        colorScheme="green"
        fontWeight="bold"
        icon={VscPlay}
        isWide
        label="Execute"
        tooltipLabel={
          <Flex alignItems="center">
            Execute (<Kbd color="black">^ + X</Kbd>)
          </Flex>
        }
        onClick={handleClick}
        data-id="workflow-configurator__execute"
      />
    </PremiumButtonIndicator>
  )
}
