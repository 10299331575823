import { Select } from "@chakra-ui/react"
import { useField } from "formik"

import { parameterType } from "components/openeo-editor/lib/propTypes"

function BooleanType(props) {
  const { parameter } = props
  const [{ value }, , { setValue }] = useField(parameter.name)

  const handleChange = ({ target: { value } }) => setValue(value)

  return (
    <Select size="xs" defaultValue={value || parameter.default} onChange={handleChange}>
      {["true", "false"].map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </Select>
  )
}

BooleanType.propTypes = {
  parameter: parameterType.isRequired,
}

export { BooleanType }
