import buildUrl from "api/utils/buildUrl"

import useAuthenticatedApi from "services/api/authenticatedApi"

const useEventsApi = () => {
  const api = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1/internal/events") })
  return {
    track: (eventName, parameters = {}) => {
      return api
        .post("track", {
          json: {
            name: eventName,
            parameters: parameters,
          },
        })
        .json()
    },
  }
}

export default useEventsApi
