import { Alert, AlertIcon, Flex, HStack, Image, Text } from "@chakra-ui/react"

import { LoadingSpinner } from "components/common"
import useModalAdditionalInfoController from "components/orb/settings/template/modalAdditionalInfoController"

function ModalAdditionalInfo() {
  const { isLoading, isError, template } = useModalAdditionalInfoController()

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isError) {
    return (
      <Alert status="error" fontSize="sm" rounded="md">
        <AlertIcon />
        Failed to fetch template details
      </Alert>
    )
  }

  return (
    <HStack spacing={2} fontSize="sm" alignItems="flex-start">
      <Flex flexBasis="60%" flexDirection="column" justifyContent="flex-start">
        <Text>You're changing your application's template to:</Text>
        <Text my={2} fontWeight="semibold" fontSize="md">
          {template.name}
        </Text>
      </Flex>
      <Flex flexBasis="40%">
        <Image w="full" src={template.thumbnail} rounded="md" shadow="sm" />
      </Flex>
    </HStack>
  )
}

export default ModalAdditionalInfo
