export const BuPu = [
  "#F7FCFD",
  "#EAF3F8",
  "#DCE9F2",
  "#C8DAEA",
  "#B5CCE3",
  "#A3BFDC",
  "#96ACD2",
  "#8C95C6",
  "#8C7DBA",
  "#8B65AE",
  "#894DA3",
  "#863394",
  "#821580",
  "#6A0867",
  "#4D004B",
]
