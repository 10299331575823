import { Alert, AlertDescription, AlertIcon, Badge, Flex, Icon, SimpleGrid, Spinner, Text } from "@chakra-ui/react"
import useGetAllowedBIllingFeatures from "api/useGetAllowedBIllingFeatures"
import { useMemo } from "react"

import categorizeProcesses, { ProcessCategory } from "components/openeo-editor/lib/processCategories"
import { BaseProcessType } from "components/openeo-editor/types"

import ProcessListEntry from "./ProcessListEntry"

export default function ProcessList(props: ProcessListProps) {
  const { filteredProcesses = [], isError, isLoading, categorize = false } = props
  const { allowedBillingFeatures } = useGetAllowedBIllingFeatures()

  const categorizedProcesses = useMemo(
    () => categorizeProcesses(filteredProcesses, allowedBillingFeatures),
    [filteredProcesses, allowedBillingFeatures]
  )

  if (isLoading) {
    return (
      <Flex w="full" alignItems="center" justifyContent="center" m={2} color="primary.900">
        <Spinner boxSize={4} me={3} /> Loading processes…
      </Flex>
    )
  }

  if (isError) {
    return (
      <Flex w="full" px={6}>
        <Alert fontSize="sm" rounded="md" status="error">
          <AlertIcon boxSize={3} />
          <AlertDescription>Failed to load list of processes</AlertDescription>
        </Alert>
      </Flex>
    )
  }

  if (categorize) {
    return (
      <Flex w="full" px={3} flexDirection="column">
        {Object.entries(categorizedProcesses).map(([categoryName, category]) => (
          <ProcessListCategory key={categoryName} categoryName={categoryName} category={category} />
        ))}
      </Flex>
    )
  } else {
    return (
      <Flex w="full" flexDirection="column">
        <SimpleGrid w="full" px={2} columns={4} spacing={4}>
          {filteredProcesses.map((process, index) => (
            <ProcessListEntry key={index} process={process} />
          ))}
        </SimpleGrid>
      </Flex>
    )
  }
}

function ProcessListCategory(props: ProcessListCategoryProps) {
  const { categoryName, category } = props

  if (!category.processes.length) {
    return null
  }

  return (
    <Flex w="full" mt={2} mb={4} flexDirection="row">
      <Flex
        minW="200px"
        w="200px"
        mb={4}
        color="primary.900"
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection="column"
      >
        <Text fontSize="md" fontWeight="bold" mb={2}>
          <Icon as={category.icon} boxSize={5} me={5} />
          {categoryName}
          {category.showPaidFeatureBadge && (
            <Badge ml={2} colorScheme="blue" rounded="base" fontSize="2xs">
              Premium
            </Badge>
          )}
        </Text>
        <Text fontSize="xs" color="gray.400">
          {category.description}
        </Text>
      </Flex>
      <Flex w="full" flexDirection="column">
        <SimpleGrid w="full" px={2} columns={3} spacing={4}>
          {category.processes.map((process, index) => (
            <ProcessListEntry key={index} process={process} />
          ))}
        </SimpleGrid>
      </Flex>
    </Flex>
  )
}

type ProcessListProps = {
  isError: boolean
  isLoading: boolean
  filteredProcesses: BaseProcessType[]
  categorize?: boolean
}

type ProcessListCategoryProps = {
  categoryName: string
  category: ProcessCategory
}
