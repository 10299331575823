import { useQuery } from "react-query"

import useWorkflowTemplateQueries from "./queryFunctions/useWorkflowTemplateQueries"

export default function useGetWorkflowTemplates(category?: string, options?: any) {
  const { listTemplatesQuery } = useWorkflowTemplateQueries()
  const { queryKey, queryFn } = listTemplatesQuery(category)

  const { isLoading, isError, data: templates } = useQuery(queryKey, queryFn, options)

  return { isLoading, isError, templates }
}
