import { Flex, VStack } from "@chakra-ui/react"
import { FieldArray, Formik, useField } from "formik"
import PropTypes from "prop-types"

import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"
import { parameterType } from "components/openeo-editor/lib/propTypes"
import useCollectionDetails from "components/openeo-editor/lib/useCollectionDetails"
import AddFilterButton from "components/openeo-editor/parameters/controls/types/metadata-filter/AddFilterButton"
import MetadataFilterEntry from "components/openeo-editor/parameters/controls/types/metadata-filter/MetadataFilterEntry"
import { useParameterEntryContext } from "components/openeo-editor/parameters/editor/ParameterEntryContext"

export function MetadataFilterType(props) {
  const { parameter } = props
  const [{ value }, , { setValue }] = useField(parameter.name)

  const { isLoading, collection } = useParameterEntryContext()
  const { isLoading: isPropertiesLoading, properties } = useCollectionDetails(collection && collection["db:id"])

  let metadataFilters = []
  try {
    metadataFilters = Object.entries(JSON.parse(value)).map((entry) => entry)
  } catch {}

  const usedProperties = metadataFilters.map(([name]) => name)
  const availableProperties = properties?.filter((property) => !usedProperties.includes(property.name))

  function handleSubmit(values) {
    const parsedFilters = values.filters.reduce((obj, [filterName, filterDefinition]) => {
      obj[filterName] = filterDefinition
      return obj
    }, {})

    setValue(JSON.stringify(parsedFilters))
  }

  if (isLoading || isPropertiesLoading) {
    return <LoadingSpinner size="sm" thickness="2px" />
  }

  return (
    <Formik initialValues={{ filters: metadataFilters }} onSubmit={handleSubmit}>
      {(formik) => (
        <MetadataFilterTypeForm
          onSubmit={formik.handleSubmit}
          properties={availableProperties}
          useCustomProperties={!collection}
        />
      )}
    </Formik>
  )
}

MetadataFilterType.propTypes = {
  parameter: parameterType.isRequired,
}

function MetadataFilterTypeForm(props) {
  const { onSubmit, properties, useCustomProperties } = props
  const [field] = useField("filters")

  const hasEntries = field.value && field.value.length > 0

  return (
    <form onSubmit={onSubmit}>
      <FieldArray
        name={field.name}
        render={({ insert, remove, push }) => (
          <VStack spacing={2} alignItems="flex-start">
            {hasEntries &&
              field.value.map((entry, index) => (
                <MetadataFilterEntry
                  key={index}
                  index={index}
                  name={field.name}
                  properties={properties}
                  useCustomProperties={useCustomProperties}
                  onInsert={insert}
                  onRemove={remove}
                  onSubmit={onSubmit}
                />
              ))}
            <Flex w="full">{!hasEntries && <AddFilterButton onClick={push} hasEntries={hasEntries} />}</Flex>
          </VStack>
        )}
      />
    </form>
  )
}

MetadataFilterTypeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired, description: PropTypes.string })),
  useCustomProperties: PropTypes.bool.isRequired,
}
