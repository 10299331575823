export const prism = [
  "#FF0000",
  "#1A00FF",
  "#3AEF00",
  "#FFDF00",
  "#FF000F",
  "#1300FF",
  "#42F400",
  "#FFD700",
  "#FF001E",
  "#0D00FF",
  "#4BFA00",
  "#FFA700",
  "#FF002C",
  "#0800FF",
  "#54FF00",
]
