import { ButtonGroup, Flex, IconButton, InputGroup } from "@chakra-ui/react"
import { useField } from "formik"
import PropTypes from "prop-types"
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai"

import MetadataFilterInput from "components/openeo-editor/parameters/controls/types/metadata-filter/MetadataFilterInput"
import MetadataFilterItem from "components/openeo-editor/parameters/controls/types/metadata-filter/MetadataFilterItem"

export default function MetadataFilterEntry(props) {
  const { name, index, onInsert, onRemove, onSubmit, properties, useCustomProperties } = props

  const [field, , { setValue }] = useField(`${name}.${index}`)

  function handleRemove() {
    onRemove(index)
    onSubmit()
  }

  function handleInsert() {
    onInsert(index, "")
  }

  function handleSave(value) {
    setValue(value)
    onSubmit()
  }

  return (
    <Flex w="full">
      <InputGroup size="xs" alignItems="center">
        {field.value ? (
          <MetadataFilterItem filterItem={field.value} />
        ) : (
          <MetadataFilterInput onSave={handleSave} properties={properties} useCustomProperties={useCustomProperties} />
        )}

        <ButtonGroup ml={2} isAttached>
          <IconButton
            aria-label="Remove entry"
            variant="outline"
            icon={<AiOutlineMinus />}
            onClick={handleRemove}
            mr="-px"
          />
          <IconButton aria-label="Add entry" variant="outline" icon={<AiOutlinePlus />} onClick={handleInsert} />
        </ButtonGroup>
      </InputGroup>
    </Flex>
  )
}

MetadataFilterEntry.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onInsert: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired, description: PropTypes.string })),
  useCustomProperties: PropTypes.bool.isRequired,
}
