export const RdGy = [
  "#67001F",
  "#9C1127",
  "#C13639",
  "#DB6B55",
  "#F09C7B",
  "#F9C4A9",
  "#FEE5D6",
  "#FEFEFE",
  "#E9E9E9",
  "#D0D0D0",
  "#B3B3B3",
  "#8F8F8F",
  "#656565",
  "#3E3E3E",
  "#1A1A1A",
]
