import CryptoJS from "crypto-js"
import { saveAs } from "file-saver"
import PropTypes from "prop-types"
import { useState } from "react"
import { useDropzone } from "react-dropzone"

import { useNotifications } from "components/common/notifications"
import useFlowBuilder from "components/openeo-editor/lib/useFlowBuilder"
import useProcessGraphBuilder from "components/openeo-editor/lib/useProcessGraphBuilder"
import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"

function useImportExportGraphModalController(onClose) {
  const { buildElements } = useFlowBuilder()
  const { buildProcessGraph } = useProcessGraphBuilder()
  const { showErrorNotification, showNotification } = useNotifications()
  const { setElements, legends, setLegends, onSaveGraph } = useOpeneoWorkflowContext()

  const [graphCode, setGraphCode] = useState("")

  const dropzoneProps = useDropzone({
    onDrop: ([file]) => {
      const reader = new FileReader()
      reader.onload = function (e) {
        try {
          const loadedGraphCode = e.target.result
          validateGraphCode(loadedGraphCode)
          setGraphCode(loadedGraphCode)
        } catch (error) {
          showErrorNotification(error, "Failed to load graph")
        }
      }
      reader.readAsText(file)
    },
    multiple: false,
  })

  function handleLoadCurrentGraph() {
    let graph = buildProcessGraph()
    if (legends) {
      graph = {
        ...graph,
        legends,
      }
    }
    const base64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(graph)))
    const sha256 = CryptoJS.SHA256(base64)

    setGraphCode(sha256 + base64)
  }

  function handleGraphUpdate({ target: { value } }) {
    setGraphCode(value)
  }

  async function handleCopyToClipboard() {
    await navigator.clipboard.writeText(graphCode)
  }

  function validateGraphCode(validatedCode) {
    const expected_sha256 = validatedCode.slice(0, 64)
    const base64 = validatedCode.slice(64)

    const sha256 = CryptoJS.SHA256(base64)

    if (sha256.toString() !== expected_sha256) {
      throw new Error("The graph code is malformed, try exporting it again")
    }

    const importedGraph = CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(base64))

    return JSON.parse(importedGraph)
  }

  function handleGraphImport() {
    try {
      const parsedGraphCode = validateGraphCode(graphCode)
      const parsedGraph = { process_graph: parsedGraphCode["process_graph"] }
      const legends = parsedGraphCode["legends"]
      const elements = buildElements(parsedGraph)

      setElements(elements)
      setLegends(legends)
      onClose()
      showNotification("Graph has been imported", null, "success")
      onSaveGraph(legends, parsedGraph)
    } catch (error) {
      showErrorNotification(error, "Failed to import graph")
    }
  }

  function handleSaveAsFile() {
    try {
      validateGraphCode(graphCode)
      saveAs(new Blob([graphCode]), "graph.orbcode")
    } catch (error) {
      showErrorNotification(error, "Failed to export graph")
    }
  }

  return {
    graphCode,
    handleLoadCurrentGraph,
    handleCopyToClipboard,
    handleGraphUpdate,
    handleGraphImport,
    handleSaveAsFile,
    dropzoneProps,
  }
}

useImportExportGraphModalController.propTypes = {
  initialGraph: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default useImportExportGraphModalController
