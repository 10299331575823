import useAdminOrbManagementQueries from "api/queryFunctions/useAdminOrbManagementQueries"
import { useMutation } from "react-query"

export default function useAdminAssignPricingPlanTrialToOrb() {
  const { assignOrbPricingPlanTrialMutation } = useAdminOrbManagementQueries()
  const { mutationFn, mutationOptions } = assignOrbPricingPlanTrialMutation()

  const { isLoading, isError, error, mutateAsync: assignPricingPlanTrial } = useMutation(mutationFn, mutationOptions)

  return { isLoading, isError, error, assignPricingPlanTrial }
}
