import { AiOutlineAppstore } from "react-icons/ai"
import { BiCodeBlock } from "react-icons/bi"
import { BsGraphUp } from "react-icons/bs"
import { GiOrganigram } from "react-icons/gi"
import { useRouteMatch } from "react-router-dom"

import SidebarItem from "components/layout/sidebar/SidebarItem"

export default function AdminSidebar() {
  const match = useRouteMatch("/admin")

  return (
    <>
      <SidebarItem exact to={match?.url} icon={BsGraphUp} tooltip="Summary">
        Summary
      </SidebarItem>

      <SidebarItem to={`${match?.url}/organisations`} icon={GiOrganigram} tooltip="Organisations">
        Organisations
      </SidebarItem>

      <SidebarItem to={`${match?.url}/custom-processes`} icon={BiCodeBlock} tooltip="Custom processes">
        Custom processes
      </SidebarItem>

      <SidebarItem to={`${match?.url}/gdp-demos`} icon={AiOutlineAppstore} tooltip="Manage GDP Demos">
        GDP Demos
      </SidebarItem>
    </>
  )
}
