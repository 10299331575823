import { FlexProps, chakra, useColorModeValue } from "@chakra-ui/react"
import { ForwardedRef, forwardRef } from "react"

function MeasureTooltip(props: FlexProps, ref: ForwardedRef<HTMLDivElement>) {
  const divStyle = useColorModeValue(
    { color: "white", bgColor: "blackAlpha.700" },
    { color: "black", bgColor: "whiteAlpha.700" }
  )

  return (
    <chakra.div
      ref={ref}
      px={2}
      py={1}
      overflowWrap="break-word"
      className="ol-tooltip hidden"
      backdropFilter="blur(5px)"
      fontSize="xs"
      rounded="lg"
      {...divStyle}
    />
  )
}

export default forwardRef(MeasureTooltip)
