import { Modal, ModalCloseButton, ModalContent, ModalOverlay, Portal } from "@chakra-ui/react"

import { useMetadataModal } from "components/openeo-editor/metadata/MetadataModalContext"

function MetadataModal() {
  const { isOpen, onClose, content } = useMetadataModal()

  if (!isOpen) {
    return null
  }

  return (
    <Portal>
      <Modal size="6xl" isOpen={isOpen} onClose={onClose} trapFocus={false} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton size="sm" />
          {content}
        </ModalContent>
      </Modal>
    </Portal>
  )
}

export default MetadataModal
