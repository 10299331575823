export const CMRmap = [
  "#000000",
  "#161648",
  "#2B2688",
  "#4226AE",
  "#632AAD",
  "#8E3189",
  "#C4385A",
  "#FF4126",
  "#F06510",
  "#E68904",
  "#E6AD12",
  "#E6CA36",
  "#E6E172",
  "#F1F1B7",
  "#FFFFFF",
]
