import { useAuth0 } from "@auth0/auth0-react"
import { Button, Modal, ModalContent, ModalOverlay, Stack, Text } from "@chakra-ui/react"
import { FC } from "react"

export const BusinessEmailRequired: FC = () => {
  const { logout } = useAuth0()

  return (
    <Modal size="md" isOpen={true} onClose={() => {}}>
      <ModalOverlay
        backgroundImage="https://s3.eu-west-1.amazonaws.com/assets.orbify.app/homepage-hero-bg.png"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
      />
      <ModalContent px={6} pt={8} pb={4} shadow="dark-lg">
        <Stack>
          <Text fontWeight="bold" fontSize="lg">
            Business email address required
          </Text>
          <Text>Please, use a business email address when registering for the Orbify platform.</Text>
          <Button onClick={() => logout()}>Logout</Button>
        </Stack>
      </ModalContent>
    </Modal>
  )
}
