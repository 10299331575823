import {
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  InputGroup,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react"
import { useField } from "formik"
import PropTypes from "prop-types"
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai"

import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"
import Markdown from "components/markdown/Markdown"
import useCollectionDetails from "components/openeo-editor/lib/useCollectionDetails"
import { useParameterEntryContext } from "components/openeo-editor/parameters/editor/ParameterEntryContext"

function BandNameEntry(props) {
  const { name, index, onInsert, onRemove, isAddingEnabled } = props
  const [{ value: existingBandEntries }] = useField(name)
  const [{ value }, , { setValue }] = useField(`${name}.${index}`)

  const { isLoading, collection } = useParameterEntryContext()
  const { isLoading: isBandsLoading, bands } = useCollectionDetails(collection && collection["db:id"])

  const availableBands = (bands?.bandsList || []).filter((band) => !existingBandEntries.includes(band.name))

  function handleRemove() {
    onRemove(index)
  }

  function handleInsert() {
    onInsert(index, "")
  }

  if (isLoading || isBandsLoading) {
    return <LoadingSpinner />
  }

  return (
    <Flex w="full">
      <InputGroup size="xs" alignItems="center">
        <Menu>
          <Flex w="full">
            {value ? (
              <Flex w="full" ms={2}>
                <BandItem band={bands?.bandsList.find((band) => band.name === value)} />
              </Flex>
            ) : (
              <MenuButton as={Button} size="xs" variant="outline">
                Select band
              </MenuButton>
            )}
          </Flex>
          <MenuList>
            {availableBands.map((band, index) => (
              <MenuItem key={index} onClick={() => setValue(band.name)}>
                <BandItem band={band} />
              </MenuItem>
            ))}
          </MenuList>
        </Menu>

        <ButtonGroup ml={2} isAttached>
          <IconButton
            aria-label="Remove entry"
            variant="outline"
            icon={<AiOutlineMinus />}
            onClick={handleRemove}
            mr="-px"
          />
          {isAddingEnabled && (
            <IconButton aria-label="Add entry" variant="outline" icon={<AiOutlinePlus />} onClick={handleInsert} />
          )}
        </ButtonGroup>
      </InputGroup>
    </Flex>
  )
}

BandNameEntry.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onInsert: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isAddingEnabled: PropTypes.bool.isRequired,
}

function BandNameEntryButton(props) {
  const { onPush } = props

  const handleClick = () => onPush("")

  return (
    <Button variant="secondary" size="xs" me={2} onClick={handleClick}>
      Add band
    </Button>
  )
}

BandNameEntryButton.propTypes = {
  onPush: PropTypes.func.isRequired,
}

function BandItem(props) {
  const { band } = props

  return (
    <Flex flexDirection="column" alignItems="flex-start">
      <Text fontSize="xs" fontWeight="semibold">
        {band.name}
      </Text>
      <Markdown color="gray.400" fontSize="2xs">
        {band.description}
      </Markdown>
    </Flex>
  )
}

export { BandNameEntry, BandNameEntryButton }
