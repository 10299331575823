import { Icon } from "@chakra-ui/icons"
import { As, Button, ButtonProps, Text, Tooltip } from "@chakra-ui/react"
import { ReactNode, forwardRef } from "react"

const ToolboxButton = forwardRef<HTMLButtonElement, ToolboxButtonProps>((props, ref) => {
  const { label, icon, onClick, variant = "solid", isWide = false, tooltipLabel = label, ...rest } = props

  return (
    <Tooltip label={tooltipLabel} shouldWrapChildren>
      <Button
        onClick={onClick}
        aria-label={label}
        variant={variant}
        rounded="base"
        colorScheme="gray"
        fontWeight="normal"
        alignItems="center"
        me="-px"
        px={2}
        size="sm"
        shadow="sm"
        {...rest}
        ref={ref}
      >
        <Icon as={icon} boxSize={3} m={0} p={0} mb={isWide ? "1px" : 0} />
        {isWide && (
          <Text display="inline-flex" ms={2}>
            {label}
          </Text>
        )}
      </Button>
    </Tooltip>
  )
})

interface ToolboxButtonProps extends ButtonProps {
  label: string
  icon: As
  onClick: () => void
  isWide?: boolean
  tooltipLabel?: string | ReactNode
}

export default ToolboxButton
