export const chart = {
  stacked: true,
  dropShadow: { enabled: false },
  zoom: { enabled: false },
  toolbar: { show: false },
}

export const tooltip = {
  marker: { show: false },
}

export const legend = {
  show: true,
  position: "right",
  offsetY: 40,
  formatter: (val: string) => val.toUpperCase(),
}
