import buildUrl from "api/utils/buildUrl"

import useAuthenticatedApi from "./api/authenticatedApi"

const useAreaOfInterestApi = () => {
  const api = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1/") })

  return {
    show: (orbId, areaType) => api.get(`public/orbs/${orbId}/area_of_interest/?area_type=${areaType}`).json(),
    create: (orbId, values) => api.post(`external/orbs/${orbId}/area_of_interest/create`, { json: values }).json(),
    remove: (orbId, areaOfInterestId) =>
      api.delete(`external/orbs/${orbId}/area_of_interest/${areaOfInterestId}`).json(),
  }
}

export default useAreaOfInterestApi
