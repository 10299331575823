import buildUrl from "api/utils/buildUrl"

import useAuthenticatedApi from "services/api/authenticatedApi"

const useOpeneoApi = () => {
  const api = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1/internal/openeo/") })
  const externalApi = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1/external/openeo/") })

  return {
    getResults: (openeoJobId) => externalApi.get(`jobs/${openeoJobId}/results`).json(),
    getGraph: (workflowId) => api.get(`process_graphs/workflow/${workflowId}`).json(),
    saveGraph: (workflowId, graph) => api.put(`process_graphs/workflow/${workflowId}`, { json: graph }).json(),
    validateGraph: (workflowId, graph) =>
      api.post(`process_graphs/workflow/${workflowId}/validation`, { json: graph }).json(),
    executeJob: (workflowId, inputs) => api.post(`jobs`, { json: { workflow_id: workflowId, inputs } }).json(),
    listCollections: () => api.get("collections").json(),
    getCollectionDetails: (collectionId) => api.get(`collections/${collectionId}`).json(),
    listProcesses: (orbId) => api.get("processes", { searchParams: { orb_id: orbId } }).json(),
  }
}

export default useOpeneoApi
