import { useAuth0 } from "@auth0/auth0-react"
import { Helmet } from "react-helmet-async"

import { useEffectOnce } from "hooks/useEffectOnce"
import { isDev } from "services/utils"

const HSQ_SRC_URL = "//js-eu1.hs-scripts.com/143783040.js"

declare global {
  interface Window {
    _hsq?: Array<any>
  }
}

export function HubspotTracker() {
  const hsq = (window._hsq = window._hsq || [])

  const { user } = useAuth0()
  const { name, given_name, family_name } = user || {}

  useEffectOnce(function identifyUser() {
    if (isDev()) {
      return
    }

    const userName = [
      given_name || name?.split(" ").slice(0, 1).at(0) || "",
      family_name || name?.split(" ").slice(1).at(0) || "",
    ].join(" ")

    const identity = {
      email: user?.email,
      name: userName,
      // TODO: Find a way of setting this up for tickets created with chat box
      // application_name: `https://${config.url}.${import.meta.env.VITE_BASE_DOMAIN}/`,
      // application_url: config?.url || "",
    }

    hsq.push(["identify", identity])
  })

  if (isDev()) {
    return null
  }

  return (
    <Helmet>
      <script type="text/javascript" id="hs-script-loader" async defer src={HSQ_SRC_URL}></script>
    </Helmet>
  )
}
