import { Flex, Image, Tag, TagLeftIcon, useColorModeValue } from "@chakra-ui/react"
import { NominatimSearchResult } from "api/models/Nominatim"

export default function SearchResultTag({ searchResult, fontSize = "xs" }: SearchResultTagProps) {
  const iconFilter = useColorModeValue("", "invert(1)")

  function Icon() {
    if (!searchResult.icon) {
      return null
    }

    return <TagLeftIcon as={Image} src={searchResult.icon} filter={iconFilter} />
  }

  if (!searchResult.type) {
    return null
  }

  return (
    <Flex>
      <Tag fontSize={fontSize} rounded="md" colorScheme="blue">
        <Icon />
        {searchResult.type.replaceAll("_", " ")}
      </Tag>
    </Flex>
  )
}

type SearchResultTagProps = {
  searchResult: NominatimSearchResult
  fontSize?: "2xs" | "xs" | "sm" | "md" | "lg"
}
