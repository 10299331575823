import { useCallback } from "react"
import { useStoreState, useZoomPanHelper } from "react-flow-renderer"

function useFlowUtils() {
  const edges = useStoreState((state) => state.edges)
  const nodes = useStoreState((state) => state.nodes)
  const { setCenter } = useZoomPanHelper()

  const getHandleConnections = useCallback(
    (type, id, handleId) => {
      const isSource = type === "source"

      const filterEdge = (edge) => {
        const edgeId = isSource ? edge.source : edge.target
        const edgeHandleId = isSource ? edge.sourceHandle : edge.targetHandle

        return edgeId === id && (handleId ? edgeHandleId === handleId : true)
      }

      return edges.filter(filterEdge)
    },
    [edges]
  )

  const isHandleConnected = (type, id, handleId) => !!getHandleConnections(type, id, handleId).length

  function focusNode(nodeId) {
    const node = nodes.filter(function (el) {
      return el.id === nodeId
    })[0]

    const x = node.__rf.position.x + node.__rf.width / 2
    const y = node.__rf.position.y + node.__rf.height / 2
    const zoom = 0.85

    setCenter(x, y, zoom)
  }

  return {
    getHandleConnections,
    isHandleConnected,
    focusNode,
  }
}

export default useFlowUtils
