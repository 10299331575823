import { Feature, area, bbox } from "@turf/turf"
import { GeoJSONFeature } from "ol/format/GeoJSON"

const squareKilometer = 1000 * 1000

export function calculateSquareKms(feature: GeoJSONFeature, round?: number) {
  const sqKilometers = area(feature) / squareKilometer
  if (round) {
    return sqKilometers.toFixed(round)
  } else {
    return sqKilometers
  }
}

export function calculateBoundingBox(feature: Feature) {
  return bbox(feature)
}
