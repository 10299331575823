import { useAuth0 } from "@auth0/auth0-react"
import ky from "ky"
import { useHistory } from "react-router-dom"

// Will attach Bearer token when user is Authenticated. If not call will be made without Bearer token
export default function useAuthedApiWhenLoggedIn(props: useAuthedApiWhenLoggedInProps) {
  const { baseUrl } = props
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0()
  const history = useHistory()

  if (!isLoading && !isAuthenticated) {
    return ky.extend({ prefixUrl: baseUrl })
  }

  return ky.extend({
    prefixUrl: baseUrl,
    hooks: {
      beforeRequest: [
        async (request) => {
          const token = await getAccessTokenSilently()
          request.headers.set("Authorization", `Bearer ${token}`)
        },
      ],
      afterResponse: [
        (_request, _options, response) => {
          switch (response.status) {
            case 403:
              history.push("/account")
              break
            default:
              return response
          }
        },
      ],
    },
  })
}

type useAuthedApiWhenLoggedInProps = {
  baseUrl: string
}
