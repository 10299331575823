import { useEffect, useRef } from "react"

type Timer = ReturnType<typeof setTimeout>
type GenericFunction = (...args: any[]) => void

export default function useDebounce<Func extends GenericFunction>(func: Func, delay = 1000) {
  const timer = useRef<Timer>()

  useEffect(() => {
    return () => {
      if (!timer.current) {
        return
      }

      clearTimeout(timer.current)
    }
  }, [])

  const debouncedFunction = ((...args) => {
    const newTimer = setTimeout(() => {
      func(...args)
    }, delay)

    clearTimeout(timer.current)

    timer.current = newTimer
  }) as Func

  return debouncedFunction
}
