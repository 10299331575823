export const seismic = [
  "#00004C",
  "#00007F",
  "#0000B1",
  "#0000E6",
  "#2525FF",
  "#6D6DFF",
  "#B5B5FF",
  "#FFFDFD",
  "#FFB5B5",
  "#FF6D6D",
  "#FF2525",
  "#EE0000",
  "#C80000",
  "#A30000",
  "#800000",
]
