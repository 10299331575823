import OLTileLayer from "ol/layer/Tile"
import PropTypes from "prop-types"
import { useEffect } from "react"

import { geeLegendTypes } from "lib/CustomPropTypes"

import { useMapContext } from "../MapContextProvider"

export default function TileLayer({
  className = "ol-tile-layer",
  geeLegend,
  opacity = 0.7,
  properties,
  source,
  zIndex = 0,
  preload = Infinity,
}) {
  const { map } = useMapContext()

  useEffect(() => {
    if (!map) return
    let tileLayer = new OLTileLayer({
      className,
      geeLegend,
      opacity,
      properties,
      source,
      zIndex,
      preload,
    })
    map.addLayer(tileLayer)

    return () => {
      if (map) {
        map.removeLayer(tileLayer)
      }
    }
  }, [className, geeLegend, map, opacity, properties, source, zIndex, preload])
  return null
}

TileLayer.propTypes = {
  source: PropTypes.object.isRequired,
  zIndex: PropTypes.number,
  opacity: PropTypes.number,
  className: PropTypes.string,
  geeLegend: geeLegendTypes,
}
