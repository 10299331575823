export const coolwarm = [
  "#3B4CC0",
  "#506BDA",
  "#6788EE",
  "#81A4FB",
  "#9ABBFF",
  "#B2CCFB",
  "#C9D7F0",
  "#DDDCDC",
  "#EDD1C2",
  "#F6BFA6",
  "#F7A889",
  "#F08B6E",
  "#E26952",
  "#CD423B",
  "#B40426",
]
