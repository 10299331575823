import * as yup from "yup"
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spacer,
  VStack,
} from "@chakra-ui/react"
import { FieldArray, Form, Formik, FormikHelpers, FormikValues, useField } from "formik"
import { InputControl } from "formik-chakra-ui"
import { VscAdd, VscTrash } from "react-icons/vsc"

import ColorPicker from "./ColorPicker"
import { ContinuousLegendType, PaletteItem } from "./types"

export default function ContinuousLegendForm(props: ContinuousLegendFormProps) {
  const { legend, setLegend } = props

  const initialValues = {
    type: "continuous",
    title: legend?.title || "",
    palette: legend?.palette || [
      { color: "#ff0000", value: 1 },
      { color: "#000", value: 0 },
    ],
  }

  const validationSchema = yup.object().shape({
    title: yup.string().required("Title field is required"),
  })

  function handleSubmit(values: FormikValues, formik: FormikHelpers<any>) {
    values.palette = values.palette.map((item: PaletteItem) => {
      return { ...item, value: Number(item.value) }
    })
    values.palette.sort((a: PaletteItem, b: PaletteItem) => b.value - a.value)
    setLegend(values as ContinuousLegendType)
    formik.setSubmitting(false)
  }

  return (
    <Flex w="full" p={6} flexDirection="column">
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} validateOnBlur>
        {({ handleSubmit }) => (
          <Form id="entryForm" onSubmit={handleSubmit}>
            <Heading size="md" mb={4}>
              Continuous legend
            </Heading>

            <InputControl name="title" label="Title" inputProps={{ size: "sm", placeholder: "Legend title…" }} />

            <Flex my={4} spacing={2} fontSize="sm">
              <GradientControl name="palette" />
              <ContinuousLegendPaletteControl name="palette" />
            </Flex>
          </Form>
        )}
      </Formik>
    </Flex>
  )
}

function ContinuousLegendPaletteControl(props: { name: string }) {
  const { name } = props
  const [field] = useField(name)

  return (
    <FieldArray
      name={name}
      render={({ remove, push }) => (
        <VStack spacing={0}>
          <VStack spacing={2} mt={3} ml={5}>
            {field.value.map((item: any, index: number) => (
              <LegendNumberInputControl
                name={`${name}.${index}`}
                index={index}
                key={index}
                onRemove={remove}
                label={index === 0 ? "Max" : index === field.value.length - 1 ? "Min" : ""}
              />
            ))}
          </VStack>
          <NewLegendItemButton onPush={push} />
        </VStack>
      )}
    />
  )
}

function GradientControl(props: { name: string }) {
  const { name } = props
  const [{ value }] = useField(name)

  const bgGradient = `linear(to-b, ${value.map((item: PaletteItem) => item.color).join(", ")})`

  return (
    <Flex w="32px" flexDirection="column" rounded="md" bgGradient={bgGradient} borderWidth={1} borderColor="gray.200">
      <Spacer />
    </Flex>
  )
}

function LegendNumberInputControl(props: {
  name: string
  index: number
  label?: string
  onRemove: (index: number) => void
}) {
  const { name, index, label, onRemove } = props
  const [{ value: paletteItem }, , { setValue: setPaletteItem }] = useField(name)

  function handleSetColor(color: string) {
    setPaletteItem({ ...paletteItem, color })
  }

  function handleRemove() {
    onRemove(index)
  }

  return (
    <HStack width="full">
      <Box width={7}>{label}</Box>
      <ColorPicker color={paletteItem.color} setColor={handleSetColor} />
      <NumberInput
        value={paletteItem.value}
        size="sm"
        maxW="100px"
        ml={4}
        onChange={(value) => setPaletteItem({ ...paletteItem, value })}
      >
        <NumberInputField rounded="md" pattern="(-)?[0-9]*(.[0-9]+)?" />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <IconButton
        size="sm"
        variant="solid"
        colorScheme="red"
        aria-label="Remove item"
        icon={<VscTrash />}
        onClick={handleRemove}
      >
        -
      </IconButton>
    </HStack>
  )
}

function NewLegendItemButton(props: NewLegendItemButtonProps) {
  const { onPush } = props
  const defaultEntry = {
    value: 0,
    color: "#000",
  }

  const handleClick = () => onPush(defaultEntry)

  return (
    <Flex w="full" justifyContent="flex-end">
      <Button variant="secondary" mt={4} size="xs" leftIcon={<VscAdd />} onClick={handleClick}>
        Add entry
      </Button>
    </Flex>
  )
}

type ContinuousLegendFormProps = {
  legend: ContinuousLegendType | null
  setLegend: (legend: ContinuousLegendType) => void
}

type NewLegendItemButtonProps = {
  onPush: (item: any) => void
}
