export const Reds = [
  "#FFF5F0",
  "#FEE9DF",
  "#FEDBCC",
  "#FDC5AE",
  "#FCAF93",
  "#FC9879",
  "#FC8161",
  "#FB694A",
  "#F44F39",
  "#EA362A",
  "#D52221",
  "#C1161B",
  "#AA1016",
  "#8A0812",
  "#67000D",
]
