import buildUrl from "api/utils/buildUrl"

import useAuthenticatedApi from "./authenticatedApi"

const useUserApi = () => {
  const api = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1/user") })

  return {
    useBonusCode: (bonus_code) => api.get(`bonus_code/${bonus_code}`).json(),
  }
}

export default useUserApi
