import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import useOrbsApi from "services/orbs"

export default function useOrbConfiguration() {
  const { id } = useParams()
  const { show } = useOrbsApi()

  const { isLoading, isError, data: orb } = useQuery(["showOrb", id], () => show(id))

  return {
    isLoading,
    isError,
    orb,
  }
}
