import useAdminDeleteCustomProcess from "api/useAdminDeleteCustomProcesses"

import { useNotifications } from "components/common/notifications"
import { SafeConfirmModal } from "components/common/safe-confirm-modal"

export const CustomProcessDeleteModal = (props: CustomProcessDeleteModalProps) => {
  const { showErrorNotification } = useNotifications()
  const { isLoading, deleteCustomProcess } = useAdminDeleteCustomProcess()
  const { customProcess, isOpen, onClose, onOpen } = props

  function handleDelete(process_id: string) {
    deleteCustomProcess(process_id).catch((error: any) =>
      showErrorNotification(error, "Failed to delete custom process schema")
    )
  }

  return (
    <SafeConfirmModal
      header={`Are you sure you want to delete custom process "${customProcess.process_id}"?`}
      additionalInfo=""
      textConfirmation=""
      modalDisclosure={{ isOpen, onOpen, onClose }}
      disableButton={isLoading}
      onConfirm={() => handleDelete(customProcess.process_id)}
      requireConfirmation={false}
      actionLabel="Delete"
    />
  )
}

type CustomProcessDeleteModalProps = {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  customProcess: any
}
