import { Flex, Image, Skeleton, useBoolean } from "@chakra-ui/react"
import { useEffect, useMemo, useState } from "react"

import { getLinkHref } from "components/openeo-editor/lib/nodeUtils"
import { BaseCollectionType } from "components/openeo-editor/types"

const orbifyIconUrl = "https://s3.eu-west-1.amazonaws.com/assets.orbify.app/login-branding/orbify-icon.png"

export default function CollectionImage(props: CollectionImageProps) {
  const { collection, boxSize = "60px" } = props
  const [isLoaded, setIsLoaded] = useBoolean(false)
  const [imageSrc, setImageSrc] = useState(orbifyIconUrl)

  const previewImageUrl = useMemo(() => getPreviewImageUrl(collection), [collection])
  useEffect(() => setImageSrc(previewImageUrl), [previewImageUrl])

  function handleImageLoadError() {
    setIsLoaded.on()
    setImageSrc(orbifyIconUrl)
  }

  return (
    <Skeleton isLoaded={isLoaded}>
      <Flex flexGrow={0} flexShrink={0} boxSize={boxSize}>
        <Image
          rounded="md"
          boxSize={boxSize}
          src={imageSrc}
          loading="lazy"
          objectFit="cover"
          draggable={false}
          onLoad={setIsLoaded.on}
          onError={handleImageLoadError}
        />
      </Flex>
    </Skeleton>
  )
}

function getPreviewImageUrl(collection?: BaseCollectionType) {
  if (!collection) {
    return orbifyIconUrl
  }

  const previewImageHref = getLinkHref(collection.links, "preview")
  const rootCatalogImageHref = getPreviewImageUrlForCatalogRoot(collection)

  return previewImageHref || rootCatalogImageHref || orbifyIconUrl
}

function getPreviewImageUrlForCatalogRoot(collection: BaseCollectionType) {
  const rootHref = getLinkHref(collection.links, "root")

  switch (rootHref) {
    case process.env.REACT_APP_STAC_GEE_COMMUNITY_CATALOG_ROOT_URL:
      return "https://user-images.githubusercontent.com/6677629/193518988-956b9571-169d-4a79-89a8-d347d46c1af7.png"
    default:
      return null
  }
}

type CollectionImageProps = {
  collection?: BaseCollectionType
  boxSize: string | number
}
