import * as Yup from "yup"
import { useDisclosure } from "@chakra-ui/react"
import useCreateWorkflow from "api/useCreateWorkflow"
import PropTypes from "prop-types"
import TagManager from "react-gtm-module"
import { useMutation, useQueryClient } from "react-query"
import { useLocation, useParams } from "react-router-dom"

import useOrbsApi from "services/orbs"

import { useNotifications } from "../common/notifications"
import { initialFormValuesTypes } from "./OrbBasicSettingsForm"

const useOrbBasicSettingsFormController = (initialFormValues, readOnlyOnStart) => {
  const { id } = useParams()
  const { isUrlAvailable } = useOrbsApi()
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: !readOnlyOnStart })
  const deletionModalDisclosure = useDisclosure()
  const location = useLocation()

  const inputProps = isOpen
    ? { size: "sm", rounded: "md", variant: "outline" }
    : {
        size: "sm",
        rounded: "md",
        variant: "filled",
        isReadOnly: true,
        isDisabled: true,
      }

  const prefillUrl = (name, setFieldValue) => {
    setFieldValue(
      "url",
      name
        .replaceAll(" ", "-")
        .replace(/[^a-zA-Z0-9-_]/g, "")
        .toLowerCase()
    )
  }

  const { showErrorNotification } = useNotifications()

  const { update, create } = useOrbsApi()
  const queryClient = useQueryClient()
  const { mutateAsync: updateOrbAsync } = useMutation(update, {
    onSuccess: () => {
      queryClient.invalidateQueries("listOrbs")
      queryClient.invalidateQueries(["showOrb", id])
    },
  })

  const { mutateAsync: createOrbAsync } = useMutation(create, {
    onSuccess: () => queryClient.invalidateQueries("listOrbs"),
  })

  const { createWorkflow } = useCreateWorkflow()

  const onCreateOrb = (values, { setSubmitting, setStatus, setErrors }) => {
    createOrbAsync(values)
      .then((response) => {
        const redirectUrl = `https://${response.url}.${process.env.REACT_APP_BASE_DOMAIN}/configuration`

        createWorkflow({ orb_id: response.id, workflow_template_id: "openeo-gee-deforestation" })
          .then(() => {
            window.location.replace(redirectUrl)
          })
          .catch((error) => {
            showErrorNotification && showErrorNotification(error, "Failed to build workflow")
          })

        queryClient.invalidateQueries("onBoardingTasks")
      })
      .catch(async (error) => {
        let message = error.message

        try {
          const response = await error.response.json()
          message = response.detail
        } catch {}

        showErrorNotification(error, "Failed creating the application", message)
        setStatus({ success: false })
        setErrors({ submit: error.message })
      })
  }

  const onUpdateOrb = (values, { setSubmitting, setStatus, setErrors, resetForm }) => {
    updateOrbAsync({ orbId: id, values, invite_only: initialFormValues.invite_only })
      .then(() => {
        setSubmitting(false)
        setStatus({ success: true })
        resetForm()
        handleOrbUpdateAnalytics(values.name, values.url, "succeeded")
      })
      .catch((error) => {
        showErrorNotification(error, "Failed updating the information")
        setStatus({ success: false })
        setErrors({ submit: error.message })
        handleOrbUpdateAnalytics(values.name, values.url, "failed")
      })
      .finally(onClose)
  }

  const handleOrbUpdateAnalytics = (newName, newUrl, status) => {
    const availableStatuses = ["succeeded", "failed"]
    if (!availableStatuses.includes(status)) return null

    const event = "update_orb"
    const tagManagerArgs = {
      dataLayer: {
        event,
        [event]: {
          orb_name: initialFormValues.url,
          new_orb_name: newName,
          new_orb_url: newUrl,
          orb_id: id,
          status,
        },
      },
    }
    TagManager.dataLayer(tagManagerArgs)
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Application's name is required"),
    description: Yup.string(),
    url: Yup.string()
      .matches(/^[\w-_]{3,63}$/, "URL must between 3 - 63 characters long and include only allowed values (a-z, _, -)")
      .test(
        "urlAvailable",
        "This URL is already taken",
        (url) =>
          url === initialFormValues.url ||
          isUrlAvailable(url)
            .then((response) => response.available)
            .catch(() => false)
      )
      .required("Application's URL is required"),
    invite_only: Yup.boolean(),
  })

  return {
    deletionModalDisclosure,
    inputProps,
    isOpen,
    onClose,
    onCreateOrb,
    onOpen,
    onSubmitOrb: location.pathname === "/orbs/create" ? onCreateOrb : onUpdateOrb,
    prefillUrl,
    validationSchema,
  }
}

useOrbBasicSettingsFormController.propTypes = {
  initialFormValues: initialFormValuesTypes,
  readOnlyOnStart: PropTypes.bool,
}

export default useOrbBasicSettingsFormController
