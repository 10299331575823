import { Feature, Properties } from "@turf/turf"
import { FeatureWithRequiredId, SupportedGeometry } from "api/models/AreaOfInterest"
import { Feature as OLFeature } from "ol"
import { Geometry } from "ol/geom"
import { ChangeEvent } from "react"

import { useNotifications } from "components/common/notifications"
import { convertPolygonFeatureToGeoJson, mergeFeatures } from "components/common/open-layers/utils"

import { AOI_HIDDEN_IMPORT_INPUT_ID } from "./GenericAOIHiddenInput"
import fileToGeoJson from "./fileToGeoJson"
import { OnDone, OnError } from "./types"

export default function useGenericAOIHiddenInputController(onDone: OnDone) {
  const { showErrorNotification } = useNotifications()
  const { handleAddAreaOfInterest } = useAddImportedAOIToMyAreas(onDone, showErrorNotification)

  function fileUploadHandler(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault()

    //Import multiple files not allowed
    const file = event.target?.files && event.target?.files[0]

    if (!file) {
      showErrorNotification(new Error(), "Failed importing file", "There was not detected any file imported")
      return
    }

    const megabyte = 1024 * 1024

    if (file.size > 1 * megabyte) {
      showErrorNotification(new Error(), "Failed importing file", "File size is restricted to 1MB")
      return
    }

    fileToGeoJson(file, convertFeaturesFromGeoJSONToCreateAOI, showErrorNotification)

    removeCacheInputValue()

    async function convertFeaturesFromGeoJSONToCreateAOI(features: OLFeature<Geometry>[]) {
      if (!features?.length) {
        showErrorNotification(new Error(), "Failed importing file", "No polygons detected")
        return
      }
      if (!file?.name) {
        showErrorNotification(new Error(), "Failed importing file", "No file name detected")
        return
      }

      try {
        const convertedFeatures = convertGeometriesFeaturesToGeoJSONs(features, showErrorNotification)
        const mergedFeatures = mergeFeatures(convertedFeatures)

        await handleAddAreaOfInterest(mergedFeatures, file.name)
      } catch (error: any) {
        showErrorNotification(error, "Failed importing file")
        return
      }
    }
  }

  return {
    fileUploadHandler,
  }
}

function useAddImportedAOIToMyAreas(onDone: OnDone, onError: OnError) {
  async function handleAddAreaOfInterest(importedAOI: Feature<SupportedGeometry, Properties>, fileName: string) {
    if (importedAOI.geometry.type !== "Polygon" && importedAOI.geometry.type !== "MultiPolygon") {
      onError(null, "Failed to create area of interest", "Geometry types other than Polygon are currently unsupported")
      return null
    }

    const name = fileName.substring(0, fileName.indexOf(".") - 1)

    const feature: FeatureWithRequiredId = {
      id: Date.now().toString(),
      type: "Feature",
      geometry: importedAOI.geometry as SupportedGeometry,
      properties: {
        description: name,
      },
    }

    try {
      onDone(feature)
    } catch (error) {
      onError(error, "Failed to create area of interest")
    }
  }

  return { handleAddAreaOfInterest }
}

function removeCacheInputValue() {
  const element = document.getElementById(AOI_HIDDEN_IMPORT_INPUT_ID) as HTMLInputElement
  if (element) element.value = ""
}

function filterSupportedGeometries(feature: OLFeature<Geometry>, onError: OnError) {
  const geometryType = feature.getGeometry()?.getType()
  const isSupportedGeometry = geometryType === "Polygon" || geometryType === "MultiPolygon"
  if (!isSupportedGeometry)
    onError("null", "Failed importing file", "Import AOI works with polygon and multipolygon only")
  return isSupportedGeometry
}

function convertGeometriesFeaturesToGeoJSONs(features: OLFeature<Geometry>[], onError: OnError) {
  return features
    .filter((feature) => filterSupportedGeometries(feature, onError))
    .map((feature) => convertPolygonFeatureToGeoJson(feature))
}
