import {
  ArrayBandNamesType,
  ArrayDateRangeType,
  ArrayDefaultType,
  ArrayNumberType,
  ArrayPaletteType,
  BooleanType,
  MetadataFilterType,
  NullType,
  NumberType,
  StringDateType,
  StringMonthType,
  StringType,
} from "components/openeo-editor/parameters/controls/types"
import ProcessGraphType from "components/openeo-editor/parameters/controls/types/ProcessGraphType"

const parameterTypeMapping = {
  array: {
    default: ArrayDefaultType,
    "band-names": ArrayBandNamesType,
    palette: ArrayPaletteType,
    "date-range": ArrayDateRangeType,
    number: ArrayNumberType,
  },
  boolean: BooleanType,
  integer: NumberType,
  number: NumberType,
  null: NullType,
  string: {
    default: StringType,
    date: StringDateType,
    "date-month": StringMonthType,
  },
  object: {
    "process-graph": ProcessGraphType,
    "metadata-filter": MetadataFilterType,
  },
}

export function getParameterTypeMapping(type, subtype, items) {
  if (type === "array") {
    const itemTypes = (Array.isArray(items) ? items : [items])
      .filter((item) => item)
      .map((item) => [item.type, item.subtype])

    const itemTypeMapping = itemTypes.map(([itemType]) => parameterTypeMapping["array"][itemType])
    if (itemTypeMapping.some((matchedType) => matchedType)) {
      return itemTypeMapping[0]
    }

    if (itemTypes.some(([itemType, itemSubtype]) => !getParameterTypeMapping(itemType, itemSubtype, []))) {
      return null
    }
  }

  let mapping = parameterTypeMapping[type]
  if (mapping && typeof mapping !== "function") {
    if (subtype in mapping) mapping = mapping[subtype]
    else mapping = mapping.default
  }

  return mapping
}

export function isSchemaMappedWithParameterControls(schema) {
  if (!schema) {
    return false
  }

  const types = Array.isArray(schema)
    ? schema.map(({ type, subtype, items }) => [type, subtype, items])
    : [schema.type, schema.subtype, schema.items]

  return types.every(([type, subtype, items]) => !!getParameterTypeMapping(type, subtype, items))
}

export function isSchemaUnsupported(schema) {
  if (!schema) {
    return false
  }

  const types = Array.isArray(schema)
    ? schema.map(({ type, subtype, items }) => [type, subtype, items])
    : [schema.type, schema.subtype, schema.items]

  return !types.some(([type, subtype, items]) => !!getParameterTypeMapping(type, subtype, items))
}
