export const spring = [
  "#FF00FF",
  "#FF12ED",
  "#FF24DB",
  "#FF37C8",
  "#FF49B6",
  "#FF5BA4",
  "#FF6D92",
  "#FF807F",
  "#FF926D",
  "#FFA45B",
  "#FFB649",
  "#FFC837",
  "#FFDB24",
  "#FFED12",
  "#FFFF00",
]
