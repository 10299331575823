import { useAuth0 } from "@auth0/auth0-react"
import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  FlexProps,
  IconButton,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react"
import { AiOutlineMenu } from "react-icons/ai"
import { IoHomeOutline } from "react-icons/io5"
import { NavLink, Route, Switch } from "react-router-dom"
import theme from "theme"

import MyAccountSidebar from "components/account/MyAccountSidebar"
import AdminSidebar from "components/admin/AdminSidebar"
import FeedbackButton from "components/feedback/FeedbackButton"
import GuidedTourButton from "components/guided-tour/GuidedTourButton"
import Logo from "components/logo/Logo"
import OrbSidebar from "components/orb/OrbSidebar"
import UserMenu from "components/user-menu/UserMenu"
import useIsAdminRoute from "hooks/useIsAdminRoute"

import KnowledgeBaseLink from "./sidebar/KnowledgeBaseLink"
import { SidebarCommunityLink } from "./sidebar/SidebarCommunityLink"
import { useSidebarContext } from "./sidebar/SidebarContextProvider"
import SidebarItem from "./sidebar/SidebarItem"
import SidebarToggleButton from "./sidebar/SidebarToggleButton"

export default function LayoutSidebar() {
  const isMobileDevice = useBreakpointValue({ base: true, md: false })
  const Sidebar = isMobileDevice ? MobileSidebar : DesktopSidebar

  return <Sidebar />
}

function DesktopSidebar(props: FlexProps) {
  const { user } = useAuth0()
  const { isOpen } = useSidebarContext()
  const isAdmin = useIsAdminRoute()
  const logoColor = isAdmin ? theme.colors.red["500"] : theme.colors.primary["900"]

  return (
    <Flex
      data-id="sidebar"
      position="sticky"
      top={0}
      h="100vh"
      w={isOpen ? ["15em"] : 12}
      flexDirection="column"
      justifyContent="space-between"
      bgColor={isAdmin ? "red.100" : "unset"}
      borderColor={isAdmin ? "red.200" : "gray.200"}
      borderRightWidth={1}
      transition="width .25s ease"
      {...props}
    >
      <Flex
        data-id="sidebar__header"
        alignItems="center"
        flexShrink={0}
        flexDirection={isOpen ? "row" : "column"}
        justifyContent="space-between"
        mt={2}
        mb={4}
        px={4}
        pb={2}
        borderBottomWidth={1}
      >
        <Box as={NavLink} ml={-1} exact to="/" mb={isOpen ? 0 : 6}>
          <Logo dotColor={logoColor} textColor={logoColor} variant={isOpen ? "large" : "small"} />
        </Box>

        {user && (
          <Stack direction={isOpen ? "row" : "column-reverse"}>
            <UserMenu />
          </Stack>
        )}
      </Flex>

      <Flex
        as="nav"
        data-id="sidebar__content"
        direction="column"
        alignItems="start"
        justifyContent="space-between"
        h="full"
        px={2}
        pt={3}
        fontSize="sm"
      >
        <Box data-id="sidebar__content--top" w="full">
          <SidebarItem exact to="/orbs" icon={IoHomeOutline} tooltip="Home page" w="full">
            Home
          </SidebarItem>
          <Switch>
            <Route path="/account" children={<MyAccountSidebar />} />
            <Route path="/orbs/view/:id" children={<OrbSidebar />} />
            <Route path="/admin" children={<AdminSidebar />} />
          </Switch>
        </Box>

        <Box data-id="sidebar__content--bottom" w="full">
          {user && <FeedbackButton />}
          <GuidedTourButton />
          <KnowledgeBaseLink />
          <SidebarCommunityLink />
          <SidebarToggleButton />
        </Box>
      </Flex>
    </Flex>
  )
}

function MobileSidebar() {
  const { isOpen, onToggle, onClose } = useSidebarContext()

  return (
    <>
      <IconButton
        data-id="sidebar"
        aria-label="open sidebar"
        onClick={onToggle}
        size="xs"
        icon={<AiOutlineMenu />}
        m={2}
        position="fixed"
        zIndex={99}
      />
      <Drawer isOpen={isOpen} onClose={onClose} placement="left">
        <DrawerOverlay />
        <DrawerContent maxW={{ sm: "15em" }}>
          <DesktopSidebar w="full" borderRightWidth={0} />
        </DrawerContent>
      </Drawer>
    </>
  )
}
