export const rainbow = [
  "#8000FF",
  "#5C38FD",
  "#386DF9",
  "#11A0F1",
  "#12C8E6",
  "#37E6D8",
  "#5AF8C8",
  "#80FFB4",
  "#A4F89F",
  "#C8E688",
  "#ECC86F",
  "#FFA055",
  "#FF6D38",
  "#FF381C",
  "#FF0000",
]
