import { useCallback } from "react"

import { processGraphToFlow } from "components/openeo-editor/lib/process-graph-builder"
import useCollections from "components/openeo-editor/lib/useCollections"
import useProcesses from "components/openeo-editor/lib/useProcesses"

function useFlowBuilder() {
  const { isLoading: isCollectionsLoading, getCollectionById } = useCollections()
  const { isLoading: isProcessesLoading, getProcessById } = useProcesses()

  const buildElements = useCallback(
    (graph, currentError = undefined) => {
      return processGraphToFlow(graph, getCollectionById, getProcessById).reduce((result, element) => {
        if (currentError?.node_id && currentError.node_id === element.data?.id) {
          element.data.error = currentError
        }
        return [...result, element]
      }, [])
    },
    [getCollectionById, getProcessById]
  )

  return {
    isLoading: isCollectionsLoading || isProcessesLoading,
    buildElements,
  }
}

export default useFlowBuilder
