export const cubehelix = [
  "#000000",
  "#160D21",
  "#1A2441",
  "#15444F",
  "#1B6145",
  "#387434",
  "#697B30",
  "#A1794A",
  "#C87B7C",
  "#D588B5",
  "#CDA2E0",
  "#C2C3F2",
  "#C6E1F1",
  "#DEF4EF",
  "#FFFFFF",
]
