import { Flex } from "@chakra-ui/react"
import { usePageTitle } from "hooks"
import mixpanel from "mixpanel-browser"
import { ReactFlowProvider } from "react-flow-renderer"
import SplitPane from "react-split-pane"

import DrawContextProvider from "components/common/open-layers/DrawContextProvider"
import MapContextProvider from "components/common/open-layers/MapContextProvider"
import CodeEditorContextProvider from "components/openeo-editor/code-editor/CodeEditorContext"
import { OpeneoWorkflowContextProvider } from "components/orb-openeo-workflow/OpeneoWorkflowContext"
import useKeyControls from "components/orb-openeo-workflow/hooks/useKeyControls"
import OpeneoWorkflowConfigurator from "components/orb-openeo-workflow/openeo-workflow-configurator/OpeneoWorkflowConfigurator"
import { OpeneoEventsContextProvider } from "components/orb-openeo-workflow/openeo-workflow-console/OpeneoEventsContext"
import OpeneoWorkflowPreviewMapOL from "components/orb-openeo-workflow/openeo-workflow-preview-map-ol/OpeneoWorkflowPreviewMap"
import ToolboxContextProvider from "components/orb-workflow/toolbox/ToolboxContextProvider"
import { useEffectOnce } from "hooks/useEffectOnce"

import "./OpeneoWorkflow.scss"

const pageTitle = "Application Dashboard - No-code Workflow Editor"

function OpeneoWorkflow() {
  usePageTitle(pageTitle)
  useEffectOnce(() => {
    mixpanel.track(`${pageTitle} view`, { page_title: pageTitle })
  })

  return (
    <ReactFlowProvider>
      <MapContextProvider>
        <OpeneoEventsContextProvider>
          <CodeEditorContextProvider>
            <OpeneoWorkflowContextProvider>
              <DrawContextProvider>
                <ToolboxContextProvider>
                  <Flex w="full" h="full" position="relative">
                    <OpeneoWorkflowPanel />
                  </Flex>
                </ToolboxContextProvider>
              </DrawContextProvider>
            </OpeneoWorkflowContextProvider>
          </CodeEditorContextProvider>
        </OpeneoEventsContextProvider>
      </MapContextProvider>
    </ReactFlowProvider>
  )
}

function OpeneoWorkflowPanel() {
  useKeyControls()

  return (
    <SplitPane split="horizontal" minSize={50} maxSize={-150} defaultSize="60%" step={15}>
      <Flex w="full" h="full">
        <OpeneoWorkflowPreviewMapOL />
      </Flex>
      <Flex w="full" h="full" flexDirection="column">
        <OpeneoWorkflowConfigurator />
      </Flex>
    </SplitPane>
  )
}

export default OpeneoWorkflow
