import { Button } from "@chakra-ui/react"
import PropTypes from "prop-types"

export default function AddFilterButton(props) {
  const { onClick } = props

  const handleClick = () => onClick("")

  return (
    <Button variant="secondary" size="xs" me={2} onClick={handleClick}>
      Add filter
    </Button>
  )
}

AddFilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}
