import { useQuery } from "react-query"

import useChartsQueries from "./queryFunctions/useChartsQueries"

export default function useGetChartFeatures(url: string) {
  const { getChartFeaturesQuery } = useChartsQueries()
  const { queryKey, queryFn } = getChartFeaturesQuery(url)

  const { isLoading, isError, data } = useQuery(queryKey, queryFn)

  const features = data || ""

  return {
    isLoading,
    isError,
    features,
  }
}
