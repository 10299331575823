import { Icon } from "@chakra-ui/icons"
import { Flex, Heading } from "@chakra-ui/react"

const Title = ({ children, colorScheme = "brand", icon, fontSize = "md", ...rest }) => (
  <Flex w="full">
    {icon && <Icon as={icon} boxSize={6} me={4} color={`${colorScheme}.600`} />}
    <Flex
      w="full"
      borderBottom="1px"
      borderColor={`${colorScheme}.600`}
      pb={1}
      mb={3}
      flexDirection="row"
      justifyContent="space-between"
      {...rest}
    >
      <Heading fontSize={fontSize} fontWeight="semibold" color={`${colorScheme}.600`}>
        {children}
      </Heading>
    </Flex>
  </Flex>
)

export default Title
