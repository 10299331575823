export const Spectral = [
  "#9E0142",
  "#C52C4B",
  "#E2514A",
  "#F57748",
  "#FCA55D",
  "#FECA79",
  "#FEE999",
  "#FFFFBE",
  "#EDF8A3",
  "#CDEB9D",
  "#A2D9A4",
  "#71C6A5",
  "#47A0B3",
  "#3F77B5",
  "#5E4FA2",
]
