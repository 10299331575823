import { ChatIcon } from "@chakra-ui/icons"
import {
  Badge,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tooltip,
} from "@chakra-ui/react"
import useGetCurrentPricingPlan from "api/useGetCurrentPricingPlan"
import { Children, ReactElement, ReactNode, cloneElement, isValidElement } from "react"

import { useOpenHubspotChat } from "services/useOpenHubspotChat"

export default function PremiumButtonIndicator(props: PremiumButtonIndicatorProps) {
  const { children, isPremium = false, isOpen = false, info, wrapperOptions, badgeSize } = props
  const { assignedPricingPlan, subscription } = useGetCurrentPricingPlan()
  const isUserSubscribed = !!assignedPricingPlan && subscription?.status !== "TRIAL"
  const isUserOnPremiumPlan = assignedPricingPlan?.name !== "FREE"
  const childrenArray = Children.toArray(children)

  const { openChat } = useOpenHubspotChat()

  if (childrenArray.length !== 1) {
    throw new Error("PremiumButtonIndicator accepts exactly one child component")
  }

  if (!isPremium) {
    return children
  }

  const button = Children.map(children, (child) => {
    if (isValidElement(child)) {
      // @ts-ignore
      return cloneElement(child, { isDisabled: true })
    } else {
      return child
    }
  })

  return (
    <Popover trigger="hover" defaultIsOpen={isOpen} closeOnBlur={!isOpen}>
      <PopoverTrigger>
        <Flex position="relative" {...wrapperOptions}>
          {button}
          <Badge
            colorScheme={isUserOnPremiumPlan ? "red" : "blue"}
            rounded="sm"
            fontSize={badgeSize || "sm"}
            position="absolute"
            top={-1}
            right={-1}
          >
            {isUserOnPremiumPlan ? "Limited" : "Premium"}
          </Badge>
        </Flex>
      </PopoverTrigger>

      <PopoverContent p={3} shadow="md">
        <PopoverArrow />
        <PopoverHeader fontSize="sm">{info}</PopoverHeader>
        <PopoverBody>
          <ButtonGroup w="full">
            <Button
              style={{ textDecoration: "none" }}
              variant="solid"
              colorScheme="primary"
              size="md"
              w="full"
              as={Link}
              href={isUserSubscribed ? process.env.REACT_APP_CONTACT_SALES_MAIL : "/account/billing"}
            >
              {isUserSubscribed ? "Contact us" : "Check available pricing plans"}
            </Button>
            <Tooltip label="Chat with us">
              <IconButton
                onClick={openChat}
                icon={<ChatIcon />}
                aria-label="Chat with us"
                bgColor="#2943D1"
                color="white"
                ms={2}
                _hover={{ bgColor: "#294399" }}
              />
            </Tooltip>
          </ButtonGroup>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

type PremiumButtonIndicatorProps = {
  children: ReactElement
  isPremium?: boolean
  isOpen?: boolean
  info: ReactNode
  wrapperOptions?: object
  badgeSize?: string
}
