import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from "@chakra-ui/react"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import { useMemo } from "react"
import Calendar from "react-calendar"

import { childrenType } from "lib/CustomPropTypes"
import { dateStringToDate } from "lib/utils"

const MonthInput = ({ children, value, setValue }) => {
  const { onOpen, onClose, isOpen } = useDisclosure()
  const formatDate = (date) => {
    try {
      return dayjs(date).format("YYYY-MM")
    } catch (e) {
      return dayjs().format("YYYY-MM")
    }
  }

  const handleMonthClick = (month) => {
    setValue(formatDate(month))
    onClose()
  }

  const selectedDate = useMemo(() => dateStringToDate(value, "YYYY-MM"), [value])

  return (
    <Popover placement="bottom-start" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <Calendar
              maxDetail="year"
              activeStartDate={selectedDate}
              onChange={handleMonthClick}
              value={selectedDate}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

MonthInput.propTypes = {
  children: childrenType,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
}

export default MonthInput
