import { useQuery } from "react-query"

import useOrbBillingQueries from "./queryFunctions/useOrbBillingQueries"

export default function useGetOrbPricingPlans() {
  const { listPricingPlansQuery } = useOrbBillingQueries()
  const { queryKey, queryFn } = listPricingPlansQuery()

  const { isLoading, isError, data: pricingPlans } = useQuery(queryKey, queryFn)

  return { isLoading, isError, pricingPlans }
}
