import { Flex, Select } from "@chakra-ui/react"
import { useField } from "formik"
import { useMemo, useState } from "react"

import ParameterTypeControl from "components/openeo-editor/parameters/controls/ParameterTypeControl"
import {
  getParameterTypeMapping,
  isSchemaUnsupported,
} from "components/openeo-editor/parameters/controls/parameterTypeMapping"

function ParameterControl(props) {
  const { parameter } = props
  const { schema } = parameter
  const [field] = useField(parameter.name)

  const controls = useMemo(() => {
    const schemas = Array.isArray(schema) ? schema : [schema]

    return schemas.reduce((output, schema) => {
      if (getParameterTypeMapping(schema.type, schema.subtype, schema.items)) {
        output.push(schema)
      }

      return output
    }, [])
  }, [schema])

  const defaultControlIndex = useMemo(() => {
    let parameterValueType = "null"
    try {
      parameterValueType = typeof JSON.parse(field.value)
    } catch {}

    if (Array.isArray(field.value)) {
      parameterValueType = "array"
    }

    const schemaIndex = controls.findIndex(
      (control) =>
        control.type === parameterValueType && getParameterTypeMapping(control.type, control.subtype, control.items)
    )
    return schemaIndex >= 0 ? schemaIndex : 0
  }, [controls, field])

  const [selectedSchema, setSelectedSchema] = useState(controls[defaultControlIndex])

  if (isSchemaUnsupported(schema)) {
    return null
  }

  return (
    <Flex flexDirection="column" w="full">
      {controls.length > 1 && (
        <Select
          size="xs"
          mb={2}
          onChange={({ target: { value } }) => setSelectedSchema(controls[value])}
          defaultValue={defaultControlIndex}
        >
          {controls.map((control, index) => (
            <option key={index} value={index}>
              {control.title || control.subtype || control.type}
            </option>
          ))}
        </Select>
      )}
      <ParameterTypeControl parameter={parameter} selectedSchema={selectedSchema} />
    </Flex>
  )
}

export default ParameterControl
