export const gist_stern = [
  "#000000",
  "#EB1224",
  "#912448",
  "#32376E",
  "#494992",
  "#5B5BB6",
  "#6D6DDA",
  "#8080FD",
  "#9292B0",
  "#A4A464",
  "#B6B617",
  "#C8C82F",
  "#DBDB77",
  "#EDEDBB",
  "#FFFFFF",
]
