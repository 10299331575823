import { useMutation } from "react-query"

import useOrbUsersQueries from "./queryFunctions/useOrbUsersQueries"

export default function useEditOrbUser() {
  const { editUserMutation } = useOrbUsersQueries()
  const { mutationFn, mutationOptions } = editUserMutation()

  const { isLoading, mutateAsync: editUser } = useMutation(mutationFn, mutationOptions)

  return { isLoading, editUser }
}
