import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import PropTypes from "prop-types"
import remarkGfm from "remark-gfm"

import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"
import { Markdown } from "components/markdown"
import BandsTable from "components/openeo-editor/collection/metadata/BandsTable"
import PropertiesTable from "components/openeo-editor/collection/metadata/PropertiesTable"
import useCollectionDetails from "components/openeo-editor/lib/useCollectionDetails"

export default function CollectionInfo(props) {
  const { collectionId, additionalTabs = [] } = props
  const { isLoading, collection, bands, properties } = useCollectionDetails(collectionId)
  const hasProperties = properties && properties.length > 0

  if (isLoading) {
    return <LoadingSpinner />
  }
  const defaultTabs = [
    {
      name: "Info",
      body: (
        <Markdown fontSize="sm" remarkPlugins={[remarkGfm]}>
          {collection?.description}
        </Markdown>
      ),
    },
  ]
  if (bands) defaultTabs.push({ name: "Bands", body: <BandsTable bands={bands} /> })
  if (hasProperties) defaultTabs.push({ name: "Properties", body: <PropertiesTable properties={properties} /> })

  const tabs = additionalTabs.concat(defaultTabs)

  return (
    <Tabs>
      <TabList mb={4}>
        {tabs.map((tab, index) => (
          <Tab py={0} pb={1} fontSize="sm" key={index}>
            {tab.name}
          </Tab>
        ))}
      </TabList>
      <TabPanels px={0}>
        {tabs.map((tab, index) => (
          <TabPanel py={0} pb={1} fontSize="sm" key={index}>
            {tab.body}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}

const tabType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  body: PropTypes.object.isRequired,
})

CollectionInfo.propTypes = {
  collectionId: PropTypes.number.isRequired,
  additionalTabs: PropTypes.arrayOf(tabType),
}
