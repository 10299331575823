export const BrBG = [
  "#543005",
  "#7C4709",
  "#A16518",
  "#C48B3A",
  "#DBB972",
  "#ECD8A5",
  "#F6ECD1",
  "#F4F5F5",
  "#D4EDE9",
  "#A8DDD5",
  "#76C6BA",
  "#419F97",
  "#167A72",
  "#015A50",
  "#003C30",
]
