import { Route, Switch, useRouteMatch } from "react-router-dom"

import NotFound from "components/not-found/NotFound"
import Create from "components/orbs-view/Create"
import List from "components/orbs-view/List"

import View from "routes/orbs/View"

const Applications = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path} component={List} />
      <Route exact path={`${path}/create`} component={Create} />
      <Route path={`${path}/view/:id`} component={View} />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}

export default Applications
