import { Accordion, Flex } from "@chakra-ui/react"

import { useInterpolatedLegends } from "hooks/useInterpolatedLegends"

import BasicLegend from "./BasicLegend"
import ContinuousLegend from "./ContinuousLegend"
import { BasicLegendType, ContinuousLegendType, LegendType } from "./types"

export default function Legend() {
  const { interpolatedLegends: legends } = useInterpolatedLegends()
  const basicLegends = legends.filter((legend: LegendType) => legend.type === "basic") as BasicLegendType[]
  const continuousLegends = legends.filter(
    (legend: LegendType) => legend.type === "continuous"
  ) as ContinuousLegendType[]

  if (!legends.length) {
    return null
  }

  return (
    <Flex
      flexDirection="column"
      w="300px"
      rounded="md"
      bgColor="whiteAlpha.900"
      backdropFilter="blur(2px)"
      shadow="lg"
      position="absolute"
      top="60px"
      right={3}
      px={4}
      py={2}
      zIndex={99}
      className="legend"
      maxH="80%"
      overflowX="scroll"
    >
      <Accordion allowMultiple defaultIndex={[0]} borderBottomColor="whiteAlpha.900">
        {basicLegends.map((legend, index) => (
          <BasicLegend key={index} legend={legend} />
        ))}
        {continuousLegends.map((legend, index) => (
          <ContinuousLegend key={index} legend={legend} />
        ))}
      </Accordion>
    </Flex>
  )
}
