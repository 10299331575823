import mixpanel from "mixpanel-browser"
import React from "react"
import ReactDOM from "react-dom"
import TagManager from "react-gtm-module"

import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { isDev } from "./services/utils"

const gtmId = process.env.REACT_APP_GTM_ID || ""
const tagManagerArgs = {
  gtmId,
  debug_mode: isDev() ? 1 : 0,
}

/* GTM initializer */
if (!!gtmId) TagManager.initialize(tagManagerArgs)

/* Mixpanel initializer */
const mixpanelId = process.env[isDev() ? "REACT_APP_MIXPANEL_DEV" : "REACT_APP_MIXPANEL_PROD"]
mixpanelId && mixpanel.init(mixpanelId, { debug: isDev(), ignore_dnt: true })
if (isDev()) mixpanel.disable() // Disable mixpanel for Dev env.

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
