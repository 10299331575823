import { useDisclosure } from "@chakra-ui/react"
import { User } from "api/models/User"
import { ReactNode } from "react"

import UserExtentsEditorModal from "./user-extents/UserExtentsEditorModal"

export default function UserExtentsEditor(props: UserExtentsEditorProps) {
  const { user, children } = props
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {children({ onOpen })}
      {isOpen && <UserExtentsEditorModal isOpen={isOpen} onClose={onClose} user={user} />}
    </>
  )
}

type ChildrenProps = {
  onOpen: () => void
}

type UserExtentsEditorProps = {
  user: User
  children: (props: ChildrenProps) => ReactNode
}
