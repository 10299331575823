import { Box, Button, Flex, Grid, Spacer, chakra } from "@chakra-ui/react"
import { RouteChildrenProps } from "react-router-dom"

import { LoadingSpinner } from "components/common"
import Section from "components/layout/section/Section"
import SectionHeader from "components/layout/section/SectionHeader"

import WorkflowCard from "./WorkflowCard"
import useWorkflowCreatorController from "./WorkflowCreatorController"
import WorkflowHeader from "./WorkflowHeader"

export default function WorkflowCreator({ history }: RouteChildrenProps): JSX.Element {
  const {
    filterString,
    setFilterString,
    currentStepIndex,
    isLoading,
    workflowCards,
    handleCardSelection,
    moveToPreviousStep,
    handleNextButtonClick,
    isWorkflowCardSelected,
    isLastStep,
  } = useWorkflowCreatorController(history)

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Box p={4}>
      <Section
        header={
          <SectionHeader
            title="Workflow creator"
            description="Configure your application's workflow using one of the predefined templates"
          />
        }
      >
        <Flex w="full">
          <chakra.form onSubmit={handleNextButtonClick}>
            <WorkflowHeader
              currentStepIndex={currentStepIndex}
              filterString={filterString}
              setFilterString={setFilterString}
            />

            <Grid
              px={1}
              py={3}
              gap={8}
              w="full"
              maxH="60vh"
              templateColumns="repeat(4, 1fr)"
              overflowY="auto"
              flexDirection="column"
            >
              {workflowCards?.map(
                (workflowCard, index: number): JSX.Element => (
                  <WorkflowCard
                    key={index}
                    workflowCard={workflowCard}
                    isSelected={isWorkflowCardSelected(workflowCard)}
                    selectionHandler={handleCardSelection}
                  />
                )
              )}
            </Grid>

            <Flex h={20} alignItems="center" justifyContent="space-between" mt={6} px={10} mx="auto">
              {currentStepIndex > 0 && (
                <Button size="lg" onClick={moveToPreviousStep}>
                  Back
                </Button>
              )}

              <Spacer />

              <Button size="lg" colorScheme="primary" type="submit" disabled={isLoading}>
                {isLastStep ? "Finish" : "Next"}
              </Button>
            </Flex>
          </chakra.form>
        </Flex>
      </Section>
    </Box>
  )
}
