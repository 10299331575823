export const flag = [
  "#FF0000",
  "#FFA570",
  "#FFFBEE",
  "#659AFF",
  "#0000FF",
  "#000085",
  "#000008",
  "#A40000",
  "#FF1C08",
  "#FFB985",
  "#FFFFFF",
  "#9ACBFF",
  "#0000EE",
  "#000070",
  "#000000",
]
