import { SmallCloseIcon } from "@chakra-ui/icons"
import { Flex, IconButton, Text } from "@chakra-ui/react"
import { NominatimSearchResult } from "api/models/Nominatim"

import AddToMyAreasButton from "./AddToMyAreasButton"
import SearchResultTag from "./SearchResultTag"

export default function SearchResultInfoBox({ searchResult, clearSearchResult }: SearchResultInfoBoxProps) {
  if (!searchResult) {
    return null
  }

  return (
    <Flex w="md" p={3} rounded="lg" shadow="xl" alignItems="center" fontSize="sm" bgColor="whiteAlpha.900">
      <Flex w="full" flexDirection="column">
        <Flex justifyContent="space-between">
          <Text mb={1} fontWeight="semibold">
            {searchResult.display_name}
          </Text>

          <IconButton
            size="xs"
            variant="ghost"
            aria-label="Hide search result"
            icon={<SmallCloseIcon />}
            onClick={clearSearchResult}
          />
        </Flex>

        <SearchResultTag searchResult={searchResult} fontSize="2xs" />

        <AddToMyAreasButton searchResult={searchResult} clearSearchResult={clearSearchResult} />
      </Flex>
    </Flex>
  )
}

type SearchResultInfoBoxProps = {
  searchResult: NominatimSearchResult | null
  clearSearchResult: () => void
}
