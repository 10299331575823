import mixpanel from "mixpanel-browser"
import { useCallback } from "react"
import { ArrowHeadType, addEdge } from "react-flow-renderer"
import { v4 as uuidv4 } from "uuid"

import { useNotifications } from "components/common/notifications"
import useSaveCode from "components/openeo-editor/code-editor/useSaveCode"
import { defaultHandlerSnippet } from "components/openeo-editor/lib/defaultHandlerCodeSnippet"
import useProcesses from "components/openeo-editor/lib/useProcesses"
import { NodeType, Position } from "components/openeo-editor/types"
import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"

import useVisualizationBuilder from "./useVisualizationBuilder"

export default function useAddElementHandler() {
  const { elements, setElements } = useOpeneoWorkflowContext() as any
  const { handleCreateCode } = useSaveCode()
  const { showErrorNotification } = useNotifications()
  const { getNodeData } = useProcesses()
  const { buildVisualisationData } = useVisualizationBuilder()

  const onAddElement = useCallback(
    async function addElement(id: string, type: string, position: Position, data: any): Promise<NodeType | undefined> {
      mixpanel.track("Add no-code workflow element", { element_id: id })

      const uniqueId = buildUniqueIdentifier(id, elements)

      if (
        [
          "save_execute_code_standalone",
          "save_execute_long_running_code_standalone",
          "execute_code_standalone",
          "execute_ml_code_standalone",
        ].includes(data.id) &&
        !data.values.code
      ) {
        const newCodeSnippetUuid = uuidv4()

        try {
          await handleCreateCode(newCodeSnippetUuid, defaultHandlerSnippet)
          data.values.code = { type: "object", subtype: "python-code", value: newCodeSnippetUuid }
        } catch (error: any) {
          showErrorNotification(error, "Failed to create code process")
          return
        }
      }

      const node = {
        id: uniqueId,
        type,
        position,
        data: { ...data, id: uniqueId, label: uniqueId },
      }

      setElements((existingNodes: NodeType[]) => existingNodes.concat(node))

      if (id !== data.id && data.id === "load_collection") {
        // when adding load_collection node that is an actual collection (id !== data.id), create a graph of:
        // temporal input -> collection -> mosaic -> visualize -> save_result_map
        await addUserInputNode(node)
        const mosaicNode = await addMosaicNode(node)
        const visualizeNode = await addVisualizeNode(mosaicNode)
        await addResultNode(visualizeNode)
      } else if (id !== data.id && data.id === "load_image") {
        // when adding load_image node that is an actual collection (id !== data.id), create a graph of:
        // image -> visualize -> save_result_map
        const visualizeNode = await addVisualizeNode(node)
        await addResultNode(visualizeNode)
      } else if (data.id === "save_execute_long_running_code_standalone") {
        // create graph of:
        // temporal input -> save_execute_long_running_code_standalone
        await addUserInputNode(node)
      } else if (["execute_code_standalone", "execute_ml_code_standalone"].includes(data.id)) {
        // create graph of:
        // temporal input -> code_block -> save_result_map
        await addUserInputNode(node)
        await addResultNode(node)
      }

      return node

      async function addUserInputNode(fromNode: NodeType) {
        const userInputNode = await createNode("input_daterange", "process", getPosition(position, -450), {
          default_start_date: "2022-06-01",
          default_end_date: "2022-07-01",
          name: "Date range",
          description: "Provide date range",
        })
        createEdge(userInputNode, fromNode, "temporal_extent")

        return userInputNode!
      }

      async function addMosaicNode(fromNode: NodeType) {
        const mosaicNode = await createNode("mosaic", "process", getPosition(position, 450))
        createEdge(fromNode, mosaicNode)

        return mosaicNode!
      }

      async function addVisualizeNode(fromNode: NodeType) {
        const visualizationData = await buildVisualisationData(node.data.values.id, data)
        const visualizeNode = await createNode("visualize", "process", getPosition(position, 900), visualizationData)
        createEdge(fromNode, visualizeNode)

        return visualizeNode!
      }

      async function addResultNode(fromNode: NodeType) {
        // save_result_map is added only if it does not exist in the graph yet
        let resultNode = elements.find((element: any) => element.data.values.process_id.startsWith("save_"))
        if (!resultNode) {
          resultNode = await createNode("save_result_map", "process", getPosition(position, 1350))
        }
        createEdge(fromNode, resultNode)

        return resultNode!
      }

      async function createNode(id: string, type: string, position: Position, values?: { [key: string]: any }) {
        const data = getNodeData(id, type)
        return await onAddElement(id, type, position, { ...data, values: { ...data.values, ...values } })
      }

      function createEdge(source?: NodeType, target?: NodeType, targetHandle?: string) {
        if (!source || !target) {
          return
        }

        const id = `${source.id}_${target.id}`

        setElements((existingElements: any) =>
          addEdge(
            {
              id,
              source: source.id,
              target: target.id,
              targetHandle: targetHandle || "data",
              arrowHeadType: ArrowHeadType.ArrowClosed,
              type: "removableEdge",
            },
            existingElements
          )
        )
      }
    },
    [setElements, handleCreateCode, showErrorNotification, elements, getNodeData, buildVisualisationData]
  )

  function getPosition(originalPosition: Position, offsetX: number = 0, offsetY: number = 0) {
    return { x: originalPosition.x + offsetX, y: originalPosition.y + offsetY }
  }

  return { onAddElement }
}

export function buildUniqueIdentifier(id: string, elements: NodeType[]) {
  const existingIndices = elements
    .filter((element: NodeType) => element.data?.values?.id === id)
    .map((element: NodeType) => Number(element.id.replace(/.*#(\d+)/, "$1")))
    .filter(Number)

  const maxExistingIndex = !existingIndices.length ? 0 : Math.max(...existingIndices)

  return `${id} #${maxExistingIndex + 1}`
}
