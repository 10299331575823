import { Icon, Table, TableProps, Tbody, Td, Th, Thead, Tr, chakra } from "@chakra-ui/react"
import {
  ColumnDef,
  ColumnMeta,
  RowData,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { ReactNode, useState } from "react"
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa"

export interface DataTableProps<Data extends object> extends TableProps {
  data: Data[]
  columns: ColumnDef<Data, any>[]
  noDataComponent?: ReactNode
  sortingState?: SortingState
}

export function DataTable<Data extends object>(props: DataTableProps<Data>) {
  const { data, columns, noDataComponent, sortingState = [], ...tableProps } = props
  const [sorting, setSorting] = useState<SortingState>(sortingState)
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  })

  function NoData() {
    if (!noDataComponent || !!table.getRowModel().rows.length) {
      return null
    }

    const colSpan = table.getAllColumns().length
    return (
      <Tr>
        <Td colSpan={colSpan}>{noDataComponent as JSX.Element}</Td>
      </Tr>
    )
  }

  return (
    <Table {...tableProps}>
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const meta = header.column.columnDef.meta as Meta
              return (
                <Th key={header.id} onClick={header.column.getToggleSortingHandler()} isNumeric={meta?.isNumeric}>
                  {flexRender(header.column.columnDef.header, header.getContext())}

                  <chakra.span pl="4">
                    {header.column.getIsSorted() ? (
                      header.column.getIsSorted() === "desc" ? (
                        <Icon as={FaSortDown} color="primary.600" aria-label="sorted descending" />
                      ) : (
                        <Icon as={FaSortUp} color="primary.600" aria-label="sorted ascending" />
                      )
                    ) : (
                      header.column.getCanSort() && <Icon as={FaSort} color="gray.300" aria-label="sorting" />
                    )}
                  </chakra.span>
                </Th>
              )
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map((row) => (
          <Tr key={row.id}>
            {row.getVisibleCells().map((cell) => {
              const meta = cell.column.columnDef.meta as Meta
              return (
                <Td key={cell.id} isNumeric={meta?.isNumeric}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              )
            })}
          </Tr>
        ))}
        <NoData />
      </Tbody>
    </Table>
  )
}

// see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
interface Meta extends ColumnMeta<RowData, unknown> {
  isNumeric: boolean
}
