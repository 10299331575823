import { useField } from "formik"

import { parameterType } from "components/openeo-editor/lib/propTypes"
import { useEffectOnce } from "hooks/useEffectOnce"

function NullType(props) {
  const { parameter } = props
  const [, , { setValue }] = useField(parameter.name)
  useEffectOnce(() => setValue(""))

  return null
}

NullType.propTypes = {
  parameter: parameterType.isRequired,
}

export { NullType }
