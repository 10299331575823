import { Flex } from "@chakra-ui/react"
import useShowWorkflow from "api/useShowWorkflow"
import { Redirect } from "react-router"
import { Route, Switch, useRouteMatch } from "react-router-dom"

import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"
import NotFound from "components/not-found/NotFound"
import OpeneoWorkflow from "components/orb-openeo-workflow/OpeneoWorkflow"
import WorkflowCreator from "components/workflow-creator/WorkflowCreator"

export default function OrbWorkflowView() {
  const { path, url } = useRouteMatch()

  const { isLoading, workflow } = useShowWorkflow()

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Flex w="full" h="full">
      <Switch>
        <Route path={`${path}/creator`} component={WorkflowCreator} />
        <Route path={`${path}`}>{!workflow ? <Redirect to={`${url}/creator`} /> : <OpeneoWorkflow />}</Route>
        <Route path="*" component={NotFound} />
      </Switch>
    </Flex>
  )
}
