import buildUrl from "api/utils/buildUrl"

import useAuthenticatedApi from "services/api/authenticatedApi"

const useOrbCodeSnippetsApi = () => {
  const api = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1/internal/orbs/") })

  return {
    getCodeSnippet: (orbId, codeSnippetUuid) => api.get(`${orbId}/code_snippets/${codeSnippetUuid}`).json(),
    saveCodeSnippet: ({ orbId, codeSnippetUuid, codeSnippet }) =>
      api.put(`${orbId}/code_snippets/${codeSnippetUuid}`, { json: codeSnippet }).json(),
  }
}

export default useOrbCodeSnippetsApi
