import Rollbar from "rollbar"

import { isDev } from "./utils"

const enabled = !isDev() && !!process.env.REACT_APP_ROLLBAR_TOKEN

export const rollbarConfig = {
  enabled,
  addErrorContext: true,
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.REACT_APP_ENVIRONMENT,
    client: {
      javascript: {
        code_version: process.env.REACT_APP_GIT_SHA,
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
  ignoredMessages: [
    "(Error: |n: |)Connection (is|got) disposed", // websocket + griot problem
    '{"type":"cancelation","msg":"operation is manually canceled"}', // uncaught in promise: useMonaco() from @monaco-editor/loader
  ],
  verbose: false, // if true, client also logs to console.log, in addition to sending the item to Rollbar.
}

export default new Rollbar(rollbarConfig)
