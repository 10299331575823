import PropTypes from "prop-types"

export const stacLinkType = PropTypes.shape({
  title: PropTypes.string,
  rel: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  type: PropTypes.string,
})

export const stacCatalogType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  links: PropTypes.arrayOf(stacLinkType).isRequired,
  type: PropTypes.string.isRequired,
})

export const stacProviderType = PropTypes.shape({
  name: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string.isRequired),
  url: PropTypes.string,
})

export const stacCollectionType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  extent: PropTypes.object,
  keywords: PropTypes.arrayOf(PropTypes.string),
  license: PropTypes.string,
  links: PropTypes.arrayOf(stacLinkType).isRequired,
  providers: PropTypes.arrayOf(stacProviderType),
  type: PropTypes.string,

  // sci specific
  "sci:citation": PropTypes.string,

  // google earth engine specific
  "gee:schema": PropTypes.object,
  "gee:visualizations": PropTypes.object,
  "gee:interval": PropTypes.object,
  "gee:terms_of_use": PropTypes.string,
  "gee:type": PropTypes.string,
})
