import { Liquid } from "liquidjs"

const engine = new Liquid()

const interpolateString = (template, variables) => {
  return engine.parseAndRender(template, variables)
}

const interpolateStringSync = (template, variables) => {
  return engine.parseAndRenderSync(template, variables)
}

export { interpolateString, interpolateStringSync }
