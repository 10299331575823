import { CalendarIcon } from "@chakra-ui/icons"
import {
  Box,
  Grid,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { DayPicker } from "react-day-picker"

const START_DATE = 0
const END_DATE = 1

const DatesRangePicker = ({ range, onRangeChange, bgColor = "inherit", inputFormat = {}, nYears = 10 }) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false)
  const [selectingType, setSelectingType] = useState(START_DATE)

  const currentYear = dayjs().year()
  const startYear = dayjs().subtract(nYears, "years").year()

  const wrapperRef = useRef(null)
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) setIsPickerOpen(false)
  }

  const handleDayClick = (day, { disabled }) => {
    if (disabled) return

    if (selectingType === START_DATE) {
      onRangeChange({ ...range, from: day })
      setSelectingType(END_DATE)
    } else {
      onRangeChange({ ...range, to: day })
      setIsPickerOpen(false)
    }
  }

  const showPicker = (type) => {
    setSelectingType(type)
    setIsPickerOpen(true)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [wrapperRef])

  const today = useMemo(() => new Date(), [])

  const disabledDays = {
    after: (() => {
      if (range.to && selectingType === START_DATE) {
        return today > range.to ? range.to : today
      } else {
        return today
      }
    })(),
    before: (() => {
      if (range.from && selectingType === END_DATE) {
        return range.from
      } else {
        return null
      }
    })(),
  }
  const modifiers = { ...range, ...disabledDays }

  return (
    <div ref={wrapperRef}>
      <Popover isOpen={isPickerOpen} placement={selectingType === START_DATE ? "bottom-start" : "bottom-end"}>
        <PopoverTrigger>
          <Grid templateColumns="repeat(2, 1fr)" gap={1}>
            <Box>
              <InputGroup data-cy="input-date-from" bgColor={bgColor} rounded="md" {...inputFormat}>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  fontSize="1em"
                  children={<CalendarIcon color="gray.300" />}
                />
                <Input
                  placeholder="From"
                  className={selectingType === START_DATE ? "focused" : ""}
                  onClick={() => showPicker(START_DATE)}
                  readOnly={true}
                  value={range.from ? dayjs(range.from).format("YYYY-MM-DD") : ""}
                />
              </InputGroup>
            </Box>
            <Box>
              <InputGroup data-cy="input-date-to" bgColor={bgColor} rounded="md" {...inputFormat}>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  fontSize="1em"
                  children={<CalendarIcon color="gray.300" />}
                  {...inputFormat}
                />
                <Input
                  placeholder="To"
                  className={selectingType === END_DATE ? "focused" : ""}
                  onClick={() => showPicker(END_DATE)}
                  readOnly={true}
                  value={range.to ? dayjs(range.to).format("YYYY-MM-DD") : ""}
                />
              </InputGroup>
            </Box>
          </Grid>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <DayPicker
            key={selectingType}
            className="datesRangePicker"
            fromYear={startYear}
            toYear={currentYear}
            defaultMonth={selectingType === START_DATE ? range.from : range.to}
            onDayClick={handleDayClick}
            modifiers={modifiers}
            selected={[range.from, range]}
            disabled={[
              (day) => {
                return day > disabledDays.after || day < disabledDays.before
              },
            ]}
            captionLayout="dropdown"
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}

DatesRangePicker.propTypes = {
  range: PropTypes.shape({
    from: PropTypes.object,
    to: PropTypes.object,
  }).isRequired,
  onRangeChange: PropTypes.func.isRequired,
  bgColor: PropTypes.string,
  inputFormat: PropTypes.object,
}

export default DatesRangePicker
