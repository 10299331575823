import PropTypes from "prop-types"

export const allowedOperators = ["eq", "neq", "lt", "lte", "gt", "gte", "list_contains"]

export function parseFilterItem(filterItem) {
  const [propertyName, subgraph] = filterItem

  let error

  const processes = Object.values(subgraph.process_graph)
  if (processes.length !== 1) {
    error = "Only one process per filter item is currently supported"
  }

  const process = processes[0]

  const operator = process.process_id
  const propertyValue = Object.values(process.arguments).find((item) => item !== propertyName) || ""

  return {
    error,
    isError: !!error,
    propertyName,
    operator,
    propertyValue,
  }
}

parseFilterItem.propTypes = {
  filterItem: PropTypes.array.isRequired,
}

export function buildArguments(x, y, operator) {
  switch (operator) {
    case "list_contains":
      return { left_field: x, right_value: y }
    case "lt":
    case "lte":
    case "gt":
    case "gte":
      return { x, y: Number(y) }
    default:
      return { x, y }
  }
}

buildArguments.propTypes = {
  x: PropTypes.string.isRequired,
  y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  operator: PropTypes.string.isRequired,
}
