import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import useAreaOfInterestApi from "services/areaOfInterest"

export default function useFetchAreasOfInterest() {
  const { id } = useParams()
  const { show } = useAreaOfInterestApi()

  const {
    isLoading: isPreviewAOILoading,
    isError: isPreviewAOIError,
    data: previewAreaOfInterestData,
  } = useQuery(["showPreviewAreaOfInterest", id], () => show(id, "preview"))

  const {
    isLoading: isPublicAOILoading,
    isError: isPublicAOIError,
    data: publicAreasOfInterest,
  } = useQuery(["showPublicAreasOfInterest", id], () => show(id, "shared"))

  const previewAreaOfInterest = previewAreaOfInterestData && previewAreaOfInterestData[0]

  return {
    isPreviewAOILoading,
    isPublicAOILoading,
    isPreviewAOIError,
    isPublicAOIError,
    previewAreaOfInterest,
    publicAreasOfInterest,
  }
}
