import { withAuthenticationRequired } from "@auth0/auth0-react"
import { Button, ButtonProps, Flex, Grid } from "@chakra-ui/react"
import { usePageTitle } from "hooks"
import { BsThreeDots, SiGoogleanalytics, VscDebug, VscTools } from "react-icons/all"

import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"
import Section from "components/layout/section/Section"
import SectionHeader from "components/layout/section/SectionHeader"

const platformTools = [
  {
    label: "Rollbar",
    href: "https://rollbar.com/orbify/all/items/?environments=production&projects=561725",
    colorScheme: "blue",
    icon: <VscDebug />,
  },
  {
    label: "Google Analytics",
    href: "https://analytics.google.com/analytics/web/#/p304291056/reports/intelligenthome",
    colorScheme: "gray",
    icon: <SiGoogleanalytics />,
  },
  { label: "MixPanel", href: "https://eu.mixpanel.com/project/2784117/", colorScheme: "purple", icon: <BsThreeDots /> },
]

function AdminSummary() {
  usePageTitle("Administration Panel - Summary")

  return (
    <Flex w="full" p={4} flexDirection="column">
      <Section
        header={<SectionHeader title="Platform administration" description="Orbify platform administration" />}
      />
      <Section header={<SectionHeader size="sm" title="Common tools" icon={VscTools} />}>
        <Flex w="full" my={6}>
          <Grid w="full" gap={12} templateColumns="repeat(4, 1fr)">
            {platformTools.map((tool, index) => (
              <AdminToolButton {...tool} key={index} />
            ))}
          </Grid>
        </Flex>
      </Section>
    </Flex>
  )
}

function AdminToolButton(props: AdminToolButtonProps) {
  const { label, href, icon, colorScheme } = props

  return (
    <Button
      as="a"
      size="lg"
      rounded="lg"
      shadow="base"
      w="full"
      h={28}
      href={href}
      target="_blank"
      colorScheme={colorScheme}
      leftIcon={icon}
    >
      {label}
    </Button>
  )
}

interface AdminToolButtonProps extends ButtonProps {
  label: string
  href: string
  icon: JSX.Element
}

export default withAuthenticationRequired(AdminSummary, { onRedirecting: () => <LoadingSpinner /> })
