export const pink = [
  "#1E0000",
  "#593737",
  "#7B4E4E",
  "#966161",
  "#AC6F6F",
  "#C07C7C",
  "#C99588",
  "#D0AC94",
  "#D8BF9E",
  "#DFD1A7",
  "#E5E1B0",
  "#ECECC1",
  "#F3F3D8",
  "#F9F9ED",
  "#FFFFFF",
]
