import * as yup from "yup"
import { Button, Flex, FormControl, HStack, Heading, IconButton, Input, VStack } from "@chakra-ui/react"
import { FieldArray, Form, Formik, FormikHelpers, FormikValues, useField } from "formik"
import { InputControl } from "formik-chakra-ui"
import { ChangeEvent } from "react"
import { VscAdd, VscTrash } from "react-icons/vsc"

import ColorPicker from "./ColorPicker"
import { BasicLegendType } from "./types"

const defaultEntry = {
  title: "",
  color: "#000",
}

export default function BasicLegendForm(props: BasicLegendFormProps) {
  const { legend, setLegend } = props

  const initialValues = {
    type: "basic",
    title: legend?.title || "",
    items: [...(legend?.items || [])],
  }

  const validationSchema = yup.object().shape({
    title: yup.string().required("Title field is required"),
  })

  function handleSubmit(values: FormikValues, formik: FormikHelpers<any>) {
    setLegend(values as BasicLegendType)
    formik.setSubmitting(false)
  }

  return (
    <Flex w="full" p={6} flexDirection="column">
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} validateOnBlur>
        {({ handleSubmit }) => (
          <Form id="entryForm" onSubmit={handleSubmit}>
            <Heading size="md" mb={4}>
              Basic legend
            </Heading>

            <InputControl name="title" label="Title" inputProps={{ size: "sm", placeholder: "Legend title…" }} />
            <LegendItemsControl name="items" />
          </Form>
        )}
      </Formik>
    </Flex>
  )
}

function LegendItemsControl(props: { name: string }) {
  const { name } = props
  const [field] = useField(name)

  return (
    <FieldArray
      name="items"
      render={({ remove, push }) => (
        <VStack spacing={2} mt={3}>
          {field.value.map((item: any, index: number) => (
            <LegendItem key={index} name={field.name} index={index} onRemove={remove} />
          ))}
          <NewLegendItemButton onPush={push} />
        </VStack>
      )}
    />
  )
}

function LegendItem(props: LegendItemProps) {
  const { name, index, onRemove } = props
  const [{ value }, , { setValue }] = useField(`${name}.${index}`)

  function handleSetColor(color: string) {
    setValue({ ...value, color })
  }

  function handleSetLabel(event: ChangeEvent<HTMLInputElement>) {
    const title = event.target.value
    setValue({ ...value, title })
  }

  function handleRemove() {
    onRemove(index)
  }

  return (
    <HStack alignItems="center" w="full" spacing={3}>
      <ColorPicker color={value.color} setColor={handleSetColor} />
      <FormControl>
        <Input w="full" size="sm" rounded="md" value={value.title} onChange={handleSetLabel} />
      </FormControl>
      <IconButton
        size="sm"
        variant="solid"
        colorScheme="red"
        aria-label="Remove item"
        icon={<VscTrash />}
        onClick={handleRemove}
      >
        -
      </IconButton>
    </HStack>
  )
}

function NewLegendItemButton(props: NewLegendItemButtonProps) {
  const { onPush } = props

  const handleClick = () => onPush(defaultEntry)

  return (
    <Flex w="full" justifyContent="flex-end">
      <Button variant="secondary" size="xs" leftIcon={<VscAdd />} onClick={handleClick}>
        Add entry
      </Button>
    </Flex>
  )
}

type NewLegendItemButtonProps = {
  onPush: (item: any) => void
}

type BasicLegendFormProps = {
  legend: BasicLegendType | null
  setLegend: (legend: BasicLegendType) => void
}

type LegendItemProps = {
  name: string
  index: number
  onRemove: (index: number) => void
}
