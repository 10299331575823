import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import { useQuery } from "react-query"

import { dateFormat } from "lib/constants"

import useBillingQueries from "./queryFunctions/useBillingQueries"

dayjs.extend(customParseFormat)

export default function useGetPlarmformUsage() {
  const { getUsageQuery } = useBillingQueries()
  const { queryKey, queryFn, options } = getUsageQuery()

  const { data, isLoading, isError } = useQuery(queryKey, queryFn, options)

  // sort data by date and format date
  const platformUsage = data?.sort((a, b) => {
    return getDate(a.date) - getDate(b.date)
  })

  return {
    isError,
    isLoading,
    platformUsage,
  }
}

function getDate(value: string): any {
  return dayjs(value, dateFormat)
}
