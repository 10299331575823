import { extendTheme } from "@chakra-ui/react"
import { darken, lighten } from "@chakra-ui/theme-tools"
import "@fontsource/inter/400.css"
import "@fontsource/inter/600.css"
import "@fontsource/inter/700.css"
import "@fontsource/inter/900.css"
import "@fontsource/inter/variable.css"
import "@fontsource/source-code-pro/300.css"
import "@fontsource/source-code-pro/500.css"

import "./Calendar.scss"
import "./DayPicker.scss"
import { colors } from "./colors"

const styles = {
  global: {
    body: {
      background: "gray.50",
      fontSize: "md",
    },
  },
}

const fonts = {
  heading: "InterVariable, Inter, sans-serif",
  body: "InterVariable, Inter, sans-serif",
  console: '"Source Code Pro", monospace',
}

const fontSizes = {
  // font sizes were set to 90% of default chakra font size
  "2xs": "0.565rem", // "0.625rem",
  xs: "0.675rem", // "0.75rem",
  sm: "0.785rem", // "0.875rem",
  md: "0.9rem", // "1rem",
  lg: "1rem", // "1.125rem",
  xl: "1.125rem", // "1.25rem",
  "2xl": "1.35rem", // "1.5rem",
  "3xl": "1.685rem", // "1.875rem",
  "4xl": "2rem", // "2.25rem",
  "5xl": "2.75rem", // "3rem",
  "6xl": "3.375rem", // 3.75rem",
  "7xl": "4.05rem", // "4.5rem",
  "8xl": "5.4rem", // "6rem",
  "9xl": "7.25rem", // "8rem",
}

const shadows = {
  outline: "0 0 0 2px rgba(0, 22, 116, 0.5)",
}

const textStyles = {
  alert: {
    color: "red.500",
    fontWeight: "semibold",
  },
}

const components = {
  Alert: {
    baseStyle: {
      title: {
        fontSize: "md",
      },
      description: {
        fontSize: "sm",
      },
    },
  },
  Badge: {
    variants: {
      primary: {
        bg: "brand.700",
        color: "white.100",
      },
      secondary: {
        bg: "brand.100",
        color: "brand.700",
      },
    },
  },
  Button: {
    baseStyle: { rounded: "md", _hover: { boxShadow: "inset 0 0 100px 100px rgba(255, 255, 255, 0.3)" } },
    sizes: {
      small: {
        py: 2.5,
        px: 3,
        fontSize: "0.75rem",
      },
      normal: {
        padding: 4,
      },
    },
    variants: {
      primary: {
        bg: "primary.900",
        color: "white",
        _hover: {
          _disabled: {
            bg: lighten("brand.900", 0.5),
          },
        },
      },
      secondary: {
        bg: "gray.100",
        color: "black",
        _hover: {
          _disabled: {
            bg: darken("gray.100", 0.5),
          },
        },
      },
    },
  },
  Drawer: {
    sizes: {
      "almost-full": { dialog: { maxW: "90%" } },
    },
    variants: {
      alwaysOpen: {
        dialog: {
          pointerEvents: "auto",
        },
        dialogContainer: {
          pointerEvents: "none",
        },
      },
    },
  },
  FormLabel: {
    baseStyle: { fontWeight: "normal", fontSize: "sm" },
  },
  Menu: {
    baseStyle: {
      fontSize: "sm",
      item: {
        fontSize: "sm",
        py: "3px",
      },
      groupTitle: {
        color: "gray.400",
        fontSize: "2xs",
        fontFamily: "heading",
        fontWeight: "semibold",
        textTransform: "uppercase",
        mt: 0,
        mb: 1,
        ml: 3,
      },
    },
  },
  MenuItem: {
    variants: {
      alert: { ...textStyles.alert },
    },
  },
  Modal: {
    baseStyle: {
      overlay: {
        backdropFilter: "blur(2px)",
      },
    },
  },
  Table: {
    baseStyle: { table: { th: { textTransform: "capitalize", fontWeight: "semibold" } } },
    sizes: {
      xs: {
        table: {
          fontSize: "sm",
          th: { p: 2 },
          td: { px: 2, py: 1 },
          caption: { fontSize: "xs", color: "gray.400" },
        },
      },
      sm: {
        th: { fontSize: "sm", py: 2 },
      },
      md: {
        th: { fontSize: "md" },
      },
    },
  },
  Tooltip: {
    baseStyle: {
      fontSize: "xs",
    },
  },
}

const theme = extendTheme({ fonts, fontSizes, colors, shadows, components, styles, textStyles })

export default theme
export { colors, fonts, fontSizes, shadows, components, styles, textStyles }
export * from "./guidedTour"
