import { useQuery } from "react-query"

import useWorkflowTemplateQueries from "./queryFunctions/useWorkflowTemplateQueries"

export default function useGetWorkflowTemplateCategories(options?: any) {
  const { listCategoriesQuery } = useWorkflowTemplateQueries()
  const { queryKey, queryFn } = listCategoriesQuery()

  const { isLoading, isError, data: categories } = useQuery(queryKey, queryFn, options)

  return { isLoading, isError, categories }
}
