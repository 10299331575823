import { Flex, Text } from "@chakra-ui/react"
import { OrbConfig } from "api/models/OrbConfig"
import { VscSettings } from "react-icons/vsc"

import Section from "components/layout/section/Section"
import SectionHeader from "components/layout/section/SectionHeader"
import OrbBasicSettingsForm from "components/orb/OrbBasicSettingsForm"

export default function BasicSettingsSection(props: BasicSettingsSectionProps) {
  const { orbConfig } = props

  if (!orbConfig) {
    throw new Error("Orb configuration was not loaded properly")
  }

  return (
    <Section
      header={
        <SectionHeader size="sm" title="Basic settings" description="Basic application settings" icon={VscSettings}>
          <Text fontSize="sm">Use this section to configure your application's name, description or URL</Text>
        </SectionHeader>
      }
    >
      <Flex my={6}>
        <OrbBasicSettingsForm
          initialFormValues={{
            name: orbConfig.name,
            description: orbConfig.description,
            url: orbConfig.url || "",
            invite_only: orbConfig.invite_only || false,
          }}
          urlSuggestion={false}
          allowDeleting={true}
          submitButtonName="Update"
        />
      </Flex>
    </Section>
  )
}

type BasicSettingsSectionProps = {
  orbConfig?: OrbConfig | null
}
