import { useMutation } from "react-query"

import useAdminCustomProcessesQueries from "./queryFunctions/useAdminCustomProcessesQueries"

export default function useAdminCreateCustomProcess() {
  const { createCustomProcessesMutation } = useAdminCustomProcessesQueries()
  const { mutationFn, mutationOptions } = createCustomProcessesMutation()

  const { isLoading, mutateAsync: createCustomProcess } = useMutation(mutationFn, mutationOptions)

  return { isLoading, createCustomProcess }
}
