import { useMutation } from "react-query"

import useOrbUsersQueries from "./queryFunctions/useOrbUsersQueries"

export default function useDeleteOrbUser() {
  const { deleteUserMutation } = useOrbUsersQueries()
  const { mutationFn, mutationOptions } = deleteUserMutation()

  const { isLoading, mutateAsync: deleteUser } = useMutation(mutationFn, mutationOptions)

  return { isLoading, deleteUser }
}
