import useOpeneoApi from "services/api/openeoApi"

import { convertVisualization } from "./nodeUtils"
import useCollections from "./useCollections"

export default function useVisualizationBuilder() {
  const { getCollectionDetails } = useOpeneoApi()
  const { getCollectionById } = useCollections()

  return { buildVisualisationData }

  async function buildVisualisationData(collectionId: string, data: any) {
    const collection = getCollectionById(collectionId)
    if (!collection) {
      return data
    }

    try {
      const details = await getCollectionDetails(collection["db:id"])
      var visualization = details.summaries["gee:visualizations"][0]
    } catch {}

    if (visualization) {
      return convertVisualization(visualization)
    }

    return {}
  }
}
