export const PuOr = [
  "#7F3B08",
  "#A44F07",
  "#C6690C",
  "#E58A20",
  "#F9B158",
  "#FECF92",
  "#FCE6C8",
  "#F6F6F7",
  "#E1E2EE",
  "#C8C6E0",
  "#ABA3CD",
  "#887CB2",
  "#664697",
  "#491C76",
  "#2D004B",
]
