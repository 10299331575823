import PropTypes from "prop-types"

const schemaType = PropTypes.shape({
  type: PropTypes.string,
  subtype: PropTypes.string,
  title: PropTypes.string,
  pattern: PropTypes.any,
  enum: PropTypes.any,
  minimum: PropTypes.any,
  maximum: PropTypes.any,
  minItems: PropTypes.any,
  maxItems: PropTypes.any,
  items: PropTypes.any,
  deprecated: PropTypes.bool,
})

const parameterType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  schema: PropTypes.oneOfType([PropTypes.arrayOf(schemaType), schemaType]).isRequired,
})

const returnsType = PropTypes.shape({
  description: PropTypes.string.isRequired,
  schema: PropTypes.oneOfType([PropTypes.arrayOf(schemaType), schemaType]).isRequired,
})

const linkType = PropTypes.shape({
  title: PropTypes.string,
  rel: PropTypes.string.isRequired,
  href: PropTypes.string,
  type: PropTypes.any,
})

const processGraphType = PropTypes.shape({})

const processTypeShape = {
  id: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  description: PropTypes.string,
  hidden: PropTypes.bool,
  categories: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  parameters: PropTypes.arrayOf(parameterType).isRequired,
  returns: PropTypes.oneOfType([PropTypes.arrayOf(returnsType), returnsType]).isRequired,
  exceptions: PropTypes.object,
  examples: PropTypes.arrayOf(PropTypes.object),
  links: PropTypes.arrayOf(linkType),
  deprecated: PropTypes.bool,
  experimental: PropTypes.bool,
  process_graph: processGraphType,
}

const processType = PropTypes.shape(processTypeShape)

const processDataType = PropTypes.shape({
  ...processTypeShape,
  values: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.array])
  ),
})

const bboxType = PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
const intervalType = PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))

const extentType = PropTypes.shape({
  spatial: PropTypes.shape({
    bbox: bboxType,
  }),
  temporal: PropTypes.shape({
    interval: intervalType,
  }),
})

const providerType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string),
  url: PropTypes.string,
})

const collectionTypeShape = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string,
  extent: extentType,
  license: PropTypes.string,
  links: PropTypes.arrayOf(linkType),
  stac_version: PropTypes.string,
  providers: PropTypes.arrayOf(providerType),
  summaries: PropTypes.objectOf(PropTypes.any),
}

const collectionType = PropTypes.shape(collectionTypeShape)

const collectionDataType = PropTypes.shape({
  ...collectionTypeShape,
  parameters: PropTypes.arrayOf(parameterType).isRequired,
  values: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.array])
  ).isRequired,
})

schemaType.parameters = PropTypes.arrayOf(parameterType)
schemaType.returns = PropTypes.oneOfType([PropTypes.arrayOf(returnsType), returnsType]).isRequired

export {
  schemaType,
  parameterType,
  returnsType,
  linkType,
  processGraphType,
  processType,
  processDataType,
  extentType,
  providerType,
  collectionType,
  collectionDataType,
}
