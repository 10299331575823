import PropTypes from "prop-types"
import TagManager from "react-gtm-module"
import { useMutation, useQueryClient } from "react-query"
import { useHistory, useParams } from "react-router-dom"

import { useNotifications } from "components/common/notifications"
import useOrbsApi from "services/orbs"

const useDeletionModalController = (modalDisclosure, url, orbId) => {
  const { id } = useParams()
  const history = useHistory()
  const { showNotification, showErrorNotification } = useNotifications()

  const removalId = orbId ? orbId : id

  const queryClient = useQueryClient()
  const { deleteOrb } = useOrbsApi()
  const { mutateAsync: deleteAsync, isLoading: isDeleting } = useMutation(deleteOrb.bind(null, removalId), {
    onSuccess: () => {
      if (modalDisclosure) {
        modalDisclosure.onClose()
        history.push("/")
      }
      queryClient.invalidateQueries()
    },
  })

  const handleAnalytics = (status) => {
    if (!url) {
      // If no url provided then it is called from app gallery and we don't need to track analytics for admin
      return
    }

    const availableStatuses = ["succeeded", "failed"]
    if (!availableStatuses.includes(status)) return null

    const event = "delete_orb_permanently"
    const tagManagerArgs = {
      dataLayer: {
        event,
        [event]: {
          orb_name: url,
          orb_id: removalId,
          status,
        },
      },
    }

    TagManager.dataLayer(tagManagerArgs)
  }

  const onHandleDelete = () => {
    deleteAsync(removalId)
      .then(() => {
        handleAnalytics("succeeded")
        showNotification("", "The application has been deleted.", "success")
      })
      .catch(async (error) => {
        let message = error.message
        try {
          const response = await error.response.json()
          message = response.detail
        } catch {}
        handleAnalytics("failed")
        showErrorNotification(error, "Failed to delete", message)
      })
  }

  return {
    onHandleDelete,
    isDeleting,
  }
}

useDeletionModalController.propTypes = {
  modalDisclosure: PropTypes.shape({
    isOpen: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
  }),
  url: PropTypes.string,
}

export default useDeletionModalController
