import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"
import useLayerAssets from "components/orb-openeo-workflow/useLayerAssets"
import GeoJSONMapLayer from "components/orb-workflow/map-layers-ol/GeoJSONMapLayer"
import MapTilesMapLayer from "components/orb-workflow/map-layers-ol/MapTilesMapLayer"
import VectorTilesMapLayer from "components/orb-workflow/map-layers-ol/VectorTilesMapLayer"

function OpeneoWorkflowLayers(props) {
  const { accessToken } = props
  const { previewJobId } = useOpeneoWorkflowContext()
  const { selectedAssets } = useLayerAssets()

  return selectedAssets.map((asset, index) => {
    switch (asset.type) {
      case "maptiles":
        return (
          <MapTilesMapLayer
            key={asset.id}
            mapTilesUrl={asset.href}
            id={`raster_${asset.id}_${previewJobId}`}
            accessToken={accessToken}
          />
        )
      case "mvt":
        return (
          <VectorTilesMapLayer
            key={asset.id}
            vectorTilesUrl={asset.href}
            id={`vector_tile_${asset.id}_${previewJobId}`}
            isNeededToBuildUrl
          />
        )
      case "geojson":
        return <GeoJSONMapLayer key={asset.id} geoJsonURL={asset.href} layerIndex={index} />
      default:
        return null
    }
  })
}

export default OpeneoWorkflowLayers
