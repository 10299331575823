export const PiYG = [
  "#8E0152",
  "#B51370",
  "#CF4191",
  "#E181B5",
  "#EEADD4",
  "#F8CEE6",
  "#FBE6F1",
  "#F7F7F6",
  "#EBF6DB",
  "#D2ECB0",
  "#B0DC7D",
  "#88C24C",
  "#62A32E",
  "#42841F",
  "#276419",
]
