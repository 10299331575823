import { ButtonGroup, Flex } from "@chakra-ui/react"
import { BiCurrentLocation, BiZoomIn, BiZoomOut } from "react-icons/bi"

import ToolboxButton from "components/common/toolbox-button/ToolboxButton"
import Search from "components/search/Search"

import { useMapboxOLControlsController } from "./mapControlsControllerOL"

export default function MapControlsOL(props) {
  const { buttonsProps, ...rest } = props
  const { geoLocationEnabled, locateUser, zoomIn, zoomOut } = useMapboxOLControlsController()

  return (
    <Flex data-id="workflow-properties__map-controls" {...rest}>
      <ToolboxButton
        label="Locate me"
        onClick={locateUser}
        icon={BiCurrentLocation}
        me={2}
        isDisabled={!geoLocationEnabled}
        {...buttonsProps}
      />

      <Search buttonStyleProps={buttonsProps} />

      <ButtonGroup isAttached>
        <ToolboxButton
          label="Zoom out"
          onClick={zoomOut}
          icon={BiZoomOut}
          {...buttonsProps}
          m={0}
          borderRightRadius={0}
        />
        <ToolboxButton
          label="Zoom in"
          onClick={zoomIn}
          icon={BiZoomIn}
          {...buttonsProps}
          ms="-1px"
          borderLeftRadius={0}
        />
      </ButtonGroup>
    </Flex>
  )
}
