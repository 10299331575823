import { CreateExtent, Extent } from "api/models/AccessControl"
import buildUrl from "api/utils/buildUrl"
import { useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import useAuthenticatedApi from "services/api/authenticatedApi"
import { QueryParams } from "services/types"

export default function useOrbExtentsQueries() {
  const api = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1/external/orbs") })
  const queryClient = useQueryClient()

  const { id: orbId } = useParams<QueryParams>()

  return {
    listExtentsQuery,
    createExtentMutation,
  }

  function listExtentsQuery() {
    const queryKey = ["list", "extents", orbId]

    return {
      queryKey,
      queryFn: function listExtents(): Promise<Extent[]> {
        return api.get(`${orbId}/extent/`).json()
      },
    }
  }

  function createExtentMutation() {
    const mutationOptions = {
      onSuccess: () => queryClient.invalidateQueries(["list", "extents", orbId]),
    }

    return {
      mutationFn: function createExtent(extent: CreateExtent): Promise<Extent> {
        return api.post(`${orbId}/extent/`, { json: extent }).json()
      },
      mutationOptions,
    }
  }
}
