import { IconButton, Tooltip } from "@chakra-ui/react"
import { VscPlay } from "react-icons/vsc"

import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"

export default function ExecuteWorkflowConsoleAction() {
  const { onExecute } = useOpeneoWorkflowContext() as any

  return (
    <Tooltip label="Execute workflow" placement="left-start">
      <IconButton
        size="xs"
        variant="solid"
        colorScheme="green"
        icon={<VscPlay />}
        onClick={onExecute}
        aria-label="Execute workflow"
      />
    </Tooltip>
  )
}
