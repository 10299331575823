import { useAuth0 } from "@auth0/auth0-react"
import ky from "ky"
import { useHistory } from "react-router-dom"

export function useApi({ baseUrl }) {
  return ky.extend({ prefixUrl: baseUrl })
}

const useAuthenticatedApi = ({ baseUrl }) => {
  const { getAccessTokenSilently } = useAuth0()
  const history = useHistory()

  return ky.extend({
    prefixUrl: baseUrl,
    hooks: {
      beforeRequest: [
        async (request) => {
          const token = await getAccessTokenSilently()
          request.headers.set("Authorization", `Bearer ${token}`)
        },
      ],
      afterResponse: [
        (_request, _options, response) => {
          switch (response.status) {
            case 403:
              history.push("/account")
              break
            default:
              return response
          }
        },
      ],
    },
  })
}

export default useAuthenticatedApi
