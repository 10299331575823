import { Flex } from "@chakra-ui/react"
import PropTypes from "prop-types"

function ParameterContent(props) {
  const { children } = props

  return (
    <Flex flexBasis="60%" fontSize="0.875em">
      {children}
    </Flex>
  )
}

ParameterContent.propTypes = {
  children: PropTypes.node,
}

export default ParameterContent
