import { Badge, Flex } from "@chakra-ui/react"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import { useMemo } from "react"

const numberOfDaysWhenFeatureIsNew = 30

function NewFeatureBadge(props) {
  const { featureCreatedAt, ...rest } = props

  const isNewFeature = useMemo(() => {
    if (!featureCreatedAt) {
      return false
    }

    const daysSinceCreated = dayjs().diff(dayjs(featureCreatedAt), "days")
    return daysSinceCreated <= numberOfDaysWhenFeatureIsNew
  }, [featureCreatedAt])

  if (!isNewFeature) {
    return null
  }

  return (
    <Flex zIndex={1} {...rest}>
      <Badge
        fontSize="md"
        backgroundColor="brand.200"
        color="white"
        rounded="xl"
        px={3}
        py={2}
        shadow="dark-lg"
        transform="rotate(10deg)"
      >
        NEW
      </Badge>
    </Flex>
  )
}

NewFeatureBadge.propTypes = {
  featureCreatedAt: PropTypes.string,
}

export default NewFeatureBadge
