export const Greys = [
  "#FFFFFF",
  "#F7F7F7",
  "#EDEDED",
  "#DFDFDF",
  "#D1D1D1",
  "#C1C1C1",
  "#ADADAD",
  "#959595",
  "#828282",
  "#6E6E6E",
  "#5C5C5C",
  "#464646",
  "#2B2B2B",
  "#151515",
  "#000000",
]
