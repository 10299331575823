import { Flex, Image, Text } from "@chakra-ui/react"
import { OrbConfig } from "api/models/OrbConfig"
import { useQuery } from "react-query"

import { LoadingSpinner } from "components/common"
import useTemplatesApi from "services/api/templatesApi"
import { OrbTemplate } from "services/types"

export function CustomTemplateInfo({ orb }: Props) {
  const { listTemplates } = useTemplatesApi()
  const { data: templates, isLoading } = useQuery(["listTemplates", orb.id], () => listTemplates(orb.id))
  const template = templates?.find((item: OrbTemplate) => item.id === orb.template)

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!template) {
    return null
  }

  return (
    <Flex w="60%" p={1} rounded="md" mb={4} bgColor="gray.100">
      <Flex w="40%" flexShrink={0} me={2} roundedLeft="md">
        <Image w="full" h="full" src={template.thumbnail} rounded="md" fit="cover" />
      </Flex>
      <Flex flexDirection="column" overflowWrap="break-word" p={2}>
        <Text fontSize="sm" fontWeight="bold">
          {template.name}
        </Text>
        <Text fontSize="xs" my={1}>
          {template.description}
        </Text>
      </Flex>
    </Flex>
  )
}

interface Props {
  orb: OrbConfig
}
