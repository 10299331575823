import { Text } from "@chakra-ui/react"

import { HeaderBaseProps } from "./types"

export default function SubTitle(props: HeaderBaseProps) {
  const { children, ...rest } = props
  return (
    <Text color="gray.600" {...rest}>
      {children}
    </Text>
  )
}
