import useAdminOrbManagementQueries from "api/queryFunctions/useAdminOrbManagementQueries"
import { useQuery } from "react-query"

export default function useAdminGetOrbAdmins(orbId: number) {
  const { getOrbAdminsQuery } = useAdminOrbManagementQueries()
  const { queryKey, queryFn } = getOrbAdminsQuery(orbId)

  const { isLoading, isError, data: admins } = useQuery(queryKey, queryFn)

  return { isLoading, isError, admins }
}
