import { GrTextAlignFull } from "react-icons/gr"

import ToolboxButton from "components/common/toolbox-button/ToolboxButton"

import { useOpeneoEventsContext } from "./OpeneoEventsContext"

export default function ToggleConsoleButton() {
  const { toggleConsole } = useOpeneoEventsContext()

  return (
    <ToolboxButton
      // @ts-ignore
      variant="outline"
      colorScheme="gray"
      fontWeight="bold"
      icon={GrTextAlignFull}
      label="Toggle log console"
      tooltipLabel="Toggle log console"
      onClick={toggleConsole}
    />
  )
}
