import { Box, FlexProps } from "@chakra-ui/react"
import { useRollbar } from "@rollbar/react"
import { MapBrowserEvent } from "ol"
import Map from "ol/Map"
import View from "ol/View"
import { Attribution, ScaleLine, defaults as defaultControls } from "ol/control"
import { defaults as defaultInteractions } from "ol/interaction"
import { Layer } from "ol/layer"
import "ol/ol.css"
import { Pixel } from "ol/pixel"
import { fromLonLat, transformExtent } from "ol/proj"
import { ReactNode, Ref, createContext, useContext, useEffect, useRef, useState } from "react"

import { useEffectOnce } from "hooks/useEffectOnce"
import { rgbaToHex } from "lib/colorUtils"
import { areColorsSimilar } from "services/colorSimilarity"

import "./Map.scss"
import DragPerspective from "./interactions/DragPerspective"

const MapContext = createContext<MapContextProviderResult | null>(null)

function useMapContext() {
  const context = useContext(MapContext)

  if (!context) throw new Error("MapContext was not initialized. Maybe component was not wrapped in provider")

  return context
}

const defaultCenter = fromLonLat([-122.4376, 37.7577])

const extent = transformExtent([-180, -90, 180, 90], "EPSG:4326", "EPSG:3857")

function MapContextProvider(props: MapContextProviderProps) {
  const { children, zoom = 6, center = defaultCenter } = props
  const Rollbar = useRollbar()

  const mapRef = useRef<HTMLDivElement>(null)
  const [resizeTimeout, setResizeTimeout] = useState()
  const [map, setMap] = useState<Map>()

  useEffectOnce(() => {
    if (mapRef.current === null) return

    const scaleControl = new ScaleLine({
      units: "metric",
    })

    const attribution = new Attribution()

    setMap(
      new Map({
        view: new View({
          zoom,
          center,
          extent,
          multiWorld: false,
          smoothResolutionConstraint: false,
        }),
        layers: [],
        controls: defaultControls({
          zoom: false,
          attribution: false,
        }).extend([scaleControl, attribution]),
        interactions: defaultInteractions().extend([new DragPerspective()]),
        overlays: [],
        maxTilesLoading: 32,
      })
    )
    return () => setMap(undefined)
  })

  useEffect(
    function renderMapInDiv() {
      if (!mapRef.current || !map) return
      map.setTarget(mapRef.current)

      return () => map.setTarget()
    },
    [map]
  )

  /*
  Center and Zoom for the initialization of the map.
  They are not related with the map controls by the zoom buttons.
  */
  useEffect(
    function handleZoomChange() {
      if (!map) return
      map.getView().setZoom(zoom)
    },
    [map, zoom]
  )

  useEffect(
    function handleCenterChange() {
      if (!map) return
      map.getView().setCenter(center)
    },
    [center, map]
  )

  useEffect(
    function displayOnHoverInfoInOverlayLayer() {
      if (!map) return
      map.on("pointermove", displayInfoInOverlay)

      function displayInfoInOverlay(event: MapBrowserEvent<UIEvent>) {
        if (!map || event.dragging) {
          return
        }

        const pixel = map.getEventPixel(event.originalEvent)
        const coordinate = event.coordinate
        const overlay = map.getOverlayById("olMainOverlay")
        const overlayContent = document.getElementsByClassName("ol-overlay-container ol-main-overlay")[0]
        const GEOJSON_LAYER_OVERLAY_ELEMENT_ID = "geojsonLayerOverlayElementId"
        const TILE_LAYER_OVERLAY_ELEMENT_ID = "tileLayerOverlayElementId"

        if (!!overlay && !!overlayContent) {
          map.getLayers().forEach((layer) => {
            if (["ol-tile-layer"].includes(layer.getClassName())) onTileLayerHover(layer as Layer)
            if (["ol-geojson-vector-layer"].includes(layer.getClassName())) onGeojsonLayerHover(layer as Layer)
          })
        }

        function onGeojsonLayerHover(vectorLayer: Layer) {
          vectorLayer
            .getFeatures(pixel)
            .then((features) => {
              const feature = features && features[0]
              if (features.length) {
                const featureCustomInfo = feature.get("custom")
                const overlayContentInnerHtml =
                  !!featureCustomInfo && setGeojsonOverlayContentInnerHtml(featureCustomInfo)
                overlayContentInnerHtml
                  ? displayOverlayContent(overlayContentInnerHtml)
                  : removeOverlayContent(GEOJSON_LAYER_OVERLAY_ELEMENT_ID)
              } else {
                removeOverlayContent(GEOJSON_LAYER_OVERLAY_ELEMENT_ID)
              }
            })
            .catch((e) => {
              if (!(e instanceof TypeError && e.message === "Cannot read properties of undefined (reading 'push')"))
                Rollbar.error("error getting features", e)
              removeOverlayContent(GEOJSON_LAYER_OVERLAY_ELEMENT_ID)
            })
        }

        function onTileLayerHover(layer: Layer) {
          const pixelColorFromLayer = readPixelColorFromTileLayer(layer, pixel)
          const { geeLegend } = layer.getProperties()

          if (!geeLegend) return null
          const { _type, ...legendData } = geeLegend

          const matchingLegendLabels =
            pixelColorFromLayer &&
            Object.entries(legendData)
              .filter(([, color]) => areColorsSimilar(color, pixelColorFromLayer))
              .map(([description]) => description)
              .flat()

          if (matchingLegendLabels?.length) {
            displayOverlayContent(`<p id="${TILE_LAYER_OVERLAY_ELEMENT_ID}">${matchingLegendLabels.toString()}</p>`)
          } else {
            removeOverlayContent(TILE_LAYER_OVERLAY_ELEMENT_ID)
          }
        }

        function displayOverlayContent(innerContent: string) {
          overlayContent.innerHTML = innerContent
          overlay.setPosition(coordinate)
        }

        function removeOverlayContent(overlayElementId: string) {
          document.getElementById(overlayElementId)?.remove()
          if (overlayContent.innerHTML === "") overlay.setPosition(undefined)
        }

        function setGeojsonOverlayContentInnerHtml(featureCustomInfo: { [key: string]: string }) {
          if (!featureCustomInfo) return null
          let text = ""
          for (const [key, value] of Object.entries(featureCustomInfo)) {
            text += `<p>${key}: ${value}<p>`
          }
          return `<p id="${GEOJSON_LAYER_OVERLAY_ELEMENT_ID}">${text}</p>`
        }

        function readPixelColorFromTileLayer(tileLayer: Layer, pixel: Pixel) {
          if (!tileLayer || !pixel) return null

          /*
          Get data for a pixel location. The return type depends on the source data. For image tiles, a four element RGBA array will be returned. For data tiles, the array length will match the number of bands in the dataset. For requests outside the layer extent, null will be returned. Data for a image tiles can only be retrieved if the source's crossOrigin property is set.
          https://openlayers.org/en/latest/apidoc/module-ol_layer_Tile-TileLayer.html
          */
          const pixelData = tileLayer.getData(pixel)

          const rgbaPixelColor = Array.isArray(pixelData) && !!pixelData.length ? rgbaToHex(pixelData) : null
          return rgbaPixelColor
        }
      }

      return () => map.un("pointermove", displayInfoInOverlay)
    },
    [Rollbar, map]
  )

  /* update map size after first render to cover 100% of the container */
  useEffect(
    function updateMapSizeAfterFirstRender() {
      if (!map) return
      setTimeout(() => map.updateSize(), 500)
    },
    [map]
  )

  /* update map size according to the size of openeo editor */
  useEffect(
    function updateMapSize() {
      if (!map) return
      const wrapper = document.getElementById("openeo-preview-map__wrapper")

      if (!wrapper) {
        clearTimeout(resizeTimeout)
        return
      }

      const resizer = new ResizeObserver(() => {
        setResizeTimeout(() => {
          setTimeout(() => map.updateSize(), 200)
          return undefined
        })
      })

      resizer.observe(wrapper)

      return () => {
        clearTimeout(resizeTimeout)
        resizer.disconnect()
      }
    },
    [map, resizeTimeout]
  )

  return <MapContext.Provider value={{ map, mapRef }}>{children}</MapContext.Provider>
}

function MapContainer({ children, className }: FlexProps) {
  const { mapRef } = useMapContext()

  return (
    <Box ref={mapRef} height="100%" width="100%" min-width="600px" min-height="500px" position="relative">
      {children}
    </Box>
  )
}

export { MapContextProvider as default, MapContainer, useMapContext }

type MapContextProviderProps = {
  children: ReactNode
  zoom?: number
  center?: typeof defaultCenter
}

type MapContextProviderResult = {
  map: Map | undefined
  mapRef: Ref<HTMLDivElement>
}
