import { useDisclosure } from "@chakra-ui/react"
import { ReactNode } from "react"

import AssignExternallyPaidSubscriptionModal from "components/admin/assign-externally-paid-subscription/AssignExternallyPaidSubscriptionModal"
import { AdminOrbsEntry } from "components/admin/gdp-demos/types"

export default function AssignExternallyPaidSubscription({ children, orb }: AssignExternallyPaidSubscriptionProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {children({ onOpen })}
      {isOpen && <AssignExternallyPaidSubscriptionModal orb={orb} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />}
    </>
  )
}

type AssignExternallyPaidSubscriptionProps = {
  children: (props: { onOpen: () => void }) => ReactNode
  orb: AdminOrbsEntry
}
