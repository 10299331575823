import { AssignOrbPricingPlanRequest, DetailedPricingPlan } from "api/models/Billing"
import buildUrl from "api/utils/buildUrl"
import { useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import useAuthenticatedApi, { useApi } from "services/api/authenticatedApi"
import { QueryParams } from "services/types"

export default function useOrbBillingQueries() {
  const api = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1/internal/orbs/billing") })
  const publicApi = useApi({ baseUrl: buildUrl("/api/v1/public/orbs") })
  const queryClient = useQueryClient()

  const { id: orbId } = useParams<QueryParams>()

  return {
    getPlanQuery,
    listPricingPlansQuery,
    assignOrbPricingPlanMutation,
  }

  function getPlanQuery() {
    const queryKey = ["get", "pricing-plan", orbId]

    return {
      queryKey,
      queryFn: function getPricingPlan(): Promise<DetailedPricingPlan> {
        return publicApi.get(`${orbId}/billing/pricing_plan`).json()
      },
    }
  }

  function listPricingPlansQuery() {
    const queryKey = ["list", "pricing-plans", orbId]

    return {
      queryKey,
      queryFn: function listPricingPlans(): Promise<DetailedPricingPlan[]> {
        return api.get("pricing_plans").json()
      },
    }
  }

  function assignOrbPricingPlanMutation() {
    const mutationOptions = {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["list", "pricing-plans", orbId])
        await queryClient.resetQueries(["get", "pricing-plan", orbId])
      },
    }

    return {
      mutationOptions,
      mutationFn: function assignOrbPricingPlan(request: AssignOrbPricingPlanRequest) {
        return api.post(`${orbId}/pricing_plan`, { json: request }).json()
      },
    }
  }
}
