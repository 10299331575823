import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import utc from "dayjs/plugin/utc"

dayjs.extend(relativeTime)
dayjs.extend(utc)

const isDev = () => process.env.REACT_APP_ENVIRONMENT === "development"

const showDateTime = (datetime) => {
  if (!datetime) {
    return null
  }

  datetime = dayjs.utc(datetime)
  const utcNow = dayjs.utc()
  const utcHourAgo = utcNow.subtract(1, "hours")

  return datetime.isAfter(utcHourAgo) ? datetime.fromNow() : datetime.format("YYYY-MM-DD HH:mm:ss")
}

const fetchUrlContentAndHeaders = (url) => {
  return fetch(url).then((response) => {
    return Promise.all([response.text(), response.headers])
  })
}

const pairwise = (arr, func, skips) => {
  skips = skips || 1
  for (let i = 0; i < arr.length; i++) {
    func(arr[i], arr[i + skips], i)
  }
}

const truncateText = (text, limit = 30) => (text.length > limit ? `${text.substring(0, limit)}…` : text)

export { fetchUrlContentAndHeaders, isDev, pairwise, showDateTime, truncateText }
