import { useBreakpointValue } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride"
import { guidedTourStyles } from "theme"

import { useGuidedTourContext } from "components/guided-tour/GuidedTourContext"

import stepsByComponent from "./GuidedTourSteps"

const locale = { back: "Back", close: "Close", last: "Finish", next: "Next", open: "Open the dialog", skip: "Skip" }

const handleJoyrideCallback = (data, setRun, setStepIndex, onComplete) => {
  const { action, index, status, type } = data

  if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
    setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1))
  }

  if ([ACTIONS.STOP, ACTIONS.CLOSE].includes(action) || (action === ACTIONS.NEXT && status === STATUS.FINISHED)) {
    onComplete()
  }
}

const GuidedTour = ({ component, callback = handleJoyrideCallback }) => {
  const isMobileDevice = useBreakpointValue({ base: true, md: false })

  const cookieName = `${component}_tourCompleted`

  const tourCompleted = document.cookie?.split(";").some((item) => item.trim().startsWith(cookieName))

  const { run, setRun, setHasSteps } = useGuidedTourContext()
  const [stepIndex, setStepIndex] = useState(0)
  const steps = stepsByComponent[component]

  useEffect(() => {
    if (!tourCompleted) {
      // this needs to be set with a timeout in order to allow e.g. modals or drawers to render before showing the tour
      setTimeout(() => setRun(true), 1000)
    }
  }, [tourCompleted, setRun])

  const handleCompletion = () => {
    document.cookie = `${cookieName}=true; path=/; max-age=31536000`
    setRun(false)
    setStepIndex(0)
  }

  const handleJoyrideCallback = (data) => callback(data, setRun, setStepIndex, handleCompletion)

  useEffect(() => {
    setHasSteps(!!steps && steps.length > 0)
    return () => setHasSteps(false)
  }, [steps, setHasSteps])

  if (isMobileDevice) {
    return null
  }

  return (
    <Joyride
      steps={steps}
      continuous={true}
      run={run}
      hideCloseButton={true}
      disableOverlayClose={true}
      callback={handleJoyrideCallback}
      stepIndex={stepIndex}
      locale={locale}
      styles={guidedTourStyles}
    />
  )
}

GuidedTour.propTypes = {
  component: PropTypes.string.isRequired,
  callback: PropTypes.func,
}

export default GuidedTour
