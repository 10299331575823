declare global {
  interface Window {
    HubSpotConversations?: any
  }
}

export function useOpenHubspotChat() {
  function openChat() {
    if (!window.HubSpotConversations) {
      return
    }

    window.HubSpotConversations.widget.open()
  }

  return { openChat }
}
