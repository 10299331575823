import { useListOrbs } from "api/useListOrbs"
import { useState } from "react"

export default function useListController() {
  const [searchString, setSearchString] = useState("")
  const [searchText, setSearchText] = useState("")
  const [sortOption, setSortOption] = useState("created_at-desc")
  const { isLoading, isError, orbs } = useListOrbs({ filterName: searchText, orderBy: sortOption })

  function handleSearch(event: UIEvent) {
    event.preventDefault()

    setSearchText(searchString)
    setSortOption(sortOption)
  }

  return {
    handleSearch,
    isError,
    isLoading,
    orbs,
    searchString,
    setSearchString,
    setSortOption,
    sortOption,
  }
}
