import { useClipboard } from "@chakra-ui/react"
import { OrbConfig } from "api/models/OrbConfig"
import useShowWorkflow from "api/useShowWorkflow"
import mixpanel from "mixpanel-browser"
import { useEffect } from "react"
import TagManager from "react-gtm-module"
import { useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import { useNotifications } from "components/common/notifications"
import usePageTitle from "hooks/usePageTitle"
import useOrbsApi from "services/orbs"
import { QueryParams } from "services/types"

const pageTitle = "Application Dashboard - Settings"

export default function useOrbGeneralViewController() {
  usePageTitle(pageTitle)

  const { id } = useParams<QueryParams>()
  const { showNotification } = useNotifications()
  const { show } = useOrbsApi()

  const { isLoading, data: orbConfig } = useQuery<OrbConfig>(["showOrb", id], () => show(id))
  const { workflow } = useShowWorkflow()

  useEffect(() => {
    if (orbConfig && workflow) {
      mixpanel.track(`${pageTitle} view`, {
        page_title: pageTitle,
        orb_id: id,
        orb_name: orbConfig?.name,
      })
    }
  }, [orbConfig, id, workflow])

  const applicationUrl = `https://${orbConfig?.url}.${process.env.REACT_APP_BASE_DOMAIN}/`
  const { hasCopied, onCopy } = useClipboard(applicationUrl)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (hasCopied && showNotification) {
      showNotification("Copy to clipboard", "Copied application URL to clipboard", "success", "copy_to_clipboard")
    }
  }, [hasCopied, showNotification])

  async function handleVisitApp() {
    await queryClient.invalidateQueries("onBoardingTasks")
    handleVisitAppAnalytics()
  }

  function handleVisitAppAnalytics() {
    const event = "visit_application_click"

    const tagManagerArgs = {
      dataLayer: {
        event,
        [event]: {
          orb_name: orbConfig?.url,
          orb_id: id && Number(id),
          from: "Orb General View",
        },
      },
    }

    TagManager.dataLayer(tagManagerArgs)
  }

  return {
    isLoading,
    orbConfig,
    applicationUrl,
    handleVisitApp,
    hasCopied,
    onCopy,
  }
}
