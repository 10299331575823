import { Badge, IconButton, Spacer, Table, Tbody, Td, Th, Thead, Tooltip, Tr } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { useState } from "react"
import { VscCheck, VscRefresh } from "react-icons/vsc"

import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"
import Paginator from "components/common/paginator/Paginator"

import { mockUsersExamples } from "./WSWUserListMockUtils"
import useInvitationSender from "./invitationSender"

const STATUSES = { ACTIVATED: "Activated", INACTIVE: "Inactive", BLOCKED: "Blocked", ACTIVE: "Public access" }

export default function UsersList({ id, invitesOnly, allowAllColumns }) {
  const [pagination, setPagination] = useState({ cursor: "", limit: 10, desc: true })

  const { onSubmitInvitation } = useInvitationSender()

  const isLoading = false

  const mockUsers = mockUsersExamples[id % 3]
  const mockInvitesOnlyUsers = { ...mockUsers, results: mockUsers.results.filter((user) => user.invited) }
  const [users, setUsers] = useState(invitesOnly ? mockInvitesOnlyUsers : mockUsers)

  function onChangePage(action) {
    setPagination({ ...pagination, cursor: users?.cursors[action] })
  }

  function getColorSchema(status) {
    const COLOR_SCHEMAS = {
      [STATUSES.BLOCKED]: "red",
      [STATUSES.ACTIVE]: "cyan",
      [STATUSES.INACTIVE]: "gray",
      [STATUSES.ACTIVATED]: "green",
    }
    return COLOR_SCHEMAS[status]
  }

  function handleAssetControlClick(userToModify, permission) {
    const modifyUserPermission = (user) => {
      return user.email === userToModify.email
        ? {
            ...userToModify,
            permissions: userToModify.permissions.map((userToModifyPermission) => {
              return userToModifyPermission.asset === permission.asset
                ? { ...userToModifyPermission, allowed: !permission.allowed }
                : userToModifyPermission
            }),
          }
        : user
    }

    setUsers({
      ...users,
      results: users.results.map(modifyUserPermission),
    })
  }

  function handleStatusClick(userToModify) {
    if (userToModify.status === STATUSES.INACTIVE) return null

    const modifyUserStatus = (user) => {
      return user.email === userToModify.email
        ? {
            ...userToModify,
            status:
              userToModify.status !== STATUSES.BLOCKED
                ? STATUSES.BLOCKED
                : userToModify.invited
                ? STATUSES.ACTIVATED
                : STATUSES.ACTIVE,
          }
        : user
    }

    setUsers({
      ...users,
      results: users.results.map(modifyUserStatus),
    })
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Table size="sm" variant="striped">
        <Thead>
          <Tr>
            <Th>E-mail address</Th>
            {allowAllColumns && <Th>Assets permissions</Th>}
            <Th>{/* Status */}</Th>
            <Th textAlign="center">Invitation sent</Th>
            <Th>{/* Re-send invitation */}</Th>
            {allowAllColumns && <Th>Cost</Th>}
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {users?.results?.map((user, index) => (
            <Tr key={index}>
              <Td>{user.email}</Td>

              {allowAllColumns && (
                <Td>
                  {user.permissions.map((permission) => (
                    <Tooltip key={permission.asset} label="Block/unblock asset">
                      <Badge
                        m={1}
                        fontSize="2xs"
                        variant={permission.allowed ? "solid" : "outline"}
                        colorScheme={permission.allowed ? "green" : "red"}
                        onClick={() => handleAssetControlClick(user, permission)}
                        cursor="pointer"
                      >
                        {permission.asset}
                      </Badge>
                    </Tooltip>
                  ))}
                </Td>
              )}

              <Td textAlign="center">
                <Tooltip label={user.status !== STATUSES.INACTIVE && "Block/unblock user access"}>
                  <Badge
                    ml="auto"
                    colorScheme={getColorSchema(user.status)}
                    variant={user.status === STATUSES.BLOCKED ? "outline" : "subtle"}
                    onClick={() => handleStatusClick(user)}
                    cursor={user.status === STATUSES.INACTIVE ? "auto" : "pointer"}
                  >
                    {user.status}
                  </Badge>
                </Tooltip>
              </Td>

              <Td textAlign="center">{user.invited && <Badge colorScheme="green" fontSize="lg" as={VscCheck} />}</Td>

              <Td textAlign="center">
                {user.status === STATUSES.INACTIVE && (
                  <Tooltip label="Re-send the invitation">
                    <IconButton
                      icon={<VscRefresh />}
                      onClick={() => onSubmitInvitation({ email: user.email, id })}
                      variant="ghost"
                      aria-label="Re-send the invitation"
                      ml="2"
                    />
                  </Tooltip>
                )}
              </Td>

              {allowAllColumns && <Td isNumeric>{user.cost}</Td>}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Spacer />
      <Paginator action={onChangePage} cursors={users?.cursors} />
    </>
  )
}

UsersList.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  allowAllColumns: PropTypes.bool,
  invitesOnly: PropTypes.bool,
  mockAllColumnsValues: PropTypes.bool,
}
