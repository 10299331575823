import { Alert, AlertDescription, AlertIcon, Button, Flex, Icon, SimpleGrid, Spinner, Text } from "@chakra-ui/react"
import { useMemo, useState } from "react"

import CollectionListEntry from "components/openeo-editor/collection-list/CollectionListEntry"
import categorizeCollections, { CollectionCategory } from "components/openeo-editor/lib/collectionCategories"
import { BaseCollectionType } from "components/openeo-editor/types"

export default function CollectionList(props: CollectionListProps) {
  const { isError, isLoading, filteredCollections = [] } = props
  const categorizedCollections = useMemo(() => categorizeCollections(filteredCollections), [filteredCollections])

  if (isLoading) {
    return (
      <Flex w="full" alignItems="center" justifyContent="center" m={2} color="primary.900">
        <Spinner boxSize={4} me={3} /> Loading collections…
      </Flex>
    )
  }

  if (isError) {
    return (
      <Flex w="full" px={6}>
        <Alert fontSize="sm" rounded="md" status="error">
          <AlertIcon boxSize={3} />
          <AlertDescription>Failed to load list of collections</AlertDescription>
        </Alert>
      </Flex>
    )
  }

  return (
    <Flex w="full" px={3} flexDirection="column">
      {Object.entries(categorizedCollections).map(([categoryName, category]) => (
        <CollectionListCategory key={categoryName} categoryName={categoryName} category={category} />
      ))}
    </Flex>
  )
}

function CollectionListCategory(props: CollectionListCategoryProps) {
  const { categoryName, category } = props
  const isCategorized = categoryName !== "Other"
  const itemCount = isCategorized ? 6 : 12
  const [showItems, setShowItems] = useState(itemCount)

  function handleShowMoreItems() {
    setShowItems((limit) => limit + itemCount)
  }

  if (!category.collections.length) {
    return null
  }

  return (
    <Flex w="full" mt={isCategorized ? 2 : 6} mb={4} flexDirection="row">
      {isCategorized && (
        <Flex
          minW="200px"
          w="200px"
          mb={4}
          color="primary.900"
          alignItems="flex-start"
          justifyContent="flex-start"
          flexDirection="column"
        >
          <Text fontSize="md" fontWeight="bold" mb={2}>
            <Icon as={category.icon} boxSize={5} me={5} />
            {categoryName}
          </Text>
          <Text fontSize="xs" color="gray.400">
            {category.description}
          </Text>
        </Flex>
      )}
      <Flex w="full" flexDirection="column">
        <SimpleGrid w="full" px={2} columns={isCategorized ? 2 : 3} spacing={4}>
          {category.collections.slice(0, showItems).map((collection, index) => (
            <CollectionListEntry key={index} collection={collection} />
          ))}
        </SimpleGrid>
        {category.collections.length && showItems < category.collections.length && (
          <Button size="xs" m={3} variant="outline" onClick={handleShowMoreItems}>
            Show more items...
          </Button>
        )}
      </Flex>
    </Flex>
  )
}

type CollectionListProps = {
  isError: boolean
  isLoading: boolean
  filteredCollections: BaseCollectionType[]
}

type CollectionListCategoryProps = {
  categoryName: string
  category: CollectionCategory
}
