import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  chakra,
} from "@chakra-ui/react"
import { Formik, FormikValues } from "formik"
import { InputControl } from "formik-chakra-ui"

import { useNotifications } from "components/common/notifications"

export default function CustomProcessEditor(props: CustomProcessEditorProps) {
  const { showErrorNotification } = useNotifications()
  const { initialValues, header, isOpen, onClose, isLoading, handleSaveCustomProcess } = props

  async function handleSubmit(values: FormikValues) {
    try {
      await handleSaveCustomProcess(values)
      onClose()
    } catch (error: any) {
      let message = error.message
      try {
        const response = await error.response.json()
        message = response.detail
      } catch {}
      showErrorNotification(error, "Failed to save custom process", message)
    }
  }

  return (
    <>
      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {(formik) => (
            <chakra.form onSubmit={formik.handleSubmit}>
              <ModalContent>
                <ModalCloseButton size="sm" />
                <ModalHeader>{header}</ModalHeader>
                <ModalBody>
                  <InputControl name="process_id" label="Process ID" inputProps={{ size: "sm", rounded: "md" }} />
                  <InputControl name="remote_access.branch" label="Branch" inputProps={{ size: "sm", rounded: "md" }} />
                  <InputControl
                    name="remote_access.base_path"
                    label="Base path"
                    inputProps={{ size: "sm", rounded: "md" }}
                  />
                  <InputControl
                    name="remote_access.repository"
                    label="Repository"
                    inputProps={{ size: "sm", rounded: "md" }}
                  />
                  <InputControl
                    name="remote_access.github_profile"
                    label="Github profile"
                    inputProps={{ size: "sm", rounded: "md" }}
                  />
                  <InputControl
                    name="remote_access.github_username"
                    label="Github username"
                    inputProps={{ size: "sm", rounded: "md" }}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button size="sm" mr={3} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button size="sm" type="submit" colorScheme="primary" isLoading={isLoading}>
                    Create
                  </Button>
                </ModalFooter>
              </ModalContent>
            </chakra.form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

type CustomProcessEditorProps = {
  initialValues?: any
  isLoading: boolean
  isOpen: boolean
  onClose: () => void
  handleSaveCustomProcess: any
  header: string
}
