import { OpenEOJobResultAsset, OpeneoWorkflowContextResult } from "api/models/OpenEO"

import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"

export default function useChartAssets() {
  const { isResultsLoading: isLoading, availableAssets } = useOpeneoWorkflowContext() as OpeneoWorkflowContextResult

  const assets = Object.entries(availableAssets).map(([id, obj]) => ({
    id: id,
    label: obj.title,
    computedVariables: obj.metadata?.computed_variables,
    // TODO: clean this up / make it a list of OpenEOJobResultAssets when refactoring this context to TS
    asset: obj,
  }))

  const chartAssets = Object.values(assets)
    .filter((asset: any) => asset.asset.type === "chart")
    .map((asset: any) => asset.asset) as OpenEOJobResultAsset[]

  return {
    isLoading,
    chartAssets,
  }
}
