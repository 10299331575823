import { useDisclosure } from "@chakra-ui/react"
import { MdOutlineLegendToggle } from "react-icons/md"

import ToolboxButton from "components/common/toolbox-button/ToolboxButton"

import ConfigureLegendModal from "./ConfigureLegendModal"

export default function ConfigureLegendButton() {
  const legendModalDisclosure = useDisclosure()

  return (
    <>
      <ConfigureLegendModal isOpen={legendModalDisclosure.isOpen} onClose={legendModalDisclosure.onClose} />
      <ToolboxButton icon={MdOutlineLegendToggle} label="Legends" onClick={legendModalDisclosure.onOpen} isWide />
    </>
  )
}
