import {
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  InputGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react"
import { useField } from "formik"
import PropTypes from "prop-types"
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai"

function NumberEntry(props) {
  const { name, index, onInsert, onRemove, isAddingEnabled } = props

  const handleRemove = () => onRemove(index)
  const handleInsert = () => onInsert(index, "")

  const [{ value }, , { setValue }] = useField(`${name}.${index}`)

  return (
    <Flex w="full">
      <InputGroup size="xs">
        <NumberInput
          value={value}
          size="xs"
          step={0.1}
          onChange={(valueAsString, valueAsNumber) => setValue(valueAsNumber)}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <ButtonGroup ml={2} isAttached>
          <IconButton
            aria-label="Remove entry"
            variant="outline"
            icon={<AiOutlineMinus />}
            onClick={handleRemove}
            mr="-px"
          />
          {isAddingEnabled && (
            <IconButton aria-label="Add entry" variant="outline" icon={<AiOutlinePlus />} onClick={handleInsert} />
          )}
        </ButtonGroup>
      </InputGroup>
    </Flex>
  )
}

NumberEntry.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onInsert: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isAddingEnabled: PropTypes.bool.isRequired,
}

function AddNumberEntryButton(props) {
  const { onPush } = props

  const handleClick = () => onPush("")

  return (
    <Button variant="secondary" size="xs" me={2} onClick={handleClick}>
      Add entry
    </Button>
  )
}

AddNumberEntryButton.propTypes = {
  onPush: PropTypes.func.isRequired,
}

export { NumberEntry, AddNumberEntryButton }
