import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
} from "@chakra-ui/react"
import { useState } from "react"

import { SafeConfirmModalTypes } from "./types"

function SafeConfirmModal(props: SafeConfirmModalTypes): JSX.Element {
  const {
    modalDisclosure,
    onConfirm,
    onConfirmColorScheme = "red",
    onCancel,
    textConfirmation,
    actionLabel = "Delete permanently",
    header,
    disableButton = false,
    additionalInfo = "",
    requireConfirmation = true,
  } = props
  const [currentText, setCurrentText] = useState("")

  const tooltipLabel = `The button is disabled until you enter: ${textConfirmation} in the field above.`

  const handleCancel = () => {
    onCancel && onCancel()
    modalDisclosure.onClose()
  }

  return (
    <Modal isOpen={modalDisclosure.isOpen} onClose={handleCancel} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md">{header}</ModalHeader>
        <ModalCloseButton size="sm" onClick={handleCancel} />
        <ModalBody>
          {additionalInfo}
          {requireConfirmation && (
            <Box my={4}>
              <Box mx={2} mb={2}>
                To confirm, enter the text: <strong>{textConfirmation}</strong>
              </Box>
              <Input placeholder={textConfirmation} onChange={({ target }) => setCurrentText(target.value)} />
            </Box>
          )}
        </ModalBody>

        <ModalFooter>
          <Button size="sm" variant="secondary" mr={3} onClick={handleCancel}>
            Cancel
          </Button>
          <Tooltip label={tooltipLabel} isDisabled={currentText === textConfirmation}>
            <div>
              <Button
                size="sm"
                variant="solid"
                colorScheme={onConfirmColorScheme}
                onClick={onConfirm}
                isDisabled={disableButton || (requireConfirmation && currentText !== textConfirmation)}
              >
                {actionLabel}
              </Button>
            </div>
          </Tooltip>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SafeConfirmModal
