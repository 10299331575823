import {
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { useAtom } from "jotai"
import mixpanel from "mixpanel-browser"
import { useCallback, useEffect } from "react"
import { FiMousePointer } from "react-icons/fi"
import { RiDeleteBin2Line, RiShape2Line } from "react-icons/ri"

import ToolboxButton from "components/common/toolbox-button/ToolboxButton"
import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"
import { useOpeneoEventsContext } from "components/orb-openeo-workflow/openeo-workflow-console/OpeneoEventsContext"
import DrawAreaOfInterestOnMapAction from "components/orb-openeo-workflow/openeo-workflow-console/actions/DrawAreaOfInterestOnMapAction"
import { executeButtonClickedAtom } from "components/orb-openeo-workflow/openeo-workflow-toolbox/openeoWorkflowToolboxAtoms"

import { useDrawContext } from "../../common/open-layers/DrawContextProvider"

export default function OpeneoWorkflowDrawControls() {
  const { previewAreaOfInterest } = useOpeneoWorkflowContext() as any
  const { isFeatureSelected, isDrawing, startDrawing, removeDrawnArea } = useDrawContext()
  const [executeButtonClicked, setExecuteButtonClicked] = useAtom(executeButtonClickedAtom)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { registerEvent } = useOpeneoEventsContext()

  const handleDrawAreaOfInterest = useCallback(
    function () {
      mixpanel.track("Start drawing preview AOI", {})
      startDrawing()
    },
    [startDrawing]
  )

  useEffect(() => {
    if (!previewAreaOfInterest && executeButtonClicked) {
      onOpen()
      registerEvent({
        type: "info",
        content: "Select an area on map to execute workflow",
        action: <DrawAreaOfInterestOnMapAction />,
      })
    }
    setExecuteButtonClicked(false)
  }, [
    previewAreaOfInterest,
    onOpen,
    executeButtonClicked,
    setExecuteButtonClicked,
    registerEvent,
    handleDrawAreaOfInterest,
    isDrawing,
    isFeatureSelected,
  ])

  const button = isFeatureSelected ? (
    <ToolboxButton
      label="Remove selected area of interest"
      onClick={removeDrawnArea}
      icon={RiDeleteBin2Line}
      variant="solid"
      colorScheme="red"
      ms={2}
      isDisabled={isDrawing}
    />
  ) : (
    <Popover isOpen={isOpen} onClose={onClose} trigger="hover" placement="bottom-start">
      <PopoverTrigger>
        <ToolboxButton
          id="workflow-properties__draw-controls"
          data-id="workflow-properties__draw-controls"
          label="Draw area of interest"
          onClick={handleDrawAreaOfInterest}
          icon={RiShape2Line}
          variant="solid"
          colorScheme="primary"
          isDisabled={isDrawing}
        />
      </PopoverTrigger>
      <PopoverContent w="200px" bgColor="whiteAlpha.700" backdropFilter="blur(5px)">
        <PopoverArrow />
        <PopoverBody fontSize="sm" textAlign="center">
          <Flex alignItems="center">
            <Icon as={FiMousePointer} boxSize={5} me={3} color="primary.900" />
            <Text>Select an area on map to execute workflow</Text>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )

  return (
    <Flex id="drawButtons-wrapper" position="absolute" top={3} right={3} justifyContent="center" zIndex={9}>
      {button}
    </Flex>
  )
}
