import { SearchIcon } from "@chakra-ui/icons"
import { Flex, Heading, Input, InputGroup, InputLeftElement, Text } from "@chakra-ui/react"
import { usePageTitle } from "hooks"

import WorkflowCreatorStartBlankButton from "components/workflow-creator/WorkflowCreatorStartBlankButton"

import steps from "./steps"

function WorkflowHeader(props: WorkflowHeaderProps): JSX.Element {
  const { currentStepIndex, filterString, setFilterString } = props
  const stepKeys = Object.keys(steps) as Array<keyof typeof steps>
  const activeStepKey = stepKeys[currentStepIndex]

  const { heading, text, pageTitle } = steps[activeStepKey]

  usePageTitle(pageTitle)

  return (
    <Flex width="full" flexDirection="column" marginX="auto" marginTop={6} marginBottom={8}>
      <Flex w="full" alignItems="center" justifyContent="space-between">
        <Flex>{stepKeys.map(mapStepKeyToStep)}</Flex>
        <Flex>
          <WorkflowCreatorStartBlankButton />
        </Flex>
      </Flex>

      <Flex alignItems="flex-end" justifyContent="space-between">
        <Flex color="gray.400" flexDirection="column" mt={6}>
          <Heading fontSize="md">{heading}</Heading>
          <Text fontSize="sm">{text}</Text>
        </Flex>

        <InputGroup size="sm" width="220px">
          <InputLeftElement size="sm" pointerEvents="none" children={<SearchIcon color="gray.300" />} />
          <Input
            autoFocus
            size="sm"
            rounded="md"
            value={filterString}
            onChange={(e) => setFilterString && setFilterString(e.target.value)}
            placeholder="Try filtering cards..."
          />
        </InputGroup>
      </Flex>
    </Flex>
  )

  function mapStepKeyToStep(stepKey: keyof typeof steps, index: number): JSX.Element | null {
    if (index > currentStepIndex) {
      return null
    }

    return <Step key={stepKey} stepKey={stepKey} isActive={activeStepKey === stepKey} />
  }
}

function Step(props: StepProps): JSX.Element {
  const { stepKey, isActive } = props
  const { number, stepCaption } = steps[stepKey]

  return (
    <>
      <Flex
        className="step-number"
        boxSize={12}
        bg={isActive ? "white" : "gray.200"}
        color={isActive ? "primary.900" : "gray.300"}
        rounded="full"
        fontSize="xl"
        fontWeight="semibold"
        justifyContent="center"
        alignItems="center"
        me={4}
      >
        {number}
      </Flex>

      {isActive && (
        <Flex
          bg="white"
          color="brand.600"
          rounded="xl"
          me={4}
          px={8}
          py={6}
          flexDirection="column"
          h={12}
          justifyContent="center"
        >
          <Heading fontSize="xl" fontWeight="semibold" fontFamily="body">
            {stepCaption}
          </Heading>
        </Flex>
      )}
    </>
  )
}

interface WorkflowHeaderProps {
  currentStepIndex: number
  filterString?: string
  setFilterString?: (arg: string) => void
}

interface StepProps {
  key: string
  stepKey: keyof typeof steps
  isActive: boolean
}

export default WorkflowHeader
