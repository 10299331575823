import { Flex, IconButton, Menu, MenuButton, MenuGroup, MenuItem, MenuList, Tooltip } from "@chakra-ui/react"
import dayjs from "dayjs"
import { BsTagsFill, BsThreeDotsVertical } from "react-icons/bs"
import { GiDualityMask } from "react-icons/gi"

import AssignExternallyPaidSubscription from "components/admin/assign-externally-paid-subscription/AssignExternallyPaidSubscription"
import CloseOrb from "components/admin/close-orb/CloseOrb"

import { AdminOrbsEntry } from "./types"

export default function AdminGdpDemosActions(props: AdminOrganisationActionsProps) {
  const { orb } = props
  const orbHasActiveSub = !!orb.orb_stripe_subscriptions.find(
    (subscription) =>
      dayjs(subscription.active_until) > dayjs() && ["ACTIVE", "PAID_EXTERNALLY"].includes(subscription.status)
  )

  return (
    <Flex textAlign="start">
      <Menu>
        <Tooltip label="Show options">
          <MenuButton as={IconButton} variant="shadow" icon={<BsThreeDotsVertical />} />
        </Tooltip>
        <MenuList>
          <AssignExternallyPaidSubscription orb={orb}>
            {({ onOpen }) => (
              <MenuItem variant="shadow" icon={<BsTagsFill />} onClick={onOpen} isDisabled={orbHasActiveSub}>
                Assign subscription
              </MenuItem>
            )}
          </AssignExternallyPaidSubscription>
          <MenuGroup my={2} title="Danger zone" color="red.500">
            <CloseOrb orb={orb}>
              {({ onOpen }) => (
                <MenuItem icon={<GiDualityMask />} onClick={onOpen} color="red.500">
                  Close Orb
                </MenuItem>
              )}
            </CloseOrb>
          </MenuGroup>
        </MenuList>
      </Menu>
    </Flex>
  )
}

type AdminOrganisationActionsProps = {
  orb: AdminOrbsEntry
}
