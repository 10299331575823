import { Text } from "@chakra-ui/react"

import { HeaderBaseProps } from "./types"

export default function Label(props: HeaderBaseProps) {
  const { children, textColor = "gray.300", ...rest } = props
  return (
    <Text color={textColor} fontSize="xs" fontWeight="semibold" textTransform="uppercase" {...rest}>
      {children}
    </Text>
  )
}
