import { Button } from "@chakra-ui/react"
import useCreateWorkflow from "api/useCreateWorkflow"
import { BiFileBlank } from "react-icons/bi"
import { useHistory, useParams } from "react-router-dom"

import { useNotifications } from "components/common/notifications"

export default function WorkflowCreatorStartBlankButton() {
  const { onClick, isCreatingWorkflow } = useWorkflowCreatorStartBlankButtonController()

  return (
    <Button size="md" colorScheme="green" leftIcon={<BiFileBlank />} onClick={onClick} isLoading={isCreatingWorkflow}>
      Start with blank workflow
    </Button>
  )
}

function useWorkflowCreatorStartBlankButtonController() {
  const { id } = useParams()
  const { showErrorNotification } = useNotifications()
  const history = useHistory()

  const { isLoading: isCreatingWorkflow, createWorkflow } = useCreateWorkflow()

  function onClick() {
    const workflow = {
      workflow_template_id: "openeo-gee-blank",
    }
    createWorkflow(workflow)
      .then(() => history.push(`/orbs/view/${id}`))
      .catch((error) => showErrorNotification(error, "Failed to build workflow"))
  }

  return { onClick, isCreatingWorkflow }
}
