import { Button, Flex, Select, Skeleton, Text, useBoolean } from "@chakra-ui/react"
import { FeatureWithRequiredId } from "api/models/AreaOfInterest"
import useCreateOrbExtent from "api/useCreateOrbExtent"
import useGetOrbExtents from "api/useGetOrbExtents"
import { ChangeEvent } from "react"
import { AiOutlineCloudUpload, AiOutlineLock } from "react-icons/ai"

import GenericAOIHiddenInput, {
  AOI_HIDDEN_IMPORT_INPUT_ID,
} from "components/common/import-area-of-interest/GenericAOIHiddenInput"
import { useNotifications } from "components/common/notifications"

export default function AddSpatialRestriction(props: AddSpatialRestrictionProps) {
  const { onAdd } = props
  const { isLoading: isLoadingExtents, extents } = useGetOrbExtents()
  const { createExtent } = useCreateOrbExtent()
  const [isOpen, setIsOpen] = useBoolean(false)
  const { showErrorNotification } = useNotifications()

  function handleOnSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    if (!event.target.value) {
      return
    }

    onAdd(Number(event.target.value))
    setIsOpen.off()
  }

  function handleImportExtent(feature: FeatureWithRequiredId) {
    // TODO: Allow to provide shape's name
    createExtent({ name: feature.properties?.description || "Shape", feature })
      .then((extent) => {
        onAdd(extent.id)
      })
      .catch((error) => showErrorNotification(error, "Failed to create extent"))
  }

  return (
    <Flex w="full" flexDirection="column">
      <Button w="full" my={2} size="sm" leftIcon={<AiOutlineLock />} onClick={setIsOpen.on}>
        Add spatial restriction
      </Button>
      {isOpen && (
        <Flex w="full" flexDirection="column" alignItems="center" pt={6}>
          <Text fontSize="sm" color="gray.400" mb={2}>
            Select existing shape
          </Text>
          <Skeleton w="full" isLoaded={!isLoadingExtents}>
            <Select
              size="sm"
              placeholder="Select existing restriction"
              onChange={handleOnSelectChange}
              disabled={!extents?.length}
            >
              {extents?.map((extent) => (
                <option key={extent.id} value={extent.id}>
                  {extent.name}
                </option>
              ))}
            </Select>
          </Skeleton>
          <Text fontSize="sm" color="gray.400" mt={8} mb={2}>
            or upload new shape…
          </Text>
          <Button
            size="sm"
            rightIcon={<AiOutlineCloudUpload />}
            onClick={() => document.getElementById(AOI_HIDDEN_IMPORT_INPUT_ID)?.click()}
          >
            Upload shape
          </Button>
          <GenericAOIHiddenInput onDone={handleImportExtent} />
        </Flex>
      )}
    </Flex>
  )
}

type AddSpatialRestrictionProps = {
  onAdd: (value: number) => void
}
