import { withRoleRequired } from "hooks"
import { Route, Switch, useRouteMatch } from "react-router-dom"

import NotFound from "components/not-found/NotFound"
import OrbOrdersView from "components/orb-orders/OrbOrdersView"
import OrbGeneralView from "components/orb/OrbGeneralView"
import OrbAccessControl from "components/orb/access-control/OrbAccessControl"
import { ORBIFY_USER_PERMISSION } from "services/userPermissions"

import OrbWorkflowView from "routes/orbs/OrbWorkflowView"

function View() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path} component={OrbGeneralView} />
      <Route path={`${path}/workflow`} component={OrbWorkflowView} />
      <Route exact path={`${path}/access-control`} component={OrbAccessControl} />
      <Route exact path={`${path}/orders`} component={OrbOrdersView} />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}

export default withRoleRequired(View, ORBIFY_USER_PERMISSION)
