export const tab10 = [
  "#1F77B4",
  "#FF7F0E",
  "#FF7F0E",
  "#2CA02C",
  "#D62728",
  "#D62728",
  "#9467BD",
  "#9467BD",
  "#8C564B",
  "#E377C2",
  "#E377C2",
  "#7F7F7F",
  "#BCBD22",
  "#BCBD22",
  "#17BECF",
]
