import { useQuery } from "react-query"

import useOrbBillingQueries from "./queryFunctions/useOrbBillingQueries"

export default function useGetOrbPricingPlan() {
  const { getPlanQuery } = useOrbBillingQueries()
  const { queryKey, queryFn } = getPlanQuery()

  const { isLoading, isError, error, data: pricingPlan } = useQuery(queryKey, queryFn)

  return { isLoading, isError, error, pricingPlan }
}
