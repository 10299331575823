import * as Yup from "yup"
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  chakra,
} from "@chakra-ui/react"
import { Formik } from "formik"
import { InputControl, SubmitButton } from "formik-chakra-ui"
import PropTypes from "prop-types"
import { useQueryClient } from "react-query"

import useInvitationSender from "./invitationSender"

const InvitationModal = ({ id, isOpen, onClose }) => {
  const queryClient = useQueryClient()

  const initialFormValues = { email: "" }
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email must be provided").email("Enter a valid email address"),
  })

  const { onSubmitInvitation } = useInvitationSender()
  const onSubmit = (values, bag) => {
    onSubmitInvitation(
      { ...values, id },
      () => onClose(),
      () => bag.setSubmitting(false)
    )
    queryClient.invalidateQueries("onBoardingTasks")
  }

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <chakra.form w="full" onSubmit={handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader />
              <ModalCloseButton />
              <ModalBody>
                <InputControl
                  name="email"
                  label="Email address"
                  inputProps={{ placeholder: "john@example.com" }}
                  helperText="Send an invitation to sign in to your application"
                />
              </ModalBody>

              <ModalFooter>
                <Button variant="secondary" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <SubmitButton variant="primary">Send invitation</SubmitButton>
              </ModalFooter>
            </ModalContent>
          </chakra.form>
        )}
      </Formik>
    </Modal>
  )
}

InvitationModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default InvitationModal
