import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  Tooltip,
  VStack,
} from "@chakra-ui/react"
import PropTypes from "prop-types"
import { VscArrowUp, VscCloudDownload, VscCloudUpload, VscCopy, VscFile, VscGist } from "react-icons/vsc"

import useImportExportGraphModalController from "components/openeo-editor/import-export/importExportGraphModalController"

function ImportExportGraphModal(props) {
  const { isOpen, onClose: onModalClose } = props

  const onClose = () => {
    handleGraphUpdate({ target: { value: "" } })
    onModalClose()
  }

  const {
    graphCode,
    handleLoadCurrentGraph,
    handleCopyToClipboard,
    handleGraphUpdate,
    handleGraphImport,
    handleSaveAsFile,
    dropzoneProps,
  } = useImportExportGraphModalController(onClose)

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(1px)" />
      <ModalContent data-testid="modal-content">
        <ModalCloseButton />
        <ModalHeader fontSize="lg">
          <Icon as={VscGist} boxSize={5} mr={4} mb={1} />
          Import or export graph
        </ModalHeader>

        <ModalBody>
          <Flex justifyContent="space-between" alignItems="center" mb={4}>
            <FormLabel mb={0}>Graph Code</FormLabel>
          </Flex>
          <FormControl>
            <Box position="absolute" top={2} right={5}>
              <VStack>
                {graphCode.length ? (
                  <Tooltip label="Copy to clipboard" shouldWrapChildren>
                    <Button
                      size="xs"
                      variant="solid"
                      colorScheme="green"
                      leftIcon={<VscCopy />}
                      zIndex={4}
                      onClick={handleCopyToClipboard}
                    >
                      Copy
                    </Button>
                  </Tooltip>
                ) : (
                  <>
                    <Tooltip label="Load current graph" shouldWrapChildren>
                      <Button
                        as={Box}
                        w="140px"
                        size="xs"
                        variant="secondary"
                        leftIcon={<VscFile />}
                        zIndex={4}
                        cursor={"pointer"}
                        onClick={handleLoadCurrentGraph}
                      >
                        Load current graph
                      </Button>
                    </Tooltip>

                    <Tooltip label="Load from file" shouldWrapChildren>
                      <Button
                        as={Box}
                        w="140px"
                        size="xs"
                        variant="secondary"
                        leftIcon={<VscCloudUpload />}
                        zIndex={4}
                        cursor={"pointer"}
                        {...dropzoneProps.getRootProps({ className: "dropzone" })}
                      >
                        <input {...dropzoneProps.getInputProps()} />
                        Load from file
                      </Button>
                    </Tooltip>
                  </>
                )}
              </VStack>
            </Box>
            <Textarea
              fontSize="xs"
              fontFamily="mono"
              rows={20}
              color="orange.700"
              bgColor="gray.50"
              value={graphCode}
              onChange={handleGraphUpdate}
            />
            <FormHelperText>Replace generated code with the graph code you would like to import</FormHelperText>
          </FormControl>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button size="sm" variant="solid" colorScheme="gray" onClick={onClose}>
            Cancel
          </Button>
          <HStack>
            <Button
              leftIcon={<VscCloudDownload />}
              size="sm"
              variant="outline"
              onClick={handleSaveAsFile}
              disabled={!graphCode.length}
            >
              Save as file
            </Button>
            <Button leftIcon={<VscArrowUp />} size="sm" onClick={handleGraphImport} disabled={!graphCode.length}>
              Import
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

ImportExportGraphModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ImportExportGraphModal
