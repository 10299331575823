export const RdYlBu = [
  "#A50026",
  "#C82227",
  "#E34933",
  "#F57748",
  "#FCA55D",
  "#FECA7C",
  "#FEE99D",
  "#FEFFC0",
  "#E9F6E8",
  "#C9E8F2",
  "#A3D3E6",
  "#7DB4D5",
  "#588CC0",
  "#3F62AB",
  "#313695",
]
