import { IoIosGitNetwork } from "react-icons/io"
import { IoCartOutline, IoPeopleOutline, IoSettingsOutline } from "react-icons/io5"
import { useRouteMatch } from "react-router-dom"

import { useSidebarContext } from "components/layout/sidebar/SidebarContextProvider"
import SidebarItem from "components/layout/sidebar/SidebarItem"
import SidebarSection from "components/layout/sidebar/SidebarSection"
import { ORBIFY_ADMIN_PERMISSION, useUserPermissions } from "services/userPermissions"

export default function OrbSidebar() {
  const match = useRouteMatch("/orbs/view/:id")
  const { isOpen } = useSidebarContext()
  const { permissions } = useUserPermissions()

  return (
    <SidebarSection isOpen={isOpen} title="Application">
      <SidebarItem exact to={match?.url} icon={IoSettingsOutline} tooltip="Settings">
        Settings
      </SidebarItem>

      <SidebarItem
        to={`${match?.url}/workflow`}
        icon={IoIosGitNetwork}
        tooltip="Workflow"
        data-id="sidebar__orb-workflow"
      >
        Workflow
      </SidebarItem>

      <SidebarItem
        to={`${match?.url}/access-control`}
        icon={IoPeopleOutline}
        tooltip="Access control"
        data-id="sidebar__orb-access-control"
      >
        Access control
      </SidebarItem>

      {permissions.includes(ORBIFY_ADMIN_PERMISSION) && (
        <SidebarItem
          to={`${match?.url}/orders`}
          icon={IoCartOutline}
          tooltip="Workflow executions"
          data-id="sidebar__orb-workflow-executions"
        >
          Workflow executions
        </SidebarItem>
      )}
    </SidebarSection>
  )
}
