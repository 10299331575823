export const viridis = [
  "#440154",
  "#481B6D",
  "#46327E",
  "#3F4889",
  "#365C8D",
  "#2E6E8E",
  "#277F8E",
  "#21918C",
  "#1FA187",
  "#2DB27D",
  "#4AC16D",
  "#70CF57",
  "#A0DA39",
  "#D0E11C",
  "#FDE725",
]
