import { Flex, Text } from "@chakra-ui/react"
import { DetailedPricingPlan } from "api/models/Billing"

import { formatMoneyValue } from "lib/utils"

export default function PriceTag(props: PriceTagProps) {
  const { pricingPlan } = props
  const { currency, billing_interval, price } = pricingPlan

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-end"
      bgColor="green.100"
      rounded="md"
      px={3}
      py={2}
    >
      <Text fontSize="xl" fontWeight="bold" color="green.600">
        {formatMoneyValue(price, currency)}
      </Text>
      <Text fontSize="xs" color="green.500" whiteSpace="nowrap">
        every {billing_interval}
      </Text>
    </Flex>
  )
}

type PriceTagProps = {
  pricingPlan: DetailedPricingPlan
}
