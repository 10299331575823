import { AddIcon, CheckIcon, CopyIcon } from "@chakra-ui/icons"
import {
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Tooltip,
  VStack,
  useBoolean,
  useClipboard,
} from "@chakra-ui/react"
import { APIKey } from "api/models/APIKeys"
import useCreateAPIKey from "api/useCreateAPIKey"
import useGetAPIKeys from "api/useGetAPIKeys"
import { AiFillEye, AiFillEyeInvisible, AiOutlineLock } from "react-icons/ai"

import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"
import { useNotifications } from "components/common/notifications"
import Section from "components/layout/section/Section"
import SectionHeader from "components/layout/section/SectionHeader"

export default function APIAccessKeysSection() {
  const { isLoading, apiKeys } = useGetAPIKeys()
  const { isLoading: isCreatingAPIKey, createAPIKey } = useCreateAPIKey()
  const { showNotification, showErrorNotification } = useNotifications()

  async function handleClick() {
    try {
      await createAPIKey()
      showNotification("", "Created API key", "success")
    } catch (error: any) {
      showErrorNotification(error, "Failed to create API key")
    }
  }

  return (
    <Section
      header={
        <SectionHeader
          size="sm"
          title="API Access tokens"
          description="Configure access tokens allowing you to use Orbify platform's API"
          icon={AiOutlineLock}
        />
      }
    >
      <Flex my={6} flexDirection="column">
        <VStack spacing={2} mb={4}>
          {isLoading && <LoadingSpinner />}
          {apiKeys.map((apiKey, index) => (
            <APIAccessKey key={index} apiKey={apiKey} />
          ))}
        </VStack>
        <Button
          size="sm"
          colorScheme="primary"
          leftIcon={<AddIcon />}
          onClick={handleClick}
          isLoading={isCreatingAPIKey}
        >
          Generate API key
        </Button>
      </Flex>
    </Section>
  )
}

function APIAccessKey(props: { apiKey: APIKey }) {
  const { apiKey } = props
  const { hasCopied, onCopy } = useClipboard(apiKey.access_token)
  const [showToken, setShowToken] = useBoolean(false)

  return (
    <InputGroup size="sm" fontFamily="mono">
      <Input
        pr="6em"
        rounded="md"
        type={showToken ? "text" : "password"}
        color={showToken ? "black" : "primary.300"}
        value={apiKey.access_token}
        isReadOnly
      />
      <InputRightElement width="6em">
        <Tooltip label={(showToken ? "Hide" : "Show") + " API authorization token"}>
          <IconButton
            size="xs"
            colorScheme="gray"
            onClick={setShowToken.toggle}
            aria-label="Show API authorization token"
            icon={showToken ? <AiFillEyeInvisible /> : <AiFillEye />}
          />
        </Tooltip>
        <Tooltip label="Copy to clipboard">
          <IconButton
            size="xs"
            colorScheme="gray"
            onClick={onCopy}
            ms={2}
            aria-label="Copy API authorization token"
            icon={hasCopied ? <CheckIcon /> : <CopyIcon />}
          />
        </Tooltip>
      </InputRightElement>
    </InputGroup>
  )
}
