export const plasma = [
  "#0D0887",
  "#350498",
  "#5302A3",
  "#7100A8",
  "#8B0AA5",
  "#A31E9A",
  "#B83289",
  "#CC4778",
  "#DB5C68",
  "#E97158",
  "#F48849",
  "#FBA139",
  "#FEBD2A",
  "#FADA24",
  "#F0F921",
]
