import { Flex } from "@chakra-ui/react"
import { createColumnHelper } from "@tanstack/react-table"

import { DataTable } from "components/common/data-table/DataTable"
import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"

import AdminGdpDemosActions from "./AdminGdpDemosActions"
import { AdminOrbsEntry } from "./types"

export default function AdminGdpDemosList(props: props) {
  const { orbs, isLoading } = props
  const columnHelper = createColumnHelper<AdminOrbsEntry>()

  if (isLoading) {
    return <LoadingSpinner />
  }

  const columns = [
    columnHelper.accessor("id", { header: "ID" }),
    columnHelper.accessor("name", { header: "Name" }),
    columnHelper.accessor("url", { header: "URL" }),
    columnHelper.accessor("created_at", { header: "Created At" }),
    columnHelper.accessor("orb_stripe_subscriptions", {
      header: "Subscription",
      cell: (props) =>
        props
          .getValue()
          .map((subscription) => `${subscription.status} (active_until: ${subscription.active_until})`)
          .join(", "),
    }),
    columnHelper.accessor("closed_at", { header: "Closed At" }),
    columnHelper.accessor("description", {
      cell: (cell) => <AdminGdpDemosActions orb={cell.row.original} />,
      header: "",
      meta: {
        isNumeric: true,
      },
      enableSorting: false,
    }),
  ]

  return (
    <DataTable
      data={orbs}
      columns={columns}
      size="sm"
      boxShadow="0 0 0 1px var(--chakra-colors-gray-200)"
      rounded="base"
      noDataComponent={
        <Flex w="full" p={4} justifyContent="center" fontWeight="semibold" color="gray.400">
          No data available
        </Flex>
      }
    />
  )
}

type props = {
  orbs: any[]
  isLoading: boolean
}
