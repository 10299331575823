import { withAuthenticationRequired } from "@auth0/auth0-react"
import { Flex } from "@chakra-ui/react"
import { withRoleRequired } from "hooks"
import { Route, Switch, useRouteMatch } from "react-router-dom"

import AdminSummary from "components/admin/AdminSummary"
import AdminWarning from "components/admin/AdminWarning"
import CustomProcesses from "components/admin/custom-processes/CustomProcesses"
import AdminGdpDemos from "components/admin/gdp-demos/AdminGdpDemos"
import AdminOrganisations from "components/admin/organisations/AdminOrganisations"
import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"
import NotFound from "components/not-found/NotFound"
import { ORBIFY_ADMIN_PERMISSION } from "services/userPermissions"

const Admin = () => {
  const { path } = useRouteMatch()

  return (
    <Flex w="full" overflowY="scroll">
      <AdminWarning />
      <Switch>
        <Route exact path={path} component={AdminSummary} />
        <Route path={`${path}/organisations`} component={AdminOrganisations} />
        <Route path={`${path}/gdp-demos`} component={AdminGdpDemos} />
        <Route path={`${path}/custom-processes`} component={CustomProcesses} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Flex>
  )
}

export default withRoleRequired(
  withAuthenticationRequired(Admin, {
    onRedirecting: () => <LoadingSpinner />,
  }),
  ORBIFY_ADMIN_PERMISSION
)
