import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Portal, Stack, StackItem } from "@chakra-ui/react"
import { OrganisationBasicInfo } from "api/models/Organisation"
import useListOrganisations from "api/useListOrganisations"
import useSwitchOrganisation from "api/useSwitchOrganisation"
import { useHistory } from "react-router-dom"

import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"

import Organisation from "./Organisation"

export default function OrganisationSwitcher(props: OrganisationSwitcherProps) {
  const { organisationSwitcherDisclosure } = props
  const history = useHistory()
  const { isOpen, onClose } = organisationSwitcherDisclosure

  const { isFetched, isLoading, organisationsInfo } = useListOrganisations()

  const { isLoading: isUpdating, switchOrganisation } = useSwitchOrganisation()

  const onClick = (organisation: OrganisationBasicInfo) => {
    switchOrganisation(organisation.id).then(() => {
      history.replace("/orbs")
      onClose()
    })
  }

  return (
    <Portal appendToParentPortal={false}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg" align="center">
            Select organisation
          </ModalHeader>
          <ModalBody pb={8}>
            <Stack>
              {isLoading || !isFetched ? (
                <StackItem>
                  <LoadingSpinner />
                </StackItem>
              ) : (
                organisationsInfo?.organisations.map((organisation, index) => (
                  <StackItem key={index}>
                    <Organisation
                      {...organisation}
                      onClick={() => onClick(organisation)}
                      selected={organisationsInfo.current_organisation_id === organisation.id || isUpdating}
                    />
                  </StackItem>
                ))
              )}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Portal>
  )
}

interface OrganisationSwitcherProps {
  organisationSwitcherDisclosure: {
    isOpen: boolean
    onOpen: () => void
    onClose: () => void
  }
}
