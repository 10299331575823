import { IconButton, Tooltip } from "@chakra-ui/react"
import { useCallback } from "react"
import { getBezierPath, getEdgeCenter, getMarkerEnd, removeElements } from "react-flow-renderer"
import { VscClose } from "react-icons/vsc"

import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"

const foreignObjectSize = 30

function RemovableEdge(props) {
  const {
    id,
    source,
    sourceX,
    sourceY,
    target,
    targetX,
    targetY,
    targetHandleId,
    sourcePosition,
    targetPosition,
    style = {},
    arrowHeadType,
    markerEndId,
  } = props

  const { elements, setElements } = useOpeneoWorkflowContext()

  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId)

  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  })

  const handleClick = useCallback(
    (event) => {
      const removeEdgeReference = (element) => {
        if (element.id !== target) {
          return element
        }

        const targetValue = element.data?.values[targetHandleId]
        if (targetValue) {
          const { [targetHandleId]: ignore, ...values } = element.data.values
          element.data.values = values
        }

        return element
      }

      event.stopPropagation()

      const removableEdges = elements.filter((element) => element.type === "removableEdge")
      const edgeToRemove = removableEdges.find(
        (edge) => edge.source === source && edge.target === target && edge.targetHandle === targetHandleId
      )

      setElements((existingElements) => removeElements([edgeToRemove], existingElements).map(removeEdgeReference))
    },
    [source, target, targetHandleId, elements, setElements]
  )

  return (
    <>
      <path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <Tooltip label="Remove connection" shouldWrapChildren>
          <IconButton
            m={0}
            boxSize={4}
            minW={4}
            rounded="full"
            colorScheme="gray"
            variant="solid"
            icon={<VscClose />}
            aria-label="Remove connection"
            className="removableEdge-button"
            onClick={handleClick}
            position="unset"
            _hover={{ backgroundColor: "red.500", color: "white" }}
          />
        </Tooltip>
      </foreignObject>
    </>
  )
}

export default RemovableEdge
