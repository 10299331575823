export const Dark2 = [
  "#1B9E77",
  "#1B9E77",
  "#D95F02",
  "#7570B3",
  "#7570B3",
  "#7570B3",
  "#E7298A",
  "#66A61E",
  "#66A61E",
  "#66A61E",
  "#E6AB02",
  "#A6761D",
  "#A6761D",
  "#A6761D",
  "#666666",
]
