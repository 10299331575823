import {
  ChangeOrbOwnershipRequest,
  OrganisationBasicInfo,
  OrganisationCreateRequest,
  OrganisationMembersUpdateRequest,
  UpdateOrganisationBalancesRequest,
} from "api/models/Organisation"
import buildUrl from "api/utils/buildUrl"
import { useQueryClient } from "react-query"

import { AdminOrganisationEntry } from "components/admin/organisations/types"
import useAuthenticatedApi from "services/api/authenticatedApi"

export default function useAdminOrganisationsQueries() {
  const api = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1/admin") })
  const queryClient = useQueryClient()

  return {
    organisationMutation,
    updateOrganisationMembersMutation,
    changeOrbOwnershipMutation,
    updateOrganisationBalancesMutation,
  }

  function organisationMutation() {
    const mutationOptions = {
      onSuccess: () => queryClient.invalidateQueries(["showAdminOrganisations"]),
    }

    return {
      mutationFn: organisationMutationFn,
      mutationOptions,
    }

    function organisationMutationFn(organisation: OrganisationCreateRequest): Promise<OrganisationBasicInfo> {
      return api.post(`organisations/`, { json: organisation }).json()
    }
  }

  function updateOrganisationMembersMutation() {
    const mutationOptions = {
      onSuccess: () => queryClient.invalidateQueries(["showAdminOrganisations"]),
    }

    return {
      mutationFn: updateOrganisationMembersMutationFn,
      mutationOptions,
    }

    function updateOrganisationMembersMutationFn(
      request: OrganisationMembersUpdateRequest
    ): Promise<OrganisationBasicInfo> {
      const payload = { user_ids: request.users.map((user) => user.id) }

      return api.put(`organisations/${request.organisationId}/members`, { json: payload }).json()
    }
  }

  function changeOrbOwnershipMutation() {
    const mutationOptions = {
      onSuccess: () => queryClient.invalidateQueries(["listOrbs"]),
    }

    return {
      mutationFn: changeOrbOwnershipMutationFn,
      mutationOptions,
    }

    function changeOrbOwnershipMutationFn(request: ChangeOrbOwnershipRequest) {
      return api
        .post(`organisations/${request.organisationId}/orbs/${request.orbId}/ownership`, {
          json: { target_org_id: request.targetOrganisationId },
        })
        .json()
    }
  }

  function updateOrganisationBalancesMutation() {
    const mutationOptions = {
      onSuccess: () => queryClient.invalidateQueries(["showAdminOrganisations"]),
    }

    return { mutationFn: updateOrganisationBalances, mutationOptions }

    function updateOrganisationBalances(request: UpdateOrganisationBalancesRequest): Promise<AdminOrganisationEntry> {
      return api
        .post(`organisations/${request.organisationId}/balances`, {
          json: { balances: request.balances },
        })
        .json()
    }
  }
}
