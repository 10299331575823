import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Link,
  Spacer,
  Text,
  chakra,
} from "@chakra-ui/react"
import useGetAllowedBIllingFeatures from "api/useGetAllowedBIllingFeatures"
import { Field, Formik } from "formik"
import { InputControl, SubmitButton, TextareaControl } from "formik-chakra-ui"
import PropTypes from "prop-types"

import OrbDeletionModal from "./OrbDeletionModal"
import useOrbBasicSettingsFormController from "./orbBasicSettingsFormController"

const OrbBasicSettingsForm = ({
  allowDeleting = false,
  initialFormValues,
  readOnlyOnStart = true,
  submitButtonName,
  urlSuggestion = true,
}) => {
  const { prefillUrl, inputProps, isOpen, onOpen, onClose, validationSchema, deletionModalDisclosure, onSubmitOrb } =
    useOrbBasicSettingsFormController(initialFormValues, readOnlyOnStart)

  const OrbBasicSettingsFormName = ({ formik }) => {
    return (
      <InputControl
        name="name"
        label="Name"
        pb={6}
        inputProps={{ ...inputProps, placeholder: "Application's name" }}
        onChange={({ target }) => urlSuggestion && prefillUrl(target.value, formik.setFieldValue)}
      />
    )
  }

  const OrbBasicSettingsFormDescription = ({ formik }) => {
    return (
      <>
        {(isOpen || (!isOpen && !!formik.values["description"])) && (
          <TextareaControl name="description" label="Application's description" pb={6} textareaProps={inputProps} />
        )}
      </>
    )
  }

  const OrbBasicSettingsFormUrl = () => {
    const { allowedBillingFeatures } = useGetAllowedBIllingFeatures()

    const noCustomAppUrl = allowedBillingFeatures && !allowedBillingFeatures.includes("application_url")

    return (
      <Field name="url">
        {({ field, form }) => (
          <FormControl pb={6} isInvalid={!!form.errors.url && form.touched.url} fontSize="sm">
            <FormLabel fontSize="sm" htmlFor="name">
              Application's URL
            </FormLabel>

            {noCustomAppUrl && isOpen && <Text>URL is automatically generated for current pricing plan</Text>}

            {!isOpen && (
              <Link
                color="primary.900"
                href={`https://${field.value}.${process.env.REACT_APP_BASE_DOMAIN}/`}
                fontSize="sm"
                fontFamily="mono"
                isExternal
              >{`https://${field.value}.${process.env.REACT_APP_BASE_DOMAIN}/`}</Link>
            )}

            {!noCustomAppUrl && isOpen && (
              <InputGroup pb={2} size="sm" fontSize="sm" rounded="md">
                <InputLeftAddon roundedLeft="md" bg="inherit" pointerEvents="none" children="https://" />
                <Input {...field} size="sm" px={4} placeholder="my-application's-address" />
                <InputRightAddon bg="inherit" pointerEvents="none" children={`.${process.env.REACT_APP_BASE_DOMAIN}`} />
              </InputGroup>
            )}

            {form.errors.url && <FormErrorMessage>{form.errors.url}</FormErrorMessage>}
          </FormControl>
        )}
      </Field>
    )
  }

  const OrbBasicSettingsFormFooterButtons = ({ formik }) => {
    return (
      <Flex>
        {isOpen && allowDeleting && (
          <>
            <ButtonGroup ml="auto">
              <Button variant="solid" colorScheme="red" size="sm" onClick={deletionModalDisclosure.onOpen}>
                Delete application
              </Button>
            </ButtonGroup>
            <Spacer />
          </>
        )}

        <ButtonGroup ml="auto">
          {isOpen ? (
            <>
              {readOnlyOnStart && (
                <Button size="sm" onClick={onClose}>
                  Cancel
                </Button>
              )}
              <SubmitButton
                colorScheme="primary"
                size="sm"
                onClick={formik.handleSubmit}
                isLoading={formik.isSubmitting}
              >
                {submitButtonName ?? "Submit"}
              </SubmitButton>
            </>
          ) : (
            <Button size="sm" onClick={onOpen}>
              Edit / Delete
            </Button>
          )}
        </ButtonGroup>
      </Flex>
    )
  }

  return (
    <Flex w="full">
      {isOpen && <OrbDeletionModal url={initialFormValues.url} modalDisclosure={deletionModalDisclosure} />}
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitOrb}
        enableReinitialize={true}
      >
        {(formik) => (
          <chakra.form w="full" onSubmit={formik.handleSubmit}>
            <OrbBasicSettingsFormName formik={formik} />
            <OrbBasicSettingsFormDescription formik={formik} />
            <OrbBasicSettingsFormUrl />
            <OrbBasicSettingsFormFooterButtons formik={formik} />
          </chakra.form>
        )}
      </Formik>
    </Flex>
  )
}

const initialFormValuesTypes = PropTypes.shape({
  description: PropTypes.string,
  invite_only: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
})

OrbBasicSettingsForm.propTypes = {
  allowDeleting: PropTypes.bool,
  initialFormValues: initialFormValuesTypes,
  readOnlyOnStart: PropTypes.bool,
  submitButtonName: PropTypes.string,
  urlSuggestion: PropTypes.bool,
}

export { OrbBasicSettingsForm as default, initialFormValuesTypes }
