class FlowToProcessGraphBuilder {
  constructor(nodes, edges) {
    this.nodes = nodes
    this.edges = edges
  }

  get processGraph() {
    const nodes = {}
    this.nodes.forEach((node) => {
      nodes[node.id] = {
        process_id: node.data.values.process_id,
        arguments: this.getArguments(node),
        result: this.isResultNode(node),
      }
    })

    return { process_graph: nodes }
  }

  isResultNode(node) {
    return node.data.values.process_id.startsWith("save_")
  }

  getArguments(node) {
    return node.data.parameters?.reduce(
      (result, parameter) => ({
        ...result,
        [parameter.name]: this.getParameterValue(parameter, node),
      }),
      {}
    )
  }

  getParameterValue(parameter, node) {
    const paramsWithExistingValues = node.data?.values || {}
    const matchingEdges = this.findMatchingEdges(parameter.name, node)
    const hasStringSchema =
      Array.isArray(parameter.schema) && parameter.schema.some((schemaType) => schemaType.type === "string")

    if (matchingEdges.length) {
      return this.buildParameterValueForEdges(parameter.name, matchingEdges)
    }

    if (parameter.name in paramsWithExistingValues) {
      let value = paramsWithExistingValues[parameter.name]

      if (!hasStringSchema) {
        try {
          value = JSON.parse(value)
        } catch {}
      }

      // convert empty strings to nulls
      return value === "" ? null : value
    }

    return null
  }

  findMatchingEdges(name, node) {
    return this.edges.filter((edge) => edge.target === node.id && edge.targetHandle === name)
  }

  buildParameterValueForEdges(name, matchingEdges) {
    if (name === "context") {
      // "context" is a special case of parameter allowing to provide a list of edges as an inputs,
      // which are used to build an object
      return matchingEdges.reduce((output, matchingEdge) => {
        output[matchingEdge.source] = { from_node: matchingEdge.source }
        return output
      }, {})
    } else {
      const parameterValue = matchingEdges.map((matchingEdge) => ({ from_node: matchingEdge.source }))
      return parameterValue.length === 1 ? parameterValue[0] : parameterValue
    }
  }
}

export default FlowToProcessGraphBuilder
