import { useEffect } from "react"

export const useEffectOnce = (callback) => {
  return useEffect(() => {
    const result = callback()

    if (typeof result === "function") return result
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
