import useFetchAreasOfInterest from "components/orb-workflow/hooks/useFetchAreasOfInterest"
import AreaOfInterestMapLayer from "components/orb-workflow/map-layers-ol/AreaOfInterestMapLayer"

export default function AreasOfInterestSavedMapLayers() {
  /*
  These are not the AOI drawn by OpeneoWorkflowDrawControls.
  previewAOI is not used in openEO as it is generated for each execution
   */
  // TODO included the way to generate publicAOIs
  const { isPublicAOILoading, publicAreasOfInterest } = useFetchAreasOfInterest()

  if (isPublicAOILoading) return null

  return (
    <>
      {publicAreasOfInterest &&
        publicAreasOfInterest.map((publicAOI, index) => (
          <AreaOfInterestMapLayer key={index} areaOfInterest={publicAOI} isPublic />
        ))}
    </>
  )
}
