import { Badge } from "@chakra-ui/react"

import useFlowUtils from "components/openeo-editor/lib/useFlowUtils"

function ParameterConnection(props) {
  const { parentId, parameter } = props
  const { getHandleConnections } = useFlowUtils()
  const connections = getHandleConnections("target", parentId, parameter.name)

  return (
    <>
      {connections.map((connection, index) => (
        <Badge key={index}>→ {connection.source}</Badge>
      ))}
    </>
  )
}

export default ParameterConnection
