import { CreateWorkflowRequest } from "api/models/Workflows"
import useCreateWorkflow from "api/useCreateWorkflow"
import useGetWorkflowTemplateCategories from "api/useGetWorkflowTemplateCategories"
import useGetWorkflowTemplates from "api/useGetWorkflowTemplates"
import H from "history"
import { useMemo, useState } from "react"
import { useParams } from "react-router-dom"

import { useNotifications } from "components/common/notifications"
import useOrbConfiguration from "components/orb-workflow/hooks/useOrbConfiguration"
import { getAppType } from "lib/utils"

import { handleWorkflowCreatorAnalytics } from "./WorkflowCreatorAnalytics"
import steps from "./steps"
import {
  CategoryInterface,
  SelectionInterface,
  UseParamsType,
  WorkflowCardType,
  WorkflowCardsType,
  WorkflowTemplateInterface,
} from "./types"

const initialSelection: SelectionInterface = {
  category: null,
  workflowTemplate: null,
}

export default function useWorkflowCreatorController(history: H.History) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const [currentSelection, setCurrentSelection] = useState(initialSelection)
  const [filterString, setFilterString] = useState("")
  const stepKeys = Object.keys(steps) as Array<keyof typeof steps>
  const typeOfCurrentWorkflowCards = stepKeys[currentStepIndex]
  const { orb } = useOrbConfiguration()

  const { showErrorNotification } = useNotifications()
  const { id } = useParams<UseParamsType>()

  const { isLoading: isCategoriesLoading, categories } = useGetWorkflowTemplateCategories({
    enabled: typeOfCurrentWorkflowCards === "category",
    onSuccess: preselectFirstWorkflowCard,
  })

  const { isLoading: isWorkflowTemplatesLoading, templates } = useGetWorkflowTemplates(
    currentSelection.category?.name,
    {
      enabled: typeOfCurrentWorkflowCards === "workflowTemplate",
      onSuccess: preselectFirstWorkflowCard,
    }
  )

  const { isLoading: isCreatingWorkflow, createWorkflow } = useCreateWorkflow()
  const isLastStep = useMemo(() => currentStepIndex === stepKeys.length - 1, [currentStepIndex, stepKeys.length])

  const redirectUrl = useMemo(
    () =>
      currentSelection.workflowTemplate?.redirect_to_orb
        ? `https://${orb.url}.${process.env.REACT_APP_BASE_DOMAIN}${currentSelection.workflowTemplate?.redirect_to_orb}`
        : null,
    [currentSelection, orb]
  )

  return {
    filterString,
    setFilterString,
    currentStepIndex,
    isLoading: isCategoriesLoading || isWorkflowTemplatesLoading || isCreatingWorkflow,
    workflowCards: getWorkflowCards(),
    handleCardSelection,
    moveToPreviousStep,
    handleNextButtonClick,
    isWorkflowCardSelected,
    isLastStep,
  }

  function preselectFirstWorkflowCard<T>(data?: T[]) {
    if (!data || data.length < 1) {
      return
    }

    const workflowCards = getWorkflowCards(data)
    if (workflowCards) {
      setCurrentSelection({ ...currentSelection, [typeOfCurrentWorkflowCards]: workflowCards[0] })
    }
  }

  function handleCardSelection(workflowCard: WorkflowCardType, event: MouseEvent) {
    event.preventDefault()
    setCurrentSelection({ ...currentSelection, [typeOfCurrentWorkflowCards]: workflowCard })

    if (event.detail > 1) {
      handleNextButtonClick(event)
    }
  }

  function moveToPreviousStep() {
    setFilterString("")
    setCurrentSelection({ ...currentSelection, workflowTemplate: null })
    setCurrentStepIndex(currentStepIndex - 1)
  }

  function handleNextButtonClick(e: React.FormEvent<HTMLFormElement> | MouseEvent) {
    e.preventDefault()

    const currentSelectionName = currentSelection[typeOfCurrentWorkflowCards]?.name
    const currentSelectionAppType = getAppType(currentSelection["workflowTemplate"]?.id)
    handleWorkflowCreatorAnalytics({
      currentSelectionAppType,
      currentSelectionName,
      isLastStep,
      typeOfCurrentWorkflowCards,
    })

    if (!isLastStep) {
      setFilterString("")
      setCurrentStepIndex(currentStepIndex + 1)
      return
    }

    const payload = buildWorkflow()

    if (payload)
      createWorkflow(payload)
        .then(() => {
          if (redirectUrl) window.location.replace(redirectUrl)
          else history.push(`/orbs/view/${id}`)
        })
        .catch((error) => {
          showErrorNotification && showErrorNotification(error, "Failed to build workflow")
        })
  }

  function isWorkflowCardSelected(workflowCard: WorkflowCardType): boolean {
    return currentSelection[typeOfCurrentWorkflowCards]?.name === workflowCard.name
  }

  function buildWorkflow(): CreateWorkflowRequest | undefined {
    const { workflowTemplate } = currentSelection
    if (!workflowTemplate) return

    return { workflow_template_id: workflowTemplate.id }
  }

  function getWorkflowCards<T>(data?: T[] | undefined): WorkflowCardsType | undefined {
    switch (typeOfCurrentWorkflowCards) {
      case "category":
        const allCategoryCards = (data as CategoryInterface[]) || categories
        const filteredCategoryCards = filterByName(allCategoryCards)

        return filteredCategoryCards

      case "workflowTemplate":
        const allWorkflowCards = (data as WorkflowTemplateInterface[]) || templates

        return filterByName(allWorkflowCards)

      default:
        const errorTxt = "Wrong type of workflow card"
        showErrorNotification && showErrorNotification({ name: errorTxt }, errorTxt)
        return []
    }

    function filterByName(workflowCards: WorkflowCardsType | undefined): WorkflowCardsType | [] {
      if (!workflowCards) return []

      return workflowCards.filter((workflowCard) =>
        workflowCard.name.toUpperCase().includes(filterString.toUpperCase())
      )
    }
  }
}
