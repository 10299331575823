import { CustomPropTypes } from "lib"
import GeoJSON from "ol/format/GeoJSON"
import { get } from "ol/proj"
import { Vector } from "ol/source"
import PropTypes from "prop-types"
import { memo } from "react"

import { VectorLayer } from "components/common/open-layers/layers"
import { styles } from "components/common/open-layers/styles"
import { WEB_MERCATOR_PROJECTION } from "components/common/open-layers/utils"

function AreaOfInterestMapLayer(props) {
  const { areaOfInterest, isPublic } = props
  const { previewAreaOfInterestStyle, publicAreaOfInterestStyle } = styles

  const style = isPublic ? publicAreaOfInterestStyle : previewAreaOfInterestStyle

  return (
    <VectorLayer
      source={
        new Vector({
          features: new GeoJSON().readFeatures(areaOfInterest.feature, {
            featureProjection: get(WEB_MERCATOR_PROJECTION),
          }),
        })
      }
      style={style}
      className={`ol-${isPublic ? "public-" : ""}AOI-layer`}
      zIndex={9}
    />
  )
}

AreaOfInterestMapLayer.propTypes = {
  areaOfInterest: CustomPropTypes.areaOfInterest,
  isPublic: PropTypes.bool,
}

export default memo(AreaOfInterestMapLayer)
