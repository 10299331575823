import { NominatimSearchResult } from "api/models/Nominatim"
import ky from "ky"

import { Query } from "lib/types/Query"

export default function useNominatimQueries() {
  const nominatimApi = ky.extend({ prefixUrl: process.env.REACT_APP_NOMINATIM_API_BASE_URL as string })

  return {
    searchQuery,
  }

  function searchQuery(q: string): Query<NominatimSearchResult[]> {
    const options = { enabled: !!q }
    const queryKey = ["search", q]

    return {
      queryKey,
      queryFn: function search() {
        return nominatimApi
          .get("search.php", {
            searchParams: {
              q,
              limit: 20,
              polygon_geojson: 1,
              format: "json",
            },
          })
          .json()
      },
      options,
    }
  }
}
