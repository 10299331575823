import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
} from "@chakra-ui/react"
import { DetailedPricingPlan } from "api/models/Billing"
import useGetOrbPricingPlans from "api/useGetOrbPricingPlans"
import { ReactNode } from "react"

import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"
import NoPricingPlan from "components/orb/billing-configuration/NoPricingPlan"

import PricingPlan from "./PricingPlan"

export default function AvailablePricingPlans(props: AvailablePricingPlansProps) {
  const { children, currentPricingPlan } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isLoading, pricingPlans } = useGetOrbPricingPlans()

  return (
    <>
      {children({ onOpen })}

      <Modal size="xl" isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton size="sm" />
          <ModalHeader fontSize="lg">Select pricing plan for the application</ModalHeader>
          <ModalBody>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {currentPricingPlan?.subscribed && <WarningAboutActiveSubscription />}
                <VStack w="full" mb={8} spacing={3}>
                  <NoPricingPlan onSelected={onClose} isCurrent={!currentPricingPlan} />
                  {pricingPlans?.map((pricingPlan, index) => (
                    <PricingPlan
                      key={index}
                      plan={pricingPlan}
                      onSelected={onClose}
                      isCurrent={pricingPlan.id === currentPricingPlan?.id}
                    />
                  ))}
                </VStack>
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

function WarningAboutActiveSubscription() {
  return (
    <Flex w="full" py={6}>
      <Alert status="error" rounded="md">
        <AlertIcon mx={6} />
        <Box flex={1}>
          <AlertTitle>The application has an active subscription</AlertTitle>
          <AlertDescription>Changing the pricing plan will cancel all active subscriptions.</AlertDescription>
        </Box>
      </Alert>
    </Flex>
  )
}

type ChildrenProps = {
  onOpen: () => void
}

type AvailablePricingPlansProps = {
  children: (props: ChildrenProps) => ReactNode
  currentPricingPlan?: DetailedPricingPlan
}
