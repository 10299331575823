export const tab20b = [
  "#393B79",
  "#5254A3",
  "#9C9EDE",
  "#637939",
  "#8CA252",
  "#CEDB9C",
  "#8C6D31",
  "#E7BA52",
  "#E7CB94",
  "#843C39",
  "#D6616B",
  "#E7969C",
  "#7B4173",
  "#CE6DBD",
  "#DE9ED6",
]
