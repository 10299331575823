import buildUrl from "api/utils/buildUrl"

import useAuthenticatedApi from "./authenticatedApi"

function useTemplatesApi() {
  const api = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1/internal/orbs") })

  return {
    listTemplates: (orbId) => {
      const searchParams = orbId ? { orb_id: orbId } : {}

      return api.get("templates/", { searchParams }).json()
    },
  }
}

export default useTemplatesApi
