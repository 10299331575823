import { Flex } from "@chakra-ui/react"
import { OrbConfig } from "api/models/OrbConfig"
import { AiTwotoneSecurityScan } from "react-icons/ai"

import Section from "components/layout/section/Section"
import SectionHeader from "components/layout/section/SectionHeader"

import AuthorizationSettingsForm from "../authorization/AuthorizationSettingsForm"

export default function AuthorizationSettingsSection(props: AuthorizationSettingsSectionProps) {
  const { orbConfig } = props

  if (!orbConfig) {
    throw new Error("Orb configuration was not loaded properly")
  }

  return (
    <Section
      header={
        <SectionHeader
          size="sm"
          title="Authorization"
          description="Application's authorization settings"
          icon={AiTwotoneSecurityScan}
        />
      }
    >
      <Flex my={6}>
        <AuthorizationSettingsForm orbConfiguration={orbConfig} />
      </Flex>
    </Section>
  )
}

type AuthorizationSettingsSectionProps = {
  orbConfig?: OrbConfig | null
}
