import { Flex, Spinner, Text } from "@chakra-ui/react"

export function LoadingOverlay({ visible }: Props) {
  if (!visible) {
    return null
  }

  return (
    <Flex
      w="full"
      h="full"
      backdropFilter="blur(5px)"
      bgColor="blackAlpha.500"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Spinner boxSize={24} thickness="12px" speed="0.8s" color="whiteAlpha.700" />

      <Text mt={8} fontSize="xl" color="whiteAlpha.700">
        Please wait...
      </Text>
    </Flex>
  )
}

interface Props {
  visible: boolean
}
