import buildUrl from "api/utils/buildUrl"

import useAuthenticatedApi from "./api/authenticatedApi"

const useOrbsApi = () => {
  const api = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1") })

  return {
    show: (orbId) => api.get(`internal/orbs/${orbId}`).json(),
    create: (values) => api.post("internal/orbs/create", { json: values }).json(),
    changeOwnership: ({ orbId, targetOrganisationId }) =>
      api
        .post(`internal/orbs/${orbId}/ownership`, {
          json: { target_org_id: targetOrganisationId },
        })
        .json(),
    update: ({ orbId, values }) => api.put(`internal/orbs/${orbId}`, { json: values }).json(),
    deleteOrb: (orbId) => api.delete(`internal/orbs/${orbId}`),
    isUrlAvailable: (url) => api.get(`orb_configuration/check_url/${url}`).json(),
    listAllHistoricalOrders: (orbId, paginationOptions = { cursor: null, desc: true, limit: 10 }) =>
      api
        .get(`internal/orbs/${orbId}/orders`, {
          searchParams: {
            item_type: "historical_order",
            cursor: paginationOptions.cursor || "",
            desc: paginationOptions.desc,
            limit: paginationOptions.limit,
          },
        })
        .json(),
  }
}

export default useOrbsApi
