import { Button, Flex } from "@chakra-ui/react"
import PropTypes from "prop-types"

const LoadMore = ({ hasNextPage, fetchNextPage, isFetchingNextPage }) => {
  return (
    <Flex justifyContent="center" mt={8}>
      <Button
        variant="outline"
        size="sm"
        isLoading={isFetchingNextPage}
        onClick={fetchNextPage}
        isDisabled={!hasNextPage}
      >
        Load more …
      </Button>
    </Flex>
  )
}

LoadMore.propTypes = {
  hasNextPage: PropTypes.bool.isRequired,
  fetchNextPage: PropTypes.func,
  isFetchingNextPage: PropTypes.bool.isRequired,
}

export default LoadMore
