import { useDecodedAuthToken } from "services/useDecodedAuthToken"

const useUserFeatureFlags = () => {
  const { isLoading, metadata } = useDecodedAuthToken()

  const featureFlags = metadata?.feature_flags
  const isFeatureEnabled = (feature) =>
    window.navigator?.userAgent?.includes(feature) || (featureFlags && !!featureFlags[feature])

  return {
    isLoading,
    isFeatureEnabled,
    featureFlags,
  }
}

export { useUserFeatureFlags }
