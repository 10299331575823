import Overlay from "ol/Overlay"
import PropTypes from "prop-types"
import { useEffect } from "react"

import { useMapContext } from "../MapContextProvider"
import "./overlayLayer.scss"

function OverlayLayer({ className, id }) {
  const { map } = useMapContext()
  useEffect(() => {
    if (!map) return
    const overlay = new Overlay({
      element: document.getElementById("ol-main-popup"),
      id,
      className,
    })
    map.addOverlay(overlay)

    return () => {
      if (map) {
        map.removeOverlay(overlay)
      }
    }
  }, [className, id, map])

  return null
}

OverlayLayer.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
}

export default OverlayLayer
