import buildUrl from "api/utils/buildUrl"

import useAuthenticatedApi from "../authenticatedApi"

const useAdminOrganisationsApi = () => {
  const api = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1/admin/organisations") })

  return {
    list: (searchOption, searchText, pageParam) =>
      api.get("", { searchParams: { cursor: pageParam ? pageParam : "", [searchOption]: searchText } }).json(),
    impersonate: (organisationId) => api.put(`${organisationId}/impersonate`),
  }
}

export default useAdminOrganisationsApi
