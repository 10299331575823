export const twilight = [
  "#E2D9E2",
  "#BCCBD1",
  "#88ACC4",
  "#6989BE",
  "#5F61B4",
  "#5C359A",
  "#491564",
  "#301437",
  "#501444",
  "#7F2350",
  "#A54350",
  "#BC6B59",
  "#C9977B",
  "#D6C2B6",
  "#E2D9E2",
]
