import { Link } from "@chakra-ui/react"
import { IoMdHelp } from "react-icons/io"

import { useGuidedTourContext } from "components/guided-tour/GuidedTourContext"
import SidebarItem from "components/layout/sidebar/SidebarItem"

export default function GuidedTourButton() {
  const { run, setRun, hasSteps } = useGuidedTourContext()

  function handleStartTour(event) {
    event.preventDefault()
    setRun(true)
  }

  if (!hasSteps) {
    return null
  }

  return (
    <SidebarItem
      as={Link}
      href=""
      target="_blank"
      icon={IoMdHelp}
      tooltip="Show help"
      onClick={handleStartTour}
      disabled={run}
    >
      Show help
    </SidebarItem>
  )
}
