export const Accent = [
  "#7FC97F",
  "#7FC97F",
  "#BEAED4",
  "#FDC086",
  "#FDC086",
  "#FDC086",
  "#FFFF99",
  "#386CB0",
  "#386CB0",
  "#386CB0",
  "#F0027F",
  "#BF5B17",
  "#BF5B17",
  "#BF5B17",
  "#666666",
]
