const handleStyle = {
  width: "0.85em",
  height: "0.85em",
  borderWidth: "1px",
  borderColor: "var(--chakra-colors-gray-600)",
  borderRadius: 0,
  background: "var(--chakra-colors-white)",
}

export default handleStyle
