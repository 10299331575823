import OLVectorTileLayer from "ol/layer/VectorTile"
import PropTypes from "prop-types"
import { useEffect } from "react"

import { useMapContext } from "components/common/open-layers/MapContextProvider"
import { geeLegendTypes } from "lib/CustomPropTypes"

export default function VectorTileLayer({
  className = "ol-vector-tile-layer",
  geeLegend,
  opacity = 0.7,
  properties,
  source,
  zIndex = 0,
}) {
  const { map } = useMapContext()

  useEffect(() => {
    if (!map) return
    let vectorTileLayer = new OLVectorTileLayer({
      className,
      geeLegend,
      opacity,
      properties,
      source,
      zIndex,
    })
    map.addLayer(vectorTileLayer)

    return () => {
      if (map) {
        map.removeLayer(vectorTileLayer)
      }
    }
  }, [className, geeLegend, map, opacity, properties, source, zIndex])
  return null
}

VectorTileLayer.propTypes = {
  source: PropTypes.object.isRequired,
  zIndex: PropTypes.number,
  opacity: PropTypes.number,
  className: PropTypes.string,
  geeLegend: geeLegendTypes,
}
