import { Badge, Flex, Link, chakra } from "@chakra-ui/react"

import { stacCollectionType } from "./stacPropTypes"

const STACLicense = ({ collection }) => {
  const licenseLink = collection.links.find((link) => link.rel === "license")
  const license = licenseLink ? (
    <Link href={licenseLink.href} target="_blank">
      {collection.license}
    </Link>
  ) : (
    collection.license
  )

  return (
    <Flex w="full">
      <Badge variant="outline" alignItems="center" fontSize="2xs">
        <chakra.span color="gray.300" mx={2}>
          License
        </chakra.span>
        {license}
      </Badge>
    </Flex>
  )
}

STACLicense.propTypes = {
  collection: stacCollectionType.isRequired,
}

export default STACLicense
