import { useQuery } from "react-query"

import useAdminUsersQueries from "./queryFunctions/useAdminUsersQueries"

export default function useAdminGetAllUsers() {
  const { getAllUsersQuery } = useAdminUsersQueries()
  const { queryKey, queryFn } = getAllUsersQuery()

  const { isLoading, isError, data: users } = useQuery(queryKey, queryFn)

  return { isLoading, isError, users }
}
