import { DragEvent } from "react"

import useProcesses from "components/openeo-editor/lib/useProcesses"

export default function useHandleItemDrag() {
  const { getNodeData } = useProcesses()

  return function dragEventHandler(event: DragEvent<HTMLElement>) {
    const { dataTransfer, target } = event
    const dataset = (target as HTMLElement).dataset

    const nodeId = dataset?.flowId || ""
    const nodeType = dataset?.flowType || "default"
    const nodeData = JSON.stringify(getNodeData(nodeId, nodeType) || {})

    dataTransfer.setData("application/reactflow-nodeId", nodeId)
    dataTransfer.setData("application/reactflow-nodeType", nodeType)
    dataTransfer.setData("application/reactflow-nodeData", nodeData)

    dataTransfer.effectAllowed = "move"
  }
}
