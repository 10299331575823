import { NominatimSearchResult } from "api/models/Nominatim"
import useNominatimQueries from "api/queryFunctions/useNominatimQueries"
import { useQuery } from "react-query"

export default function useGetNominatimSearchResults(query: string): useGetAOIListResult {
  const { searchQuery } = useNominatimQueries()
  const { queryKey, queryFn, options } = searchQuery(query)

  const { data, isLoading, isFetched, isError } = useQuery(queryKey, queryFn, options)

  return {
    isLoading,
    isFetched,
    isError,
    searchResults: data && data.length ? cleanupResults(data) : [noResultsFoundResult],
  }

  function cleanupResults(results: NominatimSearchResult[]) {
    return results.filter(filterType).filter(removeDuplicates).slice(0, 10)
  }
}

type useGetAOIListResult = {
  isLoading: boolean
  isFetched: boolean
  isError: boolean
  searchResults: NominatimSearchResult[]
}

const excludedResultTypes = ["yes", "stop", "isolated_dwelling"]

const noResultsFoundResult = {
  display_name: "No results found",
}

function filterType(result: NominatimSearchResult) {
  if (!result.type) {
    return true
  }

  return !excludedResultTypes.includes(result.type)
}

function removeDuplicates(result: NominatimSearchResult, index: number, results: NominatimSearchResult[]) {
  const existingResultIndex = results.findIndex(
    (existingResult) =>
      result.display_name === existingResult.display_name &&
      result.bounding_box === existingResult.bounding_box &&
      result.type === existingResult.type
  )

  return index === existingResultIndex
}
