import { Flex } from "@chakra-ui/react"

export const workflowCreatorCardStyles = {
  as: Flex,
  w: "full",
  minW: "full",
  pb: 1,
  bg: "white",
  rounded: "md",
  position: "relative",
  left: "0",
  whiteSpace: "normal",
  flexDirection: "column",
  shadow: "xs",
  _hover: { top: "6px", shadow: "md", transition: "ease .2s" },
}

export const workflowCreatorSelectedCardStyles = {
  ...workflowCreatorCardStyles,
  shadow: "outline",
  _hover: { top: "6px", shadow: "outline", transition: "ease .2s" },
}

export const cardImageStyles = {
  w: "full",
  h: "180px",
  bgPosition: "center",
  bgRepeat: "no-repeat",
  bgSize: "cover",
  roundedTop: "md",
  alignItems: "flex-end",
  pos: "relative",
}

export const cardNoImageStyles = {
  as: Flex,
  w: "full",
  h: "50%",
  bgColor: "red.50",
  patternFill: "red.600",
  patternOpacity: 0.5,
  roundedTop: "md",
  alignItems: "flex-end",
}
