import { Flex, chakra } from "@chakra-ui/react"
import { createColumnHelper } from "@tanstack/react-table"

import { DataTable } from "components/common/data-table/DataTable"
import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"

import AdminOrganisationActions from "./AdminOrganisationActions"
import { AdminOrganisationEntry } from "./types"

export default function AdminOrganisationList(props: AdminOrganisationListProps) {
  const { organisations, isLoading } = props
  const columnHelper = createColumnHelper<AdminOrganisationEntry>()

  if (isLoading) {
    return <LoadingSpinner />
  }

  const columns = [
    columnHelper.accessor("name", { header: "Name" }),
    columnHelper.accessor("users", { header: "Users" }),
    columnHelper.accessor("orbs", {
      cell: (cell) => {
        const orbs = cell
          .getValue()
          .split(",")
          .map((orb, index) => <chakra.li key={index}>{orb}</chakra.li>)
        return <chakra.ul fontSize="xs">{orbs}</chakra.ul>
      },
      header: "Orbs",
    }),
    columnHelper.accessor("id", {
      cell: (cell) => <AdminOrganisationActions organisation={cell.row.original} />,
      header: "",
      meta: {
        isNumeric: true,
      },
      enableSorting: false,
    }),
  ]

  return (
    <DataTable
      data={organisations}
      columns={columns}
      size="sm"
      boxShadow="0 0 0 1px var(--chakra-colors-gray-200)"
      rounded="base"
      noDataComponent={
        <Flex w="full" p={4} justifyContent="center" fontWeight="semibold" color="gray.400">
          No data available
        </Flex>
      }
    />
  )
}

type AdminOrganisationListProps = {
  organisations: any[]
  isLoading: boolean
}
