import buildUrl from "api/utils/buildUrl"

import useAuthenticatedApi from "./authenticatedApi"

const useOrbUsersApi = () => {
  const api = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1/external/orbs/users") })

  return {
    listUsers: (orbId, invitesOnly = false, paginationOptions = { cursor: null, desc: true, limit: 10 }) =>
      api
        .get(`${orbId}/`, {
          searchParams: {
            invites_only: invitesOnly,
            cursor: paginationOptions.cursor || "",
            desc: paginationOptions.desc,
            limit: paginationOptions.limit,
          },
        })
        .json(),
    countInvitations: (orbId) => api.get(`${orbId}/invitations`).json(),
    invite: (orbId, values) => api.post(`${orbId || values.id}/invite`, { json: { email: values.email } }).json(),
  }
}

export default useOrbUsersApi
