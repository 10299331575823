import { OrganisationBasicInfo, OrganisationsListInfo } from "api/models/Organisation"
import buildUrl from "api/utils/buildUrl"
import useAuthedApiWhenLoggedIn from "api/utils/useAuthedApiWhenLoggedIn"
import { useQueryClient } from "react-query"

import { Mutation } from "lib/types/Mutation"
import { Query } from "lib/types/Query"

export default function useOrganisationsQueries() {
  const api = useAuthedApiWhenLoggedIn({ baseUrl: buildUrl("/api/v1/internal/organisations") })
  const queryClient = useQueryClient()

  return {
    listOrganisationsQuery,
    switchOrganisationMutation,
  }

  function listOrganisationsQuery(): Query<OrganisationsListInfo> {
    return {
      queryKey: ["organisations"],
      queryFn: () => api.get("list").json(),
    }
  }

  function switchOrganisationMutation(): Mutation<OrganisationBasicInfo> {
    return {
      mutationFn: function switchOrganisation(id: string | number) {
        return api.put("switch", { json: { id } }).json()
      },
      mutationOptions: {
        onSuccess: () => {
          queryClient.invalidateQueries(["organisations"])
        },
      },
    }
  }
}
