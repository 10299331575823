import { Box, Flex } from "@chakra-ui/react"
import { usePageTitle } from "hooks"

import Section from "components/layout/section/Section"
import SectionHeader from "components/layout/section/SectionHeader"
import OrbBasicSettingsForm from "components/orb/OrbBasicSettingsForm"

const Create = () => {
  usePageTitle("Create new application")
  const initialFormValues = { name: "", description: "", url: "", invite_only: true }

  return (
    <Box w="80%" mx="auto">
      <Section header={<SectionHeader title="Create application" description="Create a new Orbify application" />}>
        <Flex w="full" mt={6}>
          <OrbBasicSettingsForm
            submitButtonName="Create"
            initialFormValues={initialFormValues}
            readOnlyOnStart={false}
          />
        </Flex>
      </Section>
    </Box>
  )
}

export default Create
