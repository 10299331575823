import { Avatar, AvatarProps } from "@chakra-ui/react"
import { OrbConfig } from "api/models/OrbConfig"
import { useMemo } from "react"

export default function OrbLogo({ orb, ...avatarProps }: OrbLogoProps) {
  const name = orb?.name
  const alphaName = useMemo(() => {
    return name
      ? name
          .replace(/[^a-z0-9\s]/gi, "") // remove anything that's not a letter, number or a whitespace
          .replace(/\s+/g, " ") // remove whitespace duplicates
      : ""
  }, [name])

  return <Avatar name={alphaName} rounded="md" shadow="inner" {...avatarProps} />
}

interface OrbLogoProps extends AvatarProps {
  orb: OrbConfig
}
