export const tab20c = [
  "#3182BD",
  "#6BAED6",
  "#C6DBEF",
  "#E6550D",
  "#FD8D3C",
  "#FDD0A2",
  "#31A354",
  "#A1D99B",
  "#C7E9C0",
  "#756BB1",
  "#BCBDDC",
  "#DADAEB",
  "#636363",
  "#BDBDBD",
  "#D9D9D9",
]
