import buildUrl from "api/utils/buildUrl"
import ky from "ky"
import { MVT } from "ol/format"
import VectorTileSource from "ol/source/VectorTile"
import PropTypes from "prop-types"
import { memo } from "react"

import VectorTileLayer from "components/common/open-layers/layers/VectorTileLayer"
import { geeLegendTypes } from "lib/CustomPropTypes"
import useAuthenticatedApi from "services/api/authenticatedApi"

function VectorTilesMapLayer(props) {
  const { id, isNeededToBuildUrl, geeLegend, vectorTilesUrl } = props
  const authApi = useAuthenticatedApi({ baseUrl: buildUrl("") })
  const api = isNeededToBuildUrl ? authApi : ky

  if (!vectorTilesUrl) return null

  const source = new VectorTileSource({
    url: vectorTilesUrl,
    zIndex: 0,
    crossOrigin: "",
    format: new MVT(),
    tileLoadFunction: addAuthenticationToTileRequest,
  })

  function addAuthenticationToTileRequest(tile, src) {
    tile.setLoader(async (extent, resolution, projection) => {
      api
        .get(buildUrl(src))
        .then((response) => response.status === 200 && response.arrayBuffer())
        .then((vectorBuffer) => {
          if (!vectorBuffer) return

          const format = tile.getFormat()
          const features = format.readFeatures(vectorBuffer, {
            extent: extent,
            featureProjection: projection,
          })
          tile.setFeatures(features)
        })
    })
  }

  return <VectorTileLayer source={source} geeLegend={geeLegend} properties={id} />
}

VectorTilesMapLayer.propTypes = {
  isNeededToBuildUrl: PropTypes.bool,
  geeLegend: geeLegendTypes,
  vectorTilesUrl: PropTypes.string.isRequired,
}

export default memo(VectorTilesMapLayer)
