import {
  Flex,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react"
import PropTypes from "prop-types"
import { VscGist } from "react-icons/vsc"

import useComputedVariablesModalController from "components/openeo-editor/computed-variables/computedVariablesModalController"

function ComputedVariablesModal(props) {
  const { isOpen, onClose } = props
  const { computedVariables } = useComputedVariablesModalController()

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(1px)" />
      <ModalContent data-testid="modal-content">
        <ModalCloseButton />
        <ModalHeader fontSize="lg">
          <Icon as={VscGist} boxSize={5} mr={4} mb={1} />
          Computed variables
        </ModalHeader>

        <ModalBody>
          <Flex justifyContent="space-between" alignItems="center" mb={4}>
            <FormLabel mb={0}>Variables</FormLabel>
          </Flex>
          <Textarea
            fontSize="xs"
            fontFamily="mono"
            rows={20}
            color="orange.700"
            bgColor="gray.50"
            value={JSON.stringify(computedVariables, null, 2)}
            readOnly={true}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

ComputedVariablesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ComputedVariablesModal
