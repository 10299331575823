import { User } from "@auth0/auth0-react"
import { OrbConfig } from "api/models/OrbConfig"
import { useMemo, useState } from "react"
import { useInfiniteQuery } from "react-query"

import useAdminOrganisationsApi from "services/api/admin/adminOrganisations"

const FILTER_ORGANISATIONS_OPTIONS = [
  { name: "By name", value: "filter_by_name" },
  { name: "By URL", value: "filter_by_orb_url" },
  { name: "By email", value: "filter_by_member_email" },
]

export default function useAdminOrganisationsController() {
  const [searchOption, setSearchOption] = useState("filter_by_name")
  const [searchText, setSearchText] = useState("")
  const [currentOrganisation, setCurrentOrganistaion] = useState(null)

  const { list } = useAdminOrganisationsApi()
  const { isLoading, isFetched, data, hasNextPage, fetchNextPage } = useInfiniteQuery(
    ["showAdminOrganisations", searchText, searchOption],
    ({ pageParam = null }) => list(searchOption, searchText, pageParam),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.cursors.next_cursor
      },
    }
  )

  function handleSearch(filterOption: string, searchText: string) {
    setSearchOption(filterOption)
    setSearchText(searchText)
  }

  const organisations = useMemo(() => {
    const flatOrganisations = data?.pages?.map((page) => page.results).flat(2)
    if (!flatOrganisations) return null
    return flatOrganisations.map((organisation) => {
      const { users, orbs, ...rest } = organisation
      return {
        ...rest,
        users_details: users,
        users: users.map((user: User) => user.email).join(", "),
        orbs: orbs.map((orb: OrbConfig) => orb.url).join(", "),
      }
    })
  }, [data])

  return {
    filterOptions: FILTER_ORGANISATIONS_OPTIONS,
    handleSearch,
    isLoading: isLoading && !isFetched,
    organisations: organisations || [],
    currentOrganisation,
    setCurrentOrganistaion,
    hasNextPage: hasNextPage || false,
    fetchNextPage,
  }
}
