import buildUrl from "api/utils/buildUrl"

import { CategoryInterface, WorkflowTemplateInterface } from "components/workflow-creator/types"
import useAuthenticatedApi from "services/api/authenticatedApi"

export default function useWorkflowTemplateQueries() {
  const api = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1/internal/workflows/templates") })

  return {
    listCategoriesQuery,
    listTemplatesQuery,
  }

  function listCategoriesQuery() {
    return {
      queryKey: ["listWorkflowTemplateCategories"],
      queryFn: function listWorkflowTemplateCategories(): Promise<CategoryInterface[]> {
        return api.get("categories").json()
      },
    }
  }

  function listTemplatesQuery(category?: string) {
    return {
      queryKey: ["listWorkflowTemplates"],
      queryFn: function listApiKeys(): Promise<WorkflowTemplateInterface[]> {
        return api
          .get("", {
            searchParams: {
              block_category: category || "",
            },
          })
          .json()
      },
    }
  }
}
