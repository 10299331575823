import ky from "ky"

import { Query } from "lib/types/Query"

export default function useChartsQueries() {
  return {
    getChartFeaturesQuery,
  }

  function getChartFeaturesQuery(url: string): Query<any> {
    return {
      queryKey: ["chart", "getFeatures", url],
      queryFn: function getChartFeatures() {
        return ky.get(url, { timeout: false }).text()
      },
    }
  }
}
