export const afmhot = [
  "#000000",
  "#240000",
  "#480000",
  "#6E0000",
  "#921200",
  "#B63700",
  "#DA5A00",
  "#FF8001",
  "#FFA425",
  "#FFC849",
  "#FFEC6D",
  "#FFFF91",
  "#FFFFB7",
  "#FFFFDB",
  "#FFFFFF",
]
