import { Box, Flex, HStack, Text, chakra, useRadio, useRadioGroup } from "@chakra-ui/react"
import { useField } from "formik"

import PriorityIcon from "components/feedback/PriorityIcon"

export default function PriorityControl() {
  const [, , { setValue }] = useField("priority")
  const { getRootProps, getRadioProps } = useRadioGroup({ name: "priority_radio", onChange: setValue })

  const priorities = ["MEDIUM", "HIGH", "CRITICAL"]

  function Priority({ children, priority, ...props }) {
    const { getInputProps, getCheckboxProps } = useRadio(props)

    const input = getInputProps()
    const checkbox = getCheckboxProps()

    return (
      <Box as="label">
        <input {...input} />
        <Flex
          {...checkbox}
          cursor="pointer"
          color="gray.500"
          bgColor="gray.100"
          px={2}
          py={1}
          rounded="md"
          fontSize="sm"
          textTransform="capitalize"
          justifyContent="center"
          alignItems="center"
          _focus={{ boxShadow: "outline" }}
          _checked={{ color: "brand.100", bgColor: "brand.700", fontWeight: "semibold" }}
        >
          <chakra.span me={2}>
            <PriorityIcon priority={priority} isChecked={input.checked} />
          </chakra.span>
          {children}
        </Flex>
      </Box>
    )
  }

  return (
    <Flex my={4} flexDirection="column">
      <Text fontSize="sm" fontWeight="semibold">
        How important it is?
      </Text>
      <HStack {...getRootProps()} spacing={3}>
        {priorities.map((value, index) => {
          const radio = getRadioProps({ value })
          return (
            <Priority key={index} priority={priorities.indexOf(value)} {...radio}>
              {value.toLowerCase()}
            </Priority>
          )
        })}
      </HStack>
    </Flex>
  )
}
