import { Flex, Text } from "@chakra-ui/react"
import { memo } from "react"

const colors: { [key: string]: string } = {
  development: "orange",
  staging: "red",
}

function EnvironmentIndicator() {
  const environment = process.env.REACT_APP_ENVIRONMENT as string
  const color = colors[environment]
  const isHidden = !color

  if (isHidden) {
    return null
  }

  return (
    <Flex
      px={3}
      py={1}
      color={`${color}.500`}
      bgColor={`${color}.100`}
      borderColor={`${color}.500`}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      borderWidth="0 1px 1px 1px"
      roundedBottom="md"
      shadow="md"
      position="fixed"
      top={0}
      left="15rem"
      zIndex={9999}
    >
      <Text fontSize="2xs" fontWeight="semibold" textTransform="uppercase">
        {environment.substring(0, 3)}
      </Text>
    </Flex>
  )
}

export default memo(EnvironmentIndicator)
