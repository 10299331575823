import { Flex, Spinner } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { memo } from "react"
import { Handle, Position } from "react-flow-renderer"

import Collection from "components/openeo-editor/collection/Collection"
import handleStyle from "components/openeo-editor/lib/handleStyle"
import { collectionDataType } from "components/openeo-editor/lib/propTypes"
import useCollections from "components/openeo-editor/lib/useCollections"
import useConnectionValidator from "components/openeo-editor/lib/useConnectionValidator"
import NodeRemoveButton from "components/openeo-editor/node-types/NodeRemoveButton"
import Parameters from "components/openeo-editor/parameters/Parameters"

function CollectionNode(props) {
  const { data, selected } = props
  const { isLoading, getCollectionById } = useCollections()
  const { isValidConnection } = useConnectionValidator()

  const collectionData = getCollectionById(data.values.id)

  if (isLoading) {
    return <CollectionNodeLoadingSpinner />
  }

  return (
    <Flex
      w={96}
      bg={`blue.${selected ? 100 : 50}`}
      borderColor={`blue.${selected ? 600 : 100}`}
      borderWidth={1}
      fontSize="xs"
      p={1}
      shadow="sm"
      rounded="md"
      flexDirection="column"
      _hover={{ shadow: "md" }}
      role="group"
    >
      <NodeRemoveButton data={data} />
      <Handle type="source" position={Position.Right} style={handleStyle} isValidConnection={isValidConnection} />
      <Collection label={data.label} collection={collectionData} />
      <Parameters data={data} />
    </Flex>
  )
}

CollectionNode.propTypes = {
  data: collectionDataType.isRequired,
  selected: PropTypes.bool,
}

function CollectionNodeLoadingSpinner() {
  return (
    <Flex
      w={96}
      bg="blue.50"
      borderColor="blue.100"
      borderWidth={1}
      shadow="sm"
      rounded="md"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner />
    </Flex>
  )
}

export default memo(CollectionNode)
