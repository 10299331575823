import { CheckIcon } from "@chakra-ui/icons"
import { Flex, Image, RadioProps, Text, useRadio } from "@chakra-ui/react"

import { OrbTemplate } from "services/types"

function TemplateCard(props: TemplateCardProps) {
  const {
    template: { name, description, thumbnail },
  } = props

  const { getInputProps, getCheckboxProps } = useRadio(props)
  const input: any = getInputProps()
  const checkbox = getCheckboxProps()

  const { checked } = input

  return (
    <Flex as="label" position="relative" w="full" h="100px">
      <input {...input} />
      <Flex
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        rounded="md"
        shadow="sm"
        color="gray.400"
        fontSize="sm"
        _checked={{
          bg: "gray.50",
          color: "brand.700",
          borderColor: "brand.700",
          shadow: "md",
        }}
        _focusVisible={{ shadow: "outline" }}
        _focus={{ shadow: "none" }}
        w="full"
        h="full"
      >
        {checked && <CheckedIndicator />}
        <Flex w="35%" flexShrink={0} me={2} roundedLeft="md">
          <Image w="full" h="full" src={thumbnail} roundedLeft="md" fit="cover" />
        </Flex>
        <Flex flexDirection="column" overflowWrap="break-word" p={2}>
          <Text fontSize="sm" fontWeight="bold">
            {name}
          </Text>
          <Text fontSize="xs" my={1}>
            {description}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

function CheckedIndicator() {
  return (
    <Flex
      position="absolute"
      top={-2}
      right={-2}
      boxSize={6}
      color="white"
      bgColor="primary.900"
      shadow="base"
      rounded="full"
      justifyContent="center"
      alignItems="center"
    >
      <CheckIcon />
    </Flex>
  )
}

interface TemplateCardProps extends RadioProps {
  template: OrbTemplate
}

export default TemplateCard
