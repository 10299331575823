import PropTypes from "prop-types"

function Logo(props) {
  const { dotColor = "#000000", textColor = "#02061e", variant = "small" } = props
  const isLarge = variant !== "small"
  const viewBox = isLarge ? "0 0 396 125" : "0 0 100 125"
  const width = isLarge ? "70" : "25"

  return (
    <svg width={width} height="25" viewBox={viewBox} xmlns="http://www.w3.org/2000/svg">
      <path
        id="dot"
        d="M12.667,2.367c-6.997,-0 -12.667,5.719 -12.667,12.777c0,7.058 5.67,12.778 12.667,12.778c6.997,-0 12.667,-5.72 12.667,-12.778c0,-7.058 -5.67,-12.777 -12.667,-12.777Z"
        fill={dotColor}
      />
      <path
        id="o"
        d="M103.808,65l-0,0.282c-0,21.386 -17.066,38.756 -40.068,38.756c-22.722,-0 -39.789,-16.948 -39.789,-38.474l0,-0.281c0,-21.386 17.067,-38.756 40.068,-38.756c22.723,0 39.789,16.948 39.789,38.473Zm-20.641,0.283c-0,-10.975 -7.849,-20.555 -19.413,-20.555c-11.983,0 -19.133,9.172 -19.133,20.272l-0,0.283c-0,10.974 7.849,20.553 19.412,20.553c11.983,0 19.134,-9.171 19.134,-20.272l-0,-0.281Z"
        fill={textColor}
      />
      {isLarge && (
        <>
          <path
            id="r"
            d="M132.34,42.911l0,-14.989l-20.935,-0l0,74.439l20.935,0l0,-27.499c0,-17.919 8.533,-26.245 22.443,-26.245l1.104,-0l-0,-22.09c-12.528,-0.549 -19.287,6.255 -23.547,16.384Z"
            fill={textColor}
          />
          <path
            id="b"
            d="M239.695,65l0,0.282c0,24.865 -16.116,38.474 -33.587,38.474c-11.159,-0 -18.044,-5.142 -22.988,-11.116l0,9.721l-20.935,0l0,-101.389l20.935,0l0,37.642c5.097,-7.086 11.982,-12.087 22.988,-12.087c17.192,0 33.587,13.609 33.587,38.473Zm-20.934,0c-0,-12.354 -8.254,-20.553 -18.03,-20.553c-9.776,-0 -17.904,8.199 -17.904,20.553l-0,0.282c-0,12.355 8.128,20.554 17.904,20.554c9.776,0 18.03,-8.058 18.03,-20.554l-0,-0.282Z"
            fill={textColor}
          />
          <rect id="i" x="249.513" y="27.922" width="20.935" height="74.439" fill={textColor} />
          <path
            id="fy"
            d="M395.833,27.922l-28.364,76.242c-5.517,15.145 -11.843,20.836 -24.231,20.836c-7.709,0 -13.212,-1.944 -18.588,-5.142l7.024,-15.271c2.752,1.662 6.201,2.916 8.952,2.916c3.715,-0 5.503,-1.113 7.304,-4.86l-22.247,-57.506l-15.795,0l-0,57.224l-20.935,0l-0,-57.224l-8.673,0l0,-17.356l8.673,-0l-0,-3.888c-0,-8.058 1.927,-14.032 5.922,-18.061c3.854,-3.888 9.496,-5.832 16.926,-5.832c6.606,-0 11.019,0.831 14.874,2.085l-0,17.497c-3.171,-1.113 -5.782,-1.803 -9.497,-1.803c-5.098,-0 -7.57,2.634 -7.57,8.058l0,2.085l31.577,-0l16.801,50.687l16.102,-50.687l21.745,-0Z"
            fill={textColor}
          />
        </>
      )}
    </svg>
  )
}

Logo.propTypes = {
  dotColor: PropTypes.string,
  textColor: PropTypes.string,
  variant: PropTypes.oneOf(["small", "large"]),
}

export default Logo
