import { useQuery } from "react-query"

import useOrbUsersQueries from "./queryFunctions/useOrbUsersQueries"

export default function useGetOrbRoles() {
  const { listRolesQuery } = useOrbUsersQueries()
  const { queryKey, queryFn } = listRolesQuery()

  const { isLoading, isError, data: roles } = useQuery(queryKey, queryFn)

  return { isLoading, isError, roles }
}
