import { Badge, Flex, Text } from "@chakra-ui/react"
import { useMemo } from "react"
import { remark } from "remark"
import stripMarkdown from "strip-markdown"

import CollectionImage from "components/openeo-editor/collection/CollectionImage"
import CollectionMetadata from "components/openeo-editor/collection/CollectionMetadata"
import OpenEOEditorDrawerItem from "components/openeo-editor/drawer/OpenEOEditorDrawerItem"
import { getLinkHref } from "components/openeo-editor/lib/nodeUtils"
import { useMetadataModal } from "components/openeo-editor/metadata/MetadataModalContext"
import { BaseCollectionType } from "components/openeo-editor/types"

export default function CollectionListEntry(props: CollectionListEntryProps) {
  const { collection } = props
  const { onOpen, onClose, setContent } = useMetadataModal() as any
  const description = String(remark().use(stripMarkdown).processSync(collection.description)).replaceAll("\\", "")

  function getFlowType() {
    switch (collection["gee:type"]) {
      case "table":
      case "table_collection":
        return "featureCollection"
      case "image":
        return "image"
      case "image_collection":
        return "collection"
      default:
        return "collection"
    }
  }

  function handleClick() {
    setContent(
      <CollectionMetadata
        collectionTitle={collection["title"]}
        collectionId={collection["db:id"]}
        onModalClose={onClose}
      />
    )
    onOpen()
  }

  return (
    <OpenEOEditorDrawerItem
      data-flow-id={collection.id}
      data-flow-type={getFlowType()}
      onClick={handleClick}
      flexDirection="row"
    >
      <Flex me={3} position="relative" justifyContent="center" alignItems="center">
        <CollectionImage boxSize="85px" collection={collection} />
        <CollectionBadges collection={collection} />
      </Flex>
      <Flex flexDirection="column" w="full" mr={2} overflow="auto">
        <Text mb={1} fontWeight="semibold" fontSize="xs" color="primary.900">
          {collection.title}
        </Text>
        <Text fontSize="2xs" color="gray.400" noOfLines={2}>
          {description}
        </Text>
      </Flex>
    </OpenEOEditorDrawerItem>
  )
}

function CollectionBadges(props: CollectionListEntryProps) {
  const { collection } = props
  const isDeprecated = collection.title.includes("deprecated")
  const isCommunity = useMemo(
    () => getLinkHref(collection.links, "root") === process.env.REACT_APP_STAC_GEE_COMMUNITY_CATALOG_ROOT_URL,
    [collection]
  )

  if (!isDeprecated && !isCommunity) {
    return null
  }

  return (
    <Flex w="full" justifyContent="center" alignItems="center" position="absolute" bottom={1} left={0}>
      {isDeprecated && (
        <Badge fontSize="0.475rem" bgColor="red.200" color="red.500" borderWidth={1} borderColor="red.500">
          deprecated
        </Badge>
      )}
      {isCommunity && (
        <Badge fontSize="0.475rem" bgColor="green.200" color="green.600" borderWidth={1} borderColor="green.600">
          community
        </Badge>
      )}
    </Flex>
  )
}

type CollectionListEntryProps = {
  collection: BaseCollectionType
}
