import PropTypes from "prop-types"
import { useMemo } from "react"
import { useQuery } from "react-query"

import { getBandsInfo, getProperties } from "components/openeo-editor/lib/collectionUtils"
import useOpeneoApi from "services/api/openeoApi"

function useCollectionDetails(collectionId) {
  const { getCollectionDetails } = useOpeneoApi()
  const { isLoading, data: collection } = useQuery(
    ["collectionDetails", collectionId],
    () => getCollectionDetails(collectionId),
    { enabled: !!collectionId }
  )

  const bands = useMemo(() => {
    if (!collection) {
      return null
    }

    return getBandsInfo(collection)
  }, [collection])

  const properties = useMemo(() => {
    if (!collection) {
      return null
    }

    return getProperties(collection)
  }, [collection])

  return {
    isLoading,
    collection,
    bands,
    properties,
  }
}

useCollectionDetails.propTypes = {
  collectionId: PropTypes.number,
}

export default useCollectionDetails
