import { IconButton, Tooltip } from "@chakra-ui/react"
import { BiCrosshair } from "react-icons/bi"

import useFlowUtils from "components/openeo-editor/lib/useFlowUtils"

export default function ShowProblematicElementAction(props: Props) {
  const { nodeId } = props
  const { focusNode } = useFlowUtils()

  function handleClick() {
    focusNode(nodeId)
  }

  if (!nodeId) {
    return null
  }

  return (
    <Tooltip label="Show element" placement="left-start">
      <IconButton
        size="xs"
        variant="solid"
        colorScheme="red"
        icon={<BiCrosshair />}
        onClick={handleClick}
        aria-label="Show problematic element"
      />
    </Tooltip>
  )
}

type Props = {
  nodeId?: string | null
}
