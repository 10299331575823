import { useMutation } from "react-query"

import useWorkflowQueries from "./queryFunctions/useWorkflowQueries"

export default function useCreateWorkflow() {
  const { createWorkflowMutation } = useWorkflowQueries()
  const { mutationFn, mutationOptions } = createWorkflowMutation()

  const { isLoading, mutateAsync: createWorkflow } = useMutation(mutationFn, mutationOptions)

  return { isLoading, createWorkflow }
}
