import PropTypes from "prop-types"
import { createContext, useContext } from "react"

import { collectionType, processType } from "components/openeo-editor/lib/propTypes"
import useCollections from "components/openeo-editor/lib/useCollections"
import { childrenType } from "lib/CustomPropTypes"

const ParameterEntryContext = createContext(null)

function ParameterEntryContextProvider(props) {
  const { data, children } = props

  const isCollection = ["load_collection", "load_feature_collection"].includes(data.values.process_id)
  const { isLoading, getCollectionById } = useCollections()

  const collection = isCollection && getCollectionById(data.values.id)

  return <ParameterEntryContext.Provider value={{ isLoading, collection }}>{children}</ParameterEntryContext.Provider>
}

ParameterEntryContextProvider.propTypes = {
  data: PropTypes.oneOfType([processType, collectionType]).isRequired,
  children: childrenType,
}

function useParameterEntryContext() {
  return useContext(ParameterEntryContext)
}

export { ParameterEntryContextProvider as default, useParameterEntryContext }
