import type { AlertStatus } from "@chakra-ui/alert"
import { useToast } from "@chakra-ui/react"
import { ReactNode, createContext, useContext } from "react"

import { defaultErrorTitle, defaultToastProps } from "services/displayServerError"
import { Error } from "services/types"

const NotificationContext = createContext<NotificationContextInterface | null>(null)

function NotificationContextProvider(props: NotificationContextProviderProps): JSX.Element {
  const { children } = props
  const toast = useToast()

  function showErrorNotification(error: Error, title: string = defaultErrorTitle, description?: string | undefined) {
    const { name, response, message } = error

    const errorId = name + (response?.status ?? "")
    showNotification(title, description || message, "error", errorId)
  }

  function showNotification(title: string, description: string = "", status: AlertStatus, id?: string) {
    return toast({
      ...defaultToastProps,
      title,
      description,
      status,
      id,
    })
  }

  return (
    <NotificationContext.Provider value={{ showNotification, showErrorNotification }}>
      {children}
    </NotificationContext.Provider>
  )
}

function useNotifications() {
  const context = useContext(NotificationContext)

  if (!context) throw new Error("Notification context was not initialized. Maybe component was not wrapped in provider")

  return context
}

type NotificationContextProviderProps = {
  children: ReactNode
}

interface NotificationContextInterface {
  showErrorNotification: (error: Error, title: string, description?: string) => void
  showNotification: (
    title: string,
    description: string,
    status: AlertStatus,
    id?: string
  ) => string | number | undefined // Toast returns "string"|"number"|"undefined"
}

export { useNotifications, NotificationContextProvider as default }
