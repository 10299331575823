import { createStandaloneToast } from "@chakra-ui/react"
import type { ToastPosition } from "@chakra-ui/toast"

import rollbar from "./rollbar"
import { Error as ErrorType } from "./types"

type DefaultToastPropsTypes = {
  position: ToastPosition
  duration: number
  isClosable: boolean
}

const defaultToastProps: DefaultToastPropsTypes = {
  position: "top-right",
  duration: 5000,
  isClosable: true,
}

const defaultErrorTitle = "An error occurred."

const displayServerError = (error: string | ErrorType) => {
  const toast = createStandaloneToast()
  rollbar.error(error)

  let message: string
  let id: string
  if (typeof error === "string") {
    message = error
    id = error
  } else {
    const { name, response } = error
    if (response?.status === 406) {
      window.location.replace("/business_email_required")
      return null
    }

    if (response?.status && response?.status < 500) {
      return null
    }

    message = error.message as string
    if (response?.status && name) {
      id = name + response.status
    }
  }

  setTimeout(() => {
    if (!toast.isActive(id)) {
      return toast({
        ...defaultToastProps,
        id,
        title: defaultErrorTitle,
        description: message,
        status: "error",
      })
    }
  }, 500)
}

export { displayServerError as default, defaultToastProps, defaultErrorTitle }
