import { DailyPlatformUsageData, DetailedPricingPlan, FeatureKey, UserSubscription } from "api/models/Billing"
import buildUrl from "api/utils/buildUrl"
import useAuthedApiWhenLoggedIn from "api/utils/useAuthedApiWhenLoggedIn"
import ky from "ky"

import { useNotifications } from "components/common/notifications"
import { Query } from "lib/types/Query"

export default function useBillingQueries() {
  const { showErrorNotification } = useNotifications()

  const publicApi = ky.extend({ prefixUrl: buildUrl("/api/v1/public") })
  const internalApi = useAuthedApiWhenLoggedIn({ baseUrl: buildUrl("/api/v1/internal") })

  return {
    getPricingTableQuery,
    getAllowedBillingFeaturesQuery,
    getUsageQuery,
    getUserSubscriptionQuery,
    createCheckoutSessionMutation,
  }

  function getPricingTableQuery(): Query<DetailedPricingPlan[]> {
    return {
      queryKey: ["getPricingTableQuery"],
      queryFn: () => publicApi.get(`pricing/platform`).json(),
      options: {
        onError: (error: any) => {
          showErrorNotification(error, "Failed to fetch Pricing Table", error.message)
        },
      },
    }
  }

  function getAllowedBillingFeaturesQuery(): Query<FeatureKey[]> {
    return {
      queryKey: ["getAllowedBillingFeaturesQuery"],
      queryFn: () => internalApi.get(`account/billing/allowed_features`).json(),
      options: {
        staleTime: 6000,
        onError: (error: any) => {
          // Don't show notification until backend is deployed
          // showErrorNotification(error, "Failed to fetch Allowed Billing Features", error.message)
        },
      },
    }
  }

  function getUsageQuery(): Query<DailyPlatformUsageData[]> {
    return {
      queryKey: ["getUsageQuery"],
      queryFn: function getPlatformUsage() {
        return internalApi.get("account/billing/usage-overview").json()
      },
      options: {
        onError: (error: any) => {
          showErrorNotification(error, "Failed to fetch platform usage data", error.message)
        },
      },
    }
  }

  function getUserSubscriptionQuery(): Query<UserSubscription> {
    return {
      queryKey: ["getUserSubscriptionQuery"],
      queryFn: function getUserSubscription() {
        return internalApi.get("account/billing/subscription").json()
      },
    }
  }

  function createCheckoutSessionMutation() {
    return {
      mutationFn,
      mutationOptions: {},
    }

    function mutationFn(request: { pricing_plan_id: number }): Promise<any> {
      return internalApi.post(`account/stripe/create-checkout-session`, { json: request }).json()
    }
  }
}
