import { useQuery } from "react-query"

import useWorkflowQueries from "./queryFunctions/useWorkflowQueries"

export default function useGetAllOrderExecutions(pagination: any) {
  const { listAllOrdersExecutionsQuery } = useWorkflowQueries()
  const { queryKey, queryFn } = listAllOrdersExecutionsQuery(pagination)

  const { data, isLoading, isError } = useQuery(queryKey, queryFn)

  return {
    isLoading,
    isError,
    orders: data,
  }
}
