import { Feature, union } from "@turf/turf"
import { Feature as OLFeature } from "ol"
import { GeoJSON } from "ol/format"
import { get } from "ol/proj"

import { SupportedGeometry } from "../../../api/models/AreaOfInterest"

export const WEB_MERCATOR_PROJECTION = "EPSG:3857"

export function getProjection() {
  const proj = get(WEB_MERCATOR_PROJECTION)

  if (!proj) throw new Error("Unable to get projection")

  return proj
}

export function convertPolygonFeatureToGeoJson(feature: OLFeature): Feature<SupportedGeometry> {
  const converter = new GeoJSON()

  const convertedFeature = converter.writeFeatureObject(feature, {
    featureProjection: getProjection(),
  })

  const geometryType = convertedFeature.geometry.type
  if (geometryType !== "Polygon" && geometryType !== "MultiPolygon")
    throw new Error("GeoJSON conversion works with polygon and multipolygon only")

  const xyCoordinates =
    geometryType === "MultiPolygon"
      ? convertedFeature.geometry.coordinates.map((polygon) => polygon.map(convertCoordinatesFromXYZtoXY))
      : convertedFeature.geometry.coordinates.map(convertCoordinatesFromXYZtoXY)

  return {
    type: convertedFeature.type,
    geometry: {
      ...convertedFeature.geometry,
      coordinates: xyCoordinates,
    } as SupportedGeometry,
    properties: convertedFeature.properties,
  }
}

export function mergeFeatures(features: Feature<SupportedGeometry>[]) {
  return features.reduce((a, b) => union(a, b) || a, features[0])
}

function convertCoordinatesFromXYZtoXY(coordinateSet: number[][]) {
  return coordinateSet.map((coord) => (coord.length > 2 ? coord.slice(0, 2) : coord))
}
