import { Heading } from "@chakra-ui/react"

import { HeaderBaseProps } from "./types"

function Title(props: HeaderBaseProps) {
  const { children, ...otherProps } = props
  return (
    <Heading mb={2} color="brand.600" fontSize="2xl" {...otherProps}>
      {children}
    </Heading>
  )
}

export default Title
