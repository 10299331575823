import { Icon, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Spacer, useDisclosure } from "@chakra-ui/react"
import { AiOutlineUndo } from "react-icons/ai"
import { VscGear, VscGist } from "react-icons/vsc"

import ComputedVariablesModal from "components/openeo-editor/computed-variables/ComputedVariablesModal"
import ImportExportGraphModal from "components/openeo-editor/import-export/ImportExportGraphModal"
import { ResetWorkflowModal } from "components/orb-openeo-workflow/openeo-workflow-toolbox/ResetWorkflowModal"

function OpeneoWorkflowToolboxGraphTools() {
  const importExportModalDisclosure = useDisclosure()
  const computedVariablesModalDisclosure = useDisclosure()

  return (
    <>
      <ImportExportGraphModal
        isOpen={importExportModalDisclosure.isOpen}
        onClose={importExportModalDisclosure.onClose}
      />
      <ComputedVariablesModal
        isOpen={computedVariablesModalDisclosure.isOpen}
        onClose={computedVariablesModalDisclosure.onClose}
      />
      <Menu>
        <MenuButton variant="ghost" size="sm">
          <Icon as={VscGear} boxSize={5} color="gray" />
        </MenuButton>
        <Spacer w={4} />
        <MenuList fontSize="sm" zIndex={5}>
          <MenuItem icon={<VscGist />} onClick={importExportModalDisclosure.onOpen}>
            Import or export graph
          </MenuItem>
          <MenuItem icon={<VscGist />} onClick={computedVariablesModalDisclosure.onOpen}>
            Show computed variables
          </MenuItem>
          <MenuDivider />
          <ResetWorkflowModal>
            {({ onOpen }) => (
              <MenuItem color="red" icon={<AiOutlineUndo />} onClick={onOpen}>
                Reset workflow
              </MenuItem>
            )}
          </ResetWorkflowModal>
        </MenuList>
      </Menu>
    </>
  )
}

export default OpeneoWorkflowToolboxGraphTools
