export const twilight_shifted = [
  "#301437",
  "#491564",
  "#5C369B",
  "#5F61B4",
  "#6989BE",
  "#89ADC5",
  "#BCCBD1",
  "#E2D9E2",
  "#D6C2B6",
  "#CA997C",
  "#BC6B59",
  "#A54350",
  "#802350",
  "#501444",
  "#2F1436",
]
