import { Flex, FlexProps } from "@chakra-ui/react"
import { ReactNode } from "react"

import useHandleItemDrag from "components/openeo-editor/lib/useHandleItemDrag"

export default function OpenEOEditorDrawerItem(props: OpenEOEditorDrawerItemProps) {
  const { children, ...rest } = props
  const handleItemDrag = useHandleItemDrag()

  return (
    <Flex
      className="dndnode"
      w="full"
      p={1}
      rounded="md"
      borderColor="gray.200"
      borderWidth={1}
      fontSize="xs"
      flexDirection="column"
      onDragStart={handleItemDrag}
      draggable={!rest.disabled}
      _hover={{ cursor: rest.disabled ? "default" : "pointer", shadow: rest.disabled ? "default" : "base" }}
      _active={{ borderColor: "gray.400" }}
      {...rest}
    >
      {children}
    </Flex>
  )
}

interface OpenEOEditorDrawerItemProps extends FlexProps {
  children: ReactNode
  disabled?: boolean
}
