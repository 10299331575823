import { SearchIcon } from "@chakra-ui/icons"
import {
  Box,
  ButtonProps,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  VStack,
} from "@chakra-ui/react"
import { Style } from "ol/style"
import { BiSearch } from "react-icons/bi"

import ToolboxButton from "components/common/toolbox-button/ToolboxButton"

import SearchResultInfoBox from "./SearchResultInfoBox"
import SearchResultItem from "./SearchResultItem"
import useSearchController from "./useSearchController"

const searchImputStyle = { color: "black", bgColor: "whiteAlpha.600", _placeholder: { color: "blackAlpha.500" } }

export default function Search(props: SearchProps) {
  const { buttonStyleProps, pointStyle, boundaryStyle } = props

  const searchResultBoxPosition = {
    top: "40px",
    left: 0,
  }

  const {
    isOpen,
    onOpen,
    onClose,
    isLoading,
    isFetched,
    searchQuery,
    searchResults,
    selectedSearchResult,
    handleClearSelectedSearchResult,
    handleSearchResultSelected,
    handleSearchQueryChange,
  } = useSearchController(pointStyle, boundaryStyle)

  return (
    <>
      {/* @ts-ignore */}
      <ToolboxButton me={2} label="Search" onClick={onOpen} icon={BiSearch} {...buttonStyleProps} />

      <Modal
        size="2xl"
        isOpen={isOpen}
        onClose={onClose}
        motionPreset="slideInBottom"
        scrollBehavior="inside"
        isCentered
      >
        <ModalOverlay backdropFilter="blur(3px)" />
        <ModalContent position="absolute" bgColor="transparent" backdropFilter="blur(50px)" shadow="dark-lg">
          <InputGroup size="lg">
            <InputLeftElement children={<SearchIcon />} />
            <Input onChange={handleSearchQueryChange} placeholder="Search on map..." {...searchImputStyle} />
            <InputRightElement children={isLoading ? <Spinner size="sm" thickness="2px" /> : null} />
          </InputGroup>

          {searchQuery && isFetched && (
            <VStack spacing={1} align="stretch" roundedBottom="lg" {...searchImputStyle}>
              {searchResults.map((location: any, index: number) => (
                <SearchResultItem key={index} searchResult={location} onClick={handleSearchResultSelected} />
              ))}
            </VStack>
          )}
        </ModalContent>
      </Modal>

      <Box position="absolute" {...searchResultBoxPosition}>
        <SearchResultInfoBox searchResult={selectedSearchResult} clearSearchResult={handleClearSelectedSearchResult} />
      </Box>
    </>
  )
}

type SearchProps = {
  pointStyle?: Style
  boundaryStyle?: Style
  buttonStyleProps: ButtonProps
}
