import { useBoolean, useDisclosure } from "@chakra-ui/react"
import theme from "@chakra-ui/theme"
import { NominatimSearchResult } from "api/models/Nominatim"
import useGetNominatimSearchResults from "api/useGetNominatimSearchResults"
import BaseLayer from "ol/layer/Base"
import VectorLayer from "ol/layer/Vector"
import { Icon, Stroke, Style } from "ol/style"
import { useState } from "react"

import { useMapContext } from "components/common/open-layers/MapContextProvider"
import { featureToVectorSource, flyToVectorSource } from "lib/utils"

import pin from "./locationPin.png"

const mapSearchLocationBoundaryStyle = new Style({
  stroke: new Stroke({
    color: theme.colors.red["500"],
    width: 2,
  }),
})

const mapSearchLocationPointStyle = new Style({
  image: new Icon({
    scale: 0.5,
    src: pin,
  }),
})

export default function useSearchController(
  pointStyle: Style = mapSearchLocationPointStyle,
  boundaryStyle: Style = mapSearchLocationBoundaryStyle
) {
  const { map } = useMapContext()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [searchQuery, setSearchQuery] = useState("")
  const [isTyping, setIsTyping] = useBoolean(false)
  const [typingTimeout, setTypingTimeout] = useState<any>(0)
  const [selectedSearchResult, setSelectedSearchResult] = useState<NominatimSearchResult | null>(null)
  const [searchResultLayer, setSearchResultLayer] = useState<BaseLayer | null>(null)

  const { isLoading, isFetched, isError, searchResults } = useGetNominatimSearchResults(searchQuery)

  function handleSearchResultSelected(result: NominatimSearchResult) {
    if (!result.geojson || !map) {
      return
    }

    const geojson = result.geojson

    const isBoundary = geojson.type !== "Point"
    const style = isBoundary ? boundaryStyle : pointStyle

    const source = featureToVectorSource({
      type: "Feature",
      geometry: geojson,
      properties: null,
    })

    const layer = new VectorLayer({
      className: "ol-location-layer",
      source,
      style,
    })

    if (searchResultLayer) {
      map.removeLayer(searchResultLayer)
    }

    map.addLayer(layer)
    flyToVectorSource(map, source, () => setSearchResultLayer(layer))

    setSelectedSearchResult({ ...result, geojson })
    setSearchQuery("")
    onClose()
  }

  function handleSearchQueryChange(event: any) {
    setIsTyping.on()
    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }

    setTypingTimeout(
      setTimeout(function () {
        setSearchQuery(event.target.value)
        setIsTyping.off()
      }, 1200)
    )
  }

  function handleClearSelectedSearchResult() {
    searchResultLayer && map && map.removeLayer(searchResultLayer)
    setSelectedSearchResult(null)
  }

  return {
    isOpen,
    onOpen,
    onClose,
    isLoading: isLoading || isTyping,
    isFetched,
    isError,
    searchQuery,
    searchResults,
    selectedSearchResult,
    handleClearSelectedSearchResult,
    handleSearchResultSelected,
    handleSearchQueryChange,
  }
}
