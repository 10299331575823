export const cool = [
  "#00FFFF",
  "#12EDFF",
  "#24DBFF",
  "#37C8FF",
  "#49B6FF",
  "#5BA4FF",
  "#6D92FF",
  "#807FFF",
  "#926DFF",
  "#A45BFF",
  "#B649FF",
  "#C837FF",
  "#DB24FF",
  "#ED12FF",
  "#FF00FF",
]
