import { Textarea } from "@chakra-ui/react"
import { useField } from "formik"

import { parameterType } from "components/openeo-editor/lib/propTypes"

function ProcessGraphType(props) {
  const { parameter } = props
  const [field] = useField(parameter.name)

  return <Textarea {...field} fontSize="xs" fontFamily="mono" rows={20} color="orange.700" bgColor="gray.50" />
}

ProcessGraphType.propTypes = {
  parameter: parameterType.isRequired,
}

export default ProcessGraphType
