import colormap from "colormap"

const getViewFromSource = async (source) => console.log(await source.getView().then((response) => response)) // TODO delete. Only used to get center for examples

const singleBandStyle = (band = 1) => {
  return {
    color: [
      "case",
      [">=", ["band", band], 0.2],
      [161, 0, 2],
      [">=", ["band", band], 0.16],
      [255, 0, 0],
      [">=", ["band", band], 0.15],
      [202, 98, 1],
      [">=", ["band", band], 0.14],
      [223, 224, 58],
      [">=", ["band", band], 0.13],
      [98, 192, 59],
      [">=", ["band", band], 0.12],
      [19, 161, 196],
      [">=", ["band", band], 0.1],
      [22, 97, 129],
      [0, 0, 0, 0.0],
    ],
  }
}

const normalize = (value, index = 10000) => {
  return ["/", value, index]
}

const red = normalize(["band", 1])
// const green = normalize(["band", 2])
// const blue = normalize(["band", 3])
const nir = normalize(["band", 4])

const difference = ["-", nir, red]
const sum = ["+", nir, red]

const getColorStops = (name, min, max, steps, reverse) => {
  const delta = (max - min) / (steps - 1)
  let stops = new Array(steps * 2)
  const colors = colormap({ colormap: name, nshades: steps, format: "rgba" })
  if (reverse) {
    colors.reverse()
  }
  for (let i = 0; i < steps; i++) {
    stops[i * 2] = min + i * delta
    stops[i * 2 + 1] = colors[i]
  }
  return stops
}

const ndvi = {
  color: [
    "interpolate",
    ["linear"],
    ["/", difference, sum],
    // color ramp for NDVI values
    ...getColorStops("earth", -0.5, 1, 10, true),
  ],
}

const geoTIFFStyles = {
  ndvi: () => ndvi,
  singleBand: (band) => singleBandStyle(band),
}

export { getViewFromSource, geoTIFFStyles as default }
