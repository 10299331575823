import { Button, Flex, chakra } from "@chakra-ui/react"
import { OrbConfig } from "api/models/OrbConfig"
import { Formik } from "formik"
import { VscNotebookTemplate } from "react-icons/vsc"

import Section from "components/layout/section/Section"
import SectionHeader from "components/layout/section/SectionHeader"
import { ORBIFY_ADMIN_PERMISSION, useUserPermissions } from "services/userPermissions"

import { CustomTemplateInfo } from "./template/CustomTemplateInfo"
import TemplateSelectConfirmModal from "./template/TemplateSelectConfirmModal"
import TemplateSelector from "./template/TemplateSelector"
import useTemplateSettingsSectionController from "./template/templateSettingsSectionController"

export default function TemplateSettingsSection(props: TemplateSettingsSectionProps) {
  const { orbConfig } = props
  const { initialValues, handleSubmit, handleCancel, handleConfirm, confirmModalDisclosure, cancelRef } =
    useTemplateSettingsSectionController(orbConfig)
  const { isLoading: isPermissionLoading, permissions } = useUserPermissions()

  const isAdminUser = !isPermissionLoading && permissions?.includes(ORBIFY_ADMIN_PERMISSION)

  return (
    <Section
      header={
        <SectionHeader
          size="sm"
          title="Template"
          description="Select template to be used by your application"
          icon={VscNotebookTemplate}
        >
          <Button
            as={"a"}
            w="full"
            size="sm"
            href={`https://${orbConfig?.url}.${process.env.REACT_APP_BASE_DOMAIN}/?edit=true`}
            target="_blank"
          >
            Edit template
          </Button>
        </SectionHeader>
      }
    >
      {isAdminUser ? (
        <Flex my={6} flexDirection="column">
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ handleSubmit }) => (
              <chakra.form onSubmit={handleSubmit} mb={4}>
                <TemplateSelector ref={cancelRef} />
                <TemplateSelectConfirmModal
                  handleCancel={handleCancel}
                  handleConfirm={handleConfirm}
                  confirmModalDisclosure={confirmModalDisclosure}
                />
              </chakra.form>
            )}
          </Formik>
        </Flex>
      ) : (
        <Flex my={6} flexDirection="column" alignItems="center">
          <CustomTemplateInfo orb={orbConfig} />
        </Flex>
      )}
    </Section>
  )
}

type TemplateSettingsSectionProps = {
  orbConfig: OrbConfig
}
