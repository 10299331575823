import { getColor } from "@chakra-ui/theme-tools"
import theme from "theme"

export default function PriorityIcon({ priority = 0, isChecked = false }) {
  const activeColor = isChecked ? getColor(theme, "brand.100") : getColor(theme, "brand.100")
  const inactiveColor = isChecked ? getColor(theme, "brand.200") : getColor(theme, "gray.300")

  return (
    <svg width="10" height="10" viewBox="0 0 13 12">
      <path
        d="M0 7C0 6.44772 0.447715 6 1 6H2C2.55228 6 3 6.44772 3 7V11C3 11.5523 2.55228 12 2 12H1C0.447715 12 0 11.5523 0 11V7Z"
        fill={priority >= 0 ? activeColor : inactiveColor}
      />
      <path
        d="M5 4C5 3.44772 5.44772 3 6 3H7C7.55228 3 8 3.44772 8 4V11C8 11.5523 7.55228 12 7 12H6C5.44772 12 5 11.5523 5 11V4Z"
        fill={priority >= 1 ? activeColor : inactiveColor}
      />
      <path
        d="M10 1C10 0.447715 10.4477 0 11 0H12C12.5523 0 13 0.447715 13 1V11C13 11.5523 12.5523 12 12 12H11C10.4477 12 10 11.5523 10 11V1Z"
        fill={priority >= 2 ? activeColor : inactiveColor}
      />
    </svg>
  )
}
