import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import PropTypes from "prop-types"

import Markdown from "components/markdown/Markdown"

export default function PropertiesTable(props) {
  const { properties } = props

  return (
    <Table size="xs" variant="simple">
      <Thead fontSize="xs">
        <Tr>
          <Th>Name</Th>
          <Th>Description</Th>
          <Th>Type</Th>
        </Tr>
      </Thead>
      <Tbody fontSize="sm">
        {properties.map(({ name, description, type }, index) => (
          <Tr key={index}>
            <Td color="brand.700" fontWeight="semibold" fontFamily="monospace">
              {name}
            </Td>
            <Td px={1}>
              <Markdown>{description}</Markdown>
            </Td>
            <Td px={1} fontFamily="monospace" textTransform="uppercase">
              {type}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

PropertiesTable.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ).isRequired,
}
