import { OrbConfig } from "api/models/OrbConfig"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import useGetEmbed from "state/useGetEmbed"

export function RedirectHandler({ orbs }: Props) {
  const history = useHistory()
  const isEmbed = useGetEmbed()

  useEffect(
    () => {
      if (isEmbed) {
        return
      }

      if (orbs.length === 1 && orbs[0].template === "forest") {
        // Redirect straight to GDP if its the only available application
        const href = `https://${orbs[0].url}.${process.env.REACT_APP_BASE_DOMAIN}/`
        window.location.href = href
      }

      const hasApplicationsGDP = orbs.some((orb) => orb.template === "forest")
      if (orbs.length && !hasApplicationsGDP) {
        // Redirect to /orbs if user does not have any GDP applications
        history.replace("/orbs")
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orbs]
  )

  return null
}

interface Props {
  orbs: OrbConfig[]
}
