import { Alert, AlertDescription, AlertIcon, Button, Flex } from "@chakra-ui/react"
import { IoIosGitNetwork } from "react-icons/io"
import { useHistory, useRouteMatch } from "react-router-dom"

import Section from "components/layout/section/Section"
import SectionHeader from "components/layout/section/SectionHeader"

export default function WorkflowSection() {
  const match = useRouteMatch()
  const history = useHistory()

  function handleClick() {
    history.replace(`${match.url}/workflow`)
  }

  return (
    <Section
      header={
        <SectionHeader
          size="sm"
          title="Workflow"
          description="Configure your application's workflow"
          icon={IoIosGitNetwork}
        >
          <Button w="full" size="sm" onClick={handleClick}>
            Configure workflow
          </Button>
        </SectionHeader>
      }
    >
      <Flex w="full" my={6} justifyContent="center">
        <Alert w="60%" fontSize="sm" p={2} rounded="md" mb={4}>
          <AlertIcon />
          <AlertDescription>
            Application workflow defines how the final products (e.g. processed Earth Observation data) are built. Use
            the Orbify workflow builder to create a processing chain for geospatial data.
          </AlertDescription>
        </Alert>
      </Flex>
    </Section>
  )
}
