export const Greens = [
  "#F7FCF5",
  "#EDF8E9",
  "#E1F3DC",
  "#CFECC9",
  "#BCE4B5",
  "#A7DBA0",
  "#8ED08B",
  "#73C476",
  "#56B567",
  "#3DA65A",
  "#2C944C",
  "#19833E",
  "#05712F",
  "#005B25",
  "#00441B",
]
