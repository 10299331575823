import * as yup from "yup"
import { useDisclosure } from "@chakra-ui/react"
import { OrganisationCreateRequest } from "api/models/Organisation"
import useAdminCreateOrganisation from "api/useAdminCreateOrganisation"
import { FormikValues } from "formik"

import { useNotifications } from "components/common/notifications"

export const initialValues = { name: "" }

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^.{3,63}$/, "Name must between 3 - 63 characters long")
    .required("Organisation's name is required"),
})

export default function useAdminOrganisationsAddNewController() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { showErrorNotification } = useNotifications()
  const { isLoading, createOrganisation } = useAdminCreateOrganisation()

  function handleSubmit(values: FormikValues) {
    createOrganisation(values as OrganisationCreateRequest)
      .then(onClose)
      .catch((error) => showErrorNotification(error, "Failed to create organisation"))
  }

  return {
    isOpen,
    onOpen,
    onClose,
    isLoading,
    handleSubmit,
  }
}
