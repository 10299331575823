/**
 * Add perspective interaction
 *
 * Adapted 'ol/interaction/DragRotate' to create a custom interaction
 * from 'ol-ext/map/PerspectiveMap'
 */
import { MapBrowserEvent } from "ol"
import { inAndOut } from "ol/easing"
import { Condition, altKeyOnly, mouseActionButton, mouseOnly } from "ol/events/condition"
import { FALSE } from "ol/functions"
import { Pointer as PointerInteraction } from "ol/interaction"

import { DragPerspectiveEventType, Options } from "./types"

/**
 * @typedef {Object} Options
 * @property {Condition} [condition] A function that takes an
 * {@link module:ol/MapBrowserEvent~MapBrowserEvent} and returns a boolean
 * to indicate whether that event should be handled.
 * Default is {@link module:ol/events/condition.altKeyOnly}.
 * @property {number} [duration=250] Animation duration in milliseconds.
 * @property {number} [angle=0] The perspective angle 0 (vertical) - 30 (max).
 * @property {function} [options.easing=inAndOut] The easing function used during the animation.
 */

/**
 * @classdesc
 * Allows the user to tilt the map by clicking and dragging on the map,
 * normally combined with an {@link module:ol/events/condition} that limits
 * it to when the alt key is held down.
 *
 * This interaction is only supported for mouse devices.
 * @api
 */
class DragPerspective extends PointerInteraction {
  private _angle?: number
  private _condition?: Condition
  private _dragging?: number
  private _duration?: number
  private _animatedPerspective?: number
  /**
   * @param {Options} [options] Options.
   */
  constructor(options?: Options) {
    options = options ? options : {}

    super({
      stopDown: FALSE,
    })

    /**
     * @private
     * @type {Condition}
     */
    this._condition = options.condition ? options.condition : altKeyOnly

    /**
     * @private
     * @type {number|undefined}
     */
    this._dragging = undefined

    /**
     * @private
     * @type {number|undefined}
     */
    this._angle = undefined

    /**
     * @private
     * @type {number|undefined}
     */
    this._duration = options.duration !== undefined ? options.duration : 250
  }

  /**
   * Handle pointer drag events.
   * @param {import("ol/MapBrowserEvent.js").default} mapBrowserEvent Event.
   */
  handleDragEvent(mapBrowserEvent: MapBrowserEvent<any>) {
    if (!mouseOnly(mapBrowserEvent)) {
      return
    }
    if (!!this._dragging || this._dragging === undefined) {
      const angle = this._dragging && mapBrowserEvent.originalEvent.offsetY > this._dragging ? 0.5 : -0.5
      if (angle) {
        this.setPerspective((this._angle || 0) + angle, { duration: 0 })
      }
      this._dragging = mapBrowserEvent.originalEvent.offsetY
    }
  }

  /**
   * Handle pointer up events.
   * @param {import("ol/MapBrowserEvent.js").default} mapBrowserEvent Event.
   * @return {boolean} If the event was consumed.
   */
  handleUpEvent(mapBrowserEvent: MapBrowserEvent<any>) {
    if (!mouseOnly(mapBrowserEvent)) {
      return true
    }

    const map = mapBrowserEvent.map
    const view = map.getView()
    view.endInteraction(this._duration)

    this._dragging = undefined

    return false
  }

  /**
   * Handle pointer down events.
   * @param {import("ol/MapBrowserEvent.js").default} mapBrowserEvent Event.
   * @return {boolean} If the event was consumed.
   */
  handleDownEvent(mapBrowserEvent: MapBrowserEvent<any>) {
    if (!mouseOnly(mapBrowserEvent)) {
      return false
    }

    if (mouseActionButton(mapBrowserEvent) && this._condition && this._condition(mapBrowserEvent)) {
      const map = mapBrowserEvent.map
      map.getView().beginInteraction()
      this._dragging = mapBrowserEvent.originalEvent.dragging

      return true
    } else {
      this._dragging = undefined

      return false
    }
  }

  /** Set perspective angle
   * @param {number} angle the perspective angle 0 (vertical) - 30 (max), default 0
   * @param {*} options
   * @param {number} options.duration The duration of the animation in milliseconds, default 500
   * @param {function} options.easing The easing function used during the animation, defaults to ol.easing.inAndOut).
   */
  setPerspective(angle: number, options: Options) {
    options = options || {}
    // max angle
    if (angle > 30) angle = 30
    else if (angle < 0) angle = 0
    const fromAngle = this._angle || 0
    const toAngle = Math.round(angle * 10) / 10
    const element = document?.querySelector(".ol-layers") as HTMLElement
    const { style } = element
    this._animatedPerspective && cancelAnimationFrame(this._animatedPerspective)
    this._animatedPerspective = requestAnimationFrame((t: number) => {
      this._animatePerspective(t, t, style, fromAngle, toAngle, options.duration, options.easing || inAndOut)
    })
  }

  /** Animate the perspective
   * @param {number} t0 starting timestamp
   * @param {number} t current timestamp
   * @param {CSSStyleDeclaration} style style to modify
   * @param {number} fromAngle starting angle
   * @param {number} toAngle ending angle
   * @param {number} duration The duration of the animation in milliseconds, default 500
   * @param {function} easing The easing function used during the animation, defaults to ol.easing.inAndOut).
   * @private
   */
  _animatePerspective(
    t0: number,
    t: number,
    style: { transform: string },
    fromAngle: number,
    toAngle: number,
    duration: number = 0,
    easing: (arg0: number) => number
  ) {
    let dt, end
    if (duration === 0) {
      dt = 1
      end = true
    } else {
      dt = (t - t0) / (duration || 500)
      end = dt >= 1
    }
    dt = easing(dt)
    let angle
    if (end) {
      angle = this._angle = toAngle
    } else {
      angle = this._angle = fromAngle + (toAngle - fromAngle) * dt
    }
    const fac = angle / 30
    // apply transform to the style
    style.transform =
      "translateY(-" + 17 * fac + "%) perspective(200px) rotateX(" + angle + "deg) scaleY(" + (1 - fac / 2) + ")"
    if (!end) {
      this._animatedPerspective = requestAnimationFrame((t) => {
        this._animatePerspective(t0, t, style, fromAngle, toAngle, duration || 500, easing || inAndOut)
      })
    }
    // Dispatch event
    this.dispatchEvent(new DragPerspectiveEvent(CHANGE_PERSPECTIVE, angle, !end) as unknown as DragPerspectiveEventType)
  }
}

const CHANGE_PERSPECTIVE = "change:perspective"

class DragPerspectiveEvent extends Event {
  animating: boolean
  angle: number
  /**
   * @param {typeof CHANGE_PERSPECTIVE} type Event type.
   * @param {number} angle The perspective angle 0 (vertical) - 30 (max).
   * @param {boolean} animating Animating.
   */
  constructor(type: typeof CHANGE_PERSPECTIVE, angle: number, animating: boolean) {
    super(type)

    /**
     * The perspective angle 0 (vertical) - 30 (max).
     * @type {angle}
     * @api
     */
    this.angle = angle

    /**
     * The animating.
     * @type {boolean}
     * @api
     */
    this.animating = animating
  }
}

export default DragPerspective
