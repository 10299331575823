import { Box, Flex, FlexProps, Heading, Icon, Text } from "@chakra-ui/react"
import { ReactNode } from "react"
import { IconType } from "react-icons"

import useIsAdminRoute from "hooks/useIsAdminRoute"

export default function SectionHeader(props: SectionHeaderProps) {
  const { title, description, icon, size = "md", color = "black", children } = props
  const isAdmin = useIsAdminRoute()

  return (
    <Flex w="full" flexDirection="column" pt={8}>
      <Flex flexDirection="column" mb={6}>
        <Heading
          size={size}
          color={isAdmin ? "red.500" : "primary.900"}
          fontWeight="semibold"
          mb={2}
          alignItems="center"
        >
          <HeadingIcon icon={icon} isAdmin={isAdmin} />
          {title}
        </Heading>
        {description && (
          <Text fontSize="sm" color={color}>
            {description}
          </Text>
        )}
      </Flex>

      {children || null}
    </Flex>
  )
}

function HeadingIcon(props: HeadingIconProps) {
  const { icon, isAdmin } = props
  if (!icon) {
    return null
  }

  if (typeof icon === "function") {
    return (
      <Box
        p={1}
        color={isAdmin ? "red.500" : "primary.900"}
        bgColor={isAdmin ? "red.100" : "primary.100"}
        display="inline-block"
        me={2}
        rounded="md"
      >
        <Icon as={icon} />
      </Box>
    )
  }

  return icon
}

interface SectionHeaderProps extends FlexProps {
  title: string
  description?: ReactNode
  icon?: IconType | JSX.Element
  size?: "sm" | "md" | "lg"
  children?: ReactNode
}

interface HeadingIconProps {
  icon?: IconType | JSX.Element
  isAdmin?: boolean
}
