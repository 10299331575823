import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react"
import { BsThreeDotsVertical } from "react-icons/bs"
import { FiUsers } from "react-icons/fi"
import { GiDualityMask } from "react-icons/gi"

import Impersonate from "components/admin/impersonate/Impersonate"

import UserManagement from "./user-management/UserManagement"

export default function AdminOrganisationActions(props: AdminOrganisationActionsProps) {
  const { organisation } = props

  return (
    <Flex textAlign="start">
      <Menu>
        <Tooltip label="Show options">
          <MenuButton as={IconButton} variant="shadow" icon={<BsThreeDotsVertical />} />
        </Tooltip>
        <MenuList>
          <UserManagement organisation={organisation}>
            {({ onOpen }) => (
              <MenuItem icon={<FiUsers />} onClick={onOpen}>
                Manage users
              </MenuItem>
            )}
          </UserManagement>
          <MenuDivider />
          <MenuGroup my={2} title="Danger zone" color="red.500">
            <Impersonate organisation={organisation}>
              {({ onOpen }) => (
                <MenuItem icon={<GiDualityMask />} onClick={onOpen} color="red.500">
                  Impersonate
                </MenuItem>
              )}
            </Impersonate>
          </MenuGroup>
        </MenuList>
      </Menu>
    </Flex>
  )
}

type AdminOrganisationActionsProps = {
  organisation: any
}
