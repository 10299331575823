export const RdPu = [
  "#FFF7F3",
  "#FEEAE7",
  "#FDDDD9",
  "#FCCCC8",
  "#FBBABD",
  "#FAA5B7",
  "#F988AD",
  "#F767A1",
  "#E84A9B",
  "#D62D93",
  "#BC1085",
  "#A0017C",
  "#810178",
  "#650171",
  "#49006A",
]
