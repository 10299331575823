export const mockUsersExamples = [
  {
    cursors: {
      prev_cursor: null,
      next_cursor: null,
    },
    results: [
      {
        email: "tx@gmxmai.com",
        id: 20,
        invited: true,
        status: "Inactive",
        cost: "",
        permissions: [
          {
            asset: "NDVI",
            allowed: true,
          },
          {
            asset: "EVI",
            allowed: true,
          },
          {
            asset: "high-res imagery",
            allowed: true,
          },
          {
            asset: "Hamburg",
            allowed: true,
          },
          {
            asset: "Tanzania",
            allowed: false,
          },
          {
            asset: "New Zealand",
            allowed: false,
          },
        ],
      },
      {
        email: "pp@outloo.com",
        id: 20,
        invited: true,
        status: "Activated",
        cost: "$281.57",
        permissions: [
          {
            asset: "NDVI",
            allowed: true,
          },
          {
            asset: "EVI",
            allowed: true,
          },
          {
            asset: "high-res imagery",
            allowed: true,
          },
          {
            asset: "Hamburg",
            allowed: true,
          },
          {
            asset: "Tanzania",
            allowed: true,
          },
          {
            asset: "New Zealand",
            allowed: false,
          },
        ],
      },
      {
        email: "gl@aolmai.com",
        id: 20,
        invited: true,
        status: "Blocked",
        cost: "$258.70",
        permissions: [
          {
            asset: "NDVI",
            allowed: true,
          },
          {
            asset: "EVI",
            allowed: true,
          },
          {
            asset: "high-res imagery",
            allowed: true,
          },
          {
            asset: "Hamburg",
            allowed: true,
          },
          {
            asset: "Tanzania",
            allowed: false,
          },
          {
            asset: "New Zealand",
            allowed: true,
          },
        ],
      },
      {
        email: "on@gmai.com",
        id: 20,
        invited: false,
        status: "Public access",
        cost: "$217.30",
        permissions: [
          {
            asset: "NDVI",
            allowed: true,
          },
          {
            asset: "EVI",
            allowed: true,
          },
          {
            asset: "high-res imagery",
            allowed: false,
          },
          {
            asset: "Hamburg",
            allowed: true,
          },
          {
            asset: "Tanzania",
            allowed: false,
          },
          {
            asset: "New Zealand",
            allowed: false,
          },
        ],
      },
      {
        email: "ao@hotmai.com",
        id: 20,
        invited: false,
        status: "Blocked",
        cost: "$97.95",
        permissions: [
          {
            asset: "NDVI",
            allowed: true,
          },
          {
            asset: "EVI",
            allowed: true,
          },
          {
            asset: "high-res imagery",
            allowed: false,
          },
          {
            asset: "Hamburg",
            allowed: true,
          },
          {
            asset: "Tanzania",
            allowed: false,
          },
          {
            asset: "New Zealand",
            allowed: false,
          },
        ],
      },
    ],
  },
  {
    cursors: {
      prev_cursor: null,
      next_cursor: null,
    },
    results: [
      {
        email: "gn@gmai.com",
        id: 20,
        invited: false,
        status: "Public access",
        cost: "$158.83",
        permissions: [
          {
            asset: "NDVI",
            allowed: false,
          },
          {
            asset: "EVI",
            allowed: true,
          },
          {
            asset: "high-res imagery",
            allowed: false,
          },
          {
            asset: "Hamburg",
            allowed: true,
          },
          {
            asset: "Tanzania",
            allowed: false,
          },
          {
            asset: "New Zealand",
            allowed: false,
          },
        ],
      },
      {
        email: "pj@hotmai.com",
        id: 20,
        invited: false,
        status: "Blocked",
        cost: "$239.18",
        permissions: [
          {
            asset: "NDVI",
            allowed: false,
          },
          {
            asset: "EVI",
            allowed: true,
          },
          {
            asset: "high-res imagery",
            allowed: false,
          },
          {
            asset: "Hamburg",
            allowed: false,
          },
          {
            asset: "Tanzania",
            allowed: true,
          },
          {
            asset: "New Zealand",
            allowed: false,
          },
        ],
      },
      {
        email: "kf@wpl.com",
        id: 20,
        invited: true,
        status: "Inactive",
        cost: "",
        permissions: [
          {
            asset: "NDVI",
            allowed: true,
          },
          {
            asset: "EVI",
            allowed: true,
          },
          {
            asset: "high-res imagery",
            allowed: false,
          },
          {
            asset: "Hamburg",
            allowed: true,
          },
          {
            asset: "Tanzania",
            allowed: true,
          },
          {
            asset: "New Zealand",
            allowed: true,
          },
        ],
      },
      {
        email: "zu@mail.com",
        id: 20,
        invited: true,
        status: "Activated",
        cost: "$181.68",
        permissions: [
          {
            asset: "NDVI",
            allowed: true,
          },
          {
            asset: "EVI",
            allowed: true,
          },
          {
            asset: "high-res imagery",
            allowed: true,
          },
          {
            asset: "Hamburg",
            allowed: true,
          },
          {
            asset: "Tanzania",
            allowed: true,
          },
          {
            asset: "New Zealand",
            allowed: true,
          },
        ],
      },
      {
        email: "bk@outloo.com",
        id: 20,
        invited: true,
        status: "Activated",
        cost: "$259.97",
        permissions: [
          {
            asset: "NDVI",
            allowed: true,
          },
          {
            asset: "EVI",
            allowed: true,
          },
          {
            asset: "high-res imagery",
            allowed: false,
          },
          {
            asset: "Hamburg",
            allowed: true,
          },
          {
            asset: "Tanzania",
            allowed: true,
          },
          {
            asset: "New Zealand",
            allowed: true,
          },
        ],
      },
      {
        email: "yn@mail.com",
        id: 20,
        invited: false,
        status: "Public access",
        cost: "$102.03",
        permissions: [
          {
            asset: "NDVI",
            allowed: false,
          },
          {
            asset: "EVI",
            allowed: true,
          },
          {
            asset: "high-res imagery",
            allowed: false,
          },
          {
            asset: "Hamburg",
            allowed: false,
          },
          {
            asset: "Tanzania",
            allowed: false,
          },
          {
            asset: "New Zealand",
            allowed: true,
          },
        ],
      },
      {
        email: "pl@hotmai.com",
        id: 20,
        invited: true,
        status: "Blocked",
        cost: "$271.36",
        permissions: [
          {
            asset: "NDVI",
            allowed: true,
          },
          {
            asset: "EVI",
            allowed: true,
          },
          {
            asset: "high-res imagery",
            allowed: false,
          },
          {
            asset: "Hamburg",
            allowed: true,
          },
          {
            asset: "Tanzania",
            allowed: true,
          },
          {
            asset: "New Zealand",
            allowed: true,
          },
        ],
      },
      {
        email: "yd@aolmai.com",
        id: 20,
        invited: true,
        status: "Activated",
        cost: "$121.50",
        permissions: [
          {
            asset: "NDVI",
            allowed: true,
          },
          {
            asset: "EVI",
            allowed: true,
          },
          {
            asset: "high-res imagery",
            allowed: true,
          },
          {
            asset: "Hamburg",
            allowed: true,
          },
          {
            asset: "Tanzania",
            allowed: true,
          },
          {
            asset: "New Zealand",
            allowed: true,
          },
        ],
      },
      {
        email: "ej@gmxmai.com",
        id: 20,
        invited: false,
        status: "Public access",
        cost: "$189.09",
        permissions: [
          {
            asset: "NDVI",
            allowed: false,
          },
          {
            asset: "EVI",
            allowed: true,
          },
          {
            asset: "high-res imagery",
            allowed: false,
          },
          {
            asset: "Hamburg",
            allowed: false,
          },
          {
            asset: "Tanzania",
            allowed: true,
          },
          {
            asset: "New Zealand",
            allowed: false,
          },
        ],
      },
    ],
  },
  {
    cursors: {
      prev_cursor: null,
      next_cursor: null,
    },
    results: [
      {
        email: "rw@hotmai.com",
        id: 20,
        invited: true,
        status: "Activated",
        cost: "$109.21",
        permissions: [
          {
            asset: "NDVI",
            allowed: true,
          },
          {
            asset: "EVI",
            allowed: true,
          },
          {
            asset: "high-res imagery",
            allowed: true,
          },
          {
            asset: "Hamburg",
            allowed: true,
          },
          {
            asset: "Tanzania",
            allowed: true,
          },
          {
            asset: "New Zealand",
            allowed: true,
          },
        ],
      },
      {
        email: "fj@gmxmai.com",
        id: 20,
        invited: true,
        status: "Inactive",
        cost: "",
        permissions: [
          {
            asset: "NDVI",
            allowed: true,
          },
          {
            asset: "EVI",
            allowed: true,
          },
          {
            asset: "high-res imagery",
            allowed: true,
          },
          {
            asset: "Hamburg",
            allowed: true,
          },
          {
            asset: "Tanzania",
            allowed: true,
          },
          {
            asset: "New Zealand",
            allowed: true,
          },
        ],
      },
      {
        email: "uw@gmai.com",
        id: 20,
        invited: true,
        status: "Inactive",
        cost: "",
        permissions: [
          {
            asset: "NDVI",
            allowed: true,
          },
          {
            asset: "EVI",
            allowed: true,
          },
          {
            asset: "high-res imagery",
            allowed: true,
          },
          {
            asset: "Hamburg",
            allowed: true,
          },
          {
            asset: "Tanzania",
            allowed: true,
          },
          {
            asset: "New Zealand",
            allowed: true,
          },
        ],
      },
      {
        email: "jj@gmxmai.com",
        id: 20,
        invited: true,
        status: "Activated",
        cost: "$278.31",
        permissions: [
          {
            asset: "NDVI",
            allowed: true,
          },
          {
            asset: "EVI",
            allowed: true,
          },
          {
            asset: "high-res imagery",
            allowed: true,
          },
          {
            asset: "Hamburg",
            allowed: true,
          },
          {
            asset: "Tanzania",
            allowed: true,
          },
          {
            asset: "New Zealand",
            allowed: true,
          },
        ],
      },
      {
        email: "vu@wpmail.com",
        id: 20,
        invited: true,
        status: "Blocked",
        cost: "$249.08",
        permissions: [
          {
            asset: "NDVI",
            allowed: true,
          },
          {
            asset: "EVI",
            allowed: true,
          },
          {
            asset: "high-res imagery",
            allowed: true,
          },
          {
            asset: "Hamburg",
            allowed: true,
          },
          {
            asset: "Tanzania",
            allowed: true,
          },
          {
            asset: "New Zealand",
            allowed: true,
          },
        ],
      },
      {
        email: "iq@hotmai.com",
        id: 20,
        invited: true,
        status: "Activated",
        cost: "$185.93",
        permissions: [
          {
            asset: "NDVI",
            allowed: true,
          },
          {
            asset: "EVI",
            allowed: true,
          },
          {
            asset: "high-res imagery",
            allowed: true,
          },
          {
            asset: "Hamburg",
            allowed: true,
          },
          {
            asset: "Tanzania",
            allowed: true,
          },
          {
            asset: "New Zealand",
            allowed: true,
          },
        ],
      },
      {
        email: "dy@outloo.com",
        id: 20,
        invited: true,
        status: "Inactive",
        cost: "",
        permissions: [
          {
            asset: "NDVI",
            allowed: true,
          },
          {
            asset: "EVI",
            allowed: true,
          },
          {
            asset: "high-res imagery",
            allowed: true,
          },
          {
            asset: "Hamburg",
            allowed: true,
          },
          {
            asset: "Tanzania",
            allowed: true,
          },
          {
            asset: "New Zealand",
            allowed: true,
          },
        ],
      },
    ],
  },
]

/** Random set of users creator
function createResultsList() {
  const allowActiveStatus = getRandomInt(2)
  const aleatoryListOfUsers = Array(getRandomInt(10))
    .fill()
    .map(() => createMockUser(allowActiveStatus))

  return aleatoryListOfUsers
}

function getMockStatus(invited, allowActiveStatus) {
  const statuses = {
    invitationSentStatuses: ["ACTIVATED", "Inactive", "BLOCKED"],
    activeStatuses: ["ACTIVE", "BLOCKED"],
  }
  let baseStatus = statuses[allowActiveStatus && !invited ? "activeStatuses" : "invitationSentStatuses"]
  const status = baseStatus[getRandomInt(baseStatus.length)]
  return STATUSES[status]
}

function createMockUser(allowActiveStatus) {
  const invited = allowActiveStatus ? !!getRandomInt(2) : true
  const status = getMockStatus(invited, allowActiveStatus)
  return {
    email: createAleatoryEmail(),
    id: 20,
    invited,
    status,
    cost: status !== "Inactive" ? "$" + getRandomInt(97000) / 100 : "",
  }
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max)
}

function createAleatoryEmail() {
  let name = ""
  const characters = "abcdefghijklmnopqrstuvwxyz"
  const charactersLength = characters.length
  for (let i = 0; i < 2; i++) {
    name += characters.charAt(getRandomInt(charactersLength))
  }
  return `${name}@orbify.com`
}
*/
