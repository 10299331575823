export const gist_ncar = [
  "#000080",
  "#003D59",
  "#007FFF",
  "#00FFEB",
  "#00FC5E",
  "#4CD900",
  "#84FF0C",
  "#DBFF20",
  "#FFDF00",
  "#FFA10B",
  "#FF2600",
  "#FF00D5",
  "#B044FA",
  "#F1A5F3",
  "#FEF8FE",
]
