import { ElementType, ReactNode } from "react"
import { Redirect } from "react-router-dom"

import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"
import { useUserPermissions } from "services/userPermissions"

export default function withRoleRequired(Component: ElementType, role: string, options?: WithRoleRequiredOptions) {
  const { loadingIndicator = <LoadingSpinner /> } = options || {}

  return function Wrapper({ redirectTo = "/account", ...rest }): any {
    const { isLoading, permissions } = useUserPermissions()

    if (isLoading) {
      return loadingIndicator
    }

    if (permissions.includes(role)) {
      return <Component {...rest} />
    } else {
      return <Redirect to={redirectTo} />
    }
  }
}

type WithRoleRequiredOptions = {
  loadingIndicator: ReactNode
}
