import { FieldArray, useField } from "formik"

import { parameterType } from "components/openeo-editor/lib/propTypes"

import { DateRangeSelector } from "./array/DateRangeSelector"

function ArrayDateRangeType(props) {
  const { parameter } = props
  const [field] = useField(parameter.name)

  return (
    <FieldArray
      name={field.name}
      render={({ push, pop }) => (
        <DateRangeSelector name={field.name} onPush={push} onPop={pop} entries={field.value} />
      )}
    />
  )
}

ArrayDateRangeType.propTypes = {
  parameter: parameterType.isRequired,
}

export { ArrayDateRangeType }
