import { Flex, IconButton, IconButtonProps, Tooltip } from "@chakra-ui/react"
import { VscTrash } from "react-icons/vsc"

import { useOpeneoEventsContext } from "components/orb-openeo-workflow/openeo-workflow-console/OpeneoEventsContext"
import OpeneoWorkflowConsole from "components/orb-openeo-workflow/openeo-workflow-console/OpeneoWorkflowConsole"

export default function OpeneoWorkflowSidebar() {
  const { isConsoleOpen } = useOpeneoEventsContext()

  if (!isConsoleOpen) {
    return null
  }

  return (
    <Flex
      w="full"
      h="full"
      fontFamily="console"
      bgColor="gray.50"
      flexDirection="column-reverse"
      overflow="hidden"
      overflowY="scroll"
      py={6}
    >
      <ClearConsoleButton aria-label="Clear console" position="absolute" top={12} right={3} />
      <OpeneoWorkflowConsole />
    </Flex>
  )
}

function ClearConsoleButton(props: IconButtonProps) {
  const { clearEvents } = useOpeneoEventsContext()

  return (
    <Tooltip label="Clear console">
      <IconButton icon={<VscTrash />} size="xs" shadow="sm" colorScheme="gray" onClick={clearEvents} {...props} />
    </Tooltip>
  )
}
