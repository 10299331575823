import { transparentize } from "@chakra-ui/theme-tools"

const brand = {
  50: "#f7f7fa",
  100: "#e0dfec",
  200: "#c5c3db",
  300: "#a5a2c7",
  400: "#948fbc",
  500: "#2e267a",
  600: "#6862a1",
  700: "#524b94",
  800: "#443c8b",
  900: "#2e267a",
}

const brand50Alpha = {
  700: transparentize(brand["50"], 0.8)(),
}

const colors = {
  brand,
  brand50Alpha,
  primary: brand,
}

export { colors }
