import buildUrl from "api/utils/buildUrl"
import { useQueryClient } from "react-query"

import useAuthenticatedApi from "services/api/authenticatedApi"

import { APIKey } from "../models/APIKeys"

export default function useAPIKeysQueries() {
  const api = useAuthenticatedApi({ baseUrl: buildUrl("/api/v1/internal/account") })
  const queryClient = useQueryClient()

  return {
    listAPIKeysQuery,
    createAPIKeyMutation,
  }

  function listAPIKeysQuery() {
    return {
      queryKey: ["listApiKeys"],
      queryFn: function listApiKeys(): Promise<APIKey[]> {
        return api.get("api-keys/").json()
      },
    }
  }

  function createAPIKeyMutation() {
    const mutationOptions = {
      onSuccess: () => queryClient.invalidateQueries(["listApiKeys"]),
    }

    return {
      mutationFn: function createAPIKey() {
        return api.post("api-keys/").json()
      },
      mutationOptions,
    }
  }
}
