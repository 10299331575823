import { useDisclosure } from "@chakra-ui/react"
import { useCallback, useRef, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import { useNotifications } from "components/common/notifications"
import useOrbsApi from "services/orbs"

function useTemplateSettingsSectionController(orbConfiguration) {
  const { id } = useParams()
  const { update } = useOrbsApi()
  const { showNotification, showErrorNotification } = useNotifications()
  const queryClient = useQueryClient()
  const confirmModalDisclosure = useDisclosure()
  const cancelRef = useRef()

  const { template } = orbConfiguration

  const { mutateAsync } = useMutation(update, {
    retry: false,
    onSuccess: (response) => {
      queryClient.setQueriesData(["showOrb", id], response)
    },
  })

  const [values, setValues] = useState(orbConfiguration)
  const handleSubmit = (formValues) => {
    setValues({ ...orbConfiguration, ...formValues })
    confirmModalDisclosure.onOpen()
  }

  const handleCancel = () => {
    confirmModalDisclosure.onClose()
    cancelRef.current.handleCancel()
  }

  const handleConfirm = useCallback(() => {
    mutateAsync({ orbId: id, values: values })
      .then(() => {
        showNotification("Application template", "Application template was changed", "success", "app-template-change")
        confirmModalDisclosure.onClose()
      })
      .catch((error) => {
        showErrorNotification(error, "Failed to update configuration")
        confirmModalDisclosure.onClose()
      })
  }, [id, mutateAsync, showNotification, showErrorNotification, values, confirmModalDisclosure])

  return {
    initialValues: { template },
    handleSubmit,
    handleCancel,
    handleConfirm,
    confirmModalDisclosure,
    cancelRef,
  }
}

export default useTemplateSettingsSectionController
