import { CopyIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  VStack,
  chakra,
} from "@chakra-ui/react"
import { OrbConfig } from "api/models/OrbConfig"
import { FiShare } from "react-icons/fi"
import { IoIosGitNetwork } from "react-icons/io"
import { VscLinkExternal } from "react-icons/vsc"
import { useHistory, useRouteMatch } from "react-router-dom"

import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"
import GuidedTour from "components/guided-tour/GuidedTour"
import Section from "components/layout/section/Section"
import SectionHeader from "components/layout/section/SectionHeader"
import { ORBIFY_ADMIN_PERMISSION, useUserPermissions } from "services/userPermissions"

import OrbBillingConfiguration from "./billing-configuration/OrbBillingConfiguration"
import OrbLogo from "./orb-logo/OrbLogo"
import useOrbGeneralViewController from "./orbGeneralViewController"
import AuthorizationSettingsSection from "./settings/AuthorizationSettingsSection"
import BasicSettingsSection from "./settings/BasicSettingsSection"
import TemplateSettingsSection from "./settings/TemplateSettingsSection"
import WorkflowSection from "./settings/WorkflowSection"

export default function OrbGeneralView() {
  const { isLoading, orbConfig, applicationUrl, handleVisitApp, onCopy } = useOrbGeneralViewController()
  const { isLoading: isPermissionLoading, permissions } = useUserPermissions()
  const isAdminUser = !isPermissionLoading && permissions?.includes(ORBIFY_ADMIN_PERMISSION)
  const isForestTemplate = orbConfig?.template === "forest"

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!orbConfig) {
    throw new Error("Orb configuration was not loaded properly")
  }

  return (
    <Flex flexDirection="column" position="relative" data-id="orb-general-view__overview">
      <GuidedTour component="OrbGeneralView" />
      <OrbGeneralViewHeader
        orbConfig={orbConfig}
        applicationUrl={applicationUrl}
        handleVisitApp={handleVisitApp}
        onCopy={onCopy}
      />
      <Box p={4} pt={12}>
        <Section header={<SectionHeader title="Application settings" description="Configure your application" />}>
          <VStack spacing={4} divider={<chakra.hr w="full" />} pb={16}>
            <BasicSettingsSection orbConfig={orbConfig} />
            <AuthorizationSettingsSection orbConfig={orbConfig} />
            {(isAdminUser || !isForestTemplate) && <TemplateSettingsSection orbConfig={orbConfig} />}
            <WorkflowSection />
            {isAdminUser && <OrbBillingConfiguration />}
          </VStack>
        </Section>
      </Box>
    </Flex>
  )
}

function OrbGeneralViewHeader(props: OrbGeneralViewHeaderProps) {
  const { orbConfig, applicationUrl, handleVisitApp, onCopy } = props

  const match = useRouteMatch()
  const history = useHistory()

  function handleConfigureWorkflow() {
    history.replace(`${match.url}/workflow`)
  }

  return (
    <Flex
      w="full"
      backdropFilter="blur(5px)"
      position="sticky"
      top={0}
      left={0}
      borderColor="gray.200"
      borderBottomWidth={1}
      p={4}
      zIndex={2}
      ps={{ base: 10, md: 4 }}
    >
      <OrbLogo orb={orbConfig} mr={6} />

      <Flex w="full" flexDirection="column" justifyContent="space-between">
        <Heading size="md" color="primary.900">
          {orbConfig?.name}
        </Heading>
        <Text fontSize="sm">{orbConfig?.description}</Text>
      </Flex>

      <HStack alignItems="center" spacing={6}>
        <Button size="sm" leftIcon={<IoIosGitNetwork />} onClick={handleConfigureWorkflow}>
          Configure workflow
        </Button>
        <Button
          as="a"
          size="sm"
          leftIcon={<VscLinkExternal />}
          colorScheme="primary"
          href={applicationUrl}
          target="_blank"
          onClick={handleVisitApp}
        >
          Visit application
        </Button>

        <Flex>
          <Menu>
            <Tooltip label="Share menu">
              <MenuButton
                as={IconButton}
                size="sm"
                color="brand.600"
                icon={<FiShare />}
                variant="outline"
                aria-label="Share menu"
              >
                Copy link
              </MenuButton>
            </Tooltip>
            <MenuList>
              <MenuItem icon={<CopyIcon />} onClick={onCopy}>
                Copy link
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  )
}

type OrbGeneralViewHeaderProps = {
  orbConfig: OrbConfig
  applicationUrl: string
  handleVisitApp: () => void
  onCopy: () => void
}
