import { useMemo } from "react"

import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"
import { interpolateStringSync } from "services/templateInterpolation"

const useInterpolatedLegends = () => {
  const { isResultsLoading, availableAssets, legends } = useOpeneoWorkflowContext()

  const computedVariables = useMemo(() => {
    return !isResultsLoading && availableAssets && availableAssets.length > 0
      ? availableAssets[0].computedVariables
      : {}
  }, [isResultsLoading, availableAssets])

  function interpolateLegend(legend, variables) {
    // dont interpolate the legends when variables are not present
    if (!Object.keys(variables).length) {
      return legend
    }
    // dont interpolate continous legends
    if (legend.type === "continuous") {
      return legend
    }

    //can be made async with interpolateString and https://advancedweb.hu/how-to-use-async-functions-with-array-map-in-javascript/ + https://stackoverflow.com/a/66071205
    let interpolatedItems = legend["items"].map((item) => {
      if (item["title"]) {
        return {
          ...item,
          title: interpolateStringSync(item["title"], variables),
        }
      } else {
        return item
      }
    })

    return {
      ...legend,
      title: interpolateStringSync(legend["title"], variables),
      items: interpolatedItems,
    }
  }

  const interpolatedLegends = useMemo(() => {
    if (!legends || !computedVariables) {
      return []
    }

    return legends.map((legend) => interpolateLegend(legend, computedVariables))
  }, [computedVariables, legends])

  return {
    interpolatedLegends,
  }
}

export { useInterpolatedLegends }
