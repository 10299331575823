import { VscArrowLeft } from "react-icons/vsc"

import { useNotifications } from "components/common/notifications"
import ToolboxButton from "components/common/toolbox-button/ToolboxButton"
import { useCodeEditorContext } from "components/openeo-editor/code-editor/CodeEditorContext"
import useSaveCode from "components/openeo-editor/code-editor/useSaveCode"
import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"

export default function CodeEditorCloseButton() {
  const { legends, onSaveGraph, setIsCodeEditorOpen } = useOpeneoWorkflowContext() as any
  const { isSavingCode, handleSaveCode } = useSaveCode()
  const { clearContext } = useCodeEditorContext()
  const { showErrorNotification } = useNotifications()

  async function handleClick() {
    try {
      await handleSaveCode()
      onSaveGraph(legends)
      setIsCodeEditorOpen.off()

      clearContext()
    } catch (error: any) {
      showErrorNotification(error, "Unable to save file", "")
    }
  }

  return (
    <ToolboxButton
      // @ts-ignore
      icon={VscArrowLeft}
      label="Exit editor"
      tooltipLabel="Back to flow editor"
      onClick={handleClick}
      isLoading={isSavingCode}
      color="white"
      bgColor="blackAlpha.700"
      _hover={{ bgColor: "blackAlpha.500" }}
      isWide
    />
  )
}
