import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Flex } from "@chakra-ui/react"
import PropTypes from "prop-types"

import { SafeConfirmModal } from "../common/safe-confirm-modal"
import useDeletionModalController from "./orbDeletionModalController"

const OrbDeletionModal = ({ url, modalDisclosure }) => {
  const { isDeleting, onHandleDelete } = useDeletionModalController(modalDisclosure, url)

  const additionalInfo = (
    <Flex w="full" py={6}>
      <Alert status="error" rounded="md">
        <AlertIcon mx={6} />
        <Box flex={1}>
          <AlertTitle>Deleting an application is an irreversible action</AlertTitle>
          <AlertDescription>All data associated with this application will be lost.</AlertDescription>
        </Box>
      </Alert>
    </Flex>
  )

  return (
    <SafeConfirmModal
      header="Are you sure you want to delete this application?"
      additionalInfo={additionalInfo}
      textConfirmation={`delete ${url}`}
      modalDisclosure={modalDisclosure}
      disableButton={isDeleting}
      onConfirm={onHandleDelete}
    />
  )
}

OrbDeletionModal.propTypes = {
  url: PropTypes.string.isRequired,
  modalDisclosure: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }).isRequired,
}

export default OrbDeletionModal
