import {
  Alert,
  AlertDescription,
  AlertIcon,
  Flex,
  IconButton,
  Input,
  LinkBox,
  LinkOverlay,
  Text,
  VStack,
} from "@chakra-ui/react"
import { User } from "api/models/User"
import useAdminGetAllUsers from "api/useAdminGetAllUsers"
import { ChangeEvent, useMemo, useState } from "react"
import { FaUserPlus } from "react-icons/fa"

import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"

const MAX_VISIBLE_USERS = 10

export default function AvailableUsersList(props: AvailableUsersListProps) {
  const { selectedUsers, handleAddUser } = props
  const [filterString, setFilterString] = useState("")
  const { isLoading, isError, users } = useAdminGetAllUsers()

  const filteredUsers = useMemo(() => {
    if (!users) {
      return []
    }

    return users
      .filter((user) => !selectedUsers.map((selectedUser) => selectedUser.id).includes(user.id))
      .filter((user) => user.email.toLowerCase().includes(filterString.toLowerCase()))
  }, [users, selectedUsers, filterString])

  const limitedFilteredUsers = filteredUsers.slice(0, MAX_VISIBLE_USERS)
  const remainingUsersCount = filteredUsers.length - limitedFilteredUsers.length

  function handleFilterChange(filter: string) {
    setFilterString(filter)
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isError) {
    return (
      <Flex flexDirection="column">
        <Alert status="error" rounded="md">
          <AlertIcon />
          <AlertDescription>Failed to fetch users list</AlertDescription>
        </Alert>
      </Flex>
    )
  }

  return (
    <Flex flexDirection="column">
      <AvailableUsersListFilter handleFilterChange={handleFilterChange} />
      <VStack w="full" my={2} spacing={1} alignItems="flex-start" fontSize="sm">
        {limitedFilteredUsers.map((user) => (
          <ListEntry key={user.id} user={user} handleAddUser={handleAddUser} />
        ))}
        {!!remainingUsersCount && (
          <Text color="gray.400">
            Showing first {MAX_VISIBLE_USERS} users ({remainingUsersCount} more…)
          </Text>
        )}
      </VStack>
    </Flex>
  )
}

function AvailableUsersListFilter(props: AvailableUsersListFilterProps) {
  const { handleFilterChange } = props
  const [filterInputValue, setFilterInputValue] = useState("")

  function handleFilterInputValueChange(event: ChangeEvent<HTMLInputElement>) {
    setFilterInputValue(event.target.value)
    handleFilterChange(event.target.value)
  }

  return (
    <Input
      size="sm"
      rounded="md"
      placeholder="Filter users…"
      value={filterInputValue}
      onChange={handleFilterInputValueChange}
    />
  )
}

function ListEntry(props: ListEntryProps) {
  const { user, handleAddUser } = props

  function handleClick(event: any) {
    event.preventDefault()
    handleAddUser(user)
  }

  return (
    <LinkBox w="full">
      <Flex justifyContent="space-between" alignItems="center">
        {user.email}
        <LinkOverlay href="#" onClick={handleClick}>
          <IconButton
            size="xs"
            variant="ghost"
            colorScheme="gray"
            color="gray.300"
            me={2}
            icon={<FaUserPlus />}
            aria-label="Add user"
          />
        </LinkOverlay>
      </Flex>
    </LinkBox>
  )
}

type AvailableUsersListProps = {
  selectedUsers: User[]
  handleAddUser: (user: User) => void
}

type AvailableUsersListFilterProps = {
  handleFilterChange: (filter: string) => void
}

type ListEntryProps = {
  user: User
  handleAddUser: (user: User) => void
}
