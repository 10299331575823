import { useAuth0 } from "@auth0/auth0-react"
import { Button, Heading, Link, Stack, Tbody, Td, Text, Thead, Tr } from "@chakra-ui/react"
import { DetailedPricingPlan } from "api/models/Billing"
import useCreateCheckoutSession from "api/useCreateCheckoutSession"
import useGetCurrentPricingPlan from "api/useGetCurrentPricingPlan"
import mixpanel from "mixpanel-browser"
import { useState } from "react"
import { useLocation } from "react-router"

import { useNotifications } from "components/common/notifications"
import { currencyToSign } from "lib/utils"

import PricingTableLayout from "./PricingTableLayout"

export default function PlansTable(props: { pricingPlans: DetailedPricingPlan[] }) {
  const { pricingPlans } = props
  const { showErrorNotification } = useNotifications()
  const [isSubscribing, setIsSubscribing] = useState(false)
  const { createCheckoutSession } = useCreateCheckoutSession()
  const { pathname } = useLocation()
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  const { assignedPricingPlan, subscription } = useGetCurrentPricingPlan()

  const pageTitle = pathname === "/pricing" ? "Pricing" : "Account - Billing - Pricing"

  const isUserSubscribed = !!assignedPricingPlan && subscription?.status !== "TRIAL"
  const shouldDisplaySubscribeButton = isAuthenticated && !isUserSubscribed

  return (
    <PricingTableLayout variant="unstyled">
      <Thead>
        <Tr>
          <Td></Td>
          {pricingPlans.map((pricingPlan) => (
            <Td key={pricingPlan.name}>
              <Text fontSize="20px" fontWeight="500" textTransform="capitalize">
                {pricingPlan.name}
              </Text>
            </Td>
          ))}
        </Tr>
      </Thead>

      <Tbody borderTop="1px solid #e2e8f0">
        <Tr>
          <Td></Td>
          {pricingPlans.map((pricingPlan) => (
            <Td key={pricingPlan.name} verticalAlign="bottom">
              <Stack>
                {pricingPlan.price > 0 && (
                  <Heading fontSize={20} textDecor="line-through">
                    {currencyToSign(pricingPlan.currency)}
                    {pricingPlan.price}
                  </Heading>
                )}

                <Stack display="flex" flexDir="row" alignItems="baseline">
                  {pricingPlan.category !== "info" && (
                    <Heading fontSize={22}>
                      {currencyToSign(pricingPlan.currency)}
                      {pricingPlan.discounted_price}
                    </Heading>
                  )}

                  {pricingPlan.category === "standard" && (
                    <Text pl={1} whiteSpace="nowrap">
                      / {pricingPlan.billing_interval}
                    </Text>
                  )}
                </Stack>

                {pricingPlan.category === "info" && (
                  <Button
                    as={Link}
                    href={process.env.REACT_APP_CONTACT_SALES_MAIL}
                    variant="primary"
                    style={{ textDecoration: "none" }}
                  >
                    Contact sales
                  </Button>
                )}
                {shouldDisplaySubscribeButton && pricingPlan.category === "standard" && (
                  <Button
                    isLoading={isSubscribing}
                    onClick={() => handleSubscribeClick(pricingPlan.id, pricingPlan.name)}
                    variant="primary"
                  >
                    Subscribe
                  </Button>
                )}
              </Stack>
            </Td>
          ))}
        </Tr>
      </Tbody>
    </PricingTableLayout>
  )

  async function handleSubscribeClick(pricing_plan_id: number, plan_name: string) {
    mixpanel.track("Subscribe button click", { page_title: pageTitle, plan_name })

    if (!isAuthenticated) {
      loginWithRedirect({
        redirectUri: `${window.location.origin}/pricing`,
      })
      return
    }

    setIsSubscribing(true)

    try {
      const { redirect_to } = await createCheckoutSession({ pricing_plan_id })

      if (!redirect_to) throw new Error("Redirect to link is empty")

      window.location.replace(redirect_to)
    } catch (error) {
      showErrorNotification(error as Error, "Failed to start checkout session")
    } finally {
      setIsSubscribing(false)
    }
  }
}
