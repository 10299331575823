export const Wistia = [
  "#E4FF7A",
  "#ECF95F",
  "#F3F244",
  "#FBEB27",
  "#FFE216",
  "#FFD60F",
  "#FFC908",
  "#FFBD00",
  "#FFB500",
  "#FFAC00",
  "#FFA400",
  "#FF9B00",
  "#FE9200",
  "#FD8800",
  "#FC7F00",
]
