export const Blues = [
  "#F7FBFF",
  "#E9F2FA",
  "#DBE9F6",
  "#CDDFF1",
  "#BAD6EB",
  "#A4CCE3",
  "#89BEDC",
  "#6AAED6",
  "#539ECD",
  "#3D8DC4",
  "#2B7BBA",
  "#1A68AE",
  "#0B559F",
  "#084387",
  "#08306B",
]
