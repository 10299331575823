export const Set2 = [
  "#66C2A5",
  "#66C2A5",
  "#FC8D62",
  "#8DA0CB",
  "#8DA0CB",
  "#8DA0CB",
  "#E78AC3",
  "#A6D854",
  "#A6D854",
  "#A6D854",
  "#FFD92F",
  "#E5C494",
  "#E5C494",
  "#E5C494",
  "#B3B3B3",
]
