export const defaultHandlerSnippet = `from datetime import date
from typing import Any, List, Union

import ee

import orbee as oe
from output import ChartData, NamedLayer

ComputedVariables = dict[str, Any]

ReturnType = List[
    Union[ee.Image, ee.Geometry, ee.FeatureCollection, ee.Feature, ComputedVariables, ChartData, NamedLayer]
]


def entrypoint(dates: List[date], region: ee.Geometry) -> ReturnType:
    """
    Sample script using Google Earth Engine REST APIs.

    :param dates: \`List[date]\` - contains list of dates provided as user (or default) input
    :param region: \`ee.Geometry\` - geometry provided as user input, used for spatial filtering of data
    :return: \`ReturnType\` - script must return a list of supported objects (see \`ReturnType\` definition)
    """

    # First, let's do something basic - fetch MODIS land cover data from 2001
    modis_image = ee.Image("MODIS/051/MCD12Q1/2001_01_01").select(["Land_Cover_Type_1"])

    # Time for something more advanced, we use OrbEE to fetch SO2 concentration data (image, stats and chart!)
    so2 = oe.calculate_average_index(
        dates,
        region,
        "COPERNICUS/S5P/NRTI/L3_SO2",
        "SO2_column_number_density",
        "SO2",
        "bar",
    )

    # Same for NOAA Global Forecast System and temperature
    temperature = oe.calculate_average_index(
        dates,
        region,
        "NOAA/GFS0P25",
        "temperature_2m_above_ground",
        "TEMP",
        "line",
    )

    # Now, let's use OrbEE to plot & calculate how much land is covered with forest
    forest_coverage = oe.dynamicworld_land_cover_area(
        dates,
        region,
        "trees",
        "green",
    )

    # Finally, let's have OrbEE calculate the mean canopy height
    gedi_canopy_height = oe.gedi_canopy_height(dates, region)

    # NDVI, NDMI anyone? OrbEE has got you covered
    vegetation_indices = oe.s2_vegetation_indices(dates, region)

    # Computed variables can be used in application's legend, using parameter substitution, e.g. 'Hello, {{static}}!'
    sample_computed_variables = {
        "static": "world",
        "computed": 2 + 2 * 2,
    }

    # We have the data, now - let's display them!
    # Create a dictionary that contains all computed variables that we want to return
    computed_variables = (
        sample_computed_variables
        | forest_coverage["computed_variables"]
        | gedi_canopy_height["computed_variables"]
        | so2["computed_variables"]
        | temperature["computed_variables"]
    )

    return [
        modis_image,  # Simple \`ee.Image\` object
        NamedLayer(data=so2["map"], name="SO2 Concentration"),  # \`NamedLayer\` allowing to control how layer's named
        NamedLayer(data=gedi_canopy_height["map"], name="Canopy Height"),
        NamedLayer(data=forest_coverage["map"], name="Crown Cover"),
        temperature["chart"],  # \`ChartData\` used to automatically plot the results in the application
        vegetation_indices["chart"],
        computed_variables,  # \`ComputedVariables\` that can be interpolated in the application's legend
    ]
`
