import Rollbar from "services/rollbar"

import { useMapContext } from "../components/common/open-layers/MapContextProvider"

export function useReadPixelColorValueFromCanvas(pixel) {
  const { map } = useMapContext()
  if (!map || !pixel) return null

  const canvas = map
    ?.getViewport()
    .querySelectorAll(".ol-layers :not(.ol-AOI-layer, .ol-mapbox-vector-layer, .ol-public-AOI-layer) canvas")

  const gl = canvas[0]?.getContext("2d") //TODO check what happens with more than one result

  if (!gl) {
    Rollbar.error("No Mapbox WebGL context found; unable to read pixel color value")
    return null
  }

  const rgba = gl.getImageData(pixel[0], pixel[1], 1, 1).data
  return rgbaToHex(rgba)
}

export function useReadPixelColorForEachLayer(pixel) {
  const { map } = useMapContext()
  if (!map || !pixel) return null

  const results = map?.forEachLayerAtPixel(
    pixel,
    (layer, rgba) => {
      return !!rgba.length ? rgbaToHex(rgba) : null
    },
    {
      layerFilter: (layer) => {
        return !["ol-mapbox-vector-layer", "ol-public-AOI-layer", "ol-AOI-layer"].includes(layer.className_)
      },
    }
  )
  return results
}

function rgbaComponentToHex(color, alpha) {
  if ([color, alpha].forEach((param) => typeof param !== "number" || param < 0)) {
    Rollbar.error("Missed a necessary parameter")
    return "00"
  }
  const adjustedAlpha = alpha / 255
  const adjustedColor = color * adjustedAlpha + 255 * (1 - adjustedAlpha)
  const hex = Math.round(adjustedColor, 255).toString(16)
  return hex.length === 1 ? "0" + hex : hex
}

export function rgbaToHex(arg) {
  const [r, g, b, a] = arg
  /* good approach to get rgba to hex.
  Use rbgToHex(rgbaToRgb) to get a more exact approach.
  */
  if ([r, g, b, a].forEach((param) => typeof param !== "number" || param < 0)) {
    Rollbar.error("Missed a necessary parameter")
    return null
  } else if (a === 0) return null // transparent

  return "#" + rgbaComponentToHex(r, a) + rgbaComponentToHex(g, a) + rgbaComponentToHex(b, a) // + componentToHex(Math.round(a * 255))
}

function rgbComponentToHex(c) {
  if (typeof c !== "number" || c < 0) {
    Rollbar.error("Missed a necessary parameter")
    return "00"
  }
  const hex = c.toString(16)
  return hex.length === 1 ? "0" + hex : hex
}

export function rgbToHex([r, g, b]) {
  return "#" + rgbComponentToHex(r) + rgbComponentToHex(g) + rgbComponentToHex(b)
}

export function rgbaToRgb(source, bgColor) {
  if (!source || !bgColor) {
    Rollbar.error("Missed a necessary parameter")
    return [0, 0, 0]
  }
  const [sourceR, sourceG, sourceB, sourceA255] = source
  const [bgColorR, bgColorG, bgColorB] = bgColor
  const sourceA = sourceA255 / 255

  const red = Math.round((1 - sourceA) * bgColorR + sourceA * sourceR)
  const green = Math.round((1 - sourceA) * bgColorG + sourceA * sourceG)
  const blue = Math.round((1 - sourceA) * bgColorB + sourceA * sourceB)
  return [red, green, blue]
}

export function rgbStringToRgbArray(rgbString) {
  if (!rgbString || typeof rgbString !== "string") {
    Rollbar.error("Missed a necessary parameter")
    return [0, 0, 0]
  }
  return rgbString
    .substring(4, rgbString.length - 1)
    .replace(/ /g, "")
    .split(",")
    .map((value) => Number(value))
}
