import * as Yup from "yup"
import { Badge, Flex, Spinner, Table, Tbody, Td, Text, Tr, chakra } from "@chakra-ui/react"
import useGetAllowedBIllingFeatures from "api/useGetAllowedBIllingFeatures"
import { Formik, useFormikContext } from "formik"
import { SwitchControl } from "formik-chakra-ui"
import { useEffect } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

import { useNotifications } from "components/common/notifications"
import useOrbsApi from "services/orbs"
import { ORBIFY_ADMIN_PERMISSION, useUserPermissions } from "services/userPermissions"

const AutoSavingSwitchControl = (props) => {
  const { initialValues, isSubmitting, setSubmitting, submitForm, values } = useFormikContext()

  useEffect(() => {
    if (values === initialValues) return

    submitForm().then(() => setSubmitting(false))
  }, [initialValues, values, submitForm, setSubmitting])

  if (!!isSubmitting) {
    return <Spinner size="sm" color="gray.400" />
  } else {
    return <SwitchControl {...props} />
  }
}

const AuthorizationSettingsForm = ({ orbConfiguration }) => {
  const { id } = useParams()
  const { showErrorNotification } = useNotifications()
  const { permissions } = useUserPermissions()
  const { allowedBillingFeatures } = useGetAllowedBIllingFeatures()

  const queryClient = useQueryClient()
  const { update } = useOrbsApi()

  const { mutateAsync } = useMutation(update, {
    retry: false,
    onSuccess: (response) => {
      queryClient.setQueriesData(["showOrb", id], response)
    },
  })

  const initialFormValues = {
    invite_only: orbConfiguration?.invite_only,
    public: orbConfiguration?.public,
    shared_results: orbConfiguration?.shared_results,
  }
  const validationSchema = Yup.object().shape({
    invite_only: Yup.boolean(),
    public: Yup.boolean(),
  })

  const isAdminUser = permissions?.includes(ORBIFY_ADMIN_PERMISSION)
  const showPrivateAppsFeatureBadge = allowedBillingFeatures && !allowedBillingFeatures.includes("private_applications")

  const onSubmit = (values, { resetForm }) => {
    const newConfiguration = { ...orbConfiguration, ...values }
    mutateAsync({ orbId: id, values: newConfiguration }).catch(async (error) => {
      resetForm()
      let message = error.message
      try {
        const response = await error.response.json()
        message = response.detail
      } catch {}
      showErrorNotification(error, "Failed to update configuration", message)
    })
  }

  return (
    <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <chakra.form w="full" onSubmit={handleSubmit}>
          <Table variant="unstyled">
            <Tbody>
              <Tr>
                <Td ps={0}>
                  <Flex>
                    <Text fontWeight="semibold" fontSize="md">
                      Public access
                    </Text>
                    {showPrivateAppsFeatureBadge && (
                      <Badge ml={2} colorScheme="blue" rounded="base" fontSize="2xs">
                        Premium
                      </Badge>
                    )}
                  </Flex>

                  <Text fontSize="sm" color="gray.400">
                    When enabled, application will be publicly accessible, without requiring any user authentication
                  </Text>
                </Td>
                <Td w="10%" pe={0} isNumeric={true} justifyContent="start" verticalAlign="top">
                  <AutoSavingSwitchControl name="public" switchProps={{ colorScheme: "brand" }} />
                </Td>
              </Tr>
              {isAdminUser && (
                <Tr>
                  <Td ps={0}>
                    <Text fontWeight="semibold" fontSize="md">
                      Shared results
                    </Text>
                    <Text fontSize="sm" color="gray.400">
                      When enabled, all areas of interest and execution results - created by you or your application
                      users - will be shared with every user by default
                    </Text>
                  </Td>
                  <Td w="10%" pe={0} isNumeric={true} justifyContent="start" verticalAlign="top">
                    <AutoSavingSwitchControl name="shared_results" switchProps={{ colorScheme: "brand" }} />
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </chakra.form>
      )}
    </Formik>
  )
}

export default AuthorizationSettingsForm
