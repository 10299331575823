import { Flex } from "@chakra-ui/react"
import { createColumnHelper } from "@tanstack/react-table"
import { OpenEOInternalResult, OpenEOJobStatus } from "api/models/OpenEO"
import { PaginationOptions } from "api/models/PaginatedResponse"
import { ExtendedOrderExecution } from "api/models/Workflows"
import useGetAllOrderExecutions from "api/useGetAllOrderExecutions"
import { useState } from "react"

import { DataTable } from "components/common/data-table/DataTable"
import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"
import Paginator from "components/common/paginator/Paginator"
import { showDateTime } from "services/utils"

import OrbOrderErrors from "./OrbOrderErrors"
import OrbOrderStatus from "./OrbOrderStatus"

export default function OrbOrders() {
  const [pagination, setPagination] = useState<PaginationOptions>({ cursor: null, limit: 10, desc: true })
  const { isLoading, isError, orders } = useGetAllOrderExecutions(pagination)
  const columnHelper = createColumnHelper<ExtendedOrderExecution>()

  const columns = [
    columnHelper.accessor("created_at", { header: "Created at", cell: (cell) => showDateTime(cell.getValue()) }),
    columnHelper.accessor("execution_status", {
      header: "Status",
      cell: (cell) => <OrbOrderStatus status={cell.getValue() as OpenEOJobStatus} />,
    }),
    columnHelper.accessor("internal_response.results", {
      header: "Reason",
      cell: (cell) => <OrbOrderErrors results={cell.getValue() as OpenEOInternalResult[]} />,
    }),
  ]

  function handleChangePage(action: string) {
    setPagination({ ...pagination, cursor: orders?.cursors[action] })
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <DataTable
        data={orders?.results || []}
        columns={columns}
        size="sm"
        boxShadow="0 0 0 1px var(--chakra-colors-gray-200)"
        rounded="base"
        noDataComponent={<NoDataComponent isError={isError} />}
      />
      <Paginator action={handleChangePage} cursors={orders?.cursors} />
    </>
  )
}

function NoDataComponent(props: { isError: boolean }) {
  const { isError } = props
  const color = isError ? "red.500" : "gray.400"
  const text = isError ? "There was a problem fetching orders list" : "No orders found"

  return (
    <Flex w="full" p={4} justifyContent="center" fontWeight="semibold" color={color}>
      {text}
    </Flex>
  )
}
