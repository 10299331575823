import JSZip from "jszip"
import { Feature as OLFeature } from "ol"
import { GeoJSON, KML } from "ol/format"
import { Geometry } from "ol/geom"
import { Projection, get } from "ol/proj"
import { openShp } from "shapefile"
import shp from "shpjs"

import { WEB_MERCATOR_PROJECTION } from "components/common/open-layers/utils"

import { OnError } from "./types"

export default function fileToGeoJson(file: File, onDone: OnDone, onError: OnError) {
  const fileExtension = file.name.substring(file.name.indexOf(".") + 1).toLowerCase()
  const fileReader = new FileReader()

  fileReader.onload = async function onFileReaderLoad() {
    if (!fileReader.result) {
      onError(null, "There was a problem importing the file")
      return
    }

    let features
    switch (fileExtension) {
      case "geojson":
      case "json":
        features = handleJSON(fileReader.result)
        break
      case "shp":
        features = await handleSHP(fileReader.result)
        break
      case "zip":
      case "shp.zip":
        features = await handleZip(fileReader.result)
        break
      case "kml":
        features = handleKML(fileReader.result)
        break
      case "kmz":
        features = await handleKMZ(fileReader.result, onError)
        break
      default:
        return null
    }

    features && onDone(features)
  }

  switch (fileExtension) {
    case "geojson":
    case "json":
    case "kml":
      fileReader.readAsText(file)
      break
    case "kmz":
    case "shp":
    case "shp.zip":
    case "zip":
      fileReader.readAsArrayBuffer(file)
      break
    default:
      return null
  }
}

function handleJSON(result: Result) {
  /*
    The coordinate reference system for all GeoJSON coordinates is a
    geographic coordinate reference system, using the World Geodetic
    System 1984 (WGS 84) [WGS84]
    (EPSG:4326 for 2D coordinate reference system (CRS))
  */
  return new GeoJSON().readFeatures(result, {
    featureProjection: get(WEB_MERCATOR_PROJECTION) as Projection,
  })
}

async function handleSHP(result: Result) {
  const shapeSource = await openShp(result)
  const shapeResult = await shapeSource.read()

  return new GeoJSON().readFeatures(shapeResult.value, {
    featureProjection: get(WEB_MERCATOR_PROJECTION) as Projection,
  })
}

async function handleZip(result: Result) {
  const shapeResult = await shp(result)
  return new GeoJSON().readFeatures(shapeResult, {
    featureProjection: get(WEB_MERCATOR_PROJECTION) as Projection,
  })
}

function handleKML(result: Result) {
  return new KML().readFeatures(result, {
    featureProjection: get(WEB_MERCATOR_PROJECTION) as Projection,
  })
}

async function handleKMZ(result: Result, onError: OnError) {
  let getExtension = (fileName: string) => fileName.split(".").pop()

  async function getKmlDom(kmzFile: Result) {
    const zip = await new JSZip().loadAsync(kmzFile)
    let kml: Promise<string> | null = null
    zip.forEach((relPath, file) => {
      if (getExtension(relPath) === "kml" && kml === null) {
        kml = file
          .async("string")
          .then((result) => result)
          .catch((e) => {
            throw new Error("Error during decompression process")
          })
      }
    })
    return kml || Promise.reject("No kml file found")
  }

  return await getKmlDom(result)
    .then((kmlResult) => handleKML(kmlResult))
    .catch((error) => onError(error, "Failed importing KMZ file"))
}

interface OnDone {
  (features: OLFeature<Geometry>[]): void
}

type Result = string | ArrayBuffer
