import { CloseIcon } from "@chakra-ui/icons"
import { Icon, Input, InputGroup, InputLeftElement, InputRightElement, useBoolean } from "@chakra-ui/react"
import { ChangeEvent, useEffect, useState } from "react"
import { FaSearch } from "react-icons/fa"

import useDebounce from "hooks/useDebounce"

export default function DrawerSearch(props: DrawerSearchProps) {
  const { onSearchTermChange } = props
  const [value, setValue] = useState<string>("")
  const [isFocused, setIsFocused] = useBoolean(false)
  const handleSearchTermChange = useDebounce(onSearchTermChange, 500)

  useEffect(() => handleSearchTermChange(value), [value, handleSearchTermChange])

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const {
      target: { value },
    } = event

    setValue(value)
  }

  function handleClearInput() {
    setValue("")
  }

  return (
    <InputGroup size="sm" data-id="workflow-configurator__drawer-search">
      <InputLeftElement
        pointerEvents="none"
        color={isFocused ? "blue.500" : "gray.400"}
        children={<Icon as={FaSearch} />}
      />
      <Input
        rounded="md"
        borderColor="primary.900"
        data-testid="search-toolbox"
        placeholder={`Search for elements…`}
        value={value}
        onChange={handleInputChange}
        onFocus={setIsFocused.on}
        onBlur={setIsFocused.off}
      />
      {value.length && (
        <InputRightElement
          color={isFocused ? "blue.500" : "gray.400"}
          children={<CloseIcon fontSize="2xs" />}
          onClick={handleClearInput}
          sx={{ _hover: { cursor: "pointer" } }}
        />
      )}
    </InputGroup>
  )
}

type DrawerSearchProps = {
  onSearchTermChange: (searchTerm: string) => void
}
