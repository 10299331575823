import { Alert, AlertDescription, AlertIcon, Button, Flex, VStack } from "@chakra-ui/react"
import useGetOrbPricingPlan from "api/useGetOrbPricingPlan"
import { FaCashRegister } from "react-icons/fa"
import { IoPricetagsOutline } from "react-icons/io5"

import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"
import Section from "components/layout/section/Section"
import SectionHeader from "components/layout/section/SectionHeader"
import { Error } from "services/types"

import AvailablePricingPlans from "./AvailablePricingPlans"
import PricingPlan from "./PricingPlan"

export default function OrbBillingConfiguration() {
  return (
    <Section
      header={
        <SectionHeader
          size="sm"
          title="Application billing"
          description="Configure how your application users should be billed for usage"
          icon={FaCashRegister}
        />
      }
    >
      <Flex w="full" my={6} justifyContent="center">
        <PricingPlans />
      </Flex>
    </Section>
  )
}

function PricingPlans() {
  const { isLoading, isError, error, pricingPlan } = useGetOrbPricingPlan()

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isError && (error as Error).response?.status !== 404) {
    return (
      <Flex>
        <Alert status="error" rounded="md">
          <AlertIcon />
          <AlertDescription>Failed to fetch pricing plan information</AlertDescription>
        </Alert>
      </Flex>
    )
  }

  return (
    <VStack w="full" mb={8} spacing={3}>
      {pricingPlan && <PricingPlan plan={pricingPlan} onSelected={() => {}} isCurrent />}
      <AvailablePricingPlans currentPricingPlan={pricingPlan}>
        {({ onOpen }) => (
          <Flex w="full" maxW="2xl">
            <Button size="sm" mr="auto" leftIcon={<IoPricetagsOutline />} onClick={onOpen}>
              {pricingPlan ? "Change pricing plan" : "Assign pricing plan to the application"}
            </Button>
          </Flex>
        )}
      </AvailablePricingPlans>
    </VStack>
  )
}
