export const gnuplot = [
  "#000000",
  "#44006D",
  "#6001C6",
  "#7603F9",
  "#8806F8",
  "#980CC8",
  "#A71470",
  "#B52000",
  "#C13000",
  "#CC4400",
  "#D75D00",
  "#E27B00",
  "#ECA200",
  "#F6CD00",
  "#FFFF00",
]
