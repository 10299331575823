import { Flex, Icon, Text, Tooltip } from "@chakra-ui/react"
import { FiMinimize2 } from "react-icons/fi"
import { IoStatsChartSharp } from "react-icons/io5"

export default function WorkflowChartsToggleButton(props: WorkflowChartsToggleButtonProps) {
  const { isExpanded, onToggle } = props

  return (
    <Tooltip label={`${isExpanded ? "Hide" : "Show"} charts`}>
      <Flex
        ms="auto"
        justifyContent="center"
        alignItems="center"
        cursor="pointer"
        color="primary.700"
        onClick={onToggle}
        p={3}
      >
        <Icon as={isExpanded ? FiMinimize2 : IoStatsChartSharp} boxSize={5} />
        {!isExpanded && (
          <Text fontWeight="semibold" ms={2}>
            Show charts
          </Text>
        )}
      </Flex>
    </Tooltip>
  )
}

type WorkflowChartsToggleButtonProps = {
  isExpanded: boolean
  onToggle: () => void
}
