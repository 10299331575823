import { SafeConfirmModal } from "components/common/safe-confirm-modal"
import ModalAdditionalInfo from "components/orb/settings/template/ModalAdditionalInfo"

function TemplateSelectConfirmModal(props: TemplateSelectConfirmModalProps) {
  const { handleCancel, handleConfirm, confirmModalDisclosure } = props

  return (
    <SafeConfirmModal
      onConfirm={handleConfirm}
      onConfirmColorScheme="brand"
      onCancel={handleCancel}
      header="Confirm template change"
      textConfirmation=""
      modalDisclosure={confirmModalDisclosure}
      requireConfirmation={false}
      actionLabel="Change template"
      additionalInfo={<ModalAdditionalInfo />}
    />
  )
}

type TemplateSelectConfirmModalProps = {
  handleCancel: () => void
  handleConfirm: () => void
  confirmModalDisclosure: {
    isOpen: boolean
    onOpen: () => void
    onClose: () => void
  }
}

export default TemplateSelectConfirmModal
