import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react"
import { BsChatSquareQuote } from "react-icons/bs"

import FeedbackForm from "components/feedback/FeedbackForm"
import SidebarItem from "components/layout/sidebar/SidebarItem"

export default function FeedbackButton() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <SidebarItem as="a" icon={BsChatSquareQuote} tooltip="Send us feedback!" onClick={onOpen}>
        Share feedback
      </SidebarItem>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="md">Submit your feedback or idea</ModalHeader>
          <ModalCloseButton size="sm" />
          <ModalBody>
            <FeedbackForm onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
