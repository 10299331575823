import { Input } from "@chakra-ui/react"
import { useRef } from "react"

import useGenericAOIHiddenInputController from "./GenericAOIHiddenInputController"
import { OnDone } from "./types"

export const AOI_HIDDEN_IMPORT_INPUT_ID = "aoi-hidden-import-input"

export default function GenericAOIHiddenInput(props: GenericAOIHiddenInputProps) {
  const { onDone } = props
  const { fileUploadHandler } = useGenericAOIHiddenInputController(onDone)

  const aoiFileInputRef = useRef<HTMLInputElement>(null)

  return (
    <Input
      hidden
      type="file"
      id={AOI_HIDDEN_IMPORT_INPUT_ID}
      ref={aoiFileInputRef}
      onChange={fileUploadHandler}
      accept={".geojson, .json, .kml, .kmz, .shp, .zip"}
    />
  )
}

interface GenericAOIHiddenInputProps {
  onDone: OnDone
}
