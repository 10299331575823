import { Table } from "@chakra-ui/react"
import { ReactNode } from "react"

export default function PricingTableLayout(props: { children: ReactNode; variant: string }) {
  const { children, variant } = props

  return (
    <Table minWidth="800px" variant={variant} style={{ tableLayout: "fixed" }}>
      <colgroup>
        <col style={{ width: "24%" }} />
        <col style={{ width: "19%" }} />
        <col style={{ width: "19%" }} />
        <col style={{ width: "19%" }} />
        <col style={{ width: "19%" }} />
      </colgroup>

      {children}
    </Table>
  )
}
