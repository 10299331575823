export const Set1 = [
  "#E41A1C",
  "#377EB8",
  "#377EB8",
  "#4DAF4A",
  "#4DAF4A",
  "#984EA3",
  "#984EA3",
  "#FF7F00",
  "#FFFF33",
  "#FFFF33",
  "#A65628",
  "#A65628",
  "#F781BF",
  "#F781BF",
  "#999999",
]
