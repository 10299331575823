import { Flex } from "@chakra-ui/react"
import { useRef, useState } from "react"

import { OpenEOEditorContext } from "components/openeo-editor/OpenEOEditorContext"
import OpenEOEditorDrawer from "components/openeo-editor/drawer/OpenEOEditorDrawer"
import { useOpenEOEditorDrawerState } from "components/openeo-editor/drawer/atoms"
import FlowEditor from "components/openeo-editor/flow/FlowEditor"
import MetadataModal from "components/openeo-editor/metadata/MetadataModal"
import { MetadataModalContextProvider } from "components/openeo-editor/metadata/MetadataModalContext"

function OpenEOEditor() {
  const [flowInstance, setFlowInstance] = useState(null)
  const { isDrawerOpen, onDrawerClose } = useOpenEOEditorDrawerState()
  const editorWrapper = useRef(null)

  const onLoad = (instance) => {
    setFlowInstance(instance)
    instance.fitView({
      padding: 0.2,
    })
  }

  const onClick = () => {
    if (isDrawerOpen) {
      onDrawerClose()
    }
  }

  return (
    <OpenEOEditorContext.Provider value={{ flowInstance, onLoad, editorWrapper }}>
      <MetadataModalContextProvider>
        <OpenEOEditorDrawer />
        <MetadataModal />

        <Flex
          w="full"
          h="full"
          overflow="hidden"
          ref={editorWrapper}
          onClick={onClick}
          data-id="workflow-configurator__editor"
        >
          <FlowEditor />
        </Flex>
      </MetadataModalContextProvider>
    </OpenEOEditorContext.Provider>
  )
}

export default OpenEOEditor
