import { SearchIcon } from "@chakra-ui/icons"
import { Flex, Input, InputGroup, InputLeftElement, Select, chakra } from "@chakra-ui/react"

export default function FilterAndSortOrbs(props: FilterAndSortOrbsProps) {
  const {
    handleSearch,
    searchString,
    setSearchString,
    setSortOption,
    sortOption,
    mb = 0,
    searchPlaceholder = "Try filtering orbs...",
    sortPlaceholder = "Try sorting orbs",
  } = props

  return (
    <chakra.form onSubmit={handleSearch}>
      <Flex w="full" justifyContent="flex-start" alignItems="left" mb={mb}>
        <InputGroup size="sm" width="220px">
          <InputLeftElement size="sm" pointerEvents="none" children={<SearchIcon color="gray.300" />} />
          <Input
            mr={3}
            autoFocus
            size="sm"
            rounded="md"
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            placeholder={searchPlaceholder}
          />
        </InputGroup>
        <Select
          w="auto"
          size="sm"
          rounded="md"
          onChange={(e) => setSortOption(e.target.value)}
          placeholder={sortPlaceholder}
          value={sortOption}
        >
          <option value="name-asc">A-z</option>
          <option value="name-desc">z-A</option>
          <option value="created_at-desc">Newest first</option>
          <option value="created_at-asc">Oldest first</option>
        </Select>
      </Flex>
    </chakra.form>
  )
}

type FilterAndSortOrbsProps = {
  handleSearch: any
  searchString: string
  sortOption: string
  setSearchString: React.Dispatch<React.SetStateAction<string>>
  setSortOption: React.Dispatch<React.SetStateAction<string>>
  searchPlaceholder?: string
  sortPlaceholder?: string
  mb?: number
}
