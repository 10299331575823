import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import { motion } from "framer-motion"

const MotionHeading = motion(Heading)

const defaultNotFoundDescription = "We couldn't find the page you were looking for."

export default function NotFound(props: NotFoundProps) {
  const { customDescription = "Sorry. Something when wrong.", error = null } = props

  const is404Error = error?.response?.status === 404
  const description = is404Error ? defaultNotFoundDescription : customDescription

  return (
    <Flex w="full" h="100vh" justifyContent="center" alignItems="center" flexDirection="column" bgColor="gray.50">
      <WigglingSadSmileyFace />

      <Box w="sm" color="gray.300" textAlign={"center"}>
        <Heading size="xl">Ooops!</Heading>
        <Text>{description}</Text>
        {!is404Error && error && (
          <Text>{error?.message?.description || error?.message || (typeof error === "string" && error)}</Text>
        )}
      </Box>
    </Flex>
  )
}

function WigglingSadSmileyFace() {
  return (
    <MotionHeading
      size="xl"
      pb={5}
      animate={{
        rotate: [105, 75, 75, 105],
      }}
      transition={{
        duration: 1,
        ease: "easeInOut",
        times: [0, 0.2, 0.5, 0.8, 1],
        repeat: Infinity,
        repeatType: "loop",
        repeatDelay: 1,
      }}
    >
      :-(
    </MotionHeading>
  )
}

type NotFoundProps = {
  customDescription?: string
  error?: any
}
