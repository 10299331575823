import { useQuery } from "react-query"

import useOrbExtentsQueries from "./queryFunctions/useOrbExtentsQueries"

export default function useGetOrbExtents() {
  const { listExtentsQuery } = useOrbExtentsQueries()
  const { queryKey, queryFn } = listExtentsQuery()

  const { isLoading, isError, data: extents } = useQuery(queryKey, queryFn)

  return { isLoading, isError, extents }
}
