import { useMutation } from "react-query"

import useOrganisationsQueries from "./queryFunctions/useOrganisationsQueries"

export default function useSwitchOrganisation() {
  const { switchOrganisationMutation } = useOrganisationsQueries()
  const { mutationFn, mutationOptions } = switchOrganisationMutation()

  const { isLoading, mutateAsync: switchOrganisation } = useMutation(mutationFn, mutationOptions)

  return { isLoading, switchOrganisation }
}
