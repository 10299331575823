export const YlGnBu = [
  "#FFFFD9",
  "#F5FBC2",
  "#E8F6B1",
  "#D1EDB3",
  "#B2E1B6",
  "#89D1BA",
  "#65C3BF",
  "#40B5C4",
  "#2CA1C2",
  "#1E8ABD",
  "#216DAF",
  "#2352A3",
  "#253997",
  "#182A7A",
  "#081D58",
]
