import FlowToProcessGraphBuilder from "components/openeo-editor/lib/process-graph-builder/FlowToProcessGraphBuilder"
import ProcessGraphToFlowBuilder from "components/openeo-editor/lib/process-graph-builder/ProcessGraphToFlowBuilder"

function processGraphToFlow(graph, getCollectionById, getProcessById) {
  const builder = new ProcessGraphToFlowBuilder(graph, getCollectionById, getProcessById)
  return builder.elements
}

function flowToProcessGraph(nodes, edges) {
  const builder = new FlowToProcessGraphBuilder(nodes, edges)
  return builder.processGraph
}

export { processGraphToFlow, flowToProcessGraph }
