import { Icon, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Tooltip } from "@chakra-ui/react"
import { User } from "api/models/User"
import { BiDotsVertical } from "react-icons/bi"
import { RiRoadMapLine, RiUserSettingsLine } from "react-icons/ri"
import { VscRefresh, VscTrash } from "react-icons/vsc"

import useDeleteOrbUser from "../../../api/useDeleteOrbUser"
import { useNotifications } from "../notifications"
import UserExtentsEditor from "./UserExtentsEditor"
import UserRolesEditor from "./UserRolesEditor"
import useInvitationSender from "./invitationSender"

export default function UserActionsMenu(props: { user: User; orbId: string | number }) {
  const { orbId, user } = props
  const { onSubmitInvitation } = useInvitationSender()
  const { showNotification } = useNotifications()
  const { isLoading, deleteUser } = useDeleteOrbUser()

  function handleResendInvitation() {
    onSubmitInvitation({ email: user.email, orbId })
  }

  async function handleRemoveUser() {
    await deleteUser(user.id)
    showNotification("User removal", "Successfully removed user", "success")
  }

  return (
    <Menu>
      <Tooltip label="Show actions">
        <MenuButton>
          <Icon as={BiDotsVertical} />
        </MenuButton>
      </Tooltip>
      <MenuList>
        <UserRolesEditor user={user}>
          {({ onOpen }) => (
            <MenuItem icon={<RiUserSettingsLine />} onClick={onOpen}>
              Edit roles
            </MenuItem>
          )}
        </UserRolesEditor>
        <UserExtentsEditor user={user}>
          {({ onOpen }) => (
            <MenuItem icon={<RiRoadMapLine />} onClick={onOpen}>
              Edit spatial access
            </MenuItem>
          )}
        </UserExtentsEditor>
        <MenuDivider />
        <MenuItem icon={<VscRefresh />} isDisabled={user.activated} onClick={handleResendInvitation}>
          Re-send invitation
        </MenuItem>
        <MenuItem icon={<VscTrash />} onClick={handleRemoveUser} color="red" isDisabled={isLoading}>
          Remove user
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
