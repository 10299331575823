import { useQuery } from "react-query"

import useBillingQueries from "./queryFunctions/useBillingQueries"

export default function useGetCurrentPricingPlan() {
  const { getUserSubscriptionQuery } = useBillingQueries()
  const { queryKey, queryFn } = getUserSubscriptionQuery()
  const { data, isLoading, isError } = useQuery(queryKey, queryFn)

  return {
    isError,
    isLoading,
    assignedPricingPlan: data?.pricing_plan,
    subscription: data?.subscription,
  }
}
