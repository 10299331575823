import { Badge, Flex, Text } from "@chakra-ui/react"
import PropTypes from "prop-types"

import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"
import Markdown from "components/markdown/Markdown"
import useCollectionDetails from "components/openeo-editor/lib/useCollectionDetails"
import { parseFilterItem } from "components/openeo-editor/parameters/controls/types/metadata-filter/utils"
import { useParameterEntryContext } from "components/openeo-editor/parameters/editor/ParameterEntryContext"

export default function MetadataFilterItem(props) {
  const { filterItem } = props
  const { propertyName, operator, propertyValue, isError, error } = parseFilterItem(filterItem)

  const { isLoading, collection } = useParameterEntryContext()
  const { isLoading: isPropertiesLoading, properties } = useCollectionDetails(collection && collection["db:id"])

  const property = properties?.find((property) => property.name === propertyName) || { name: propertyName }

  if (isError) {
    return <Flex>{error}</Flex>
  }

  if (isLoading || isPropertiesLoading) {
    return <LoadingSpinner />
  }

  return (
    <Flex w="full" justifyContent="space-between" alignItems="center">
      <Flex w="40%" flexDirection="column">
        <Text fontWeight="semibold">{property.name}</Text>
        <Markdown fontSize="2xs" color="gray.400">
          {property.description}
        </Markdown>
      </Flex>
      <Flex w="20%" justifyContent="center">
        <Badge fontFamily="monospace" textTransform="none">
          {operator}
        </Badge>
      </Flex>
      <Flex w="40%" fontFamily="monospace" justifyContent="center">
        {propertyValue}
      </Flex>
    </Flex>
  )
}

MetadataFilterItem.propTypes = {
  filterItem: PropTypes.array.isRequired,
}
