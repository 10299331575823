import { Route, Switch, useRouteMatch } from "react-router-dom"

import MyAccountSettings from "components/account/MyAccountSettings"
import PlatformAccessControl from "components/account/access-control/PlatformAccessControl"
import PlatformUsage from "components/account/usage/PlatformUsage"
import NotFound from "components/not-found/NotFound"
import BillingPage from "components/payments/BillingPage"

const MyAccount = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path} component={MyAccountSettings} />
      <Route path={`${path}/billing`} component={BillingPage} />
      <Route path={`${path}/access-control`} component={PlatformAccessControl} />
      <Route exact path={`${path}/usage`} component={PlatformUsage} />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}

export default MyAccount
