import { Flex, List, ListItem, Text, Tooltip, chakra } from "@chakra-ui/react"

import { EnrichedOpeneoEvent } from "components/orb-openeo-workflow/types"

import { useOpeneoEventsContext } from "./OpeneoEventsContext"

function OpeneoWorkflowConsole() {
  const { events } = useOpeneoEventsContext()
  return (
    <List w="full" spacing={1} fontSize="2xs" px={2}>
      {events.map((event) => (
        <OpeneoWorkflowConsoleEntry key={event.id} {...event} />
      ))}
    </List>
  )
}

function OpeneoWorkflowConsoleEntry(props: EnrichedOpeneoEvent) {
  const { type, date, content, hint, action } = props

  let color = ""
  switch (type) {
    case "critical":
      color = "red.600"
      break
    case "error":
      color = "red.500"
      break
    case "success":
      color = "green.500"
      break
    case "warning":
      color = "orange.500"
      break
    case "info":
      color = "gray.500"
      break
    default:
      color = ""
  }

  if (type === "spacer") {
    return <ListItem my={5} />
  }

  return (
    <ListItem color={color} alignItems="center" justifyContent="center">
      <Tooltip label={hint} placement="left-start">
        <Flex w="full" alignItems="center">
          <chakra.span mr={3} fontWeight="300">
            {date.format("HH:mm:ss")}
          </chakra.span>
          <Text w="full">{content}</Text>
          {action}
        </Flex>
      </Tooltip>
    </ListItem>
  )
}

export default OpeneoWorkflowConsole
