import { useMutation } from "react-query"

import useOrbExtentsQueries from "./queryFunctions/useOrbExtentsQueries"

export default function useCreateOrbExtent() {
  const { createExtentMutation } = useOrbExtentsQueries()
  const { mutationFn, mutationOptions } = createExtentMutation()

  const { isLoading, mutateAsync: createExtent } = useMutation(mutationFn, mutationOptions)

  return { isLoading, createExtent }
}
