import { Badge, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { useQuery } from "react-query"

import useOrbUsersApi from "services/api/orbUsers"
import { FEATURES, useUserFeatureFlags } from "services/feature-flags"

import UsersList from "./UsersList"
import WSWUsersList from "./WSWUsersList"

const mockedInvitationsAmount = [3, 5, 7]

export default function AccessControlTabs(props) {
  const { id, allowAllColumns } = props
  const { countInvitations } = useOrbUsersApi()
  const { isLoading, data: invitations } = useQuery(["countOrbInvitations", id], () => countInvitations(id))

  const { isFeatureEnabled } = useUserFeatureFlags()

  const isWhoSeesWhatDesignEnabled = isFeatureEnabled(FEATURES.WHO_SEES_WHAT_DESIGN)
  const DisplayUsersList = isWhoSeesWhatDesignEnabled ? WSWUsersList : UsersList

  return (
    <Tabs my={10} size="sm" colorScheme="primary" isLazy={true}>
      <TabList>
        <Tab>All users</Tab>
        <Tab>
          Invited
          {isLoading ? (
            <Spinner ml={1} color="gray.400" size="xs" />
          ) : (
            <Badge ml={1} fontSize="0.8em" colorScheme="gray">
              {isWhoSeesWhatDesignEnabled ? mockedInvitationsAmount[id % 3] : invitations}
            </Badge>
          )}
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel px={0}>
          <DisplayUsersList key="all_users" id={id} allowAllColumns={allowAllColumns} />
        </TabPanel>
        <TabPanel px={0}>
          <DisplayUsersList key="invited_users" id={id} allowAllColumns={allowAllColumns} invitesOnly />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

AccessControlTabs.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  allowAllColumns: PropTypes.bool,
}
