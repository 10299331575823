import { useMutation } from "react-query"

import useOrbBillingQueries from "./queryFunctions/useOrbBillingQueries"

export default function useAssignPricingPlanToOrb() {
  const { assignOrbPricingPlanMutation } = useOrbBillingQueries()
  const { mutationFn, mutationOptions } = assignOrbPricingPlanMutation()

  const { isLoading, isError, error, mutateAsync: assignPricingPlan } = useMutation(mutationFn, mutationOptions)

  return { isLoading, isError, error, assignPricingPlan }
}
