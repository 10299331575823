import { Flex, Image, Tag, TagLeftIcon, Text, useColorModeValue } from "@chakra-ui/react"
import { NominatimSearchResult } from "api/models/Nominatim"

export default function SearchResultItem({ searchResult, onClick }: SearchResultItemProps) {
  const hoverStyle = useColorModeValue({ bgColor: "blackAlpha.100" }, { bgColor: "whiteAlpha.100" })

  const iconFilter = useColorModeValue("", "invert(1)")

  function handleLocationSelect() {
    onClick(searchResult)
  }

  function LocationTag() {
    if (!searchResult.type) {
      return null
    }

    return (
      <Tag fontSize="xs" rounded="md" colorScheme="blue">
        {searchResult.icon && <TagLeftIcon as={Image} src={searchResult.icon} boxSize="15px" filter={iconFilter} />}
        {searchResult.type.replaceAll("_", " ")}
      </Tag>
    )
  }

  return (
    <Flex
      px={3}
      py={2}
      fontSize="sm"
      justifyContent="space-between"
      alignItems="center"
      cursor="pointer"
      onClick={handleLocationSelect}
      _hover={hoverStyle}
    >
      <Text w="full">{searchResult.display_name}</Text>
      <Flex w="25%" justifyContent="flex-end">
        <LocationTag />
      </Flex>
    </Flex>
  )
}

type SearchResultItemProps = {
  searchResult: NominatimSearchResult
  onClick: (location: any) => void
}
