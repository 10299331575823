import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react"
import { useField } from "formik"
import { VscCalendar } from "react-icons/vsc"

import MonthInput from "components/common/month-input/MonthInput"
import { parameterType } from "components/openeo-editor/lib/propTypes"

function StringMonthType(props) {
  const { parameter } = props
  const [field, , { setValue }] = useField(parameter.name)

  return (
    <MonthInput value={field.value} setValue={setValue}>
      <InputGroup size="xs">
        <InputLeftElement children={<VscCalendar />} />
        <Input {...field} readOnly={true} />
      </InputGroup>
    </MonthInput>
  )
}

StringMonthType.propTypes = {
  parameter: parameterType.isRequired,
}

export { StringMonthType }
