import { useMutation } from "react-query"

import useAdminCustomProcessesQueries from "./queryFunctions/useAdminCustomProcessesQueries"

export default function useAdminRefreshCustomProcess() {
  const { refreshCustomProcessesMutation } = useAdminCustomProcessesQueries()
  const { mutationFn, mutationOptions } = refreshCustomProcessesMutation()

  const { isLoading, mutateAsync: refreshCustomProcess } = useMutation(mutationFn, mutationOptions)

  return { isLoading, refreshCustomProcess }
}
