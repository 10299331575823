import { Badge, Flex, Tag, VStack } from "@chakra-ui/react"
import { CellContext, createColumnHelper } from "@tanstack/react-table"
import { Extent } from "api/models/AccessControl"
import { PaginationOptions } from "api/models/PaginatedResponse"
import { User, UserRole } from "api/models/User"
import useGetAllowedBIllingFeatures from "api/useGetAllowedBIllingFeatures"
import useGetOrbUsers from "api/useGetOrbUsers"
import { useState } from "react"
import { VscCheck } from "react-icons/vsc"

import { DataTable } from "components/common/data-table/DataTable"
import LoadingSpinner from "components/common/loading-spinner/LoadingSpinner"
import Paginator from "components/common/paginator/Paginator"

import UserActionsMenu from "./UserActionsMenu"

export default function UsersList(props: UsersListProps) {
  const { id: orbId, invitesOnly = false } = props
  const [pagination, setPagination] = useState<PaginationOptions>({ cursor: null, limit: 10, desc: true })
  const { isLoading, users } = useGetOrbUsers(orbId, invitesOnly, pagination)
  const { allowedToManageAppUsers } = useGetAllowedBIllingFeatures()

  const columnHelper = createColumnHelper<User>()

  function handleChangePage(action: string) {
    setPagination({ ...pagination, cursor: users?.cursors[action] })
  }

  const columns = [
    columnHelper.accessor("email", { header: "E-mail" }),
    columnHelper.accessor("activated", {
      header: "Activated",
      cell: getActivatedCell,
    }),
    columnHelper.accessor("invited", {
      header: "Invited",
      cell: getInvitedCell,
    }),
    columnHelper.accessor("roles", {
      header: "Roles",
      cell: getRolesCell,
    }),
    columnHelper.accessor("extents", {
      header: "Extent restrictions",
      cell: getExtentsCell,
    }),
    columnHelper.accessor("id", {
      header: "",
      meta: { isNumeric: true },
      enableSorting: false,
      cell: getIdCell,
    }),
  ]

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <DataTable
        data={users?.results || []}
        columns={columns}
        size="sm"
        boxShadow="0 0 0 1px var(--chakra-colors-gray-200)"
        rounded="base"
        noDataComponent={
          <Flex w="full" p={4} justifyContent="center" fontWeight="semibold" color="gray.400">
            No users found
          </Flex>
        }
      />
      <Paginator action={handleChangePage} cursors={users?.cursors} />
    </>
  )

  function getInvitedCell(cell: CellContext<User, boolean | undefined>) {
    if (!cell.getValue()) {
      return null
    }

    return <Badge colorScheme="green" fontSize="lg" as={VscCheck} />
  }

  function getActivatedCell(cell: CellContext<User, boolean | undefined>) {
    if (cell.getValue()) {
      return null
    }

    return (
      <Badge colorScheme="gray" fontSize="xs">
        Inactive
      </Badge>
    )
  }

  function getRolesCell(cell: CellContext<User, UserRole[] | undefined>) {
    return (
      <VStack w="full" alignItems="flex-start">
        {cell.row.original.roles?.map((role) => (
          <Tag key={role.name} size="xs" fontSize="xs" p="2px" rounded="base" colorScheme="primary">
            {role.description}
          </Tag>
        ))}
      </VStack>
    )
  }

  function getExtentsCell(cell: CellContext<User, Extent[] | undefined>) {
    return (
      <VStack w="full" alignItems="flex-start">
        {cell.row.original.extents?.map((extent) => (
          <Tag key={extent.id} size="xs" fontSize="xs" p="2px" rounded="base" colorScheme="red">
            {extent.name}
          </Tag>
        ))}
      </VStack>
    )
  }

  function getIdCell(cell: CellContext<User, number>) {
    return allowedToManageAppUsers && <UserActionsMenu user={cell.row.original} orbId={orbId} />
  }
}

type UsersListProps = {
  id: string | number
  invitesOnly?: boolean
}
