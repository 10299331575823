export const autumn = [
  "#FF0000",
  "#FF1200",
  "#FF2400",
  "#FF3700",
  "#FF4900",
  "#FF5B00",
  "#FF6D00",
  "#FF8000",
  "#FF9200",
  "#FFA400",
  "#FFB600",
  "#FFC800",
  "#FFDB00",
  "#FFED00",
  "#FFFF00",
]
