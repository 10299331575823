import { Button, Flex, Heading, LinkBox, LinkOverlay, Text } from "@chakra-ui/react"
import { OrbConfig } from "api/models/OrbConfig"
import TagManager from "react-gtm-module"
import { VscLinkExternal, VscSettingsGear } from "react-icons/vsc"
import { NavLink } from "react-router-dom"

import OrbCardMenu from "components/orb/OrbCardMenu"
import OrbLogo from "components/orb/orb-logo/OrbLogo"

export default function OrbCard({ orb }: OrbCardProps) {
  const orbConfigurationHref = `/orbs/view/${orb.id}`

  return (
    <Flex
      p={3}
      flexDirection="column"
      flexGrow={0}
      bgColor="white"
      rounded="lg"
      shadow="sm"
      justifyContent="space-between"
    >
      <LinkBox as={Flex} variant="ghost" w="full">
        <OrbLogo orb={orb} size="md" me={4} />

        <Flex flexDirection="column">
          <Heading fontSize="md" fontWeight="semibold" color="brand.700">
            <LinkOverlay as={NavLink} to={orbConfigurationHref}>
              {orb.name}
            </LinkOverlay>
          </Heading>
          <Text color="gray.400" fontSize="sm" mt={2} noOfLines={2}>
            {orb.description}
          </Text>
        </Flex>
      </LinkBox>

      <Flex w="full" mt={6} justifyContent="space-between">
        <ViewOrbAsUserButton orb={orb} />
        <ConfigureOrbButton href={orbConfigurationHref} />
        <OrbCardMenu orbId={orb.id} />
      </Flex>
    </Flex>
  )
}

function ViewOrbAsUserButton(props: { orb: OrbConfig }) {
  const { orb } = props
  const href = `https://${orb.url}.${process.env.REACT_APP_BASE_DOMAIN}/`

  function handleAnalytics() {
    const event = "visit_application_click"

    const tagManagerArgs = {
      dataLayer: {
        event,
        [event]: {
          orb_name: orb.url,
          orb_id: orb?.id && Number(orb.id),
          from: "Orb Card",
        },
      },
    }

    TagManager.dataLayer(tagManagerArgs)
  }

  return (
    <Button
      as="a"
      href={href}
      target="_blank"
      variant="ghost"
      colorScheme="gray"
      size="xs"
      leftIcon={<VscLinkExternal />}
      disabled={!orb.url}
      onClick={handleAnalytics}
    >
      View as user
    </Button>
  )
}

function ConfigureOrbButton(props: { href: string }) {
  return (
    <Button as={NavLink} to={props.href} variant="ghost" colorScheme="gray" size="xs" leftIcon={<VscSettingsGear />}>
      Configuration
    </Button>
  )
}

type OrbCardProps = {
  orb: OrbConfig
}
