import { useFormikContext } from "formik"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import useTemplatesApi from "services/api/templatesApi"

function useModalAdditionalInfoController() {
  const { id: orbId } = useParams()
  const { values } = useFormikContext()

  const { listTemplates } = useTemplatesApi()
  const { data: templates, isLoading, isError } = useQuery(["listTemplates", orbId], () => listTemplates(orbId))

  const template = templates?.find((template) => template.id === values.template)

  return {
    isLoading,
    isError,
    template,
  }
}

export default useModalAdditionalInfoController
