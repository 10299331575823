import { useDisclosure } from "@chakra-ui/react"
import { CustomProcess } from "api/models/CustomProcess"
import useAdminUpdateCustomProcess from "api/useAdminUpdateCustomProcess"
import { ReactNode } from "react"

import CustomProcessEditor from "./CustomProcessEditor"

export function CustomProcessEditAction({ customProcess, children }: Props) {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { isLoading, updateCustomProcess } = useAdminUpdateCustomProcess()

  return (
    <>
      {children({ onOpen })}

      <CustomProcessEditor
        initialValues={customProcess}
        header={`Edit '${customProcess.process_id}' custom process`}
        isOpen={isOpen}
        onClose={onClose}
        isLoading={isLoading}
        handleSaveCustomProcess={updateCustomProcess}
      />
    </>
  )
}

type ChildrenProps = {
  onOpen: () => void
}

interface Props {
  customProcess: CustomProcess
  children: (props: ChildrenProps) => ReactNode
}
