import { Badge, Flex, Heading, Spacer, Text, chakra } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { memo } from "react"

import CollectionImage from "components/openeo-editor/collection/CollectionImage"
import { formatHeading, rightSplit } from "components/openeo-editor/lib/nodeUtils"
import { collectionType } from "components/openeo-editor/lib/propTypes"

function Collection(props) {
  const { label, collection } = props
  const { id, title, license } = collection || {}

  return (
    <Flex>
      <Flex me={3}>
        <CollectionImage collection={collection} />
      </Flex>
      <Flex flexDirection="column" w="full" mr={2}>
        <CollectionHeading text={label || id} />

        <chakra.p fontSize="0.875em" color="gray.700">
          {title}
        </chakra.p>
        <Spacer />
        <Flex w="full" justifyContent="flex-end">
          <Badge fontSize="2xs" fontWeight="light" colorScheme="blue">
            {license}
          </Badge>
        </Flex>
      </Flex>
    </Flex>
  )
}

Collection.propTypes = {
  label: PropTypes.string,
  collection: collectionType,
}

function CollectionHeading(props) {
  const { text } = props
  const [catalog, asset] = rightSplit(text, "/", 1)

  return (
    <Heading fontSize="xs" mb={1} wordBreak="break-all">
      {catalog && (
        <Text fontSize="2xs" fontWeight="normal" color="gray.500">
          {formatHeading(catalog)}
        </Text>
      )}
      {asset}
    </Heading>
  )
}

export default memo(Collection)
