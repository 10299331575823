import { VscSave } from "react-icons/vsc"

import { useNotifications } from "components/common/notifications"
import ToolboxButton from "components/common/toolbox-button/ToolboxButton"
import useSaveCode from "components/openeo-editor/code-editor/useSaveCode"
import { useOpeneoWorkflowContext } from "components/orb-openeo-workflow/OpeneoWorkflowContext"

export default function CodeEditorSaveButton() {
  const { legends, onSaveGraph } = useOpeneoWorkflowContext() as any
  const { showErrorNotification } = useNotifications()
  const { isSavingCode, handleSaveCode } = useSaveCode()

  async function handleClick() {
    try {
      await handleSaveCode()
      onSaveGraph(legends)
    } catch (error: any) {
      showErrorNotification(error, "Unable to save file", "")
    }
  }

  return (
    <ToolboxButton
      // @ts-ignore
      icon={VscSave}
      label="Save"
      tooltipLabel="Save code"
      onClick={handleClick}
      isLoading={isSavingCode}
      colorScheme="green"
      isWide
    />
  )
}
